import { useAsync } from "utils/use-async";
import { useCallback, useEffect } from "react";
import { cleanObject } from "utils/index";
import { useHttp } from "utils/http";
import { Job } from "../screens/job/all-jobs/list";
import { JobGeneralData } from "../types/job-general-data";
import { JobRecordAttendanceData, JobRecordData } from "../types/job-record";

export const useJobRecords = (param?: { taskUuid: string }) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{
    list: JobRecordData[];
    total: number;
  }>();

  const fetchJobs = useCallback(
    () =>
      client(`tasks/${param?.taskUuid}/records`, {
        data: cleanObject(param || {}),
      }),
    [param, client]
  );

  useEffect(() => {
    run(fetchJobs(), {
      retry: fetchJobs,
    });
  }, [param, run, fetchJobs]);

  return result;
};

export const useJobRecordAttendance = () => {
  const client = useHttp();
  const { run, ...result } = useAsync<{
    list: JobRecordAttendanceData[];
    total: number;
  }>();

  const mutate = (param?: { uuid: string; taskUuid: string }) => {
    return run(
      client(`tasks/${param?.taskUuid}/records/${param?.uuid}`, {
        method: "GET",
      })
    );
  };

  return {
    mutate,
    ...result,
  };
};

export const useEditJobAttendance = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: Partial<JobRecordAttendanceData>) => {
    return run(
      client(`tasks/attendances/${params.uuid}`, {
        data: params,
        method: "PUT",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useJobDetail = (param?: Partial<JobGeneralData>) => {
  const client = useHttp();
  const { run, ...result } = useAsync<JobGeneralData>();

  const fetchJobs = useCallback(() => client(`tasks/${param?.uuid}`, {}), [
    param,
    client,
  ]);

  useEffect(() => {
    run(fetchJobs(), {
      retry: fetchJobs,
    });
  }, [param, run, fetchJobs]);

  return result;
};

export const useAddJobRecord = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: Partial<JobRecordData>, taskUuid: string) => {
    return run(
      client(`tasks/${taskUuid}/records`, {
        data: params,
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useEditJobRecord = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (
    params: Partial<JobRecordData>,
    taskUuid: string,
    taskRecordUuid: string
  ) => {
    return run(
      client(`tasks/${taskUuid}/records/${taskRecordUuid}`, {
        data: params,
        method: "PUT",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useEditJobRecordPrice = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (
    params: Partial<JobRecordData>,
    taskUuid: string,
    taskRecordUuid: string
  ) => {
    return run(
      client(`tasks/${taskUuid}/records/${taskRecordUuid}/price`, {
        data: params,
        method: "PUT",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};
