import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popover,
  Radio,
  Row,
  Select,
  TimePicker,
  Modal,
} from "antd";
import styled from "@emotion/styled";
import { FormInstance } from "antd/es/form";
import { TopBar } from "screens/job/create-job/top-bar";
import {
  changeArrayToStringInObject,
  useDebounce,
  useDocumentTitle,
} from "utils";
import { Employer } from "screens/employer/employer-list/list";
import { Profiler } from "components/profiler";
import { HelperPositionSelect } from "components/helper-position-select";
import { useAddJob } from "utils/job";
import { useAdmin } from "utils/admin";
import { District } from "components/district";
import { AdminSelect } from "components/admin-select";
import { useSearchEmployers, useEditEmployer } from "utils/employer";
import { useHelperPositions } from "utils/helper-position";
import { Position } from "screens/helper-prices/helper-price";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../../components/layout/PageContainer";

export interface HelperRequest {
  [key: string]: number | string;
  position: string | number;
  amount: number | string;
  helperSalary: number;
}

export const CreateJobScreen = () => {
  useDocumentTitle("創建工作", false);
  const formRef = React.createRef<FormInstance>();
  const [form] = Form.useForm();

  const editCreditLimitFormRef = React.createRef<FormInstance>();
  const [editCreditLimitForm] = Form.useForm();
  const [showEditCreditLimitModal, setShowEditCreditLimitModal] = useState(
    false
  );

  const [helperPositionList, setHelperPositionList] = useState<Position[]>([]);

  const [helperRequests, setHelperRequests] = useState<HelperRequest[]>([]);
  const [selectedEmployer, setSelectedEmployer] = useState<Partial<Employer>>();
  const [periodType, setPeriodType] = useState<number>(0);
  const [workingHours, setWorkingHours] = useState<number>(0);
  const navigate = useNavigate();

  const [param, setParam] = useState({});

  const { mutate, isLoading, isError, isSuccess, data } = useAddJob();
  const { data: adminList } = useAdmin(useDebounce(param, 200));
  const { data: helperPositions } = useHelperPositions();
  const { mutate: updateCreditLimit } = useEditEmployer();

  const {
    mutate: searchEmployer,
    data: searchEmployerResult,
  } = useSearchEmployers();

  const addHelperRequest = () => {
    setHelperRequests((prevState) => {
      return prevState.concat({
        position: "",
        amount: 0,
        helperSalary: 0,
      });
    });
  };

  const removeHelperRequest = (index: number) => {
    setHelperRequests((prevState) => {
      return prevState.filter((_, i) => i !== index);
    });
  };

  const handleHelperRequestChange = (e: any, index: number) => {
    const { name, value }: { name: string; value: string } = e.target;

    setHelperRequests((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }
        return item;
      });
    });
  };

  const handleHelperPositionChange = (value: string, index: number) => {
    setHelperRequests((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          item.position = value;
        }
        return item;
      });
    });
    updateHelperPrice();
  };

  useEffect(() => {}, [helperPositions]);

  useEffect(() => {
    if (isSuccess && data) {
      // @ts-ignore
      navigate(`/job/${data?.uuid}`);
    }
  }, [isSuccess]);

  const onSearchEmployer = (searchText: string) => {
    searchEmployer({ keyword: searchText });
  };

  const onSelectEmployerChange = (data: string, option: any) => {
    setSelectedEmployer(option?.employer);

    const filteredHelperPositions =
      (helperPositions?.list || []).filter(
        (item) => item.type === option?.employer?.type
      ) || [];
    setHelperPositionList(filteredHelperPositions);

    // update address
    form.setFieldsValue({
      district: option?.employer?.district
        ? option?.employer?.district.split(",")
        : null,
      address: option?.employer?.address,
    });
  };

  const onEditCreditLimitOpen = () => {
    editCreditLimitForm.setFieldsValue({
      uuid: selectedEmployer?.uuid,
      creditLimit: selectedEmployer?.creditLimit,
    });
    setShowEditCreditLimitModal(true);
  };

  const onEditCreditLimitSubmit = async (values: Partial<Employer>) => {
    await updateCreditLimit(values);
    setSelectedEmployer((prevState) => ({
      ...prevState,
      creditLimit: values?.creditLimit,
    }));
    setShowEditCreditLimitModal(false);
  };

  const onEditCreditLimitCancel = () => {
    setShowEditCreditLimitModal(false);
    editCreditLimitFormRef.current!.resetFields();
  };

  const getHelperSalary = (data: any, key: string) => {
    return data[key].toString();
  };

  const updateHelperPrice = () => {
    if (helperRequests.length > 0 && selectedEmployer?.uuid && workingHours) {
      const newHelperRequests = [];
      let hasPosition = false;
      const employerPriceKey = "employer" + workingHours;
      const helperSalaryKey = "helper" + workingHours;

      for (const helperRequest of helperRequests) {
        hasPosition = false;
        for (const helperPosition of helperPositionList) {
          if (helperRequest.position === helperPosition.name) {
            hasPosition = true;
            newHelperRequests.push({
              ...helperRequest,
              employerPrice: getHelperSalary(helperPosition, employerPriceKey),
              helperSalary: getHelperSalary(helperPosition, helperSalaryKey),
            });
            break;
          }
        }
        if (!hasPosition) {
          newHelperRequests.push({
            ...helperRequest,
            position: "",
            helperSalary: 0,
          });
        }
      }

      setHelperRequests(newHelperRequests);
    }
  };

  useEffect(() => {
    updateHelperPrice();
  }, [workingHours, selectedEmployer]);

  const onSubmit = async (values?: any) => {
    if (!selectedEmployer?.uuid) {
      return alert("請選擇僱主");
    }
    if (helperRequests.length === 0) {
      return alert("請輸入幫手要求");
    } else {
      let hasEmptyData = false;
      for (const request of helperRequests) {
        if (!request.position || !request.amount) {
          hasEmptyData = true;
          break;
        }
      }
      if (hasEmptyData) {
        return alert("請輸入幫手要求");
      }
    }
    if (values.startDate) {
      values.startDate = moment(values.startDate).format("YYYY-MM-DD");
    }
    if (values.endDate) {
      values.endDate = moment(values.endDate).format("YYYY-MM-DD");
    }
    values.startTime = moment(values.startTime).format("HH:mm");
    values.personInCharge = values.personInCharge
      ? parseInt(values.personInCharge)
      : values.personInCharge;

    let submitData = changeArrayToStringInObject(values);
    submitData.employer = selectedEmployer?.uuid;
    submitData.taskHelperRequirements = helperRequests;

    await mutate(submitData);
  };

  return (
    <Profiler id="創建工作">
      <PageContainer>
        <Form
          form={form}
          ref={formRef}
          layout="vertical"
          initialValues={{
            workingPeriod: periodType,
          }}
          onFinish={onSubmit}
          onValuesChange={(changedValues) => {
            if (Object.keys(changedValues)[0] === "workingPeriod") {
              const newWorkingPeriod =
                changedValues[Object.keys(changedValues)[0]];
              setPeriodType(newWorkingPeriod);
              if (newWorkingPeriod !== periodType) {
                if (newWorkingPeriod === 1) {
                  form.setFieldsValue({
                    endDate: null,
                    week: [],
                  });
                } else if (newWorkingPeriod === 2) {
                  form.setFieldsValue({
                    endDate: null,
                  });
                }
              }
            } else if (Object.keys(changedValues)[0] === "workingHours") {
              setWorkingHours(changedValues[Object.keys(changedValues)[0]]);
            }
          }}
        >
          <TopBar isLoading={isLoading} />
          <ContentContainer>
            <FormCard>
              <CardTitle>工作信息</CardTitle>
              <EmployerDataContainer>
                <Row>
                  <Col span={14}>
                    <EmployerName>
                      {selectedEmployer?.companyName ||
                        selectedEmployer?.contactPerson}
                    </EmployerName>
                    <EmployerAddress>
                      {selectedEmployer?.address}
                    </EmployerAddress>
                  </Col>
                  <Col span={6}>
                    {selectedEmployer?.uuid && (
                      <>
                        <CreditLimitTitle>
                          Credit Limit
                          <Button
                            type="link"
                            style={{ fontSize: 14 }}
                            onClick={() => onEditCreditLimitOpen()}
                          >
                            更改
                          </Button>
                        </CreditLimitTitle>
                        <CreditLimit>
                          $
                          {selectedEmployer?.creditLimit
                            ? selectedEmployer?.creditLimit
                            : 0}
                        </CreditLimit>
                      </>
                    )}
                  </Col>
                  <Col span={4}>
                    <Popover
                      content={
                        <SelectEmployerListContainer>
                          <Select
                            onChange={onSelectEmployerChange}
                            onSearch={onSearchEmployer}
                            filterOption={false}
                            style={{ width: "100%" }}
                            showSearch
                          >
                            {(searchEmployerResult?.list || []).map((item) => (
                              <Select.Option
                                key={item.uuid}
                                value={item.companyName || item.contactPerson}
                                employer={item}
                              >
                                {item.companyName || item.contactPerson} (
                                {item.contactNumber}) ){" "}
                              </Select.Option>
                            ))}
                          </Select>
                        </SelectEmployerListContainer>
                      }
                      title="選擇僱主"
                      trigger="click"
                      placement="bottom"
                    >
                      <Button style={{ marginTop: 6 }}>
                        {selectedEmployer?.uuid ? "更換僱主" : "選擇僱主"}
                      </Button>
                    </Popover>
                  </Col>
                </Row>
              </EmployerDataContainer>

              {selectedEmployer?.uuid && (
                <>
                  <Row gutter={[16, 4]}>
                    <Col span={8}>
                      <Form.Item
                        name="title"
                        label="標題"
                        rules={[{ required: true, message: "請輸入標題" }]}
                      >
                        <Input placeholder="標題" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item name="personInCharge" label="負責同事">
                        <AdminSelect placeholder="負責同事" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 4]}>
                    <Col span={24}>
                      <Form.Item
                        name="description"
                        label="工作備註"
                        rules={[{ required: true, message: "請輸入工作備註" }]}
                      >
                        <Input placeholder="工作備註" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[16, 4]}>
                    <Col span={6}>
                      <Form.Item
                        name="district"
                        label="地區"
                        rules={[{ required: true, message: "請輸入地區" }]}
                      >
                        <District />
                      </Form.Item>
                    </Col>
                    <Col span={18}>
                      <Form.Item
                        name="address"
                        label="地址"
                        rules={[{ required: true, message: "請輸入地址" }]}
                      >
                        <Input placeholder="地址" />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
            </FormCard>

            {selectedEmployer?.uuid && (
              <>
                <FormCard>
                  <CardTitle>幫手要求</CardTitle>
                  <Row gutter={[16, 4]} style={{ backgroundColor: "#E7ECFF" }}>
                    <Col span={14}>幫手職級</Col>
                    <Col span={7}>數量</Col>
                    <Col span={3}></Col>
                  </Row>
                  {helperRequests.map((item, index) => (
                    <Row key={index} gutter={[16, 4]} style={{ marginTop: 10 }}>
                      <Col span={14}>
                        <HelperPositionSelect
                          placeholder="幫手職級"
                          value={item.position}
                          onSelect={(e: any) =>
                            handleHelperPositionChange(e.toString(), index)
                          }
                        />
                        {/* <Select
                          placeholder={"幫手職級"}
                          value={item.position}
                          style={{ width: "100%" }}
                          onSelect={(e) =>
                            handleHelperPositionChange(e.toString(), index)
                          }
                        >
                          {(helperPositionList || []).map((item) => (
                            <Select.Option key={item.uuid} value={item.name}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select> */}
                      </Col>
                      <Col span={7}>
                        <Input
                          placeholder="數量"
                          name="amount"
                          value={item.amount}
                          onChange={(e) => handleHelperRequestChange(e, index)}
                        />
                      </Col>
                      <Col span={3}>
                        <Button
                          type="link"
                          onClick={() => removeHelperRequest(index)}
                          block
                        >
                          刪除
                        </Button>
                      </Col>
                    </Row>
                  ))}
                  <Row style={{ marginTop: 20 }}>
                    <Col span={24}>
                      <Button
                        type="dashed"
                        onClick={() => addHelperRequest()}
                        block
                      >
                        + 增加幫手職級
                      </Button>
                    </Col>
                  </Row>
                </FormCard>

                <FormCard>
                  <CardTitle>工作時間</CardTitle>
                  <Row gutter={[16, 4]}>
                    <Col span={24}>
                      <Form.Item
                        name="workingPeriod"
                        label="工作週期"
                        rules={[{ required: true, message: "請輸入工作週期" }]}
                      >
                        <Radio.Group>
                          <Radio value={0}>時段性</Radio>
                          <Radio value={1}>一次性</Radio>
                          <Radio value={2}>週期性</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[16, 4]}>
                    <Col span={4}>
                      <Form.Item
                        name="startDate"
                        label="開始工作日期"
                        rules={[
                          { required: true, message: "請輸入開始工作日期" },
                        ]}
                      >
                        <DatePicker placeholder="開始工作日期" />
                      </Form.Item>
                    </Col>
                    {periodType === 0 && (
                      <Col span={4}>
                        <Form.Item
                          name="endDate"
                          label="工作期至"
                          rules={[
                            { required: true, message: "請輸入工作期至" },
                          ]}
                        >
                          <DatePicker placeholder="工作期至" />
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={4}>
                      <Form.Item
                        name="startTime"
                        label="開始時間"
                        rules={[{ required: true, message: "請輸入開始時間" }]}
                      >
                        <TimePicker format="HH:mm" placeholder="開始時間" />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        name="workingHours"
                        label="工作時數"
                        rules={[{ required: true, message: "請輸入工作時數" }]}
                      >
                        <Select allowClear style={{ width: "100%" }}>
                          <Select.Option value={1}>1</Select.Option>
                          <Select.Option value={2}>2</Select.Option>
                          <Select.Option value={3}>3</Select.Option>
                          <Select.Option value={4}>4</Select.Option>
                          <Select.Option value={5}>5</Select.Option>
                          <Select.Option value={6}>6</Select.Option>
                          <Select.Option value={7}>7</Select.Option>
                          <Select.Option value={8}>8</Select.Option>
                          <Select.Option value={9}>9</Select.Option>
                          <Select.Option value={10}>10</Select.Option>
                          <Select.Option value={11}>11</Select.Option>
                          <Select.Option value={12}>12</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {(periodType === 0 || periodType === 2) && (
                      <Col span={24}>
                        <Form.Item
                          name="week"
                          label="星期"
                          rules={[{ required: true, message: "請輸入星期" }]}
                        >
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="星期"
                          >
                            <Select.Option value="1">星期一</Select.Option>
                            <Select.Option value="2">星期二</Select.Option>
                            <Select.Option value="3">星期三</Select.Option>
                            <Select.Option value="4">星期四</Select.Option>
                            <Select.Option value="5">星期五</Select.Option>
                            <Select.Option value="6">星期六</Select.Option>
                            <Select.Option value="7">星期日</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </FormCard>
              </>
            )}
          </ContentContainer>
        </Form>
      </PageContainer>

      <Modal
        title={"編輯Credit Limit"}
        visible={showEditCreditLimitModal}
        footer={null}
        onCancel={() => onEditCreditLimitCancel()}
      >
        <Form
          form={editCreditLimitForm}
          ref={editCreditLimitFormRef}
          layout="vertical"
          initialValues={selectedEmployer}
          onFinish={onEditCreditLimitSubmit}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid" />
          </Form.Item>

          <Form.Item name="creditLimit" label="Credit Limit">
            <InputNumber
              min={0}
              placeholder="Credit Limit"
              style={{ width: 200 }}
            />
          </Form.Item>

          <Button
            htmlType="button"
            onClick={() => onEditCreditLimitCancel()}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>
    </Profiler>
  );
};

const ContentContainer = styled.div`
  padding: 10px 20px 20px 20px;
`;

const FormCard = styled.div`
  padding: 2.4rem;
  background-color: #ffffff;
  margin-bottom: 2.4rem;
  border-radius: 8px;
`;

const CardTitle = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
`;

const SelectEmployerListContainer = styled.div`
  width: 500px;
`;

const EmployerDataContainer = styled.div`
  border: 1px dotted #4b66e5;
  border-radius: 8px;
  padding: 10px;
  width: 700px;
  margin-top: 10px;
  margin-bottom: 20px;
  // background-color: #f9fbfd;
`;

const EmployerName = styled.div`
  color: #3c3e42;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 4px;
  min-height: 16px;
`;

const EmployerAddress = styled.div`
  color: #8d939e;
  font-size: 12px;
  min-height: 14px;
`;

const CreditLimitTitle = styled.div`
  color: #8d939e;
  font-size: 12px;
`;

const CreditLimit = styled.div`
  color: #676b73;
  font-size: 12px;
  font-weight: bold;
`;

CreateJobScreen.whyDidYouRender = false;
