import { useMemo } from "react";
import { useUrlQueryParam } from "./url";

export interface Page {
  pageSize?: number;
  pageNumber?: number;
}

// export const useUrlQueryPaging = ({pageSize = 10, pageNumber = 1}: {pageSize: number, pageNumber: number}) => {
export const useUrlQueryPaging = () => {
  const [{ pageNumber = "1", pageSize = "25" }, setParam] = useUrlQueryParam([
    "pageSize",
    "pageNumber",
  ]);

  return [
    useMemo(
      () => ({
        pageNumber: parseInt(pageNumber),
        pageSize: parseInt(pageSize),
      }),
      [pageNumber, pageSize]
    ),
    setParam,
  ] as const;
};
