import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormInstance } from "antd/es/form";
import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Modal,
  Table,
  TimePicker,
  Select,
} from "antd";
import {
  DownOutlined, EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
// import { Row } from "components/lib";
import { cleanObject, useDebounce } from "../../../utils";
import {
  useAddJobRecord,
  useEditJobAttendance,
  useEditJobRecord,
  useEditJobRecordPrice,
  useJobRecordAttendance,
  useJobRecords,
} from "../../../utils/job-record";
import {
  gender,
  taskAttendanceStatus,
  taskRecord,
} from "../../../utils/common";
import moment from "moment";
import {
  JobRecordAttendanceData,
  JobRecordData,
} from "../../../types/job-record";
import TextArea from "antd/es/input/TextArea";

export const JobRecords = (props: {
  workingPeriod: number | undefined;
  status: number | undefined;
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [param, setParam] = useState({ taskUuid: id });
  const { workingPeriod, status } = props;

  const { isLoading, error, data, retry } = useJobRecords(
    useDebounce(param, 200)
  );

  const {
    mutate,
    data: attendance,
    isLoading: attendanceLoading,
  } = useJobRecordAttendance();
  const {
    mutate: updateAttendance,
    isLoading: updateAttendanceLoading,
    isSuccess: updateAttendanceSuccess,
  } = useEditJobAttendance();

  const { mutate: editJobRecord } = useEditJobRecord();
  const { mutate: addJobRecord } = useAddJobRecord();
  const { mutate: editJobRecordPrice } = useEditJobRecordPrice();

  const [formType, setFormType] = useState("create");

  const recordFormRef = React.createRef<FormInstance>();
  const [recordForm] = Form.useForm();
  const [showRecordModal, setShowRecordModal] = useState(false);

  const [remarkForm] = Form.useForm();
  const [showRemarkModal, setShowRemarkModal] = useState(false);

  const [recordPriceForm] = Form.useForm();
  const [showRecordPriceModal, setShowRecordPriceModal] = useState(false);

  const [jobRecordDetails, setJobRecordDetails] = useState<JobRecordData>();
  const [helperAttendance, setHelperAttendance] = useState<
    JobRecordAttendanceData[]
  >();

  useEffect(() => {
    if (attendance?.list) {
      setHelperAttendance(attendance?.list);
    }
  }, [attendance]);

  useEffect(() => {
    if (updateAttendanceSuccess) {
      mutate({ taskUuid: id, uuid: jobRecordDetails?.uuid || "" });
    }
  }, [updateAttendanceSuccess]);

  useEffect(() => {
    for (const recordData of data?.list || []) {
      if (recordData.uuid === jobRecordDetails?.uuid) {
        setJobRecordDetails({
          uuid: recordData.uuid,
          taskRecordNo: recordData.taskRecordNo,
          status: recordData.status,
          remark: recordData?.remark,
          startDate: recordData.startDate,
          startTime: recordData.startTime,
          workingHours: recordData.workingHours,
          helperSalary: recordData.helperSalary,
          employerPrice: recordData.employerPrice,
        });
        break;
      }
    }
  }, [data]);

  const helperColumns = [
    {
      width: 150,
      title: "幫手姓名",
      dataIndex: ["helper", "nameCn"],
      key: "nameCn",
    },
    {
      width: 80,
      title: "電話",
      dataIndex: ["helper", "phone"],
    },
    {
      width: 60,
      title: "性別",
      dataIndex: ["helper", "gender"],
      render: (text: string) => gender(parseInt(text)),
    },
    {
      title: "幫手職級",
      dataIndex: ["helper", "position"],
      key: "type",
    },
    {
      title: "幫手價錢",
      dataIndex: "salary",
      key: "salary",
      render: (text: string) => (text ? `$${text}` : "-"),
    },
    {
      title: "僱主價錢",
      dataIndex: "employerPrice",
      key: "employerPrice",
      render: (text: string) => (text ? `$${text}` : "-"),
    },
    {
      width: 90,
      title: "出勤紀錄",
      fix: "right",
      dataIndex: "attendance",
      render: (text: string, record: JobRecordAttendanceData) => {
        return (
          <Dropdown overlay={menu(record)} trigger={["click"]}>
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              {taskAttendanceStatus(parseInt(text))} <DownOutlined />
            </a>
          </Dropdown>
        );
      },
    },
  ];

  const handleChangeAttendance = (e: any, data: JobRecordAttendanceData) => {
    const attendanceStatus = e.key;
    updateAttendance({
      uuid: data.uuid,
      attendance: parseInt(attendanceStatus),
    });
  };

  const menu = (data: JobRecordAttendanceData) => (
    <Menu onClick={(e) => handleChangeAttendance(e, data)}>
      <Menu.Item key={2}>簽到</Menu.Item>
      <Menu.Item key={3}>缺席</Menu.Item>
      <Menu.Item key={4}>請假</Menu.Item>
      <Menu.Item key={5}>取消</Menu.Item>
    </Menu>
  );

  const handleRecordClick = (jobRecord: JobRecordData) => {
    mutate({ taskUuid: id, uuid: jobRecord.uuid });
    setJobRecordDetails({
      uuid: jobRecord.uuid,
      taskRecordNo: jobRecord.taskRecordNo,
      status: jobRecord.status,
      remark: jobRecord.remark,
      startDate: jobRecord.startDate,
      startTime: jobRecord.startTime,
      workingHours: jobRecord.workingHours,
      helperSalary: jobRecord.helperSalary,
      employerPrice: jobRecord.employerPrice,
    });
  };

  const handleEditRecordPriceClick = (jobRecord: JobRecordData) => {
    recordPriceForm.setFieldsValue({
      uuid: jobRecord.uuid,
      helperSalary: jobRecord.helperSalary,
      employerPrice: jobRecord.employerPrice,
    });
    setShowRecordPriceModal(true);
  };

  const handleEditRecordRemarkClick = (jobRecord?: JobRecordData) => {
    remarkForm.setFieldsValue({
      uuid: jobRecord?.uuid,
      remark: jobRecord?.remark,
    });
    setShowRemarkModal(true);
  };

  const handleUpdateDateClick = (jobRecord: JobRecordData) => {
    recordForm.setFieldsValue({
      uuid: jobRecord.uuid,
      startDate: jobRecord.startDate
        ? moment(jobRecord.startDate)
        : jobRecord.startDate,
      startTime: jobRecord.startTime
        ? moment(jobRecord.startTime, "HH:mm")
        : jobRecord.startTime,
      workingHours: jobRecord.workingHours,
    });

    setFormType("edit");
    setShowRecordModal(true);
  };

  const handleRecordCancel = async (jobRecordUuid: string) => {
    await editJobRecord({ status: 2 }, param.taskUuid, jobRecordUuid);
    retry();
  };

  const handleCancelRecordClick = (jobRecord: JobRecordData) => {
    Modal.confirm({
      title: "取消記錄?",
      icon: <ExclamationCircleOutlined />,
      content: `取消${jobRecord.taskRecordNo}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      maskClosable: true,
      onOk() {
        handleRecordCancel(jobRecord.uuid);
      },
    });
  };

  const onAddRecordModalOpen = () => {
    recordForm.setFieldsValue({
      uuid: null,
      startDate: null,
      startTime: null,
      workingHours: null,
    });
    setFormType("create");
    setShowRecordModal(true);
  };

  const onAddRecordModalCancel = () => {
    setShowRecordModal(false);
  };

  const onAddRecordModalSubmit = async (values: any) => {
    values.startDate = moment(values.startDate).format("YYYY-MM-DD");
    values.startTime = moment(values.startTime).format("HH:mm");
    if (values.uuid) {
      await editJobRecord(
        {
          startDate: values.startDate,
          startTime: values.startTime,
          workingHours: values.workingHours,
        },
        param.taskUuid,
        values.uuid
      );
    } else {
      await addJobRecord(cleanObject(values), param.taskUuid);
    }

    retry();
    setShowRecordModal(false);
  };

  const onRecordPriceModalCancel = () => {
    setShowRecordPriceModal(false);
  };

  const onRecordPriceModalSubmit = async (values: any) => {
    await editJobRecordPrice(
      {
        helperSalary: values.helperSalary,
        employerPrice: values.employerPrice,
      },
      param.taskUuid,
      values.uuid
    );

    await mutate({ taskUuid: id, uuid: jobRecordDetails?.uuid || "" });
    setShowRecordPriceModal(false);
  };

  const onRecordRemarkModalSubmit = async (values: any) => {
    await editJobRecord(
      {
        remark: values.remark,
      },
      param.taskUuid,
      values.uuid
    );

    retry();
    // await mutate({ taskUuid: id, uuid: jobRecordDetails?.uuid || "" });
    setShowRemarkModal(false);
  };


  return (
    <>
      <Container>
        <RecordsContainer>
          <TitleContainer>
            <Title>
              工作紀錄{data?.list.length && `(${data?.list.length})`}
            </Title>
            <TitleButtonGroup>
              {(workingPeriod === 0 || workingPeriod === 2) &&
                (status === 0 || status === 1 || status === 2) && (
                  <PlusOutlined
                    style={{ color: "#3C3E42", fontSize: 18 }}
                    onClick={() => onAddRecordModalOpen()}
                  />
                )}
            </TitleButtonGroup>
          </TitleContainer>
          {data?.list.map((item, index) => (
            <Record
              key={item.uuid}
              onClick={() => handleRecordClick(item)}
              style={
                item.uuid === jobRecordDetails?.uuid
                  ? { backgroundColor: "#69DCFF" }
                  : {}
              }
            >
              <JobRecordNo>{item.taskRecordNo}</JobRecordNo>
              <JobRecordStatus>{taskRecord(item.status)}</JobRecordStatus>
              <JobRecordDate>
                <TextPadding>
                  {moment(item.startDate).format("YYYY-MM-DD")}
                </TextPadding>
                {moment(item.startTime, "HH:mm:ss").format("HH:mm")} (
                {item.workingHours}小時)
              </JobRecordDate>
              {
                <RecordActionBtn>
                  <Dropdown.Button
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="1"
                          onClick={() => handleEditRecordPriceClick(item)}
                        >
                          更改價錢
                        </Menu.Item>
                        <Menu.Item
                          key="2"
                          onClick={() => handleUpdateDateClick(item)}
                        >
                          更改時間
                        </Menu.Item>
                        <Menu.Item
                          key="3"
                          onClick={() => handleCancelRecordClick(item)}
                        >
                          取消記錄
                        </Menu.Item>
                      </Menu>
                    }
                  />
                </RecordActionBtn>
              }
            </Record>
          ))}
        </RecordsContainer>
        {Object.keys(jobRecordDetails || {}).length > 0 && (
          <DetailsContainer>
            <MainContainer>
              <Title>
                <TextPadding>{jobRecordDetails?.taskRecordNo}</TextPadding>
                <TextPadding>
                  {moment(jobRecordDetails?.startDate).format("YYYY-MM-DD")}
                </TextPadding>
                {moment(jobRecordDetails?.startTime, "HH:mm:ss").format(
                  "HH:mm"
                )}{" "}
                -{" "}
                {moment(jobRecordDetails?.startTime, "HH:mm:ss")
                  .add(jobRecordDetails?.workingHours, "h")
                  .format("HH:mm")}{" "}
                ({jobRecordDetails?.workingHours}小時)
              </Title>
              <HelperTable>
                <HelperTableTitle>幫手出勤</HelperTableTitle>
                <Table
                  columns={helperColumns}
                  dataSource={helperAttendance}
                  pagination={false}
                  loading={attendanceLoading}
                />
              </HelperTable>
              <RemarkArea>
                <span style={{ color: "#3c3e42", fontWeight: 500}}>
                  備注
                </span>

                <EditOutlined
                  style={{
                    color: "#3C3E42",
                    fontSize: 18,
                    marginLeft: 30,
                  }}
                  onClick={() => handleEditRecordRemarkClick(jobRecordDetails)}
                />
                <div style={{whiteSpace: "pre", marginTop: 15}}>
                  {jobRecordDetails?.remark}
                </div>

              </RemarkArea>
            </MainContainer>
          </DetailsContainer>
        )}
      </Container>

      <Modal
        title={formType === "edit" ? "更改時間" : "新增工作紀錄"}
        visible={showRecordModal}
        footer={null}
        onCancel={onAddRecordModalCancel}
      >
        <Form
          form={recordForm}
          ref={recordFormRef}
          layout="vertical"
          onFinish={onAddRecordModalSubmit}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid" />
          </Form.Item>

          <Form.Item
            name="startDate"
            label="開始工作日期"
            rules={[{ required: true, message: "請輸入開始工作日期" }]}
          >
            <DatePicker placeholder="開始工作日期" />
          </Form.Item>

          <Form.Item
            name="startTime"
            label="開始時間"
            rules={[{ required: true, message: "請輸入開始時間" }]}
          >
            <TimePicker format="HH:mm" placeholder="開始時間" />
          </Form.Item>

          <Form.Item
            name="workingHours"
            label="工作時數"
            rules={[{ required: true, message: "請輸入工作時數" }]}
          >
            <InputNumber type="number" />
            {/*<Select allowClear style={{ width: "100%" }}>*/}
            {/*  <Select.Option value={1}>1</Select.Option>*/}
            {/*  <Select.Option value={2}>2</Select.Option>*/}
            {/*  <Select.Option value={3}>3</Select.Option>*/}
            {/*  <Select.Option value={4}>4</Select.Option>*/}
            {/*  <Select.Option value={5}>5</Select.Option>*/}
            {/*  <Select.Option value={6}>6</Select.Option>*/}
            {/*  <Select.Option value={7}>7</Select.Option>*/}
            {/*  <Select.Option value={8}>8</Select.Option>*/}
            {/*  <Select.Option value={9}>9</Select.Option>*/}
            {/*  <Select.Option value={10}>10</Select.Option>*/}
            {/*  <Select.Option value={11}>11</Select.Option>*/}
            {/*  <Select.Option value={12}>12</Select.Option>*/}
            {/*</Select>*/}
          </Form.Item>

          <Button
            htmlType="button"
            onClick={onAddRecordModalCancel}
            style={{ marginRight: 20, marginTop: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title={"更改價錢"}
        visible={showRecordPriceModal}
        footer={null}
        onCancel={onRecordPriceModalCancel}
      >
        <Form
          form={recordPriceForm}
          layout="vertical"
          onFinish={onRecordPriceModalSubmit}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid" />
          </Form.Item>

          <Form.Item
            name="employerPrice"
            label="僱主價錢"
            rules={[{ required: true, message: "請輸入僱主價錢" }]}
          >
            <InputNumber placeholder="僱主價錢" style={{ width: 200 }} />
          </Form.Item>

          <Form.Item
            name="helperSalary"
            label="幫手價錢"
            rules={[{ required: true, message: "請輸入幫手價錢" }]}
          >
            <InputNumber placeholder="幫手價錢" style={{ width: 200 }} />
          </Form.Item>

          <Button
            htmlType="button"
            onClick={onRecordPriceModalCancel}
            style={{ marginRight: 20, marginTop: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title={"更改備注"}
        visible={showRemarkModal}
        footer={null}
        onCancel={() => setShowRemarkModal(false)}
      >
        <Form
          form={remarkForm}
          layout="vertical"
          onFinish={onRecordRemarkModalSubmit}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid" />
          </Form.Item>

          <Form.Item
            name="remark"
          >
            <TextArea
              placeholder="僱主價錢"
              autoSize={{ minRows: 3, maxRows: 5 }}
              // style={{ width: 200 }}
            />
          </Form.Item>
          <Button
            htmlType="button"
            onClick={() => setShowRemarkModal(false)}
            style={{ marginRight: 20, marginTop: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  // flex-grow: 1;
  padding: 0px 0px 2rem 2rem;
  height: calc(100vh - 190px - 6px);
  border-left: 2px solid #e0e5ef;
  // background-color: #ffffff;
  // overflow: auto;
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Title = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
  flex-grow: 1;
`;

const TitleButtonGroup = styled.div`
  padding-top: 10px;
  padding-right: 10px;
`;

const TextPadding = styled.span`
  padding-right: 20px;
`;

const RecordsContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 10px;
  left: 0;
  width: 400px;
  padding: 20px 20px 10px 20px;
  // height: 100%;
  overflow: auto;
`;

const Record = styled.div`
  padding: 12px;
  border: 2px solid #e0e5ef;
  border-radius: 10px;
  width: 100%;
  margin-top: 16px;
  background-color: #ffffff;
  cursor: pointer;
  position: relative;
`;

const RecordActionBtn = styled.div`
  position: absolute;
  top: 30px;
  right: 20px;
`;

const JobRecordNo = styled.div`
  display: inline-block;
  //font-size: 16px;
  color: #3c3e42;
  padding-right: 6px;
`;

const JobRecordStatus = styled.div`
  display: inline-block;
  background-color: #d1f3f8;
  padding: 5px 10px;
  color: #2498aa;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
`;

const JobRecordDate = styled.div`
  color: #3c3e42;
  //font-size: 16px;
  padding-top: 10px;
`;

const DetailsContainer = styled.div`
  width: calc(100% - 400px);
  margin-left: 400px;
  // padding-right: 400px;
  height: 100%;
  // overflow: auto;
`;

const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 2rem;
  overflow: auto;
`;

const HelperTable = styled.div`
  background-color: #ffffff;
  padding: 16px;
  margin-top: 20px;
  margin-right: 20px;
`;

const RemarkArea = styled.div`
  background-color: #ffffff;
  padding: 16px;
  min-height: 200px;
  margin-top: 20px;
  margin-right: 20px;
`;

const HelperTableTitle = styled.div`
  color: #3c3e42;
  //font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
`;
