import React, {ReactNode} from "react";
import {Content} from "antd/es/layout/layout";
import styled from "@emotion/styled";

const PageContainer = ({children}: { children: ReactNode }) => {
  return (
    <CusPage>
      {children}
    </CusPage>
  )
}

const CusPage = styled(Content)`
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
`;


export default PageContainer;
