import React from "react";
import { Tabs, Button } from "antd";
import styled from "@emotion/styled";
import { Row } from "components/lib";

interface TopBarData {
  activeTab?: string;
  tabChange: (key: string) => void;
  handleAddNewPriceClick: () => void;
}

export const TopBar = (props: TopBarData) => {
  const { activeTab, tabChange, handleAddNewPriceClick } = props;

  return (
    <Container>
      <FixedBar between={true}>
        <BarLeft gap={true}>
          <Title>幫手職級價目表</Title>
        </BarLeft>
        <BarRight>
          <Button type="primary" onClick={() => handleAddNewPriceClick()}>
            ＋新增價錢
          </Button>
        </BarRight>
      </FixedBar>
      <TabContainer>
        <Tabs activeKey={activeTab} onChange={(key) => tabChange(key)}>
          <Tabs.TabPane tab="私人護理價目" key="1"></Tabs.TabPane>
          <Tabs.TabPane tab="院舍護理價目" key="2"></Tabs.TabPane>
        </Tabs>
      </TabContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 123px;
  padding: 28px 32px;
  background-color: #ffffff;
  position: sticky;
  top: 0px;
  z-index: 999;
`;

const FixedBar = styled(Row)`
  z-index: 1;
`;
const BarLeft = styled(Row)``;
const BarRight = styled(Row)``;

const Title = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
`;

const TabContainer = styled.div`
  padding-top: 21px;
`;
