import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Table, Popover, Row, Col } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import { dateDisplay } from "utils/moment-util";
import { Helper } from "screens/helper/helper-list/list";
import { gender, taskStatus } from "utils/common";
import { TablePagination } from "components/table-pagination";
import useWindowSize from "hooks/useWindowSize";

export interface TaskHelper {
  helper: Helper;
}

export interface Job {
  uuid: string;
  taskNo: string;
  title: string;
  description: string;
  district: string;
  address: string;
  workingPeriod: number;
  week: [];
  status: number;
  startDate: Date;
  endDate: Date;
  startTime: string;
  workingHours: number;
  personInCharge?: {
    username: string;
  };
  employer?: {
    companyName: string;
    contactPerson: string;
    contactNumber?: string;
  };
  taskHelperRequirements?: any[];
  createdAt: string;
  taskHelpers?: TaskHelper[];
}

interface ListProps {
  refresh?: () => void;
  tableHeight?: number;
  tableWidth?: number;
  tableProps?: TableProps<Job>,
  paginationProps?: {
    pageSize: number;
    current: number;
    total: number;
    onChange: (page: number, pageSize: number) => void;
  }
}

export const JobList = ({ ...props }: ListProps) => {
  const { windowWidth, windowHeight } = useWindowSize();

  const geHelperPopover = (taskHelpers: TaskHelper[]) => {
    return (
      <PopoverContainer>
        <Row>
          {taskHelpers?.map((taskHelper: any, index: number) => (
            <Col key={index} span={12}>
              <PopoverItem>
                <PopoverItemTitle>
                  {taskHelper?.helper?.nameCn}
                </PopoverItemTitle>
                <PopoverItemDescription>{`${gender(
                  taskHelper?.helper?.gender
                )} ． ${taskHelper?.helper?.position}`}</PopoverItemDescription>
              </PopoverItem>
            </Col>
          ))}
        </Row>
      </PopoverContainer>
    );
  };

  const columns: ColumnsType<Job> = [
    {
      title: "工作編號",
      dataIndex: "taskNo",
      key: "taskNo",
      render: (text, record) => <a href={`/job/${record.uuid}`}>{text}</a>,
      width: 100,
    },
    {
      title: "工作開始日期",
      dataIndex: ["startDate"],
      key: "startDate",
      width: 100,
    },
    {
      title: "工作狀態",
      dataIndex: ["status"],
      key: "status",
      width: 90,
      render: (text) => taskStatus(text),
    },
    {
      title: "僱主",
      dataIndex: ["employer"],
      key: "employer",
      render: (text, record) =>
        (record?.employer?.companyName
          ? record?.employer?.companyName
          : record?.employer?.contactPerson) +
        `(${record?.employer?.contactNumber})`,
    },
    {
      title: "標題",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "幫手",
      dataIndex: "",
      key: "helper",
      render: (text, record) =>
        record.taskHelpers && record.taskHelpers.length > 0 ? (
          <Popover
            content={geHelperPopover(record.taskHelpers || [])}
            title="幫手"
            trigger="hover"
          >
            {record.taskHelpers && record.taskHelpers.length > 0
              ? record.taskHelpers.map((item) =>
                  item.helper.nameCn
                    ? `${item.helper.nameCn} (${item.helper.phone})`
                    : `${item.helper.nameEn} (${item.helper.phone})`
                )
              : `沒有幫手`}
          </Popover>
        ) : (
          <span style={{ color: "red" }}>沒有幫手</span>
        ),
    },
    {
      title: "負責人",
      dataIndex: ["personInCharge", "username"],
      key: "personInCharge",
      width: 100,
    },
    {
      title: "創建日期",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => dateDisplay(text),
      width: 100,
    },
  ];

  return (
    <div>
      <ListContainer style={{width: `${(props.tableWidth || windowWidth) - 260}px`, height: `${props.tableHeight || windowHeight - 320}px`}}>
        <Table<Job>
          columns={columns}
          size="small"
          {...props.tableProps}
        />
      </ListContainer>

      <TablePagination
        pageSize={props.paginationProps?.pageSize}
        pageSizeOptions={["10", "25", "50", "100"]}
        showSizeChanger={true}
        showQuickJumper={false}
        current={props.paginationProps?.current}
        total={props.paginationProps?.total}
        onChange={props.paginationProps?.onChange}
      />
    </div>
  );
};

const PopoverContainer = styled.div`
  width: 400px;
`;

const PopoverItem = styled.div`
  padding: 8px 12px;
`;

const PopoverItemTitle = styled.div`
  color: #3c3e42;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 2px;
`;

const PopoverItemDescription = styled.div`
  color: #8d939e;
  font-size: 12px;
`;

const ListContainer = styled.div`
  overflow-y: hidden;
`;
