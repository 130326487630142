import React from "react";
import moment from "moment";
import {Table} from "antd";
import { HelperAttendance } from "types/helper-attendance";

export const HelperPaymentExpandedTable = (record: {
  helperAttendances: HelperAttendance[];
}) => {
  const nestColumns = [
    {
      title: "工作編號",
      // width: 100,
      dataIndex: ["taskRecord", "task", "taskNo"],
      render: (text: string, record: HelperAttendance) => <a href={`/job/${record.taskRecord.task?.uuid}`}>{text}</a>,
      key: "taskNo",
    },
    {
      title: "標題",
      // width: 100,
      dataIndex: ["taskRecord", "task", "title"],
      key: "title",
    },
    {
      title: "僱主",
      // width: 160,
      dataIndex: ["taskRecord", "employer"],
      key: "employer",
      render: (text: string, record: HelperAttendance) => (`${record.taskRecord.task?.employer?.companyName || record.taskRecord.task?.employer?.contactPerson} (${ record.taskRecord.task?.employer?.contactNumber})`)
    },
    {
      title: "工作日子",
      // width: 160,
      dataIndex: ["taskRecord", "startDate"],
      key: "startDate",
    },
    {
      title: "工作時間",
      // width: 160,
      dataIndex: "",
      key: "startTime",
      render: (text: string, workRecord: HelperAttendance) => {
        return (
          <>
            {moment(workRecord?.taskRecord?.startTime, "HH:mm:ss").format(
              "HH:mm"
            )}{" "}
            -{" "}
            {moment(workRecord.taskRecord.startTime, "HH:mm:ss")
              .add(workRecord.taskRecord.workingHours, "hour")
              .format("HH:mm")}{" "}
            ({workRecord.taskRecord.workingHours}小時)
          </>
        );
      },
    },
    {
      title: "幫手薪金",
      dataIndex: "salary",
      key: "salary",
      render: (text: string) => `$${text}`,
      // width: 100,
    },
  ];

  return (
    <Table
      columns={nestColumns}
      dataSource={record.helperAttendances}
      pagination={false}
    />
  );
};
