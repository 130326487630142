import { useAsync } from "utils/use-async";
import { useCallback, useEffect } from "react";
import { cleanObject } from "utils/index";
import { useHttp, useHttpDownload } from "utils/http";
import { Helper } from "../screens/helper/helper-list/list";
import { Page } from "./paging";
import { HelperSearch } from "../screens/helper/helper-list/search-panel";
import { Certifications } from "../screens/helper/helper-details/certifications";

export const useHelpers = (param?: HelperSearch, pageObject?: Page) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{ list: Helper[]; total: number }>();

  const fetchHelpers = useCallback(
    () =>
      client("helpers", {
        data: cleanObject({ ...param, ...pageObject } || {}),
      }),
    [param, client, pageObject]
  );

  useEffect(() => {
    run(fetchHelpers(), {
      retry: fetchHelpers,
    });
  }, [param, run, fetchHelpers]);

  return result;
};

export const useSimpleHelpers = (param?: Partial<Helper>) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{ list: Helper[]; total: number }>();

  const fetchHelpers = useCallback(
    () => client("helpers/simple_helper", { data: cleanObject(param || {}) }),
    [param, client]
  );

  useEffect(() => {
    run(fetchHelpers(), {
      retry: fetchHelpers,
    });
  }, [param, run, fetchHelpers]);

  return result;
};

export const useSearchHelpers = () => {
  const { run, ...asyncResult } = useAsync<{
    list: Helper[];
    total: number;
  }>();
  const client = useHttp();
  const mutate = (params: { keyword: string }) => {
    return run(
      client(`helpers/simple_helper`, {
        data: {
          keyword: params.keyword,
          pageSize: 20,
        },
        method: "GET",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useHelperDetail = (param?: Partial<Helper>) => {
  const client = useHttp();
  const { run, ...result } = useAsync<Helper>();

  const fetchHelpers = useCallback(() => client(`helpers/${param?.uuid}`, {}), [
    param,
    client,
  ]);

  useEffect(() => {
    run(fetchHelpers(), {
      retry: fetchHelpers,
    });
  }, [param, run, fetchHelpers]);

  return result;
};

export const useEditHelper = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: Partial<Helper>) => {
    return run(
      client(`helpers/${params.uuid}`, {
        data: params,
        method: "PUT",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useAddHelper = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: Partial<Helper>) => {
    return run(
      client(`helpers`, {
        data: params,
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useAddHelperCertAttachments = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: FormData) => {
    return run(
      client(`helpers/cert-attachments/upload`, {
        data: params,
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useDeleteHelperCertAttachments = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: { uuid: string }) => {
    return run(
      client(`helpers/cert-attachments/${params.uuid}`, {
        data: params,
        method: "DELETE",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useAddHelperCert = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (uuid: string, params: any) => {
    return run(
      client(`helpers/${uuid}/certs`, {
        data: params,
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useUpdateHelperCert = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (uuid: string, params: any) => {
    return run(
      client(`helpers/${uuid}/certs/${params.uuid} `, {
        data: params,
        method: "PUT",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useGetHelperCertAttachment = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttpDownload();
  const mutate = (uuid: string) => {
    return client(`helpers/cert-attachments/${uuid}`);
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useGetHelperCerts = (param?: any) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{ list: any; total: number }>();

  const fetchHelpers = useCallback(
    () =>
      client(`helpers/${param.uuid}/certs`, { data: cleanObject(param || {}) }),
    [param, client]
  );

  useEffect(() => {
    run(fetchHelpers(), {
      retry: fetchHelpers,
    });
  }, [param, run, fetchHelpers]);

  return result;
};
