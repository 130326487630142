import React, { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import { TopBar } from "screens/helper/helper-details/top-bar";
import { JobList } from "screens/helper/helper-details/job-list";
import { PaymentRecords } from "screens/helper/helper-details/payment-records";
import { HelperDetails } from "screens/helper/helper-details/helper-details";
import { Certifications } from "screens/helper/helper-details/certifications";

import { useDebounce, useDocumentTitle } from "utils";
import { Profiler } from "components/profiler";
import {
  useEditHelper,
  useGetHelperCerts,
  useHelperDetail,
} from "../../../utils/helper";
import {CreditDebitNotes} from "./credit-debit-notes";
import PageContainer from "../../../components/layout/PageContainer";

type RouteParams = {
  id: string | number;
};

export const HelperDetailsScreen = () => {
  useDocumentTitle("幫手詳情", false);
  const navigate = useNavigate();

  const { id } = useParams();
  const [param, setParam] = useState({ uuid: id });
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get("tab") || "1");
  const { isLoading, error, data, retry } = useHelperDetail(
    useDebounce(param, 200)
  );

  const {
    mutate,
    isLoading: editLoading,
    isError,
    isSuccess,
  } = useEditHelper();

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <Profiler id={"幫手詳情"}>
      <PageContainer>
        <TopBar
          uuid={data?.uuid}
          nameCn={data?.nameCn}
          position={data?.position}
          status={data?.status}
          createdAt={data?.createdAt}
          activeTab={activeTab}
          tabChange={handleTabChange}
          refresh={retry}
        />

        {activeTab === "1" && (
          <ContentContainer>
            <JobList
              jobs={data?.taskHelpers}
              numOfReject={data?.numOfReject || 0}
              numOfCancelled={data?.numOfCancelled || 0}
              numOfLeave={data?.numOfLeave || 0}
              numOfAbsence={data?.numOfAbsence || 0}
            />
          </ContentContainer>
        )}

        {activeTab === "2" && (
          <ContentContainer>
            <PaymentRecords
              helperUuid={data?.uuid || ""}
              bankDetails={data?.bankDetails || []}
            />
          </ContentContainer>
        )}

        {activeTab === "3" && (
          <ContentContainer>
            <CreditDebitNotes helperUuid={param.uuid}/>
          </ContentContainer>
        )}

        {activeTab === "4" && (
          <ContentContainer>
            <HelperDetails onUpdate={mutate} reload={retry} {...data} />
          </ContentContainer>
        )}

        {activeTab === "5" && <Certifications />}
      </PageContainer>
    </Profiler>
  );
};

const ContentContainer = styled.div`
  margin-top: 3px;
  // padding: 3.2rem;
  padding: 2rem;
  flex-direction: column;
  position: relative;
  overflow: auto;
`;

HelperDetailsScreen.whyDidYouRender = false;
