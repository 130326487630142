import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { TopBar } from "screens/job/job-details/top-bar";
import { GeneralData } from "screens/job/job-details/general-data";
import { HelperData } from "screens/job/job-details/helper-data";
import { ActivityLog } from "screens/job/job-details/activity-log";
import { JobRecords } from "screens/job/job-details/job-records";
import { useDebounce, useDocumentTitle } from "utils";
import { Profiler } from "components/profiler";
import { useEditJob, useJobDetail } from "utils/job";
import { useJobDetailHelperList } from "utils/job-helper";
import { useJobDetailActivityLogList } from "utils/job-activity-log";
import { Modal, Row, Col } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export const JobDetailsScreen = () => {
  useDocumentTitle("工作詳情", false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [param, setParam] = useState({ uuid: id });

  const [activeTab, setActiveTab] = useState("1");
  const [appliedHelpers, setAppliedHelpers] = useState([]);
  const [selectedHelpers, setSelectedHelpers] = useState([]);
  const [selectedHelperIds, setSelectedHelperIds] = useState([]);
  const [oldHelpers, setOldHelpers] = useState([]);
  const [helpers, setHelpers] = useState([]);
  const [logRecords, setLogRecords] = useState([]);

  const { isLoading, error, data, retry } = useJobDetail(
    useDebounce(param, 200)
  );

  const {
    data: helperList,
    isSuccess: helperListSuccess,
    retry: reloadHelper,
  } = useJobDetailHelperList(useDebounce(param, 200));

  const {
    data: activityLogList,
    isSuccess: activityLogListSuccess,
    retry: reloadActivityLog,
  } = useJobDetailActivityLogList(useDebounce(param, 200));

  const { mutate, isSuccess } = useEditJob();

  const handleJobCancel = () => {
    Modal.confirm({
      title: "取消工作?",
      icon: <ExclamationCircleOutlined />,
      content: "取消這份工作?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      maskClosable: true,
      onOk() {
        mutate({ uuid: id, status: 4 });
      },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(0);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (helperListSuccess) {
      let temApply: any = [];
      let temSelect: any = [];
      let temOld: any = [];
      let temSelectId: any = [];

      const helpers = helperList?.list.map((item: any) => {
        if (item.status === 0) {
          temSelect.push(item.helper);
          temSelectId.push(item.uuid);
        } else if (item.status === 1) {
          temApply.push(item.helper);
        } else if (item.status === 2) {
          temOld.push(item.helper);
        }

        return { ...item.helper, ...item };
      });

      setAppliedHelpers(temApply);
      setSelectedHelperIds(temSelectId);
      setSelectedHelpers(temSelect);
      setOldHelpers(temOld);
      setHelpers(helpers);
    }
  }, [helperListSuccess]);

  useEffect(() => {
    if (activityLogListSuccess) {
      setLogRecords(activityLogList.list);
    }
  }, [activityLogListSuccess]);

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <Profiler id={"工作詳情"}>
      <PageContainer>
        <TopBar
          activeTab={activeTab}
          selectedHelpersLength={selectedHelpers.length}
          tabChange={handleTabChange}
          cancelledJob={handleJobCancel}
          {...data}
        />

        {activeTab === "1" && (
          <ContentContainer>
            <Row>
              <Col flex="1 1 1px">
                <GeneralData {...data} refresh={retry} />
              </Col>
              <Col flex="0 1 338px">
                <HelperData
                  taskUuid={id}
                  appliedHelpers={appliedHelpers}
                  selectedHelpers={selectedHelpers}
                  helpers={helpers}
                  status={data?.status}
                  oldHelpers={oldHelpers}
                  selectedHelperIds={selectedHelperIds}
                  reloadHelper={reloadHelper}
                  reloadActivityLog={reloadActivityLog}
                />
              </Col>
              <Col flex="0 1 338px">
                <ActivityLog
                  jobId={param.uuid}
                  logRecords={logRecords}
                  reloadActivityLog={reloadActivityLog}
                />
              </Col>
            </Row>
          </ContentContainer>
        )}

        {activeTab === "2" && (
          <JobRecords
            workingPeriod={data?.workingPeriod}
            status={data?.status}
          />
        )}
      </PageContainer>
    </Profiler>
  );
};

const PageContainer = styled.div`
  width: 100%;
  background-color: #f0f2f5;
`;

const ContentContainer = styled.div`
  margin-top: 3px;
  background-color: #ffffff;
  position: relative;
`;

JobDetailsScreen.whyDidYouRender = false;
