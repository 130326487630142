import React from "react";
import {useAuth} from "context/auth-context";
import styled from "@emotion/styled";
import {ButtonNoPadding, Row} from "components/lib";
import {Button, Dropdown, Menu} from "antd";
import {Routes} from "react-router";
import {BrowserRouter as Router, Route} from "react-router-dom";
import {resetRoute} from "utils";
import {ProjectModal} from "screens/project-list/project-modal";
import {SideMenu} from "components/side-menu";
import {CreateJobScreen} from "screens/job/create-job";
import {AllJobsScreen} from "screens/job/all-jobs";
import {LateCheckoutJobsScreen} from "screens/job/late-checkout-jobs";
import {JobDetailsScreen} from "screens/job/job-details";
import {EmployerListScreen} from "screens/employer/employer-list";
import {CreateCompanyEmployerScreen} from "screens/employer/create-employer/company";
import {CreateIndividualEmployerScreen} from "screens/employer/create-employer/individual";
import {EmployerDetailsScreen} from "screens/employer/employer-details";
import {HelperListScreen} from "screens/helper/helper-list";
import {CreateHelperScreen} from "screens/helper/create-helper";
import {HelperDetailsScreen} from "screens/helper/helper-details";
import {EmployerInvoiceListScreen} from "screens/employer-invoices";
import {HelperPaymentListScreen} from "screens/helper-payments";
import {CreditDebitNoteListScreen} from "screens/credit-debit-note";
import {HelperPricesScreen} from "screens/helper-prices";
import {AccountingReportScreen} from "screens/accounting/accounting-report";
import {AccountingCategoryScreen} from "screens/accounting/accounting-category";
import {Report} from "./screens/report";

/**
 * grid 和 flex 各自的应用场景
 * 1. 要考虑，是一维布局 还是 二维布局
 * 一般来说，一维布局用flex，二维布局用grid
 * 2. 是从内容出发还是从布局出发？
 * 从内容出发：你先有一组内容(数量一般不固定),然后希望他们均匀的分布在容器中，由内容自己的大小决定占据的空间
 * 从布局出发：先规划网格(数量一般比较固定)，然后再把元素往里填充
 * 从内容出发，用flex
 * 从布局出发，用grid
 *
 */

// prop drilling

export const AuthenticatedApp = () => {
  return (
    <Container>
      <PageHeader/>
      <Main>
        <Router>
          <SideMenu/>
          <Routes>
            <Route path={"/analysis"} element={<Report/>}/>
            <Route path={"/create_job"} element={<CreateJobScreen/>}/>
            <Route path={"/all_jobs"} element={<AllJobsScreen/>}/>
            <Route
              path={"/late_checkout_jobs"}
              element={<LateCheckoutJobsScreen/>}
            />
            {/*<Route path={"/completed_jobs"} element={<CompletedJobsScreen />} />*/}
            {/*<Route*/}
            {/*  path={"/in_progress_jobs"}*/}
            {/*  element={<InProgressJobsScreen />}*/}
            {/*/>*/}
            {/*<Route path={"/new_jobs"} element={<NewJobsScreen />} />*/}
            {/*<Route path={"/starting_jobs"} element={<StartingJobsScreen />} />*/}
            {/*<Route path={"/waiting_jobs"} element={<WaitingJobsScreen />} />*/}
            <Route path={"/job/:id"} element={<JobDetailsScreen/>}/>
            <Route path={"/employers"} element={<EmployerListScreen/>}/>
            <Route path={"/employer/:id"} element={<EmployerDetailsScreen/>}/>
            <Route
              path={"/create_company_employer"}
              element={<CreateCompanyEmployerScreen/>}
            />
            <Route
              path={"/create_individual_employer"}
              element={<CreateIndividualEmployerScreen/>}
            />
            <Route path={"/helpers"} element={<HelperListScreen/>}/>
            <Route path={"/create_helper"} element={<CreateHelperScreen/>}/>
            <Route path={"/helper/:id"} element={<HelperDetailsScreen/>}/>
            <Route
              path={"/employer_invoices"}
              element={<EmployerInvoiceListScreen/>}
            />
            <Route
              path={"/helper_payments"}
              element={<HelperPaymentListScreen/>}
            />
            <Route
              path={"/credit_debit_notes"}
              element={<CreditDebitNoteListScreen/>}
            />
            <Route path={"/hepler_prices"} element={<HelperPricesScreen/>}/>
            <Route
              path={"/accounting_report"}
              element={<AccountingReportScreen/>}
            />
            <Route
              path={"/accounting_category"}
              element={<AccountingCategoryScreen/>}
            />
          </Routes>
        </Router>
      </Main>
      <ProjectModal/>
    </Container>
  );
};

const PageHeader = () => {
  return (
    <Header between={true}>
      <HeaderLeft gap={true}>
        <ButtonNoPadding type={"link"} onClick={resetRoute}>
          <img src="/logo.png" style={{height: 34}}/>
        </ButtonNoPadding>
      </HeaderLeft>
      <HeaderRight>
        <User/>
      </HeaderRight>
    </Header>
  );
};

const User = () => {
  const {logout, user} = useAuth();
  return (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item key={"logout"}>
            <Button onClick={logout} type={"link"}>
              登出
            </Button>
          </Menu.Item>
        </Menu>
      }
    >
      <Button type={"link"} onClick={(e) => e.preventDefault()}>
        Hi, {user?.username}
      </Button>
    </Dropdown>
  );
};

// temporal dead zone(暂时性死区)
const Container = styled.div`
  display: grid;
  grid-template-rows: 6rem 1fr;
  height: 100vh;
`;

// grid-area 用来给grid子元素起名字
const Header = styled(Row)`
  // padding: 3.2rem;
  padding: 20px;
  // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #eeeeee;
  z-index: 1;
`;
const HeaderLeft = styled(Row)``;
const HeaderRight = styled.div``;
const Main = styled.main`
  display: flex;
  overflow: hidden;
  min-height: "100vh";
`;
