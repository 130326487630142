import React, { useState, useEffect } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Select,
  Input,
  Button,
  DatePicker,
  Modal,
  Upload,
  Drawer,
  Space,
  Tag
} from "antd";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { CommonList } from "components/listing/common-list";
import {HelperPaymentMethodSelect} from "components/helper-payment-method";
import { HelperPaymentExpandedTable } from "components/listing/helper-payment-expanded-table";
import { HelperAttendance } from "types/helper-attendance";
import { useDebounce, useDocumentTitle } from "utils";
import {
  useAllHelperPayments,
  useAddAllHelperSalary,
  useAddHelperPayment,
  useEditHelperPayment,
  useHelperPaymentDetail, useExportPaymentExcel,
} from "utils/helper-payment";
import { useUrlQueryPaging } from "utils/paging";
import { ColumnsType } from "antd/es/table";
import { useHelperPaymentSearchParams } from "../../search-utils/util";
import { Row } from "components/lib";
import { Profiler } from "components/profiler";
import { SearchPanel } from "./search-panel";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { paymentStatus, paymentMethod } from "utils/common";
import { dateDisplay } from "utils/moment-util";
import { PaymentRecord } from "screens/helper/helper-details/payment-records";
import { HelperPaymentRecord } from "types/helper-payment";
import { BankAccount } from "types/bank-account";
import useWindowSize from "hooks/useWindowSize";
import PageContainer from "../../components/layout/PageContainer";

export const HelperPaymentListScreen = () => {
  useDocumentTitle("幫手出糧帳單", false);
  const { windowHeight } = useWindowSize();

  const [param, setParam] = useHelperPaymentSearchParams();
  const [pageObject, setPageObject] = useUrlQueryPaging();
  const [createPaymentForm] = Form.useForm();
  const [showCreatePaymentModal, setShowCreatePaymentModal] = useState(false);
  const [paymentDetailsForm] = Form.useForm();
  const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    number | null | undefined
  >(null);
  const [confirmPaymentText, setConfirmPaymentText] = useState("");
  const [paymentDetailData, setPaymentDetailData] = useState<
    Partial<HelperPaymentRecord>
  >({
    method: null,
    referenceNo: "",
    remark: "",
    attachments: [],
    status: null,
  });
  const [bankDetails, setBankDetails] = useState<BankAccount[]>([]);
  const [helperUuid, setHelperUuid] = useState("");

  const [salaryData, setSalaryData] = useState<Partial<HelperPaymentRecord>>(
    {}
  );

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [imageList, setImageList] = useState([]);

  const {
    mutate: addAllHelperSalary,
    isLoading: createSalaryLoading,
  } = useAddAllHelperSalary();

  const {
    data: paymentDetail,
    mutate: getPaymentDetail,
    isLoading: getPaymentDetailLoading,
  } = useHelperPaymentDetail();

  const {
    mutate: addHelperPayment,
    isLoading: createPaymentLoading,
  } = useAddHelperPayment();

  const {
    mutate: editHelperPayment,
    isLoading: editPaymentLoading,
  } = useEditHelperPayment();

  const setPaymentDetail = async (paymentDetail: any) => {
    if (paymentDetail) {
      await setSelectedPaymentMethod(paymentDetail.method);
      await setPaymentDetailData({
        method: paymentDetail.method,
        referenceNo: paymentDetail.referenceNo ,
        remark: paymentDetail.remark ,
        // bankNumber: paymentDetail.method === 1 ? paymentDetail.referenceNo : "",
        attachments: paymentDetail?.attachments,
        status: paymentDetail.status,
      });
      paymentDetailsForm.setFieldsValue(paymentDetail)
    } else {
      await paymentDetailsForm.resetFields();
      await setHelperUuid("");
      await setPaymentDetailData({
        method: null,
        referenceNo: "",
        remark: "",
        // bankNumber: "",
        attachments: [],
        status: null,
      });
      await setBankDetails([]);
    }

  }

  useEffect(() => {
    setPaymentDetail(paymentDetail)
  }, [paymentDetail]);

  const {
    data: paymentsData,
    isLoading: paymentsLoading,
    retry,
  } = useAllHelperPayments(useDebounce(param, 500), pageObject);

  const {
    mutate: exportExcel,
    data: excelFile
  } = useExportPaymentExcel();

  const onCreatePaymentModalOpen = () => {
    createPaymentForm.setFieldsValue({
      datePeriod: [null, null],
    });
    setShowCreatePaymentModal(true);
  };

  const onCreatePaymentModalCancel = () => {
    setShowCreatePaymentModal(false);
  };

  const onCreatePaymentModalSubmit = async (values: any) => {
    const settleFrom = moment(values?.datePeriod?.[0]).format("YYYY-MM-DD");
    const settleTo = moment(values?.datePeriod?.[1]).format("YYYY-MM-DD");

    await addAllHelperSalary({
      settleFrom: settleFrom,
      settleTo: settleTo,
    });

    retry();
    setShowCreatePaymentModal(false);
  };

  useEffect(() => {
    setPageObject({
      pageSize: pageObject.pageSize,
      pageNumber: 1,
    });
  }, [param.position, param.status, param.keyword]);

  const updateConfirmPaymentText = (
    helperUuid: string,
    salaryRecordUuid?: string
  ) => {
    setConfirmPaymentText(`
    *環宇護理UniCare360薪金領取確認*\n\n ✅ *確認薪金領取* ✅ \nhttps://360unicare.com/confirmpayment/${helperUuid}/${salaryRecordUuid}`);
  };

  const handlePaymentDetailsOpen = async (record: PaymentRecord) => {
    setHelperUuid(record.helper.uuid);
    updateConfirmPaymentText(record.helper.uuid || "", record.uuid);
    setSalaryData({
      uuid: record?.uuid,
      settleFrom: record.settleFrom
        ? moment(record.settleFrom).format("YYYY-MM-DD")
        : "",
      settleTo: record.settleTo
        ? moment(record.settleTo).format("YYYY-MM-DD")
        : "",
      salary: record.salary,
      payment: record.payment,
    });

    setBankDetails(record.helper.bankDetails);

    // if (record.payment) {
    //   await getPaymentDetail(helperUuid, record.uuid || "");
    // }

    await getPaymentDetail(record.helper.uuid || "", record.uuid || "");
    await setShowPaymentDetailsModal(true);
  };

  const handlePaymentDetailsClose = async () => {
    await paymentDetailsForm.resetFields();
    await setHelperUuid("");
    await setPaymentDetailData({
      method: null,
      referenceNo: "",
      remark: "",
      // bankNumber: "",
      attachments: [],
      status: null,
    });
    await setBankDetails([]);
    await setShowPaymentDetailsModal(false);
  };

  const handlePaymentDetailsSubmit = async (values: any) => {
    let submitData = {
      method: values.method,
      remark: values.remark,
      referenceNo: values.referenceNo,
      attachments: [],
    };

    if (salaryData?.payment) {
      await editHelperPayment(submitData, helperUuid, salaryData?.uuid || "");
    } else {
      await addHelperPayment(submitData, helperUuid, salaryData?.uuid || "");
    }

    await setShowPaymentDetailsModal(false);
  };

  const handleImageModalOpen = (file: any) => {
    setImageSrc(file?.url);
    setShowImageModal(true);
  };

  const handleImageModalClose = () => {
    setShowImageModal(false);
    setImageSrc("");
  };

  const handleImageUpload = (file: any, fileList: any[]) => {
    console.log(file);
    console.log(fileList);

    return false;
  };

  const onExportModalOpen = async () => {
    Modal.confirm({
      title: "導出excel",
      icon: <ExclamationCircleOutlined />,
      content: `導出所有未出糧記錄?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      maskClosable: true,
      onOk: async () => {
        const tempUrl = await exportExcel();
        if (tempUrl && typeof tempUrl === "string") {
          let a = document.createElement("a"); //Create <a>
          a.href = tempUrl
          a.download = "未出糧記錄.xlsx";
          a.click()
        }


      },
    });
  };

  const columns: ColumnsType<PaymentRecord> = [
    {
      title: "出糧編號",
      dataIndex: "recordeNo",
      key: "recordeNo",
      render: (text, record) => (
        <a href={`/helper/${record.helper.uuid}?tab=2`}>{text}</a>
      ),
      width: 110
    },
    {
      title: "幫手",
      dataIndex: ["helper", "nameCn"],
      key: "helperName",
      render: (text, record) => (
        `${text} (${record.helper.phone})`
      ),
      // width: 90
    },
    {
      title: "出糧狀態",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text, record) =>
        record?.payment?.status ? (
          <Tag color="success">{paymentStatus(record?.payment?.status)}</Tag>
        ) : (
          // paymentMethod(text)
          "-"
        ),
      width: 90
    },
    {
      title: "出糧日期",
      dataIndex: "releaseDate",
      key: "releaseDate",
      render: (text, record) => {
        return dateDisplay(text);
      },
      width: 110
    },
    {
      title: "結算時段",
      dataIndex: "",
      key: "period",
      render: (text, record) => {
        return (
          dateDisplay(record.settleFrom) + " - " + dateDisplay(record.settleTo)
        );
      },
      width: 220
    },
    {
      title: "出糧方式",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (text, record) =>
        record?.payment ? (
          <Tag color="success">{paymentMethod(record?.payment?.method)}</Tag>
        ) : (
          // paymentMethod(text)
          "-"
        ),
      width: 90
    },
    {
      title: "出糧薪金",
      dataIndex: "salary",
      key: "salary",
      render: (text, record) => {
        return !text && text !== 0 ? "--" : `$${text}`;
      },
      width: 120
    },
    {
      title: "功能",
      dataIndex: "",
      key: "action",
      // fixed: 'right',
      render: (_, record) => {
        return (
          <Space size="middle">
            <a onClick={() => handlePaymentDetailsOpen(record)}>出糧</a>
            {/* <a>編輯</a> */}
          </Space>
        );
      },
      width: 90
    },
  ];

  return (
    <Profiler id={"幫手出糧帳單"}>
      <PageContainer>
        <SearchPanel param={param} setParam={setParam} />
        <ContentContainer>
          <Row marginBottom={2} between={true}>
            <h1>幫手出糧帳單列表</h1>
            <div>
              <Button
                style={{ marginRight: 15 }}
                onClick={() => onExportModalOpen()}
              >
                Export
              </Button>
              <Button type="primary" onClick={() => onCreatePaymentModalOpen()}>
                ＋創建出糧記錄
              </Button>
            </div>
          </Row>

          <CommonList
            refresh={retry}
            tableProps={{
              loading: paymentsLoading,
              columns,
              dataSource: paymentsData?.list || [],
              expandable: {
                expandedRowRender: (record: {
                  helperAttendances: HelperAttendance[];
                }) => HelperPaymentExpandedTable(record),
              },
              pagination: false,
              scroll: {
                y: windowHeight - 340,
              }
            }}
            paginationProps={{
              pageSize: pageObject.pageSize,
              current: pageObject.pageNumber,
              total: paymentsData?.total || 0,
              onChange: (page, pageSize) => {
                setPageObject({
                  pageSize,
                  pageNumber: page,
                });
              }
            }}
          />
        </ContentContainer>
      </PageContainer>
      <Modal
        title={"創建出糧紀錄"}
        visible={showCreatePaymentModal}
        footer={null}
        onCancel={onCreatePaymentModalCancel}
      >
        <Form
          form={createPaymentForm}
          layout="vertical"
          onFinish={onCreatePaymentModalSubmit}
        >
          <Form.Item
            name="datePeriod"
            label="結算時段"
            rules={[{ required: true, message: "請輸入日期" }]}
          >
            <DatePicker.RangePicker placeholder={["由", "至"]} />
          </Form.Item>

          <Button
            htmlType="button"
            onClick={onCreatePaymentModalCancel}
            style={{ marginRight: 20, marginTop: 20 }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={createSalaryLoading}
          >
            Submit
          </Button>
        </Form>
      </Modal>

      <Drawer
        title="出糧紀錄"
        placement="right"
        destroyOnClose
        onClose={handlePaymentDetailsClose}
        visible={showPaymentDetailsModal}
        width={376}
      >
        <Form
          form={paymentDetailsForm}
          layout="vertical"
          preserve={false}
          initialValues={paymentDetailData}
          onFinish={handlePaymentDetailsSubmit}
          onValuesChange={(changedValues) => {
            if (Object.keys(changedValues)[0] === "method") {
              setSelectedPaymentMethod(
                changedValues[Object.keys(changedValues)[0]]
              );
            }
          }}
        >
          <DrawerContent>
            <Form.Item
              name="method"
              label="出糧方式"
              // rules={[{ required: true, message: "請輸入" }]}
            >
              <HelperPaymentMethodSelect />
            </Form.Item>

            {selectedPaymentMethod === 0 && (
              <Form.Item name="referenceNo" label="支票號碼">
                <Input placeholder="支票號碼" />
              </Form.Item>
            )}

            {selectedPaymentMethod === 1 && (
              <Form.Item name="referenceNo" label="銀行戶口">
                <Select placeholder="銀行戶口">
                  {(bankDetails || []).map((item, index) => (
                    <Select.Option key={index} value={item.bankNumber}>
                      {item.bankName} - {item.bankNumber}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {selectedPaymentMethod !== 0 && selectedPaymentMethod !== 1  && (
              <Form.Item name="referenceNo" label="參考號碼">
                <Input placeholder="參考號碼" />
              </Form.Item>
            )}

            <Form.Item name="remark" label="備注">
              <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} placeholder="備注" />
            </Form.Item>

            <DrawerRow>
              <DrawerRowTitle>結算時段</DrawerRowTitle>
              <div>
                {salaryData.settleFrom} - {salaryData.settleTo}
              </div>
            </DrawerRow>

            <DrawerRow>
              <DrawerRowTitle>出糧薪金</DrawerRowTitle>
              <div>
                $
                {!salaryData.salary && salaryData.salary !== 0
                  ? "--"
                  : salaryData.salary}
              </div>
            </DrawerRow>

            <DrawerRow>
              <DrawerRowTitle>狀態</DrawerRowTitle>
              <div>{paymentStatus(paymentDetailData.status || 0)}</div>
              <DrawerLinkButton>
                {paymentDetailData.status === 0 && (
                  <CopyToClipboard
                    text={confirmPaymentText}
                    onCopy={() => alert("copied")}
                  >
                    <Button type="primary">發送確認領取提醒</Button>
                  </CopyToClipboard>
                )}
              </DrawerLinkButton>
            </DrawerRow>

            <DrawerImageTitle>參考圖片</DrawerImageTitle>
            <Upload
              listType="picture-card"
              fileList={imageList}
              onPreview={handleImageModalOpen}
              // onChange={this.handleChange}
              beforeUpload={handleImageUpload}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
          </DrawerContent>
          <DrawerButtonGroup>
            <Button
              htmlType="button"
              style={{ marginRight: 20 }}
              onClick={() => handlePaymentDetailsClose()}
            >
              取消
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={createPaymentLoading || editPaymentLoading}
            >
              儲存
            </Button>
          </DrawerButtonGroup>
        </Form>
      </Drawer>
    </Profiler>
  );
};

const ContentContainer = styled.div`
  padding: 3.2rem;
`;

const DrawerContent = styled.div`
  margin-bottom: 60px;
  over-flow: auto;
`;

const DrawerButtonGroup = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 52px;
  background-color: #ffffff;
  border-top: 1px solid #f0f0f0;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
`;

const DrawerRow = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const DrawerRowTitle = styled.div`
  flex-grow: 1;
`;

const DrawerImageTitle = styled.div`
  margin-bottom: 10px;
`;

const DrawerLinkButton = styled.div`
  padding-left: 8px;
`;

HelperPaymentListScreen.whyDidYouRender = false;
