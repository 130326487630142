import React, {useEffect, useState} from "react";
import {DatePicker, Form, Input, Select} from "antd";
import styled from "@emotion/styled";
import {District} from "components/district";
import {Page} from "../../../utils/paging";
import moment from "moment";

const {RangePicker} = DatePicker;
// import { Project } from "types/project";

const {Option} = Select;

export interface JobSearch extends Page {
    dateFrom: string
    dateTo: string
    district: string
    status: string
    keyword: string
}

interface SearchPanelProps {
    param: JobSearch;
    setParam: (param: SearchPanelProps["param"]) => void;
}

export const SearchPanel = ({param, setParam}: SearchPanelProps) => {
    const [dateRange, setDateRange] = useState<any>([])

    useEffect(() => {
        if (param.dateFrom || param.dateTo) {
            setDateRange([moment(param.dateFrom), moment(param.dateTo)])
        }
    }, [param])

    return (
        <TopSearchBar>
            <Form layout={"inline"}>
                <Form.Item>
                    <RangePicker
                        value={dateRange}
                        onChange={(value: any) => {
                            if (value && value.length > 0) {
                                setParam({
                                    ...param,
                                    dateFrom: value[0]?.format('YYYY-MM-DD'),
                                    dateTo: value[1]?.format('YYYY-MM-DD'),
                                })
                            } else {
                                setParam({
                                    ...param,
                                    dateFrom: '',
                                    dateTo: '',
                                })
                            }

                            setDateRange(value)
                        }
                        }
                    />
                </Form.Item>
                <Form.Item>
                    <District
                        value={param.district && [...param.district.split(",")]}
                        onChange={(value: any) =>
                            setParam({
                                ...param,
                                district: value.toString(),
                            })
                        }
                    />
                </Form.Item>

                <Form.Item>
                    <Input
                        placeholder="工作編號，僱主, 電話"
                        allowClear
                        value={param.keyword}
                        onChange={(value) =>
                            setParam({
                                ...param,
                                keyword: value.target.value,
                            })
                        }
                    />
                </Form.Item>
            </Form>
        </TopSearchBar>
    );
};

const TopSearchBar = styled.div`
  width: 100%;
  height: 64px;
  padding: 16px 20px;
  background-color: #ffffff;
`;
