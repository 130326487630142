import { useAsync } from "utils/use-async";
import { useCallback, useEffect } from "react";
import { cleanObject } from "utils/index";
import { useHttp } from "utils/http";
import { Helper } from "../screens/helper/helper-list/list";

export const useJobDetailHelperList = (param?: any) => {
  const client = useHttp();
  const { run, ...result } = useAsync<any>();

  const fetchJobs = useCallback(() => client(`tasks/${param?.uuid}/helpers`), [
    param,
    client,
  ]);

  useEffect(() => {
    run(fetchJobs(), {
      retry: fetchJobs,
    });
  }, [param, run, fetchJobs]);

  return result;
};

export const useAddJobHelper = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: any, uuid: string) => {
    return run(
      client(`tasks/${uuid}/helpers`, {
        data: { helpers: params },
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useAddJobHelperManual = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (helperUuid: string, taskUuid: string) => {
    return run(
      client(`tasks/${taskUuid}/helpers/manual`, {
        data: { helper: helperUuid },
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useRemoveJobHelper = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: {
    helperUuid: string;
    taskUuid: string;
    reason: number;
    remark?: string;
  }) => {
    return run(
      client(`tasks/${params.taskUuid}/helpers/${params.helperUuid}`, {
        data: params,
        method: "DELETE",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};
