import { useAsync } from "utils/use-async";
import { useCallback, useEffect } from "react";
import { cleanObject } from "utils/index";
import { useHttp } from "utils/http";
import { EmployerInvoiceRecord } from "types/employer-invoice-record";
import {JobSearch} from "../screens/job/all-jobs/search-panel";
import {Page} from "./paging";
import {EmployerSearch} from "../screens/employer-invoices/search-panel";

export const useEmployerInvoices = (param?: EmployerSearch, pageObject?: Page) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{
    list: EmployerInvoiceRecord[];
    total: number;
  }>();

  const fetchEmployerInvoices = useCallback(
    () =>
      client(`employers/invoices`, { data: cleanObject({ ...param, ...pageObject } || {}) }),
    [client, param]
  );

  useEffect(() => {
    run(fetchEmployerInvoices(), {
      retry: fetchEmployerInvoices,
    });
  }, [run, fetchEmployerInvoices]);

  return result;
};

export const useCreateEmployerInvoices = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: { settleFrom: string; settleTo: string }) => {
    return run(
      client(`employers/task_invoices`, {
        data: params,
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};
