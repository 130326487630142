import React from "react";
import { Table } from "antd";
import { TableProps } from "antd/es/table";
import styled from "@emotion/styled";
import { TablePagination } from "components/table-pagination";
import useWindowSize from "hooks/useWindowSize";
import {HelperAttendance} from "../../types/helper-attendance";
import {HelperPaymentExpandedTable} from "./helper-payment-expanded-table";

interface ListProps {
  refresh?: () => void;
  tableHeight?: number;
  tableWidth?: number;
  tableProps?: TableProps<any>,
  paginationProps?: {
    pageSize: number;
    current: number;
    total: number;
    onChange: (page: number, pageSize: number) => void;
  }
}

export const CommonList = ({ ...props }: ListProps) => {
  const { windowWidth, windowHeight } = useWindowSize();

  return (
    <div>
      <ListContainer>
      {/*<ListContainer style={{width: `${(props.tableWidth || windowWidth) - 260}px`, height: `${props.tableHeight || windowHeight - 320}px`}}>*/}
        <Table
          rowKey={(record) => record.uuid}
          size="small"
          {...props.tableProps}
          // scroll={{y: windowHeight - 340,}}
        />
      </ListContainer>
      <TablePagination
        pageSize={props.paginationProps?.pageSize}
        pageSizeOptions={["10", "25", "50", "100"]}
        showSizeChanger={true}
        showQuickJumper={false}
        current={props.paginationProps?.current}
        total={props.paginationProps?.total}
        onChange={props.paginationProps?.onChange}
      />
    </div>
  );
};

const ListContainer = styled.div`
  overflow-y: hidden;
`;
