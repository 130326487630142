import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/es/form";
import styled from "@emotion/styled";
import { Employer } from "../employer-list/list";
import { AdminSelect } from "components/admin-select";
import { District } from "components/district";
import { employerStatus, gender } from "utils/common";
import { changeArrayToStringInObject } from "utils";

interface SearchPanelProps extends Employer {
  onUpdate: (value: Partial<Employer>) => void;
  loading: boolean;
  reload: () => void;
}

export const IndividualEmployerDetails = ({
  onUpdate,
  reload,
  loading,
  ...data
}: SearchPanelProps) => {
  const formatData = {
    uuid: data.uuid,
    contactPerson: data.contactPerson,
    contactNumber: data.contactNumber,
    district: data?.district?.split(",") || [],
    address: data.address,
    email: data.email,
    status: data.status,
    personInCharge: data.personInCharge?.id,
  };

  const editBasicInfoFormRef = React.createRef<FormInstance>();
  const [editBasicInfoForm] = Form.useForm();
  const [showEditBasicInfoModal, setShowEditBasicInfoModal] = useState(false);

  const editContactFormRef = React.createRef<FormInstance>();
  const [editContactForm] = Form.useForm();
  const [showEditContactModal, setShowEditContactModal] = useState(false);

  const editAdminInfoFormRef = React.createRef<FormInstance>();
  const [editAdminInfoForm] = Form.useForm();
  const [showEditAdminInfoModal, setShowEditAdminInfoModal] = useState(false);

  const editPatientFormRef = React.createRef<FormInstance>();
  const [editPatientForm] = Form.useForm();
  const [showEditPatientModal, setShowEditPatientModal] = useState(false);

  const onEditFormClick = (type: string) => {
    switch (type) {
      case "basic":
        setShowEditBasicInfoModal(!showEditBasicInfoModal);
        break;
      case "contact":
        editContactForm.setFieldsValue(formatData);
        setShowEditContactModal(!showEditContactModal);
        break;
      case "adminInfo":
        setShowEditAdminInfoModal(!showEditAdminInfoModal);
        break;
      case "patient":
        setShowEditPatientModal(!showEditPatientModal);
        break;
    }
  };

  const onEditModalSubmit = async (values: Partial<Employer>) => {
    await onUpdate(changeArrayToStringInObject(values));
    reload();

    if (showEditBasicInfoModal) {
      setShowEditBasicInfoModal(!showEditBasicInfoModal);
    }

    if (showEditContactModal) {
      setShowEditContactModal(!showEditContactModal);
    }

    if (showEditAdminInfoModal) {
      setShowEditAdminInfoModal(!showEditAdminInfoModal);
    }

    if (showEditPatientModal) {
      setShowEditPatientModal(!showEditPatientModal);
    }
  };

  return (
    <>
      <SectionContainer>
        <SectionTitle>僱主信息</SectionTitle>
        <EditButton>
          <EditOutlined onClick={() => onEditFormClick("basic")} />
        </EditButton>
        <DataContainer>
          <DataTitle>僱主:</DataTitle>
          <DataContent>{data?.contactPerson}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>與病人關係:</DataTitle>
          <DataContent>{data?.relation}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>社區劵編號:</DataTitle>
          <DataContent>{data?.code}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>社區劵百分比:</DataTitle>
          <DataContent>{data?.codePercentage && `${data?.codePercentage}%`}</DataContent>
        </DataContainer>
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>聯絡信息</SectionTitle>
        <EditButton>
          <EditOutlined onClick={() => onEditFormClick("contact")} />
        </EditButton>
        <DataContainer>
          <DataTitle>聯絡電話:</DataTitle>
          <DataContent>{data?.contactNumber}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>電郵:</DataTitle>
          <DataContent>{data?.email}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>服務地區:</DataTitle>
          <DataContent>{data?.district}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>完整服務地址:</DataTitle>
          <DataContent>{data?.address}</DataContent>
        </DataContainer>
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>UniCare備註</SectionTitle>
        <EditButton>
          <EditOutlined onClick={() => onEditFormClick("adminInfo")} />
        </EditButton>
        <DataContainer>
          <DataTitle>負責人:</DataTitle>
          <DataContent>{data?.personInCharge?.username}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>狀態:</DataTitle>
          <DataContent>{employerStatus(data?.status)}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>備註:</DataTitle>
          <DataContent>{data?.remark}</DataContent>
        </DataContainer>
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>病人信息</SectionTitle>
        <EditButton>
          <EditOutlined onClick={() => onEditFormClick("patient")} />
        </EditButton>
        <DataContainer>
          <DataTitle>病人姓名:</DataTitle>
          <DataContent>{data?.patientName}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>香港身分證/護照號碼 (頭4位):</DataTitle>
          <DataContent>{data?.patientIdCardNum}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>性別:</DataTitle>
          <DataContent>{gender(data?.patientGender)}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>年齡:</DataTitle>
          <DataContent>{data?.patientAge}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>身高(cm):</DataTitle>
          <DataContent>{data?.patientHeight}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>體重(kg):</DataTitle>
          <DataContent>{data?.patientWeight}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>疾病:</DataTitle>
          <DataContent>{data?.patientDisease}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>癌症:</DataTitle>
          <DataContent>{data?.cancer}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>其他疾病:</DataTitle>
          <DataContent>{data?.otherDisease}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>喉管:</DataTitle>
          <DataContent>{data?.throatPipe}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>其他喉管:</DataTitle>
          <DataContent>{data?.otherPipe}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>身體狀況:</DataTitle>
          <DataContent>{data?.bodyCondition}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>精神狀況:</DataTitle>
          <DataContent>{data?.mentalCondition}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>活動能力:</DataTitle>
          <DataContent>{data?.ability}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>失禁:</DataTitle>
          <DataContent>{data?.incontinence}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>主要護理:</DataTitle>
          <DataContent>{data?.mainCare}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>打針:</DataTitle>
          <DataContent>{data?.injection}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>口服藥物:</DataTitle>
          <DataContent>{data?.oralDrugs}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>其他:</DataTitle>
          <DataContent>{data?.otherNursing}</DataContent>
        </DataContainer>
      </SectionContainer>

      <Modal
        title={"編輯僱主信息"}
        visible={showEditBasicInfoModal}
        footer={null}
        onCancel={() => onEditFormClick("basic")}
      >
        <Form
          form={editBasicInfoForm}
          ref={editBasicInfoFormRef}
          layout="vertical"
          initialValues={data}
          onFinish={onEditModalSubmit}
        >
          <Form.Item name="uuid" label="employerId" hidden>
            <Input placeholder="employerId" />
          </Form.Item>

          <Form.Item
            name="contactPerson"
            label="聯絡人姓名"
            rules={[{ required: true, message: "請輸入聯絡人姓名" }]}
          >
            <Input placeholder="聯絡人姓名" />
          </Form.Item>

          <Form.Item
            name="relation"
            label="與病人關係"
            rules={[{ required: true, message: "請輸入與病人關係" }]}
          >
            <Input placeholder="與病人關係" />
          </Form.Item>

          <Form.Item name="code" label="社區劵編號">
            <Input placeholder="社區劵編號" />
          </Form.Item>

          <Form.Item name="codePercentage" label="社區劵百分比">
            <InputNumber placeholder="社區劵百分比" style={{ width: "100%" }} />
          </Form.Item>

          <Button
            htmlType="button"
            onClick={() => onEditFormClick("basic")}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title={"編輯聯絡信息"}
        visible={showEditContactModal}
        footer={null}
        onCancel={() => onEditFormClick("contact")}
      >
        <Form
          form={editContactForm}
          ref={editContactFormRef}
          layout="vertical"
          initialValues={data}
          onFinish={onEditModalSubmit}
        >
          <Form.Item name="uuid" label="employerId" hidden>
            <Input placeholder="employerId" />
          </Form.Item>

          <Form.Item
            name="contactNumber"
            label="聯絡電話"
            rules={[{ required: true, message: "請輸入聯絡電話" }]}
          >
            <Input placeholder="聯絡電話" />
          </Form.Item>

          <Form.Item
            name="email"
            label="電郵"
            rules={[{ required: true, message: "請輸入電郵" }]}
          >
            <Input placeholder="電郵" />
          </Form.Item>

          <Form.Item
            name="district"
            label="服務地區"
            rules={[{ required: true, message: "請輸入服務地區" }]}
          >
            <District />
          </Form.Item>

          <Form.Item
            name="address"
            label="完整服務地址"
            rules={[{ required: true, message: "請輸入完整服務地址" }]}
          >
            <Input placeholder="完整服務地址" />
          </Form.Item>

          <Button
            htmlType="button"
            onClick={() => onEditFormClick("contact")}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title={"編輯UniCare備註"}
        visible={showEditAdminInfoModal}
        footer={null}
        onCancel={() => onEditFormClick("adminInfo")}
      >
        <Form
          form={editAdminInfoForm}
          ref={editAdminInfoFormRef}
          layout="vertical"
          initialValues={data}
          onFinish={onEditModalSubmit}
        >
          <Form.Item name="uuid" label="employerId" hidden>
            <Input placeholder="employerId" />
          </Form.Item>

          <Form.Item name="personInCharge" label="負責員工">
            <AdminSelect placeholder="負責員工" />
          </Form.Item>

          <Form.Item name="status" label="狀態">
            <Select placeholder="狀態">
              <Select.Option value={0}>啟用</Select.Option>
              <Select.Option value={1}>麻煩客</Select.Option>
              <Select.Option value={2}>停用</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="remark" label="備註">
            <Input.TextArea
              placeholder="備註"
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>

          <Button
            htmlType="button"
            onClick={() => onEditFormClick("adminInfo")}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title={"編輯病人信息"}
        visible={showEditPatientModal}
        footer={null}
        onCancel={() => onEditFormClick("patient")}
        width={1100}
      >
        <Form
          form={editPatientForm}
          ref={editPatientFormRef}
          layout="vertical"
          initialValues={data}
          onFinish={onEditModalSubmit}
        >
          <Form.Item name="uuid" label="employerId" hidden>
            <Input placeholder="employerId" />
          </Form.Item>

          <Form.Item
            name="patientName"
            label="病人姓名"
            rules={[{ required: true, message: "請輸入病人姓名" }]}
          >
            <Input placeholder="病人姓名" />
          </Form.Item>

          <Form.Item
            name="patientIdCardNum"
            label="香港身分證/護照號碼 (頭4位)"
            rules={[{ required: true, message: "請輸入香港身分證/護照號碼" }]}
          >
            <Input placeholder="香港身分證/護照號碼" />
          </Form.Item>

          <Form.Item
            name="patientGender"
            label="性別"
            rules={[{ required: true, message: "請輸入性別" }]}
          >
            <Radio.Group>
              <Radio value={1}>女性</Radio>
              <Radio value={0}>男性</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="patientAge"
            label="年齡"
            rules={[{ required: true, message: "請輸入年齡" }]}
          >
            <InputNumber min={0} max={150} placeholder="年齡" />
          </Form.Item>

          <Form.Item
            name="patientHeight"
            label="身高(cm)"
            rules={[{ required: true, message: "請輸入身高" }]}
          >
            <InputNumber min={0} max={300} placeholder="身高(cm)" />
          </Form.Item>

          <Form.Item
            name="patientWeight"
            label="體重(kg)"
            rules={[{ required: true, message: "請輸入體重" }]}
          >
            <InputNumber min={0} max={1000} placeholder="體重(kg)" />
          </Form.Item>

          <Form.Item name="patientDisease" label="疾病">
            <Checkbox.Group>
              <Row>
                <Col span={6}>
                  <Checkbox value="中風">中風</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="糖尿病">糖尿病</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="高血壓">高血壓</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="心臟病">心臟病</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="肝炎">肝炎</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="傳染病">傳染病</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="腎衰竭">腎衰竭</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item name="cancer" label="癌症">
            <Input placeholder="請註明器官/位置" />
          </Form.Item>
          <Form.Item name="otherDisease" label="其他疾病">
            <Input placeholder="請註明" />
          </Form.Item>
          <Form.Item name="throatPipe" label="喉管">
            <Checkbox.Group>
              <Row>
                <Col span={6}>
                  <Checkbox value="胃喉">胃喉</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="尿喉">尿喉</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="PEG">PEG</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="氣管造口">氣管造口</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="氧氣喉">氧氣喉</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="洗肚喉管">洗肚喉管</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item name="otherPipe" label="其他喉管">
            <Input placeholder="請註明" />
          </Form.Item>
          <Form.Item
            name="bodyCondition"
            label="身體狀況"
            rules={[{ required: true, message: "請輸入身體狀況" }]}
          >
            <Radio.Group>
              <Radio value="穩定健康">穩定健康</Radio>
              <Radio value="情況穩定">情況穩定</Radio>
              <Radio value="情況不穩定">情況不穩定</Radio>
              <Radio value="情況危重">情況危重</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="mentalCondition"
            label="精神狀況"
            rules={[{ required: true, message: "請輸入精神狀況" }]}
          >
            <Radio.Group>
              <Radio value="清醒">清醒</Radio>
              <Radio value="嗜睡/呆滯">嗜睡/呆滯</Radio>
              <Radio value="混亂/對答糊塗">混亂/對答糊塗</Radio>
              <Radio value="昏迷/不能回應">昏迷/不能回應</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="ability"
            label="活動能力"
            rules={[{ required: true, message: "請輸入活動能力" }]}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="經常下床走動">經常下床走動</Radio>
                <Radio value="需要協助才可行走">需要協助才可行走</Radio>
                <Radio value="僅限坐姿(輪椅)">僅限坐姿(輪椅)</Radio>
                <Radio value="不能移動(無法移動肢體或身體，不能轉換姿勢)">
                  不能移動(無法移動肢體或身體，不能轉換姿勢)
                </Radio>
                <Radio value="非常限制(需協助轉換姿勢，在協助下才可移動肢體或身體)">
                  非常限制(需協助轉換姿勢，在協助下才可移動肢體或身體)
                </Radio>
                <Radio value="少許限制(可控制及移動肢體或身體，但有限制，需協助轉換姿勢)">
                  少許限制(可控制及移動肢體或身體，但有限制，需協助轉換姿勢)
                </Radio>
                <Radio value="沒有限制(經常可獨立移動肢體或身體)">
                  沒有限制(經常可獨立移動肢體或身體)
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="incontinence"
            label="失禁"
            rules={[{ required: true, message: "請輸入失禁" }]}
          >
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="沒有">沒有</Radio>
                <Radio value="大小便失禁(不能控制大小便，約 7-10 次/天)">
                  大小便失禁(不能控制大小便，約 7-10 次/天)
                </Radio>
                <Radio value="通常小便失禁(小便約 3-6 次小便失禁/天，或過去 24 小時腹瀉)">
                  通常小便失禁(小便約 3-6 次小便失禁/天，或過去 24 小時腹瀉)
                </Radio>
                <Radio value="間中(約 1-2 次大便或小便失禁/天，但不是因為利尿藥/瀉藥，有尿喉但可克制大便)">
                  間中(約 1-2
                  次大便或小便失禁/天，但不是因為利尿藥/瀉藥，有尿喉但可克制大便)
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="mainCare" label="主要護理">
            <Checkbox.Group>
              <Row>
                <Col span={5}>
                  <Checkbox value="洗肚">洗肚</Checkbox>
                </Col>
                <Col span={5}>
                  <Checkbox value="洗血">洗血</Checkbox>
                </Col>
                <Col span={5}>
                  <Checkbox value="沖涼/抹身">沖涼/抹身</Checkbox>
                </Col>
                <Col span={5}>
                  <Checkbox value="轉身">轉身</Checkbox>
                </Col>
                <Col span={4}>
                  <Checkbox value="餵食">餵食</Checkbox>
                </Col>
                <Col span={5}>
                  <Checkbox value="抽痰">抽痰</Checkbox>
                </Col>
                <Col span={5}>
                  <Checkbox value="洗傷口">洗傷口</Checkbox>
                </Col>
                <Col span={5}>
                  <Checkbox value="換尿片">換尿片</Checkbox>
                </Col>
                <Col span={5}>
                  <Checkbox value="簡單運動">簡單運動</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item name="injection" label="打針">
            <Input placeholder="註明藥物名稱" />
          </Form.Item>

          <Form.Item name="oralDrugs" label="口服藥物">
            <Input placeholder="註明藥物名稱" />
          </Form.Item>

          <Form.Item name="otherNursing" label="其他護理">
            <Input placeholder="請註明" />
          </Form.Item>

          <Button
            htmlType="button"
            onClick={() => onEditFormClick("patient")}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};

const SectionContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
`;

const SectionTitle = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 6px;
`;

const EditButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const DataContainer = styled.div`
  // padding-top: 2px;
`;

const DataTitle = styled.div`
  display: inline-block;
  font-size: 14px;
  color: #8d939e;
  padding-right: 5px;
`;

const DataContent = styled.div`
  display: inline-block;
  font-size: 14px;
  color: #3c3e42;
  font-weight: 500;
`;
