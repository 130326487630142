import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Row,
  Col,
  Modal,
} from "antd";
import { FormInstance } from "antd/es/form";
import styled from "@emotion/styled";
import { TopBar } from "screens/helper-prices/top-bar";
import { HelperPrice } from "screens/helper-prices/helper-price";
import { useDebounce, useDocumentTitle, cleanObject } from "utils";

import { Profiler } from "components/profiler";
import {
  useHelperPositions,
  useAddHelperPosition,
  useEditHelperPosition,
} from "../../utils/helper-position";
import PageContainer from "../../components/layout/PageContainer";

export const HelperPricesScreen = () => {
  useDocumentTitle("幫手職級價目表", false);

  const helperPriceFormRef = React.createRef<FormInstance>();
  const [helperPriceForm] = Form.useForm();
  const [showHelperPriceModal, setShowHelperPriceModal] = useState(false);

  const [helperPriceData, setHelperPriceData] = useState({
    uuid: null,
    type: 0,
    name: "",
    description: "",
    helper1: null,
    helper2: null,
    helper3: null,
    helper4: null,
    helper5: null,
    helper6: null,
    helper7: null,
    helper8: null,
    helper9: null,
    helper10: null,
    helper11: null,
    helper12: null,
    employer1: null,
    employer2: null,
    employer3: null,
    employer4: null,
    employer5: null,
    employer6: null,
    employer7: null,
    employer8: null,
    employer9: null,
    employer10: null,
    employer11: null,
    employer12: null,
  });

  const [param, setParam] = useState({});
  const { isLoading, error, data, retry } = useHelperPositions(
    useDebounce(param, 200)
  );

  const {
    mutate: addMutate,
    isLoading: addLoading,
    isError: isAddError,
    isSuccess: isAddSuccess,
  } = useAddHelperPosition();
  const {
    mutate: editMutate,
    isLoading: editLoading,
    isError: isEditError,
    isSuccess: isEditSuccess,
  } = useEditHelperPosition();

  const [activeTab, setActiveTab] = useState("1");

  const handleAddNewPriceClick = () => {
    setHelperPriceData({
      uuid: null,
      type: activeTab === "1" ? 0 : 1,
      name: "",
      description: "",
      helper1: null,
      helper2: null,
      helper3: null,
      helper4: null,
      helper5: null,
      helper6: null,
      helper7: null,
      helper8: null,
      helper9: null,
      helper10: null,
      helper11: null,
      helper12: null,
      employer1: null,
      employer2: null,
      employer3: null,
      employer4: null,
      employer5: null,
      employer6: null,
      employer7: null,
      employer8: null,
      employer9: null,
      employer10: null,
      employer11: null,
      employer12: null,
    });
    helperPriceForm.setFieldsValue({
      uuid: null,
      type: activeTab === "1" ? 0 : 1,
      name: "",
      description: "",
      helper1: null,
      helper2: null,
      helper3: null,
      helper4: null,
      helper5: null,
      helper6: null,
      helper7: null,
      helper8: null,
      helper9: null,
      helper10: null,
      helper11: null,
      helper12: null,
      employer1: null,
      employer2: null,
      employer3: null,
      employer4: null,
      employer5: null,
      employer6: null,
      employer7: null,
      employer8: null,
      employer9: null,
      employer10: null,
      employer11: null,
      employer12: null,
    });
    setShowHelperPriceModal(true);
  };

  const handleEditPriceClick = (priceData: any) => {
    setHelperPriceData(priceData);
    helperPriceForm.setFieldsValue(priceData);
    setShowHelperPriceModal(true);
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const handleHelperPriceModalCancel = () => {
    setShowHelperPriceModal(false);
  };

  const handleHelperPriceModalSubmit = async (values: any) => {
    if (helperPriceData.uuid) {
      await editMutate(values);
    } else {
      const data1 = {
        ...values,
        type: 0,
      };
      const data2 = {
        ...values,
        type: 1,
      };
      await Promise.all([
        addMutate(cleanObject(data1)),
        addMutate(cleanObject(data2)),
      ]);
    }
    retry();
    setShowHelperPriceModal(false);
  };

  return (
    <Profiler id={"幫手職級價目表"}>
      <PageContainer>
        <TopBar
          activeTab={activeTab}
          tabChange={handleTabChange}
          handleAddNewPriceClick={handleAddNewPriceClick}
        />

        {activeTab === "1" && (
          <ContentContainer>
            <HelperPrice
              positionData={data?.list?.filter((item) => item.type === 0) || []}
              handleEditPriceClick={handleEditPriceClick}
            />
          </ContentContainer>
        )}

        {activeTab === "2" && (
          <ContentContainer>
            <HelperPrice
              positionData={data?.list?.filter((item) => item.type === 1) || []}
              handleEditPriceClick={handleEditPriceClick}
            />
          </ContentContainer>
        )}
      </PageContainer>

      <Modal
        title={helperPriceData.uuid ? "編輯幫手職級價錢" : "新增幫手職級價錢"}
        visible={showHelperPriceModal}
        footer={null}
        onCancel={() => handleHelperPriceModalCancel()}
      >
        <Form
          form={helperPriceForm}
          ref={helperPriceFormRef}
          layout="vertical"
          // initialValues={helperPriceData}
          onFinish={handleHelperPriceModalSubmit}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid" />
          </Form.Item>

          {/* <Form.Item
            name="type"
            label="類型"
            rules={[{ required: true, message: "請輸入類型" }]}
          >
            <Select placeholder="類型">
              <Select.Option value={0}>私人護理</Select.Option>
              <Select.Option value={1}>院舍護理</Select.Option>
            </Select>
          </Form.Item> */}

          <Form.Item
            name="name"
            label="職級"
            rules={[{ required: true, message: "請輸入職級" }]}
          >
            <Input placeholder="職級" />
          </Form.Item>

          <Form.Item name="description" label="描述">
            <Input placeholder="描述" />
          </Form.Item>

          <Row>
            <Col span={12}>
              <Form.Item
                name="helper1"
                label="幫手1小時價錢"
                rules={[{ required: true, message: "請輸入1小時價錢" }]}
              >
                <InputNumber placeholder="1小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="employer1"
                label="僱主1小時價錢"
                rules={[{ required: true, message: "請輸入1小時價錢" }]}
              >
                <InputNumber placeholder="1小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="helper2"
                label="幫手2小時價錢"
                rules={[{ required: true, message: "請輸入2小時價錢" }]}
              >
                <InputNumber placeholder="2小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="employer2"
                label="僱主2小時價錢"
                rules={[{ required: true, message: "請輸入2小時價錢" }]}
              >
                <InputNumber placeholder="2小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="helper3"
                label="幫手3小時價錢"
                rules={[{ required: true, message: "請輸入3小時價錢" }]}
              >
                <InputNumber placeholder="3小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="employer3"
                label="僱主3小時價錢"
                rules={[{ required: true, message: "請輸入3小時價錢" }]}
              >
                <InputNumber placeholder="3小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="helper4"
                label="幫手4小時價錢"
                rules={[{ required: true, message: "請輸入4小時價錢" }]}
              >
                <InputNumber placeholder="4小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="employer4"
                label="僱主4小時價錢"
                rules={[{ required: true, message: "請輸入4小時價錢" }]}
              >
                <InputNumber placeholder="4小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="helper5"
                label="幫手5小時價錢"
                rules={[{ required: true, message: "請輸入5小時價錢" }]}
              >
                <InputNumber placeholder="5小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="employer5"
                label="僱主5小時價錢"
                rules={[{ required: true, message: "請輸入5小時價錢" }]}
              >
                <InputNumber placeholder="5小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="helper6"
                label="幫手6小時價錢"
                rules={[{ required: true, message: "請輸入6小時價錢" }]}
              >
                <InputNumber placeholder="6小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="employer6"
                label="僱主6小時價錢"
                rules={[{ required: true, message: "請輸入6小時價錢" }]}
              >
                <InputNumber placeholder="6小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="helper7"
                label="幫手7小時價錢"
                rules={[{ required: true, message: "請輸入7小時價錢" }]}
              >
                <InputNumber placeholder="7小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="employer7"
                label="僱主7小時價錢"
                rules={[{ required: true, message: "請輸入7小時價錢" }]}
              >
                <InputNumber placeholder="7小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="helper8"
                label="幫手8小時價錢"
                rules={[{ required: true, message: "請輸入8小時價錢" }]}
              >
                <InputNumber placeholder="8小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="employer8"
                label="僱主8小時價錢"
                rules={[{ required: true, message: "請輸入8小時價錢" }]}
              >
                <InputNumber placeholder="8小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="helper9"
                label="幫手9小時價錢"
                rules={[{ required: true, message: "請輸入9小時價錢" }]}
              >
                <InputNumber placeholder="9小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="employer9"
                label="僱主9小時價錢"
                rules={[{ required: true, message: "請輸入9小時價錢" }]}
              >
                <InputNumber placeholder="9小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="helper10"
                label="幫手10小時價錢"
                rules={[{ required: true, message: "請輸入10小時價錢" }]}
              >
                <InputNumber placeholder="10小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="employer10"
                label="僱主10小時價錢"
                rules={[{ required: true, message: "請輸入10小時價錢" }]}
              >
                <InputNumber placeholder="10小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="helper11"
                label="幫手11小時價錢"
                rules={[{ required: true, message: "請輸入11小時價錢" }]}
              >
                <InputNumber placeholder="11小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="employer11"
                label="僱主11小時價錢"
                rules={[{ required: true, message: "請輸入11小時價錢" }]}
              >
                <InputNumber placeholder="11小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="helper12"
                label="幫手12小時價錢"
                rules={[{ required: true, message: "請輸入12小時價錢" }]}
              >
                <InputNumber placeholder="12小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="employer12"
                label="僱主12小時價錢"
                rules={[{ required: true, message: "請輸入12小時價錢" }]}
              >
                <InputNumber placeholder="12小時價錢" style={{ width: 200 }} />
              </Form.Item>
            </Col>
          </Row>

          <Button
            htmlType="button"
            onClick={() => handleHelperPriceModalCancel()}
            style={{ marginRight: 20, marginTop: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>
    </Profiler>
  );
};

const ContentContainer = styled.div`
  margin-top: 3px;
  // padding: 3.2rem;
  padding: 2rem;
  flex-direction: column;
  position: relative;
  overflow: auto;
`;

HelperPricesScreen.whyDidYouRender = false;
