import { useAsync } from "utils/use-async";
import { useCallback, useEffect } from "react";
import { cleanObject } from "utils/index";
import { useHttp } from "utils/http";
import { Job } from "../screens/job/all-jobs/list";
import { JobGeneralData } from "../types/job-general-data";

export const useJobDetail = (param?: Partial<JobGeneralData>) => {
  const client = useHttp();
  const { run, ...result } = useAsync<JobGeneralData>();

  const fetchJobs = useCallback(
    () => client(`tasks/${param?.uuid}/application`, {}),
    [param, client]
  );

  useEffect(() => {
    run(fetchJobs(), {
      retry: fetchJobs,
    });
  }, [param, run, fetchJobs]);

  return result;
};

export const useApplyJob = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: { uuid: string; phone: string }) => {
    return run(
      client(`tasks/${params?.uuid}/application`, {
        data: params,
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useCheckJobDetail = () => {
  // const client = useHttp();
  // const { run, ...result } = useAsync<JobGeneralData>();

  // const fetchJobs = useCallback(
  //   () => client(`tasks/${param?.uuid}/records/attendance?phone=${param?.phone}`, {}),
  //   [param, client]
  // );

  // useEffect(() => {
  //   run(fetchJobs(), {
  //     retry: fetchJobs,
  //   });
  // }, [param, run, fetchJobs]);

  // return result;

  interface JobData {
    uuid: string;
    attendance: number;
    helper: {
      uuid: string;
    };
    taskRecord: {
      uuid: string;
      startDate: string;
      startTime: string;
      workingHours: number;
      task: {
        address: string;
        taskNo: string;
        employer: {
          type: number;
          companyName: string;
          patientGender: number;
          patientAge: number;
          patientHeight: number;
          patientWeight: number;
        };
      };
    };
  }

  const { run, ...asyncResult } = useAsync<JobData>();
  const client = useHttp();
  const mutate = (params: { uuid: string; phone: string }) => {
    return run(
      client(`tasks/${params?.uuid}/records/attendance`, {
        data: {
          phone: params.phone,
        },
        method: "GET",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useCheckoutJob = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: {
    uuid: string;
    attendanceUuid: string;
    phone: string;
  }) => {
    return run(
      client(`tasks/${params?.uuid}/records/attendance`, {
        data: {
          attendanceUuid: params.attendanceUuid,
          phone: params.phone,
        },
        method: "PUT",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};
