import React, { useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import styled from "@emotion/styled";
import { District } from "components/district";
import { Page } from "../../utils/paging";
import { HelperPositionSelect } from "components/helper-position-select";
import { DatePicker, Space } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const { Option } = Select;

export interface CreditDebitNoteSearch extends Page {
  // district: string;
  // position: string;
  // status: string;
  keyword: string;
}

interface SearchPanelProps {
  param: CreditDebitNoteSearch;
  setParam: (param: SearchPanelProps["param"]) => void;
}

export const SearchPanel = ({ param, setParam }: SearchPanelProps) => {
  return (
    <TopSearchBar>
      <Form layout={"inline"}>
        {/* <Form.Item>
                <District
                  value={param.district}
                  style={{width: 200}}
                  allowClear
                  onChange={(value: any) =>
                    setParam({
                        ...param,
                        district: value,
                    })
                }
                />
              </Form.Item> */}

        {/* <Form.Item>
          <HelperPositionSelect
            value={param.position}
            style={{ width: 200 }}
            allowClear
            onChange={(value: any) =>
              setParam({
                ...param,
                position: value,
              })
            }
          />
        </Form.Item>

        <Form.Item>
          <Select
            value={param.status}
            style={{ width: 200 }}
            allowClear
            // mode={"multiple"}
            options={[
              { label: "未付款", value: "0" },
              { label: "已付款", value: "1" },
              { label: "取消", value: "2" },
            ]}
            onChange={(value: any) =>
              setParam({
                ...param,
                status: value,
              })
            }
          />
        </Form.Item> */}

        <Form.Item>
          <Input
            placeholder="僱主姓名, ID, 電話"
            allowClear
            value={param.keyword}
            onChange={(value) =>
              setParam({
                ...param,
                keyword: value.target.value,
              })
            }
          />
        </Form.Item>
      </Form>
    </TopSearchBar>
  );
};

const TopSearchBar = styled.div`
  width: 100%;
  height: 64px;
  padding: 16px 20px;
  background-color: #ffffff;
`;
