import React, { useEffect, useState } from "react";
import { Button, Modal, Input, Select, Table, Form } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { gender } from "utils/common";
import {
  useAddJobHelper,
  useAddJobHelperManual,
  useRemoveJobHelper,
} from "utils/job-helper";
import { useParams } from "react-router-dom";
import { HelperSelect } from "components/helper-select";

interface HelperGeneralData {
  uuid?: string;
  nameCn?: string;
  nameEn?: string;
  phone: string;
  gender?: number;
  position?: string;
}

interface HelperArray {
  taskUuid: string;
  appliedHelpers: HelperGeneralData[];
  selectedHelpers: HelperGeneralData[];
  helpers: HelperGeneralData[];
  oldHelpers: HelperGeneralData[];
  selectedHelperIds: any;
  status?: number;
  reloadHelper: () => void;
  reloadActivityLog: () => void;
}

export const HelperData = (props: HelperArray) => {
  const {
    taskUuid,
    appliedHelpers,
    selectedHelpers,
    helpers,
    oldHelpers,
    selectedHelperIds,
    status,
    reloadHelper,
    reloadActivityLog,
  } = props;

  const [addHelperForm] = Form.useForm();
  const [showAddHelperModal, setShowAddHelperModal] = useState(false);
  const [removeHelperForm] = Form.useForm();
  const [showRemoveHelperModal, setShowRemoveHelperModal] = useState(false);
  const [showSelectHelperModal, setShowSelectHelperModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [removeHelperData, setRemoveHelperData] = useState({
    helperUuid: "",
    name: "",
  });

  const { id } = useParams();
  const { mutate, isLoading, isError, isSuccess, data } = useAddJobHelper();
  const {
    mutate: addHelperManual,
    isLoading: addHelperManualLoading,
    isSuccess: isAddHelperManualSuccess,
  } = useAddJobHelperManual();
  const {
    mutate: removeHelper,
    isLoading: removeHelperLoading,
    isSuccess: isRemoveHelperSuccess,
  } = useRemoveJobHelper();

  const columns: ColumnsType<HelperGeneralData> = [
    {
      title: "幫手名字",
      dataIndex: "nameCn",
      key: "nameCn",
      render: (text, record) => record.nameCn || record.nameEn,
    },
    {
      title: "性別",
      dataIndex: "gender",
      key: "gender",
      render: (text, record) => {
        return gender(parseInt(text));
      },
    },
    {
      title: "職級",
      dataIndex: "position",
      key: "position",
    },
  ];

  useEffect(() => {
    if (isSuccess) {
      reloadHelper();
      reloadActivityLog();
      setShowSelectHelperModal(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isAddHelperManualSuccess) {
      reloadHelper();
      reloadActivityLog();
      setShowAddHelperModal(false);
    }
  }, [isAddHelperManualSuccess]);

  useEffect(() => {
    if (isRemoveHelperSuccess) {
      reloadHelper();
      reloadActivityLog();
      setShowRemoveHelperModal(false);
    }
  }, [isRemoveHelperSuccess]);

  useEffect(() => {
    if (selectedHelperIds.length > 0) {
      setSelectedRow(selectedHelperIds);
    }
  }, [selectedHelperIds]);

  const handleSelectHelperOpen = () => {
    setShowSelectHelperModal(true);
  };

  const onSelectHelperModalSubmit = () => {
    mutate(selectedRow, id);
  };

  const onAddHelperModalSubmit = async (values: any) => {
    await addHelperManual(values.helper, id);
  };

  const handleRemoveHelperOpen = (
    helperUuid: string | undefined,
    name: string | undefined
  ) => {
    if (helperUuid) {
      setRemoveHelperData({
        helperUuid,
        name: name || "",
      });
      removeHelperForm.setFieldsValue({ helperUuid: helperUuid });
      setShowRemoveHelperModal(true);
    }
  };

  const onRemoveHelperModalCancel = () => {
    setShowRemoveHelperModal(false);
  };

  const onRemoveHelperModalSubmit = async (values: any) => {
    const submitData = {
      ...values,
      taskUuid,
    };
    await removeHelper(submitData);
  };

  return (
    <>
      <Container>
        <TitleContainer>
          <Title>幫手資料</Title>
          {(status === 0 || status === 1 || status === 2) && (
            <AddButton>
              <UsergroupAddOutlined
                style={{ color: "#3C3E42", fontSize: 24 }}
                onClick={() => setShowAddHelperModal(true)}
              />
            </AddButton>
          )}
        </TitleContainer>

        {selectedHelpers.length === 0 ? (
          <HelperApplyTextContainer>
            <HelperApplyText>
              幫手申請人數: {appliedHelpers.length}
            </HelperApplyText>
            {appliedHelpers.length > 0 && (
              <Button type="primary" onClick={() => handleSelectHelperOpen()}>
                確認幫手名單
              </Button>
            )}
          </HelperApplyTextContainer>
        ) : (
          <>
            <Subtitle>現任幫手 ({selectedHelpers.length})</Subtitle>
            {selectedHelpers.map((item, index) => (
              <HelperContainer key={item.uuid}>
                <HelperLogo>
                  <UserOutlined style={{ color: "#8D939E" }} />
                </HelperLogo>
                <HelperMainContainer>
                  <HelperName>
                    {item.nameCn || item.nameEn} ({item.phone})
                  </HelperName>
                  <HelperType>
                    {gender(item.gender)} - {item.position}
                  </HelperType>
                </HelperMainContainer>
                {(status === 0 || status === 1 || status === 2) && (
                  <HelperDeleteButton>
                    <DeleteOutlined
                      style={{ color: "#3C3E42" }}
                      onClick={() =>
                        handleRemoveHelperOpen(
                          item.uuid,
                          item.nameCn || item.nameEn
                        )
                      }
                    />
                  </HelperDeleteButton>
                )}
              </HelperContainer>
            ))}
          </>
        )}

        {oldHelpers.length > 0 && (
          <>
            <Subtitle>過往幫手 ({oldHelpers.length})</Subtitle>
            {oldHelpers.map((item: any, index: number) => (
              <HelperContainer key={item.id}>
                <HelperLogo>
                  <UserOutlined style={{ color: "#8D939E" }} />
                </HelperLogo>
                <HelperMainContainer>
                  <HelperName>
                    {item.nameCn || item.nameEn} ({item.phone})
                  </HelperName>
                  <HelperType>
                    {gender(item.gender)} - {item.position}
                  </HelperType>
                </HelperMainContainer>

                {status === 0 && (
                  <HelperDeleteButton>
                    <DeleteOutlined style={{ color: "#3C3E42" }} />
                  </HelperDeleteButton>
                )}
              </HelperContainer>
            ))}
          </>
        )}
      </Container>

      <Modal
        title={"確認幫手名單"}
        destroyOnClose
        confirmLoading={isLoading}
        visible={showSelectHelperModal}
        onOk={onSelectHelperModalSubmit}
        onCancel={() => setShowSelectHelperModal(false)}
        width={1000}
      >
        <Table<HelperGeneralData>
          rowSelection={{
            selectedRowKeys: selectedRow,
            onChange: (rows: any) => setSelectedRow(rows),
          }}
          rowKey="uuid"
          columns={columns}
          dataSource={helpers}
        />
      </Modal>

      <Modal
        title={"選擇幫手"}
        destroyOnClose
        visible={showAddHelperModal}
        footer={null}
        onCancel={() => setShowAddHelperModal(false)}
      >
        <Form
          form={addHelperForm}
          layout="vertical"
          preserve={false}
          onFinish={onAddHelperModalSubmit}
        >
          <Form.Item
            name="helper"
            label="選擇幫手"
            rules={[{ required: true, message: "選擇幫手" }]}
          >
            <HelperSelect placeholder="選擇幫手" />
          </Form.Item>

          <Button
            htmlType="button"
            onClick={() => setShowAddHelperModal(false)}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={addHelperManualLoading}
          >
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title={`移除幫手 - ${removeHelperData.name}`}
        destroyOnClose
        visible={showRemoveHelperModal}
        footer={null}
        onCancel={() => onRemoveHelperModalCancel()}
      >
        <Form
          form={removeHelperForm}
          layout="vertical"
          onFinish={onRemoveHelperModalSubmit}
        >
          <Form.Item name="helperUuid" label="helperUuid" hidden>
            <Input placeholder="helperUuid" />
          </Form.Item>

          <Form.Item
            name="reason"
            label="原因"
            rules={[{ required: true, message: "請選擇原因" }]}
          >
            <Select placeholder="原因">
              <Select.Option value={0}>幫手放棄工作</Select.Option>
              <Select.Option value={1}>幫手拒絕提供雇主服務</Select.Option>
              <Select.Option value={2}>被僱主取消工作</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="remark" label="備註">
            <Input placeholder="備註" />
          </Form.Item>

          <Button
            htmlType="button"
            onClick={() => onRemoveHelperModalCancel()}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={removeHelperLoading}
          >
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};

const Container = styled.div`
  height: calc(100vh - 190px - 6px);
  padding: 2rem;
  background-color: #fcfcfd;
  border-left: 2px solid #e0e5ef;
  overflow: auto;
`;

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Title = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
`;

const Subtitle = styled.div`
  color: #8d939e;
  font-size: 12px;
  padding-top: 20px;
`;

const AddButton = styled.div`
  position: absolute;
  top: 4px;
  right: 10px;
`;

const HelperContainer = styled.div`
  padding: 10px 12px;
  border: 2px solid #e0e5ef;
  border-radius: 10px;
  width: 100%;
  margin-top: 10px;
  position: relative;
  min-height: 62px;
`;

const HelperLogo = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const HelperDeleteButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const HelperMainContainer = styled.div`
  margin-left: 36px;
  margin-right: 36px;
`;

const HelperName = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

const HelperType = styled.div`
  color: #8d939e;
  font-size: 12px;
  padding-top: 2px;
`;

const HelperApplyTextContainer = styled.div`
  text-align: center;
  padding-top: 80px;
`;

const HelperApplyText = styled.div`
  color: #8d939e;
  padding-bottom: 30px;
`;
