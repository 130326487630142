import React from "react";
import moment from "moment";
import styled from "@emotion/styled";
import {Row, Col} from "antd";
import {CompanyLogo} from "components/company-logo";
import {CompanySignature} from "components/company-signature";
import {paymentMethod, paymentMethodEng} from "../utils/common";

type InvoiceData = {
  invoiceDetails?: any;
  printType?: string
};

export class ComponentToPrint extends React.PureComponent<InvoiceData> {
  render() {
    const {invoiceDetails, printType} = this.props;

    const toTotal = (invoiceItem: any) => {
      if (printType === "RECEIPT") {
        let paidAmount = 0;

        if (invoiceItem?.payments && invoiceItem?.payments.length > 0) {
          for (let i = 0; i < invoiceItem?.payments.length; i++) {
            paidAmount += invoiceItem?.payments[i].amount;
          }
        }

        return invoiceItem?.total - paidAmount;
      } else {
        return invoiceItem?.total;
      }
    }

    return (
      <PrintContainer>
        <InvoiceHeader>
          <CompanyLogo/>
          <CompanyDataContainer>
            <Title>{printType}</Title>
            <BrandName>Unicare360</BrandName>
            <CompanyBasicData>Devco Technology Limited</CompanyBasicData>
            <CompanyBasicData>1/F, On Lok Yuen Building,</CompanyBasicData>
            <CompanyBasicData>
              25 Des Voeux Road Central, Central,
            </CompanyBasicData>
            <CompanyBasicData>Hong Kong</CompanyBasicData>

            <div style={{paddingTop: 10}}>
              <CompanyBasicData>98675630</CompanyBasicData>
              <CompanyBasicData>www.unicare360.com</CompanyBasicData>
            </div>
          </CompanyDataContainer>
        </InvoiceHeader>

        <hr
          style={{
            borderTop: "2px solid #DEE1E2",
            marginTop: 10,
            marginBottom: 10,
          }}
        />

        <EmployerDataContainer>
          <div>
            <BillTo>BILL TO</BillTo>
            <EmployerData>{invoiceDetails?.employer.companyName || invoiceDetails?.employer.contactPerson}</EmployerData>
            <EmployerData>{invoiceDetails?.employer.address}</EmployerData>
            <div style={{paddingTop: 10}}>
              <EmployerData>{invoiceDetails?.employer.contactNumber}</EmployerData>
            </div>
          </div>
          <div>
            <FlexBox>
              <InvoiceDataLeft>
                <BoldText>Invoice Number:</BoldText>
                <BoldText>Invoice Date:</BoldText>
                <BoldText>Payment Due:</BoldText>
                <BoldText>Amount Due (HKD):</BoldText>
              </InvoiceDataLeft>
              <InvoiceDataRight>
                <NormalText>{invoiceDetails?.invoiceNo}</NormalText>
                <NormalText>
                  {invoiceDetails?.invoiceDate
                    ? moment(invoiceDetails?.invoiceDate).format("LL")
                    : "/"}
                </NormalText>
                <NormalText>
                  {invoiceDetails?.dueDate
                    ? moment(invoiceDetails?.dueDate).format("LL")
                    : "/"}
                </NormalText>
                <BoldText>{`$${invoiceDetails?.total}`}</BoldText>
              </InvoiceDataRight>
            </FlexBox>
          </div>
        </EmployerDataContainer>

        <div>
          <Row
            style={{
              backgroundColor: "#444444",
              color: "#FFFFFF",
              fontWeight: "bold",
              padding: "6px 30px"
            }}
          >
            <Col span={10}>Items</Col>
            <Col span={3} style={{textAlign: "center"}}>
              Quantity
            </Col>
            <Col span={5} style={{textAlign: "right"}}>
              Price
            </Col>
            <Col span={6} style={{textAlign: "right"}}>
              Amount
            </Col>
          </Row>
          {(invoiceDetails?.fixedInvoiceItems || []).map(
            (item: any, index: number) => (
              <Row key={index} style={{padding: "10px 30px 0px 30px", color: "#000000"}}>
                <Col span={10} style={{whiteSpace: "pre", lineHeight: 1.2}}>
                  {item.itemName}
                </Col>
                <Col span={3} style={{textAlign: "center"}}>
                  {item.quantity}
                </Col>
                <Col
                  span={5}
                  style={{textAlign: "right"}}
                >{`$${item.cost}`}</Col>
                <Col
                  span={6}
                  style={{textAlign: "right"}}
                >{`$${item.subTotal}`}</Col>
              </Row>
            )
          )}
          {(invoiceDetails?.invoiceItems || []).map(
            (item: any, index: number) => (
              <Row key={index} style={{padding: "10px 30px 0px 30px", color: "#000000"}}>
                <Col span={10} style={{whiteSpace: "pre", lineHeight: 1.2}}>
                  {item.itemName}
                </Col>
                <Col span={3} style={{textAlign: "center"}}>
                  {item.quantity}
                </Col>
                <Col
                  span={5}
                  style={{textAlign: "right"}}
                >{`$${item.cost}`}</Col>
                <Col
                  span={6}
                  style={{textAlign: "right"}}
                >{`$${item.subTotal}`}</Col>
              </Row>
            )
          )}
        </div>

        <hr
          style={{
            borderTop: "2px solid #DEE1E2",
            marginTop: 10,
            marginBottom: 10,
          }}
        />

        <Row style={{padding: "2px 30px 0px 30px", color: "#000000"}}>
          <Col span={8}></Col>
          <Col span={12} style={{textAlign: "right", fontWeight: "bold"}}>
            Total:
          </Col>
          <Col
            span={4}
            style={{textAlign: "right"}}
          >{`$${invoiceDetails?.total}`}</Col>
        </Row>
        { printType === "RECEIPT" && invoiceDetails?.payments &&
          invoiceDetails?.payments?.length > 0 &&
          invoiceDetails?.payments.map((item: any) =>
            <Row style={{padding: "2px 30px 0px 30px", color: "#000000"}}>
              <Col span={8}></Col>
              <Col span={12} style={{textAlign: "right", fontWeight: "bold"}}>
                Payment on {moment(item).format("MMMM DD, YYYYY")} using {paymentMethodEng(item.method)}:
              </Col>
              <Col
                span={4}
                style={{textAlign: "right"}}
              >{`$${item.amount}`}</Col>
            </Row>
          )
        }

        <Row style={{paddingTop: 2, color: "#000000"}}>
          <Col span={8}></Col>
          <Col span={16}>
            <hr
              style={{
                borderTop: "2px solid #DEE1E2",
                marginTop: 10,
                marginBottom: 10,
              }}
            />
          </Col>
        </Row>
        <Row style={{padding: "2px 30px 0px 30px", color: "#000000"}}>
          <Col span={8}></Col>
          <Col span={12} style={{textAlign: "right", fontWeight: "bold"}}>
            Amount Due (HKD):
          </Col>
          <Col
            span={4}
            style={{textAlign: "right", fontWeight: "bold"}}
          >
            {toTotal(invoiceDetails)}
          </Col>
        </Row>
        {
         ( printType === "INVOICE" || printType === "QUOTATION") &&
            <>
                <TermsContainer>
                    <TermsItem style={{fontWeight: "bold"}}>Notes / Terms</TermsItem>
                    <TermsItem>
                        Please transfer the payment to the following account
                    </TermsItem>
                    <TermsItem>- Bank Name: HSBC</TermsItem>
                    <TermsItem>- Bank Account Name: Devco Technology Limited</TermsItem>
                    <TermsItem>- Bank Account Number: 819-720293-838</TermsItem>
                    <TermsItem>
                        If you have any question, please feel free to contact us on phone
                        9867 5630.
                    </TermsItem>
                </TermsContainer>

                <CompanySignatureContainer>
                    <CompanySignature/>
                </CompanySignatureContainer>
            </>
        }

        {printType !== "QUOTATION" && invoiceDetails?.remark &&
          <Row style={{padding: "2px 30px 0px 30px", color: "#000000"}}>
            <Col span={8}>

              REMARK:
              <div>
                {invoiceDetails.remark}
              </div>
            </Col>
          </Row>
        }

        <FooterMessage>Thank you for your business!</FooterMessage>

        <div className="page-footer"></div>

        {/* <div className="print-container" style={{ margin: 0, padding: 40 }}>
          <div className="page-break">
            qwkehjqkwhe
          </div>
          Thank you for your business!
        </div> */}
      </PrintContainer>
    );
  }
}

const PrintContainer = styled.div`

`;

const InvoiceHeader = styled.div`
  padding: 0px 30px 0px 30px;
  display: flex;
  justify-content: space-between;
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CompanyDataContainer = styled.div`
  text-align: right;
`;

const Title = styled.div`
  font-size: 48px;
  font-weight: 500;
  color: #000000;
`;

const BrandName = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  line-height: 1.2;
`;

const CompanyBasicData = styled.div`
  font-size: 13px;
  color: #000000;
  line-height: 1.2;
`;

const BillTo = styled.div`
  font-size: 16px;
  color: #8c959a;
  line-height: 1.2;
  font-weight: 600;
`;

const EmployerDataContainer = styled.div`
  padding: 30px;
  display: flex;
  justify-content: space-between;
`;

const EmployerData = styled.div`
  font-size: 14px;
  color: #000000;
  line-height: 1.2;
  font-weight: 600;
`;

const InvoiceDataLeft = styled.div`
  text-align: right;
`;

const InvoiceDataRight = styled.div`
  text-align: left;
`;

const BoldText = styled.div`
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  padding-right: 10px;
`;

const NormalText = styled.div`
  font-size: 14px;
  color: #000000;
`;

const TermsContainer = styled.div`
  padding-top: 40px;
  padding-left: 30px;
`;

const TermsItem = styled.div`
  line-height: 1.3;
  color: #000000;
`;

const CompanySignatureContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 40px;
  height: 100px;
`;

const FooterMessage = styled.div`
  padding-top: 100px;
  display: flex;
  justify-content: center;
`;
