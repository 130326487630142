import React, { useState, useEffect } from "react";
import moment from "moment";
import {Button, Checkbox, DatePicker, Form, Modal} from "antd";
import styled from "@emotion/styled";
import {ColumnsType} from "antd/es/table";
import {EmployerInvoiceRecord} from "types/employer-invoice-record";
import {invoiceRecordStatus} from "utils/common";
import {dateDisplay} from "utils/moment-util";
import { CommonList } from "components/listing/common-list";
import { InvoiceExpandedTable } from "components/listing/invoice-expanded-table";
import { useDebounce, useDocumentTitle } from "utils";
import {
  useCreateEmployerInvoices,
  useEmployerInvoices,
} from "utils/employer-invoice";
import { HelperAttendance } from "types/helper-attendance";
import { Row } from "components/lib";
import { Profiler } from "components/profiler";
import { useUrlQueryPaging } from "utils/paging";
import { useEmployerInvoiceSearchParams } from "../../search-utils/util";
import { SearchPanel } from "./search-panel";
import useWindowSize from "hooks/useWindowSize";
import PageContainer from "../../components/layout/PageContainer";

export const EmployerInvoiceListScreen = () => {
  useDocumentTitle("僱主發票", false);
  const { windowHeight } = useWindowSize();
  const [createInvoiceForm] = Form.useForm();
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
  const [param, setParam] = useEmployerInvoiceSearchParams();
  const [pageObject, setPageObject] = useUrlQueryPaging();

  const {
    data: invoicesData,
    isLoading: invoicesLoading,
    retry,
  } = useEmployerInvoices(useDebounce(param, 500), pageObject);
  const {
    mutate: createEmployerInvoice,
    isLoading: createInvoiceLoading,
  } = useCreateEmployerInvoices();

  const onCreateInvoiceModalOpen = () => {
    createInvoiceForm.setFieldsValue({
      dateRange: [null, null],
    });
    setShowCreateInvoiceModal(true);
  };

  const onCreateInvoiceModalCancel = () => {
    setShowCreateInvoiceModal(false);
  };

  const onCreateInvoiceModalSubmit = async (values: any) => {
    const settleFrom = moment(values?.dateRange?.[0]).format("YYYY-MM-DD");
    const settleTo = moment(values?.dateRange?.[1]).format("YYYY-MM-DD");

    await createEmployerInvoice({
      settleFrom,
      settleTo,
    });

    retry();
    setShowCreateInvoiceModal(false);
  };

  useEffect(() => {
    setPageObject({
      pageSize: pageObject.pageSize,
      pageNumber: 1,
    });
  }, [
    param.district,
    param.status,
    param.keyword,
    param.settleFrom,
    param.settleTo,
  ]);

  const columns: ColumnsType<EmployerInvoiceRecord> = [
    {
      title: "發票編號",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      render: (text, record) => (
        <a href={`/employer/${record.employerUuid}?tab=2`}>{text}</a>
      ),
    },
    {
      title: "僱主",
      dataIndex: ["employer", "contactPerson"],
      key: "contactName",
      render: (text, record) =>
        `${record.employer?.companyName || record.employer?.contactPerson} (${record.employer?.contactNumber})`
    },
    {
      title: "付款狀態",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return invoiceRecordStatus(record.status);
      },
    },
    {
      title: "總額",
      dataIndex: "total",
      render: (text: string) => `$${text}`,
    },
    {
      title: "付款日期",
      dataIndex: "paymentDate",
      render: (text, record) => {
        if (record.payments && record.payments.length > 0) {
          return dateDisplay(record.payments[record.payments?.length - 1].settleDate || "") + ` (${record.payments?.length} 次)`
        }
      },
    },
    {
      title: "已付款",
      dataIndex: "paid",
      render: (text, record) => {
        let paidAmount = 0;

        if (record?.payments && record?.payments.length > 0) {
          for (let i = 0; i < record?.payments.length; i++) {
            paidAmount += record?.payments[i].amount;
          }
        }

        return record.total > paidAmount ? (
          <span style={{color: "red"}}>${paidAmount}</span>
        ) : (
          <span>${paidAmount}</span>
        );
      },
    },
    {
      title: "發票日期",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (text, record) => dateDisplay(text),
    },
    {
      title: "截止日期",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (text, record) => dateDisplay(text),
    }
  ];

  return (
    <Profiler id={"僱主發票"}>
      <PageContainer>
        <SearchPanel param={param} setParam={setParam} />
        <ContentContainer>
          <Row marginBottom={2} between={true}>
            <h1>僱主發票列表</h1>
            <Button type="primary" onClick={() => onCreateInvoiceModalOpen()}>
              ＋創建發票
            </Button>
          </Row>

          <CommonList
            refresh={retry}
            tableProps={{
              loading: invoicesLoading,
              columns,
              dataSource: invoicesData?.list || [],
              expandable: {
                expandedRowRender: (record: {
                  helperAttendances: HelperAttendance[];
                }) => InvoiceExpandedTable(record),
              },
              pagination: false,
              scroll: {
                y: windowHeight - 320,
              }
            }}
            paginationProps={{
              pageSize: pageObject.pageSize,
              current: pageObject.pageNumber,
              total: invoicesData?.total || 0,
              onChange: (page, pageSize) => {
                setPageObject({
                  pageSize,
                  pageNumber: page,
                });
              }
            }}
          />
        </ContentContainer>
      </PageContainer>

      <Modal
        title={"創建發票紀錄"}
        visible={showCreateInvoiceModal}
        footer={null}
        onCancel={onCreateInvoiceModalCancel}
      >
        <Form
          form={createInvoiceForm}
          layout="vertical"
          onFinish={onCreateInvoiceModalSubmit}
        >
          <Form.Item
            name="dateRange"
            label="結算時段"
            rules={[{ required: true, message: "請輸入日期" }]}
          >
            <DatePicker.RangePicker placeholder={["由", "至"]} />
          </Form.Item>
          <Form.Item
            name="signInOnly"
            valuePropName="checked"
          >
            <Checkbox>只計算已簽到記錄</Checkbox>
          </Form.Item>

          <Button
            htmlType="button"
            onClick={onCreateInvoiceModalCancel}
            style={{ marginRight: 20, marginTop: 20 }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={createInvoiceLoading}
          >
            Submit
          </Button>
        </Form>
      </Modal>
    </Profiler>
  );
};

const ContentContainer = styled.div`
  padding: 3.2rem;
`;

const ListContainer = styled.div`
  overflow-y: hidden;
`;

EmployerInvoiceListScreen.whyDidYouRender = false;
