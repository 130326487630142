import React, {useEffect, useState} from "react";
import {Button, Collapse, DatePicker, Form, Input, Modal, Spin, Upload} from "antd";
import styled from "@emotion/styled";
import {SearchPanel} from "./components/search-panel";
import {DeleteOutlined, EditOutlined, UploadOutlined} from "@ant-design/icons";
import {
    useAddHelperCert,
    useAddHelperCertAttachments,
    useDeleteHelperCertAttachments,
    useGetHelperCerts,
    useUpdateHelperCert
} from "../../../utils/helper";
import {useParams} from "react-router";
import moment from "moment";
import {getBase64, useConvertToUploadCertFileList} from "../../../utils";
import {UploadFile} from "antd/es/upload/interface";
import {RcFile} from "antd/lib/upload";
import {useNavigate} from "react-router-dom";

const {Panel} = Collapse;

export interface Certifications {
    uuid: string,
    name: string,
    dueDate: string | moment.Moment,
    status?: string
    attachments?: Attachment[]
    fileList?: UploadFile<RcFile>[]
}

export interface Attachment {
    fileName: string
    fileSize: number
    name: string
    type: string
    uuid: string
}


export const Certifications = () => {
    const {id} = useParams();
    const [param] = useState({uuid: id});
    const {data: certData, retry: refreshCertData, isSuccess, isLoading} = useGetHelperCerts(param)

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalTitle, setModalTitle] = useState('創建證書')
    const [certDetail, setCertDetail] = useState<Certifications>({
        uuid: '',
        name: '',
        attachments: [],
        dueDate: ''
    })
    const {mutate} = useAddHelperCertAttachments();
    const {mutate: deleteFile} = useDeleteHelperCertAttachments();
    // const {mutate: getFile} = useGetHelperCertAttachment();
    const {mutate: addHelperCert, isLoading: createLoading} = useAddHelperCert();
    const {mutate: updateHelperCert, isLoading: updateLoading} = useUpdateHelperCert();

    const {convertAttachment} = useConvertToUploadCertFileList()
    const navigate = useNavigate();

    // const [fileList, setDefaultFileList] = useState<Array<Certifications>>([]);
    const [newCertList, setNewCertList] = useState<Array<Certifications>>([]);
    const [spin, setSpin] = useState(true);
    const [fileState, setFileState] = useState({
        previewVisible: false,
        previewImage: '',
        previewTitle: '',
    })

    useEffect(() => {
        if (isSuccess) {
            const tempList = [...certData?.list]
            tempList.map(async item => {
                const temp = await convertAttachment(item.attachments || [])

                item.fileList = [...temp];

                return item
            })

            setNewCertList([...tempList])
        }
    }, [isSuccess])


    const uploadCert = async (options: any) => {
        const {onSuccess, onError, file, onProgress} = options;

        const formData = new FormData();
        formData.append("file", file);

        try {
            const res: Attachment = await mutate(formData);

            let tempAttachments: Attachment[] = []

            if (certDetail.attachments && certDetail.attachments.length > 0) {
                tempAttachments = certDetail.attachments
            }

            tempAttachments.push({
                fileName: res.fileName,
                fileSize: res.fileSize,
                name: res.name,
                type: res.type,
                uuid: res.uuid,
            })

            const tempFileList = await convertAttachment(tempAttachments);

            // @ts-ignore
            setCertDetail({...certDetail, fileList: tempFileList, attachments: tempAttachments})

            onSuccess("Ok");
        } catch (err) {
            const error = new Error("Some error");
            onError({err});
        }
    };

    const uploadMaterialProps: any = {
        name: "file",
        multiple: true,
        // showUploadList: {
        //   showRemoveIcon: ["master", "admin", "clerk"].includes(
        //       getAuthority().toString()
        //   ),
        // },
        // fileList,
        customRequest: uploadCert,
        onChange(info: { file: { status: any; uid: any; }; }) {
            const {status, uid} = info.file;
            if (status === "removed") {
                deleteFile({uuid: uid});
            }
        },
        onRemove: (file: { url: any; name: any; }) => {
            return new Promise((resolve, reject) => {
                if (!file.url) {
                    return resolve(false);
                }

                const isConfirm = window.confirm(`確定刪除 ${file.name} ？`);

                if (isConfirm) {
                    resolve(true);
                }
            });
        },
    };

    const onHandleSubmit = async (value: any) => {
        await setSpin(true)
        let data = {
            uuid: value.uuid,
            name: value.name,
            dueDate: value.dueDate,
            attachments: certDetail.attachments?.map(item => item.uuid),
            // fileList: certDetail.fileList
        }

        if (modalTitle === '創建證書') {
            delete data.uuid
            await addHelperCert(id, data)
        } else {
            await updateHelperCert(id, data)
        }

        window.location.href = window.location.href.replace( /[\?#].*|$/, "?tab=5" );
        // await refreshCertData()
        // await setIsModalVisible(false)
        // await setSpin(false)

    }

    const onShowCreateModal: Function = (type: string) => {
        if (type === 'create') {
            setModalTitle('創建證書')
            setCertDetail({
                uuid: '',
                name: '',
                dueDate: '',
            })
        } else {
            setModalTitle('編緝證書')
        }
        setIsModalVisible(true)
    }

    const genHeader = (name: string, date: string | moment.Moment) => (
        <span style={{fontSize: 14}}>
            {name}
            <span style={{
                marginLeft: 10,
                fontSize: '12px',
                color: moment(date).isBefore(moment()) ? 'red' : "#999"
            }}>到期日: {moment(date).format("YYYY-MM-DD")}</span>
        </span>
    )

    const genExtra = (item: Certifications) => (
        <>
            <EditOutlined
                onClick={(event) => {
                    event.stopPropagation();
                    let temp = Object.assign({}, item)
                    temp.dueDate = moment(temp.dueDate)
                    setCertDetail(temp)
                    onShowCreateModal('update')
                }}
            />
            <DeleteOutlined style={{marginLeft: 15}} onClick={(event) => {
                event.stopPropagation();
            }}/>
        </>
    )

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setFileState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    const onFileChange = (fileList: any) => {
       console.log(fileList)
    }

    return (
        <Spin spinning={isLoading}>
            <SearchPanel onShowCreateModal={onShowCreateModal}/>
            <ContentContainer>
                {newCertList.map( (item) => {

                    return (
                        <Collapse key={item.uuid}  bordered={false} style={{marginBottom: 20}} onChange={() => {
                            setSpin(false)
                        }}>
                            <Panel header={genHeader(item.name, item.dueDate)} key={item.uuid} extra={genExtra(item)}>
                                <Spin spinning={spin}>
                                    <Upload
                                        listType="picture"
                                        fileList={item.fileList}
                                        onChange={onFileChange}
                                        className="upload-list-inline"
                                        onPreview={handlePreview}
                                    />
                                </Spin>
                            </Panel>

                        </Collapse>
                    )
                })}

            </ContentContainer>
            <Modal title={modalTitle} footer={null} visible={isModalVisible} destroyOnClose
                   onOk={() => setIsModalVisible(false)}
                   onCancel={() => setIsModalVisible(false)}>
                <Form
                    name="basic"
                    layout="vertical"
                    initialValues={certDetail}
                    onFinish={onHandleSubmit}
                    preserve={false}
                    autoComplete="off"
                >

                    <Form.Item
                        name="uuid"
                        hidden
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="證書名稱"
                        name="name"
                        rules={[{required: true, message: 'Please input your username!'}]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="證書到期日"
                        name="dueDate"
                    >
                        <DatePicker style={{width: "100%"}}/>
                    </Form.Item>

                    <Form.Item valuePropName="fileList"
                               noStyle>
                        <Upload
                            {...uploadMaterialProps}
                            fileList={certDetail?.fileList}
                            listType="picture"
                            onPreview={handlePreview}
                        >
                            <Button icon={<UploadOutlined/>}>Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item wrapperCol={{offset: 20, span: 4}}>
                        <Button type="primary" htmlType="submit" block loading={createLoading || updateLoading}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                visible={fileState.previewVisible}
                title={fileState.previewTitle}
                footer={null}
                onCancel={() => setFileState({...fileState, previewVisible: false})}
            >
                <img alt="example" style={{width: '100%'}} src={fileState.previewImage}/>
            </Modal>
        </Spin>
    );
};

const ContentContainer = styled.div`
  margin-top: 3px;
  // padding: 3.2rem;
  padding: 2rem;
  flex-direction: column;
  position: relative;
  overflow: auto;
`;
