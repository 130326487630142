import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Tabs } from "antd";
import {
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Row } from "components/lib";
import { Helper } from "../helper-list/list";
import moment from "moment";
import { useEditHelper } from "utils/helper";

interface TopBarData extends Partial<Helper> {
  creditLimit?: number;
  activeTab?: string;
  tabChange: (key: string) => void;
  refresh: () => void;
}

export const TopBar = (props: TopBarData) => {
  const navigate = useNavigate();
  const {
    uuid,
    nameCn,
    position,
    status,
    createdAt,
    activeTab,
    tabChange,
    refresh,
  } = props;

  const {
    mutate: editHelper,
    isLoading: editLoading,
    isError,
    isSuccess: isEditHelperSuccess,
  } = useEditHelper();

  useEffect(() => {
    if (isEditHelperSuccess) {
      refresh();
    }
  }, [isEditHelperSuccess]);

  const handleDisableHelper = async () => {
    await editHelper({ uuid: uuid, status: status === 1 ? 0 : 1 });
  };

  const onChangeHelperStatus = () => {
    Modal.confirm({
      title: status === 0 ? "停用幫手?" : "啟用幫手?",
      icon: <ExclamationCircleOutlined />,
      content: status === 0 ? "停用這位幫手?" : "啟用這位幫手?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      maskClosable: true,
      onOk() {
        handleDisableHelper();
      },
    });
  };

  return (
    <>
      <Container>
        <FixedBar between={true}>
          <BarLeft gap={true}>
            <ArrowLeftOutlined onClick={() => navigate(-1)} />
            <Title>{nameCn}</Title>
            <Status> {status === 0 ? "啟用" : "停用"}</Status>
            <InfoText>職級: {position}</InfoText>
            <InfoText>
              登記日期: {moment(createdAt).format("YYYY-MM-DD")}
            </InfoText>
          </BarLeft>
          <BarRight>
            <Button onClick={() => onChangeHelperStatus()}>
              {status === 0 ? "停用幫手" : "啟用幫手"}
            </Button>
          </BarRight>
        </FixedBar>
        <TabContainer>
          <Tabs activeKey={activeTab} onChange={(key) => tabChange(key)}>
            <Tabs.TabPane tab="工作紀錄" key="1"></Tabs.TabPane>
            <Tabs.TabPane tab="出糧紀錄" key="2"></Tabs.TabPane>
            <Tabs.TabPane tab="Credit Debit" key="3"></Tabs.TabPane>
            <Tabs.TabPane tab="個人信息" key="4"></Tabs.TabPane>
            <Tabs.TabPane tab="證書" key="5"></Tabs.TabPane>
          </Tabs>
        </TabContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 123px;
  padding: 28px 32px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0px;
  z-index: 999;
`;

const FixedBar = styled(Row)`
  z-index: 1;
`;
const BarLeft = styled(Row)``;
const BarRight = styled(Row)``;

const InfoText = styled.div`
  display: inline-block;
  color: #8d939e;
  font-size: 14px;
  padding-right: 10px;
`;

const Title = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
`;

const Status = styled.div`
  background-color: #d1f3f8;
  padding: 5px 10px;
  color: #2498aa;
  font-size: 12px;
  font-weight: bold;
  border-radius: 8px;
`;

const TabContainer = styled.div`
  padding-top: 21px;
`;
