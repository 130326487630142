import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import styled from "@emotion/styled";
import { CommonList } from "components/listing/common-list";
import { List } from "screens/helper/helper-list/list";
import { useDebounce, useDocumentTitle } from "utils";
import { Helper } from "types/helper";
import { Row } from "components/lib";
import { Profiler } from "components/profiler";
import { useHelpers } from "../../../utils/helper";
import { useUrlQueryPaging } from "../../../utils/paging";
import { TablePagination } from "components/table-pagination";
import { SearchPanel } from "./search-panel";
import { ColumnsType } from "antd/es/table";
import { helperStatus } from "utils/common";
import { dateDisplay } from "utils/moment-util";
import {
  useHelperSearchParams,
  useJobSearchParams,
} from "../../../search-utils/util";
import { useHelperPositions } from "../../../utils/helper-position";
import useWindowSize from "hooks/useWindowSize";
import PageContainer from "../../../components/layout/PageContainer";

export const HelperListScreen = () => {
  useDocumentTitle("幫手列表", false);
  const navigate = useNavigate();
  const { windowHeight } = useWindowSize();

  const [param, setParam] = useHelperSearchParams();
  const [pageObject, setPageObject] = useUrlQueryPaging();

  const { isLoading, error, data, retry } = useHelpers(
    useDebounce(param, 500),
    pageObject
  );

  useEffect(() => {
    setPageObject({
      pageSize: pageObject.pageSize,
      pageNumber: 1,
    });
  }, [param.position, param.keyword]);

  const { data: helperPositions } = useHelperPositions();

  const columns: ColumnsType<Helper> = [
    {
      title: "幫手編號",
      dataIndex: "helperNo",
      key: "helperNo",
      render: (text, record) => <a href={`/helper/${record.uuid}`}>{text}</a>,
    },
    {
      title: "幫手",
      dataIndex: "nameCn",
      key: "nameCn",
      render: (text, record) => `${record.nameCn || record.nameEn} (${record.phone})`,
    },
    {
      title: "用戶狀態",
      dataIndex: "status",
      key: "status",
      render: (text, record) => helperStatus(text),
    },
    {
      title: "登記日期",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => dateDisplay(text),
    },
  ];

  return (
    <Profiler id={"幫手列表"}>
      <PageContainer>
        <SearchPanel
          helperPositions={helperPositions?.list || []}
          param={param}
          setParam={setParam}
        />
        <ContentContainer>
          <Row marginBottom={2} between={true}>
            <h1>幫手列表</h1>
            <Button type="primary" onClick={() => navigate("/create_helper")}>
              ＋創建幫手
            </Button>
          </Row>

          <CommonList
            refresh={retry}
            tableProps={{
              loading: isLoading,
              columns,
              dataSource: data?.list || [],
              pagination: false,
              scroll: {
                y: windowHeight - 320,
              }
            }}
            paginationProps={{
              pageSize: pageObject.pageSize,
              current: pageObject.pageNumber,
              total: data?.total || 0,
              onChange: (page, pageSize) => {
                setPageObject({
                  pageSize,
                  pageNumber: page,
                });
              }
            }}
          />
        </ContentContainer>
      </PageContainer>
    </Profiler>
  );
};

const ContentContainer = styled.div`
  padding: 3.2rem;
`;

const ListContainer = styled.div`
  overflow: auto;
`;

HelperListScreen.whyDidYouRender = false;
