import React from "react";
import { Pagination } from "antd";
import styled from "@emotion/styled";
import {PaginationProps} from "antd/es/pagination";

// type PaginationProps = PaginationProps;

export const TablePagination = (props: PaginationProps) => {
  const {
    pageSize = 10,
    current = 1,
    total = 0,
    pageSizeOptions = ["10", "25", "50", "100"],
    showSizeChanger = true,
    showQuickJumper = true,
    ...otherProps
  } = props;

  return (
    <PaginationContainer>
      <Pagination
        pageSize={pageSize}
        current={current}
        total={total}
        pageSizeOptions={pageSizeOptions}
        showSizeChanger={showSizeChanger}
        showQuickJumper={showQuickJumper}
        {...otherProps}
      />
    </PaginationContainer>
  );
};

const PaginationContainer = styled.div`
  padding-top: 10px;
  text-align: right;
`;
