import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tabs, Select, Button, Modal } from "antd";
import {
  ArrowLeftOutlined,
  CopyOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Row } from "components/lib";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { JobBasicData } from "types/job-basic-data";
import { Employer } from "../../employer/employer-list/list";
import { Job } from "../all-jobs/list";
import { create } from "domain";
import {
  changeArrayToWeekDay,
  taskStatus,
  workingPeriodStatus,
} from "../../../utils/common";
import moment from "moment";
import { useEditJob } from "../../../utils/job";

const { Option } = Select;

interface TopBarDat extends Partial<Job> {
  activeTab?: string;
  selectedHelpersLength?: number;
  tabChange: (key: string) => void;
  cancelledJob: () => void;
}

export const TopBar = (props: TopBarDat) => {
  const navigate = useNavigate();
  const {
    taskNo,
    taskHelperRequirements,
    workingHours,
    status,
    workingPeriod,
    personInCharge,
    createdAt,
    activeTab,
    endDate,
    address,
    startDate,
    startTime,
    week,
    district,
    selectedHelpersLength = 0,
    cancelledJob,
    tabChange,
  } = props;

  const { id } = useParams();

  // combine to one line after add variables
  const jobInvitation = `
    *環宇護理UniCare360工作邀請*\n
    ⏰ *工作編號:* ${taskNo}
    ⏰ *需要工種:* ${taskHelperRequirements?.map(item => item.position ).filter(item => item !== "自定價錢").join(", ")}
    ⏰ *工作週期:* ${workingPeriodStatus(workingPeriod)}
    🗓 *開始日期:* ${moment(startDate).format("YYYY-MM-DD")}
    ${
      workingPeriod === 0 &&
      `🗓 *結束日期:* ${moment(endDate).format("YYYY-MM-DD")}`
    }
    ⏰ *工作時間:* ${moment(startTime, "HH:mm:ss").format("HH:mm")} - ${moment(startTime, "HH:mm:ss").add(workingHours, "h").format("HH:mm")}
    ${week && ` 🗓 *週期:* ${changeArrayToWeekDay(week || [])}`}
    *工作地址:* ${district}\n
    *如有查詢請致電/whatsApps：98675630，如果沒空接以上工作，請麻煩轉發給有興趣的朋友/同事，感激不盡🙇‍♀‍\n
    ✅ *申請工作* ✅
    https://360unicare.com/applyjob/${id}`;

  const jobConfirm = `
    *環宇護理UniCare360工作確認與簽到*\n
    ⏰ *工作編號:* ${taskNo}
    ⏰ *工作週期:* ${workingPeriodStatus(workingPeriod)}
    🗓 *開始日期:* ${moment(startDate).format("YYYY-MM-DD")}
    ⏰ *工作時間:* ${moment(startTime, "HH:mm:ss").format("HH:mm")} - ${moment(startTime, "HH:mm:ss").add(workingHours, "h").format("HH:mm")}
    *工作地址:* ${district} - ${address}\n
    *如有查詢請致電/whatsApps：98675630‍\n
    ✅ *工作確認* ✅
    https://360unicare.com/checkoutjob/${id}`;

  return (
    <Container>
      <FixedBar between={true}>
        <BarLeft gap={true}>
          <ArrowLeftOutlined onClick={() => navigate(-1)} />
          <Title>{taskNo}</Title>
          <Status>{taskStatus(status)}</Status>
          <Frequency>{workingPeriodStatus(workingPeriod)}</Frequency>
          <AdminName>創建人: {personInCharge?.username}</AdminName>
          <CreateDate>
            創建日期: {moment(createdAt).format("YYYY-MM-DD HH:mm")}
          </CreateDate>
        </BarLeft>
        {(status === 0 || status === 1 || status === 2) && (
          <BarRight>
            {selectedHelpersLength > 0 ? (
              <CopyToClipboard text={jobConfirm} onCopy={() => alert("copied")}>
                <Button style={{ marginRight: 16 }}>
                  <CopyOutlined />
                  複製工作確認與簽到
                </Button>
              </CopyToClipboard>
            ) : (
              <CopyToClipboard
                text={jobInvitation}
                onCopy={() => alert("copied")}
              >
                <Button style={{ marginRight: 16 }}>
                  <CopyOutlined />
                  複製工作邀請
                </Button>
              </CopyToClipboard>
            )}
            <Button onClick={() => cancelledJob()}>取消工作</Button>
          </BarRight>
        )}
      </FixedBar>
      <TabContainer>
        <Tabs activeKey={activeTab} onChange={(key) => tabChange(key)}>
          <Tabs.TabPane tab="工作詳情" key="1"></Tabs.TabPane>
          <Tabs.TabPane tab="工作紀錄" key="2"></Tabs.TabPane>
        </Tabs>
      </TabContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 122px;
  padding: 28px 32px;
  background-color: #ffffff;
  position: sticky;
  top: 0px;
  z-index: 999;
`;

const FixedBar = styled(Row)`
  z-index: 1;
`;
const BarLeft = styled(Row)``;
const BarRight = styled(Row)``;

const Title = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
`;

const Status = styled.div`
  background-color: #d1f3f8;
  padding: 5px 10px;
  color: #2498aa;
  font-size: 12px;
  font-weight: bold;
  border-radius: 8px;
`;

const Frequency = styled.div`
  background-color: #eff2f8;
  padding: 5px 10px;
  color: #676b73;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
`;

const AdminName = styled.div`
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
`;

const CreateDate = styled.div`
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
`;

const TabContainer = styled.div`
  padding-top: 21px;
`;
