import React, { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import { TopBar } from "screens/employer/employer-details/top-bar";
import { JobList } from "screens/employer/employer-details/job-list";
import { BlacklistList } from "screens/employer/employer-details/blacklist";
import { IndividualEmployerDetails } from "screens/employer/employer-details/individual-employer-details";
import { CompanyEmployerDetails } from "screens/employer/employer-details/company-employer-details";
import { InvoiceRecords } from "screens/employer/employer-details/invoice-records";
import { PaymentRecords } from "screens/employer/employer-details/payment-records";
import { CreditDebitNotes } from "screens/employer/employer-details/credit-debit-notes";
import { useUrlQueryPaging } from "utils/paging";
import { useDebounce, useDocumentTitle } from "utils";
import { Profiler } from "components/profiler";
import {
  useEditEmployer,
  useEmployerDetail,
  useEmployerPayments,
} from "utils/employer";
import { TablePagination } from "components/table-pagination";
import PageContainer from "../../../components/layout/PageContainer";

export const EmployerDetailsScreen = () => {
  useDocumentTitle("僱主詳情", false);
  const navigate = useNavigate();

  const { id } = useParams();
  const [param, setParam] = useState({ uuid: id });
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(searchParams.get("tab") || "1");
  const [jobPageObject, setJobPageObject] = useUrlQueryPaging();

  const { isLoading, error, data, retry } = useEmployerDetail(
    useDebounce(param, 200)
  );

  const {
    data: paymentsData,
    isLoading: paymentsLoading,
    retry: retryPaymentsData,
  } = useEmployerPayments(param);

  const {
    mutate,
    isLoading: editLoading,
    isError,
    isSuccess,
  } = useEditEmployer();

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  return (
    <Profiler id={"僱主詳情"}>
      <PageContainer>
        <TopBar
          uuid={data?.uuid}
          type={data?.type}
          contactPerson={data?.companyName || data?.contactPerson}
          patientName={data?.contactPerson && data?.patientName}
          status={data?.status}
          creditLimit={data?.creditLimit}
          credit={data?.credit}
          activeTab={activeTab}
          tabChange={handleTabChange}
          onUpdate={mutate}
          reload={retry}
        />

        {activeTab === "1" && (
          <ContentContainer>
            <JobList jobs={data?.tasks || []} />
            {/* <TablePagination
              pageSize={jobPageObject.pageSize}
              pageSizeOptions={["10", "25", "50", "100"]}
              showSizeChanger={true}
              showQuickJumper={false}
              current={jobPageObject.pageNumber}
              total={(data && data.total) || 0}
              onChange={(page, pageSize) => {
                setJobPageObject({
                  pageSize,
                  pageNumber: page,
                });
              }}
            /> */}
          </ContentContainer>
        )}

        {activeTab === "2" && (
          <ContentContainer>
            <InvoiceRecords employerUuid={param.uuid} employerData={data} />
          </ContentContainer>
        )}

        {activeTab === "3" && (
          <ContentContainer>
            <PaymentRecords employerUuid={param.uuid} />
          </ContentContainer>
        )}

        {activeTab === "4" && (
          <ContentContainer>
            <CreditDebitNotes employerUuid={param.uuid} employerData={data} />
          </ContentContainer>
        )}

        {activeTab === "5" && data?.type === 0 && (
          <ContentContainer>
            <IndividualEmployerDetails
              onUpdate={mutate}
              loading={editLoading}
              reload={retry}
              {...data}
            />
          </ContentContainer>
        )}

        {activeTab === "5" && data?.type === 1 && (
          <ContentContainer>
            <CompanyEmployerDetails
              onUpdate={mutate}
              loading={editLoading}
              reload={retry}
              {...data}
            />
          </ContentContainer>
        )}

        {activeTab === "6" && <BlacklistList />}
      </PageContainer>
    </Profiler>
  );
};
const ContentContainer = styled.div`
  margin-top: 3px;
  // padding: 3.2rem;
  padding: 2rem;
  flex-direction: column;
  position: relative;
  overflow: auto;
`;

EmployerDetailsScreen.whyDidYouRender = false;
