import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Modal, Radio, Row, Select, Space,} from "antd";
import {EditOutlined, MinusCircleOutlined, PlusOutlined,} from "@ant-design/icons";
import {FormInstance} from "antd/es/form";
import styled from "@emotion/styled";
import {Helper} from "../helper-list/list";
import moment from "moment";
import { v4 as uuid } from "uuid";
import {changeArrayToStringInObject} from "../../../utils";
import {District} from "components/district";
import {HelperPaymentMethodSelect} from "components/helper-payment-method";
import {formatTimeTable, gender, languages, paymentMethod} from "utils/common";
import {useHelperPositions} from "../../../utils/helper-position";

export interface BankAccount {
  key?: string | number;
  id?: number;
  bankName?: string;
  accountName?: string;
  accountNumber?: string;
}

interface SearchPanelProps extends Partial<Helper> {
  onUpdate: (value: Partial<Helper>) => void;
  reload: () => void;
}

export const HelperDetails = ({
  onUpdate,
  reload,
  ...data
}: SearchPanelProps) => {
  const timeTableValue: string[] = [];

  if (data.timeTable) {
    for (const [key, value] of Object.entries(data.timeTable)) {
      if (typeof value === "boolean" && value) {
        timeTableValue.push(key);
      }
    }
  }

  const formatData = {
    uuid: data.uuid,
    nameCn: data.nameCn,
    nameEn: data.nameEn,
    gender: data.gender,
    position: data.position,
    language: data.language && data.language.split(","),
    idCardNo: data.idCardNo,
    idCardName: data.idCardName,
    dob: data.dob ? moment(data.dob) : null,
    dateOfVaccines: data.dateOfVaccines ? moment(data.dateOfVaccines) : null,
    typesOfVaccines: data.typesOfVaccines,
    phone: data.phone,
    email: data.email,
    district: data.district && data.district.split(","),
    address: data.address,
    status: data.status,
    height: data.height,
    weight: data.weight,
    emergencyContactPerson: data.emergencyContactPerson,
    emergencyContactPhone: data.emergencyContactPhone,
    bankDetails: data.bankDetails,
    dailyCareSkills: data.dailyCareSkills?.split(",") || null,
    professionalNursingSkills:
      data.professionalNursingSkills?.split(",") || null,
    advancedNursingSkills: data.advancedNursingSkills?.split(",") || null,
    personInCharge: data.personInCharge,
    timeTable: timeTableValue,
  };

  console.log(formatData)

  const editBasicInfoFormRef = React.createRef<FormInstance>();
  const [editBasicInfoForm] = Form.useForm();
  const [showEditBasicInfoModal, setShowEditBasicInfoModal] = useState(false);

  const editVaccineFormRef = React.createRef<FormInstance>();
  const [editVaccineForm] = Form.useForm();
  const [showEditVaccineModal, setShowEditVaccineModal] = useState(false);

  const editBankAccountFormRef = React.createRef<FormInstance>();
  const [editBankAccountForm] = Form.useForm();
  const [showEditBankAccountModal, setShowEditBankAccountModal] = useState(
    false
  );

  const editSkillFormRef = React.createRef<FormInstance>();
  const [editSkillForm] = Form.useForm();
  const [showEditSkillModal, setShowEditSkillModal] = useState(false);

  const editTimeTableFormRef = React.createRef<FormInstance>();
  const [editTimeTableForm] = Form.useForm();
  const [showEditTimeTableModal, setShowEditTimeTableModal] = useState(false);

  const { data: helperPositions } = useHelperPositions();

  const onEditFormClick = (type: string) => {
    switch (type) {
      case "basic":
        setShowEditBasicInfoModal(!showEditBasicInfoModal);
        break;
      case "vaccine":
        setShowEditVaccineModal(!showEditVaccineModal);
        break;
      case "bank":
        editBankAccountForm.setFieldsValue({bankDetails: formatData.bankDetails, uuid: formatData.uuid})
        setShowEditBankAccountModal(!showEditBankAccountModal);
        break;
      case "skill":
        setShowEditSkillModal(!showEditSkillModal);
        break;
      case "time":
        editTimeTableForm.setFieldsValue({ timeTable: timeTableValue });
        setShowEditTimeTableModal(!showEditTimeTableModal);
        break;
    }
  };

  const onEditModalSubmit = async (values: Partial<Helper>) => {
    // if (values.timeTable && values.timeTable.length > 0) {
    //   values.timeTable = values.timeTable.reduce((a: any, v: string) => ({...a, [v]: true}), {})
    // }


    // return console.log(changeArrayToStringInObject(values));

    if (values.dateOfVaccines) {
      values.dateOfVaccines = moment(values.dateOfVaccines).format("YYYY-MM-DD");
    }

    if (values.dob) {
      values.dob = moment(values.dob).format("YYYY-MM-DD");
    }

    if (values.timeTable) {
      const newTimeTable = formatTimeTable(values.timeTable || []);

      await onUpdate(
          changeArrayToStringInObject({
            uuid: values.uuid,
            timeTable: newTimeTable,
          })
      );
    } else {
      await onUpdate(changeArrayToStringInObject(values));
    }

    await reload();

    if (showEditBasicInfoModal) {
      await setShowEditBasicInfoModal(!showEditBasicInfoModal);
    }

    if (showEditVaccineModal) {
      await setShowEditVaccineModal(!showEditVaccineModal);
    }

    if (showEditBankAccountModal) {
      await setShowEditBankAccountModal(!showEditBankAccountModal);
    }

    if (showEditSkillModal) {
      await setShowEditSkillModal(!showEditSkillModal);
    }

    if (showEditTimeTableModal) {
      await setShowEditTimeTableModal(!showEditTimeTableModal);
    }
  };

  return (
    <>
      <SectionContainer>
        <SectionTitle>幫手信息</SectionTitle>
        <EditButton>
          <EditOutlined onClick={() => onEditFormClick("basic")} />
        </EditButton>
        <DataContainer>
          <DataTitle>中文姓名:</DataTitle>
          <DataContent>{data?.nameCn}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>英文姓名:</DataTitle>
          <DataContent>{data?.nameEn}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>性別:</DataTitle>
          <DataContent>{gender(data?.gender)}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>身份證號碼:</DataTitle>
          <DataContent>{data?.idCardNo}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>身份證明文件上名稱:</DataTitle>
          <DataContent>{data?.idCardName}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>出生日期:</DataTitle>
          <DataContent>{data?.dob}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>身高 (cm):</DataTitle>
          <DataContent>{data?.height}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>體重 (kg):</DataTitle>
          <DataContent>{data?.weight}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>職級:</DataTitle>
          <DataContent>{data?.position}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>語言:</DataTitle>
          <DataContent>{languages(data?.language)}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>電話:</DataTitle>
          <DataContent>{data?.phone}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>電郵:</DataTitle>
          <DataContent>{data?.email}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>緊急聯絡人:</DataTitle>
          <DataContent>{data?.emergencyContactPerson}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>緊急聯絡人電話:</DataTitle>
          <DataContent>{data?.emergencyContactPhone}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>地區:</DataTitle>
          <DataContent>{data?.district}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>完整地址:</DataTitle>
          <DataContent>{data?.address}</DataContent>
        </DataContainer>
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>疫苗接種</SectionTitle>
        <EditButton>
          <EditOutlined onClick={() => onEditFormClick("vaccine")}/>
        </EditButton>
        <DataContainer>
          <DataTitle>接種新冠肺炎疫苗:</DataTitle>
          <DataContent>{data?.typesOfVaccines ? "有" : "沒有"}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>疫苗種類 / 次數:</DataTitle>
          <DataContent>{data?.typesOfVaccines}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>接種日期:</DataTitle>
          <DataContent>{data?.dateOfVaccines}</DataContent>
        </DataContainer>
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>銀行戶口</SectionTitle>
        <EditButton>
          <EditOutlined onClick={() => onEditFormClick("bank")} />
        </EditButton>
        <DataContainer>
          <Row>
            <Col span={4}>
              <DataTitle>銀行名稱</DataTitle>
            </Col>
            <Col span={4}>
              <DataTitle>戶口持有人姓名</DataTitle>
            </Col>
            <Col span={4}>
              <DataTitle>戶口號碼</DataTitle>
            </Col>
            <Col span={4}>
              <DataTitle>支付方法</DataTitle>
            </Col>
            <Col span={4}>
              <DataTitle>Default</DataTitle>
            </Col>
          </Row>
          {data?.bankDetails?.map((item, index) => (
            <Row key={item.bankName}>
              <Col span={4}>
                <DataContent>{item.bankName}</DataContent>
              </Col>
              <Col span={4}>
                <DataContent>{item.accountName}</DataContent>
              </Col>
              <Col span={4}>
                <DataContent>{item.bankNumber}</DataContent>
              </Col>
              <Col span={4}>
                <DataContent>{paymentMethod(item.paymentMethod)}</DataContent>
              </Col>
              <Col span={4}>
                <DataContent>{item.default ? 'Yes' : ''}</DataContent>
              </Col>
            </Row>
          ))}
        </DataContainer>
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>技能</SectionTitle>
        <EditButton>
          <EditOutlined onClick={() => onEditFormClick("skill")} />
        </EditButton>
        <DataContainer>
          <DataTitle>日常護理技能:</DataTitle>
          <DataContent>{data?.dailyCareSkills}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>專業護理技能:</DataTitle>
          <DataContent>{data?.professionalNursingSkills}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>進階護理技能:</DataTitle>
          <DataContent>{data?.advancedNursingSkills}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>其他:</DataTitle>
          <DataContent>{data?.otherSkill}</DataContent>
        </DataContainer>
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>時間表</SectionTitle>
        <EditButton>
          <EditOutlined onClick={() => onEditFormClick("time")} />
        </EditButton>
        <DataContainer>
          {/* <DataTitle>時間表:</DataTitle> */}
          <DataContent>
            <Checkbox.Group value={timeTableValue} disabled>
              <Row gutter={[16, 4]}>
                <Col flex={1}>
                  <div>星期一</div>
                  <Checkbox value="monAm">早更</Checkbox>
                  <br />
                  <br />
                  <Checkbox value="monPm">夜更</Checkbox>
                </Col>
                <Col flex={2}>
                  <div>星期二</div>
                  <Checkbox value="tueAm">早更</Checkbox>
                  <br />
                  <br />
                  <Checkbox value="tuePm">夜更</Checkbox>
                </Col>
                <Col flex={3}>
                  <div>星期三</div>
                  <Checkbox value="wedAm">早更</Checkbox>
                  <br />
                  <br />
                  <Checkbox value="wedPm">夜更</Checkbox>
                </Col>
                <Col flex={4}>
                  <div>星期四</div>
                  <Checkbox value="thuAm">早更</Checkbox>
                  <br />
                  <br />
                  <Checkbox value="thuPm">夜更</Checkbox>
                </Col>
                <Col flex={5}>
                  <div>星期五</div>
                  <Checkbox value="friAm">早更</Checkbox>
                  <br />
                  <br />
                  <Checkbox value="friPm">夜更</Checkbox>
                </Col>
                <Col flex={6}>
                  <div>星期六</div>
                  <Checkbox value="satAm">早更</Checkbox>
                  <br />
                  <br />
                  <Checkbox value="satPm">夜更</Checkbox>
                </Col>
                <Col flex={7}>
                  <div>星期日</div>
                  <Checkbox value="sunAm">早更</Checkbox>
                  <br />
                  <br />
                  <Checkbox value="sunPm">夜更</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </DataContent>
        </DataContainer>
      </SectionContainer>

      <Modal
        title={"編輯幫手信息"}
        visible={showEditBasicInfoModal}
        footer={null}
        onCancel={() => onEditFormClick("basic")}
      >
        <Form
          form={editBasicInfoForm}
          ref={editBasicInfoFormRef}
          layout="vertical"
          initialValues={formatData}
          onFinish={onEditModalSubmit}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid" />
          </Form.Item>

          <Form.Item
            name="nameCn"
            label="中文姓名"
            rules={[{ required: true, message: "請輸入中文姓名" }]}
          >
            <Input placeholder="中文姓名" />
          </Form.Item>

          <Form.Item
            name="nameEn"
            label="英文姓名"
            rules={[{ required: true, message: "請輸入英文姓名" }]}
          >
            <Input placeholder="英文姓名" />
          </Form.Item>

          <Form.Item
            name="gender"
            label="性別"
            rules={[{ required: true, message: "請輸入性別" }]}
          >
            <Radio.Group>
              <Radio value={1}>女性</Radio>
              <Radio value={0}>男性</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="idCardNo"
            label="身份證號碼"
            rules={[{ required: true, message: "請輸入身份證號碼" }]}
          >
            <Input placeholder="身份證號碼" />
          </Form.Item>

          <Form.Item
            name="idCardName"
            label="身份證明文件上名稱"
            rules={[{ required: true, message: "請輸入身份證明文件上名稱" }]}
          >
            <Input placeholder="身份證明文件上名稱" />
          </Form.Item>

          <Form.Item
            name="dob"
            label="出生日期"
            rules={[{ required: true, message: "請輸入出生日期" }]}
          >
            <DatePicker format={"DD/MM/YYYY"} placeholder="DD/MM/YYYY" />
          </Form.Item>

          <Form.Item
            name="height"
            label="身高 (cm)"
            rules={[{ required: true, message: "請輸入身高" }]}
          >
            <InputNumber
              min={0}
              max={250}
              style={{ width: 180 }}
              placeholder="身高 (cm)"
            />
          </Form.Item>

          <Form.Item
            name="weight"
            label="體重 (kg)"
            rules={[{ required: true, message: "請輸入體重" }]}
          >
            <InputNumber
              min={0}
              max={300}
              style={{ width: 180 }}
              placeholder="體重 (kg)"
            />
          </Form.Item>

          <Form.Item
            name="position"
            label="職級"
            rules={[{ required: true, message: "請輸入職級" }]}
          >
            <Select placeholder={"幫手職級"} style={{ width: "100%" }}>
              {(helperPositions?.list || []).map((item) => (
                <Select.Option key={item.uuid} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="language"
            label="語言"
            rules={[{ required: true, message: "請輸入語言" }]}
          >
            <Select mode="multiple" placeholder="語言">
              <Select.Option value="cantonese">廣東話</Select.Option>
              <Select.Option value="english">英語</Select.Option>
              <Select.Option value="putonghua">普通話</Select.Option>
              <Select.Option value="潮洲話">潮洲話</Select.Option>
              <Select.Option value="上海話">上海話</Select.Option>
              <Select.Option value="福建話">福建話</Select.Option>
              <Select.Option value="客家話">客家話</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="phone"
            label="電話"
            rules={[{ required: true, message: "請輸入電話" }]}
          >
            <Input placeholder="電話" />
          </Form.Item>

          <Form.Item name="email" label="電郵">
            <Input placeholder="電郵" />
          </Form.Item>

          <Form.Item
            name="emergencyContactPerson"
            label="緊急聯絡人"
            rules={[{ required: true, message: "請輸入緊急聯絡人" }]}
          >
            <Input placeholder="緊急聯絡人" />
          </Form.Item>

          <Form.Item
            name="emergencyContactPhone"
            label="緊急聯絡人電話"
            rules={[{ required: true, message: "請輸入緊急聯絡人電話" }]}
          >
            <Input placeholder="緊急聯絡人電話" />
          </Form.Item>

          <Form.Item
            name="district"
            label="地區"
            rules={[{ required: true, message: "請輸入地區" }]}
          >
            <District />
          </Form.Item>

          <Form.Item
            name="address"
            label="完整地址"
            rules={[{ required: true, message: "請輸入完整地址" }]}
          >
            <Input placeholder="完整地址" />
          </Form.Item>

          {/* <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>上傳幫手身分證</Button>
          </Upload> */}

          <Button
            htmlType="button"
            onClick={() => onEditFormClick("basic")}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title={"編輯疫苗接種"}
        visible={showEditVaccineModal}
        footer={null}
        onCancel={() => onEditFormClick("vaccine")}
      >
        <Form
            form={editVaccineForm}
            ref={editVaccineFormRef}
            layout="vertical"
            initialValues={formatData}
            onFinish={onEditModalSubmit}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid"/>
          </Form.Item>

          {/*<Form.Item label="接種新冠肺炎疫苗">*/}
          {/*  <Radio.Group>*/}
          {/*    <Radio value="y">有</Radio>*/}
          {/*    <Radio value="n">沒有</Radio>*/}
          {/*  </Radio.Group>*/}
          {/*</Form.Item>*/}

          <Form.Item name="typesOfVaccines" label="疫苗種類 / 次數">
            <Input placeholder="疫苗種類 / 次數"/>
          </Form.Item>

          <Form.Item name="dateOfVaccines" label="接種日期">
            <DatePicker placeholder="接種日期"/>
          </Form.Item>

          <Button
              htmlType="button"
              onClick={() => onEditFormClick("vaccine")}
              style={{marginRight: 20}}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title={"編輯銀行戶口"}
        visible={showEditBankAccountModal}
        footer={null}
        onCancel={() => onEditFormClick("bank")}
        width={900}
      >
        <Form
          form={editBankAccountForm}
          ref={editBankAccountFormRef}
          layout="vertical"
          onFinish={onEditModalSubmit}
          onValuesChange={(changedValues, allValues) => {
            let itemIndex = 0;
            let isUpdateDefault = false;

            for (let i = 0; i < changedValues.bankDetails.length; i++) {
              if (changedValues.bankDetails[i] && changedValues.bankDetails[i].default !== undefined) {
                itemIndex = i;
                isUpdateDefault = true;
                break;
              }
            }

            if (isUpdateDefault) {
              let newBankDetails = [];
              let bankDetails = allValues?.bankDetails || [];


              for (let i = 0; i < bankDetails.length; i++) {
                if (i === itemIndex) {
                  newBankDetails.push(bankDetails[i]);
                } else {
                  newBankDetails.push({
                    ...bankDetails[i],
                    default: false,
                  });
                }
              }

              editBankAccountForm.setFieldsValue({
                ...allValues,
                bankDetails: newBankDetails
              });
            }
          }}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid" />
          </Form.Item>

          <Form.List name="bankDetails">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Space key={field.key} align="baseline">
                    <Form.Item
                      {...field}
                      key={uuid()}
                      label="銀行名稱"
                      name={[field.name, "bankName"]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      key={uuid()}
                      label="戶口持有人姓名"
                      name={[field.name, "accountName"]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      key={uuid()}
                      label="戶口號碼"
                      name={[field.name, "bankNumber"]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      key={uuid()}
                      label="出糧方式"
                      style={{ width: 140 }}
                      name={[field.name, "paymentMethod"]}
                    >
                      <HelperPaymentMethodSelect />
                    </Form.Item>

                    <Form.Item
                      {...field}
                      key={uuid()}
                      label=" "
                      valuePropName="checked"
                      name={[field.name, "default"]}
                    >
                      <Checkbox>Default</Checkbox>
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    銀行戶口
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Button
            htmlType="button"
            onClick={() => onEditFormClick("bank")}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title={"編輯技能"}
        visible={showEditSkillModal}
        footer={null}
        onCancel={() => onEditFormClick("skill")}
        width={1100}
      >
        <Form
          form={editSkillForm}
          ref={editSkillFormRef}
          layout="vertical"
          initialValues={formatData}
          onFinish={onEditModalSubmit}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid" />
          </Form.Item>

          <Form.Item name="dailyCareSkills" label="日常護理技能">
            <Checkbox.Group style={{ width: "100%" }}>
              <Row>
                <Col span={4}>
                  <Checkbox value="簡單煮食">簡單煮食</Checkbox>
                </Col>
                <Col span={4}>
                  <Checkbox value="換尿片">換尿片</Checkbox>
                </Col>
                <Col span={4}>
                  <Checkbox value="扶抱">扶抱</Checkbox>
                </Col>
                <Col span={4}>
                  <Checkbox value="餵飯">餵飯</Checkbox>
                </Col>
                <Col span={4}>
                  <Checkbox value="提醒服藥">提醒服藥</Checkbox>
                </Col>
                <Col span={4}>
                  <Checkbox value="沐浴">沐浴</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item name="professionalNursingSkills" label="專業護理技能">
            <Checkbox.Group style={{ width: "100%" }}>
              <Row>
                <Col span={6}>
                  <Checkbox value="胃喉餵食">胃喉餵食</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="手術後護理">手術後護理</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="簡單傷口處理">簡單傷口處理</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="量度生命表徵">量度生命表徵</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="檢驗血糖，尿液">檢驗血糖，尿液</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="處理尿袋">處理尿袋</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="PEG">PEG</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="呼吸機">呼吸機</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="腹膜透析">腹膜透析</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="靈養服務/疼痛處理">
                    靈養服務/疼痛處理
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="入院，岀院及轉送技巧">
                    入院，岀院及轉送技巧
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="復康運動">復康運動</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="按摩（有證書）">按摩（有證書）</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="整理床舖及更換床單">
                    整理床舖及更換床單
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="傷口護理及更換止血墊">
                    傷口護理及更換止血墊
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="造口護理（包括：腹部造口/結腸造口護理)">
                    造口護理（包括：腹部造口/結腸造口護理)
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="藥物注射及服用">藥物注射及服用</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="基本急救（有證書）">
                    基本急救（有證書）
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item name="advancedNursingSkills" label="進階護理技能">
            <Checkbox.Group style={{ width: "100%" }}>
              <Row>
                <Col span={6}>
                  <Checkbox value="心肺復甦法（有證書）">
                    心肺復甦法（有證書）
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="接種疫苗和免疫接種">
                    接種疫苗和免疫接種
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="協助病人預備X光檢查及接受心電圖測試的技巧">
                    協助病人預備X光檢查及接受心電圖測試的技巧
                  </Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="抽痰">抽痰</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="抽血">抽血</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="更換胃喉">更換胃喉</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="更換尿喉">更換尿喉</Checkbox>
                </Col>
                <Col span={6}>
                  <Checkbox value="打胰島素針">打胰島素針</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>

          <Form.Item
            name="otherSkill"
            label="其他"
            style={{width: 280}}
            // rules={[{ required: true, message: "請選擇日常護理技能" }]}
          >
            <Input />
          </Form.Item>

          <Button
            htmlType="button"
            onClick={() => onEditFormClick("skill")}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title={"編輯時間表"}
        visible={showEditTimeTableModal}
        footer={null}
        onCancel={() => onEditFormClick("time")}
        width={700}
      >
        <Form
          form={editTimeTableForm}
          ref={editTimeTableFormRef}
          layout="vertical"
          initialValues={formatData}
          onFinish={onEditModalSubmit}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid" />
          </Form.Item>

          <Form.Item name="timeTable">
            <Checkbox.Group>
              <Row gutter={[16, 4]}>
                <Col flex={1}>
                  <div>星期一</div>
                  <Checkbox value="monAm">早更</Checkbox>
                  <br />
                  <br />
                  <Checkbox value="monPm">夜更</Checkbox>
                </Col>
                <Col flex={2}>
                  <div>星期二</div>
                  <Checkbox value="tueAm">早更</Checkbox>
                  <br />
                  <br />
                  <Checkbox value="tuePm">夜更</Checkbox>
                </Col>
                <Col flex={3}>
                  <div>星期三</div>
                  <Checkbox value="wedAm">早更</Checkbox>
                  <br />
                  <br />
                  <Checkbox value="wedPm">夜更</Checkbox>
                </Col>
                <Col flex={4}>
                  <div>星期四</div>
                  <Checkbox value="thuAm">早更</Checkbox>
                  <br />
                  <br />
                  <Checkbox value="thuPm">夜更</Checkbox>
                </Col>
                <Col flex={5}>
                  <div>星期五</div>
                  <Checkbox value="friAm">早更</Checkbox>
                  <br />
                  <br />
                  <Checkbox value="friPm">夜更</Checkbox>
                </Col>
                <Col flex={6}>
                  <div>星期六</div>
                  <Checkbox value="satAm">早更</Checkbox>
                  <br />
                  <br />
                  <Checkbox value="satPm">夜更</Checkbox>
                </Col>
                <Col flex={7}>
                  <div>星期日</div>
                  <Checkbox value="sunAm">早更</Checkbox>
                  <br />
                  <br />
                  <Checkbox value="sunPm">夜更</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>

          <Button
            htmlType="button"
            onClick={() => onEditFormClick("time")}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};

const SectionContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
`;

const SectionTitle = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 6px;
`;

const EditButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const DataContainer = styled.div`
  // padding-top: 2px;
`;

const DataTitle = styled.div`
  display: inline-block;
  font-size: 14px;
  color: #8d939e;
  padding-right: 5px;
`;

const DataContent = styled.div`
  display: inline-block;
  font-size: 14px;
  color: #3c3e42;
  font-weight: 500;
`;
