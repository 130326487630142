import React, { useState } from "react";
import { useAuth } from "context/auth-context";
import { Form, Input } from "antd";
import { LongButton } from "unauthenticated-app/index";
import { Button, Card, Divider, Typography } from "antd";
import styled from "@emotion/styled";
import logo from "assets/logo.svg";
import left from "assets/left.svg";
import right from "assets/right.svg";
import { useAsync } from "utils/use-async";
import { useDispatch } from "react-redux";
import { useDocumentTitle } from "utils";

const apiUrl = process.env.REACT_APP_API_URL;

export const LoginScreen = ({
  error,
  onError,
}: {
  error: Error | null;
  onError: (error: Error) => void;
}) => {
  useDocumentTitle("Unicare 360 Login");
  const { login, user } = useAuth();
  const { run, isLoading } = useAsync(undefined, { throwOnError: true });
  const dispatch = useDispatch();

  // HTMLFormElement extends Element
  const handleSubmit = async (values: {
    username: string;
    password: string;
  }) => {
    try {
      await run(login(values));
    } catch (e) {
      onError(e);
    }
  };

  return (
    <Container>
      <Header />
      <Background />
      <Logo>
        <img src="/logo.png" />
      </Logo>
      <ShadowCard>
        <Title>登入</Title>
        {error ? (
          <Typography.Text type={"danger"}>{error.message}</Typography.Text>
        ) : null}

        <Form onFinish={handleSubmit}>
          <Form.Item
            name={"username"}
            rules={[{ required: true, message: "username" }]}
          >
            <Input placeholder={"user name"} type="text" id={"username"} />
          </Form.Item>
          <Form.Item
            name={"password"}
            rules={[{ required: true, message: "password" }]}
          >
            <Input placeholder={"password"} type="password" id={"password"} />
          </Form.Item>
          <Form.Item>
            <LongButton
              loading={isLoading}
              htmlType={"submit"}
              type={"primary"}
            >
              登入
            </LongButton>
          </Form.Item>
        </Form>
      </ShadowCard>
    </Container>
  );
};

const Title = styled.h2`
  margin-bottom: 2.4rem;
  color: rgb(94, 108, 132);
`;

// const Background = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background-repeat: no-repeat;
//   background-attachment: fixed;
//   background-position: left bottom, right bottom;
//   background-size: calc(((100vw - 40rem) / 2) - 3.2rem),
//     calc(((100vw - 40rem) / 2) - 3.2rem), cover;
//   background-image: url(${left}), url(${right});
// `;

const Logo = styled.div`
  position: absolute;
  top: 10%;
  left: 200px;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-image: url("/login_bg.png");
`;

const Header = styled.header`
  background: url(${logo}) no-repeat center;
  padding: 5rem 0;
  background-size: 8rem;
  width: 100%;
`;

const ShadowCard = styled(Card)`
  position: absolute;
  top: 20%;
  right: 10%;

  width: 520px;
  // height: 452px;
  // min-height: 45rem;
  padding: 60px 70px;
  border-radius: 0.3rem;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 10px;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;
