import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Button, Form, Input, Modal, Table} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {FormInstance} from "antd/es/form";
import styled from "@emotion/styled";
import {useDebounce} from "../../../utils";
import {useAddEmployerBlacklist, useBlacklist, useDeleteEmployerBlacklist} from "../../../utils/blacklist";
import TextArea from "antd/es/input/TextArea";
import {HelperSelect} from "../../../components/helper-select";

export interface Blacklist {
  employerId: string;
  uuid: string;
  helper: {
    uuid: string
    nameCn: string;
  }
  remarks: string;
}

export interface EmployerBlacklistSearch {
  uuid: string
  keyword: string
}

export const BlacklistList = () => {
  const addHelperFormRef = React.createRef<FormInstance>();
  const [addHelperForm] = Form.useForm();
  const {id} = useParams();
  const [showAddHelperModal, setShowAddHelperModal] = useState(false);
  const [addHelperData, setAddHelperData] = useState({
    employerId: id,
    helperId: "",
  });

  const [param, setParam] = useState({uuid: id, keyword: ''})
  const {mutate, isLoading: addBlacklistLoading} = useAddEmployerBlacklist();
  const {mutate: deleteBlack} = useDeleteEmployerBlacklist();
  // const [param, setParam] = useEmployerBlacklistSearchParams();
  // const [pageObject, setPageObject] = useUrlQueryPaging();

  const {isLoading, error, data, retry} = useBlacklist(
      useDebounce(param, 200)
  );

  const onSearch = (keyword: string) => {
    setParam({...param, keyword: keyword})
    console.log(keyword);
  };

  const onDeleteHelper = (uuid: string, employerId: string, helperName: string) => {
    Modal.confirm({
      title: "移除黑名單?",
      icon: <ExclamationCircleOutlined/>,
      content: `移除${helperName}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      maskClosable: true,
      onOk: async () => {
        await deleteBlack({uuid, employerId})
        await retry();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onAddHelperClick = () => {
    setShowAddHelperModal(true);
  };

  const onAddHelperModalSubmit = async (values: Blacklist) => {
    await mutate(values)
    await setShowAddHelperModal(false);
    await retry();
  };

  const onAddHelperModalCancel = () => {
    setShowAddHelperModal(false);
    // addHelperFormRef.current!.resetFields();
  };

  return (
      <>
        <ContentContainer>
          <SearchBar>
            <Input.Search
                placeholder="ID, 幫手姓名"
                allowClear
                enterButton="搜尋"
                onSearch={onSearch}
                style={{width: 300}}
            />
            <Button
                type="primary"
                style={{position: "absolute", top: 15, right: 15}}
                onClick={() => onAddHelperClick()}
            >
              ＋加入幫手
            </Button>
          </SearchBar>

          <TableContainer>
            <Table<Blacklist>
                loading={isLoading}
                // columns={columns}
                dataSource={data?.list}
                // pagination={{ current: 1, total: 100 }}
            >
              <Table.Column
                  title="幫手編號"
                  dataIndex={['helper', 'helperNo']}
                  key="helperId"
              />
              <Table.Column
                  title="幫手姓名"
                  dataIndex={['helper', 'nameCn']}
                  key="helperName"
              />
              <Table.Column title="備註" dataIndex="remark" key="remark"/>
              <Table.Column
                  title="操作"
                  key="action"
                  render={(text, record: Blacklist) => (
                      <Button
                          type="link"
                          style={{padding: 0}}
                          onClick={() => onDeleteHelper(record.uuid, id, record?.helper?.nameCn)}
                      >
                        移除
                      </Button>
                  )}
              />
            </Table>
          </TableContainer>
        </ContentContainer>

        <Modal
            title={"加黑名單"}
            visible={showAddHelperModal}
            footer={null}
            destroyOnClose
            onCancel={onAddHelperModalCancel}
        >
          <Form
              form={addHelperForm}
              ref={addHelperFormRef}
              initialValues={addHelperData}
              layout="vertical"
              preserve={false}
              onFinish={onAddHelperModalSubmit}
          >
            <Form.Item name="employerId" label="employerId" hidden>
              <Input placeholder="employerId"/>
            </Form.Item>

            <Form.Item name="helper" label="幫手">
              <HelperSelect/>
              {/*<AdminSelect />*/}
            </Form.Item>

            <Form.Item name="remark" label="備注">
              <TextArea rows={4}/>
            </Form.Item>

            <Button
                htmlType="button"
                onClick={onAddHelperModalCancel}
                style={{marginRight: 20}}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={addBlacklistLoading}>
              Submit
            </Button>
          </Form>
      </Modal>
    </>
  );
};

const ContentContainer = styled.div`
  margin-top: 2px;
  overflow: auto;
`;

const SearchBar = styled.div`
  width: 100%;
  padding: 15px;
  background-color: #ffffff;
  position: relative;
`;

const TableContainer = styled.div`
  padding: 2rem;
  overflow: auto;
`;
