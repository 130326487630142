import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useDebounce } from "utils";
import {
  workingPeriodStatus,
  changeArrayToWeekDay,
  gender,
} from "utils/common";
import { Input, Button } from "antd";
import styled from "@emotion/styled";
import { useCheckJobDetail, useCheckoutJob } from "utils/job-public";

export const PublicCheckoutJobScreen = () => {
  const { uuid } = useParams();
  const [param, setParam] = useState({ uuid: uuid, phone: "" });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isFutureDate, setIsFutureDate] = useState(false);
  const [showJobData, setShowJobData] = useState(false);
  const [jobDetail, setJobDetail] = useState<any>();

  // const [jobData, setJobData] = useState({
  //   startDate: "",
  //   startTime: "",
  //   hours: "",
  // });
  const [submitData, setSubmitData] = useState({
    uuid: uuid,
    attendanceUuid: "",
    phone: "",
  });

  const {
    mutate: getJobDetail,
    data: jobDetailData,
    isSuccess: getJobDetailSuccess,
    isError: getJobDetailError,
  } = useCheckJobDetail();

  const {
    mutate,
    isLoading,
    isError,
    isSuccess,
    data: checkoutResult,
  } = useCheckoutJob();

  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setSubmitData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setParam((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!submitData.phone) {
      return alert("請輸入電話號碼");
    }
    await getJobDetail({ uuid, phone: submitData.phone });
  };

  const handleComplete = async () => {
    const isConfirm = window.confirm(
      `你確定${jobDetail?.attendance === 0 ? "確認工作" : "簽到"}嗎？`
    );

    if (isConfirm) {
      await mutate(submitData);
    }
  };

  useEffect(() => {
    if (getJobDetailSuccess) {
      const today = moment().format("YYYY-MM-DD");
      let checkDate =
        jobDetail?.taskRecord?.startDate === today
          ? true
          : moment(jobDetail?.taskRecord?.startDate).isAfter(today);
      setIsFutureDate(checkDate);
      setJobDetail(jobDetailData);
      setSubmitData((prevState) => ({
        ...prevState,
        attendanceUuid: jobDetailData?.uuid || "",
      }));
      setShowJobData(true);
    }
  }, [getJobDetailSuccess]);

  useEffect(() => {
    if (getJobDetailError) {
      alert("無效的電話號碼");
    }
  }, [getJobDetailError]);

  useEffect(() => {
    if (isSuccess && checkoutResult) {
      alert(jobDetail?.attendance === 0 ? "成功確認工作" : "成功簽到");

      setIsSubmitted(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      alert(jobDetail?.attendance === 0 ? "確認工作失敗" : "簽到失敗");
    }
  }, [isError]);

  return (
    <Container>
      <HeaderContainer>
        <h1>環宇護理 UniCare360</h1>
      </HeaderContainer>

      {showJobData ? (
        <>
          <RowContainer>
            <b>工作編號:</b> {jobDetail?.taskRecord?.task?.taskNo}
          </RowContainer>

          <RowContainer>
            <b>工作日期:</b> {jobDetail?.taskRecord?.startDate}
          </RowContainer>

          <RowContainer>
            <b>開始時間:</b> {jobDetail?.taskRecord?.startTime}
          </RowContainer>

          <RowContainer>
            <b>工作時數:</b> {jobDetail?.taskRecord?.workingHours}小時
          </RowContainer>

          <RowContainer>
            <b>工作地址:</b> {jobDetail?.taskRecord?.task?.district} {jobDetail?.taskRecord?.task?.address}
          </RowContainer>

          <RowContainer>
            <b>服務對象:</b>{" "}
            {jobDetail?.taskRecord?.task?.employer?.type === 1
              ? jobDetail?.taskRecord?.task?.employer?.companyName
              : `${gender(
                  jobDetail?.taskRecord?.task?.employer?.patientGender
                )}, 年齡: ${
                  jobDetail?.taskRecord?.task?.employer?.patientAge
                }, 身高: ${
                  jobDetail?.taskRecord?.task?.employer?.patientHeight
                }cm, 體重: ${
                  jobDetail?.taskRecord?.task?.employer?.patientWeight
                }kg`}
          </RowContainer>

          <RowContainer>
            如有查詢請致電/
            <a
              href="https://wa.me/+85298675630/?text=我想查詢工作詳情"
              target="_blank"
              rel="noreferrer"
            >
              whatsApps
            </a>
            ：98675630
          </RowContainer>

          {jobDetail?.attendance === 1 && isFutureDate ? (
            <FormContainer>
              <h3 style={{ paddingTop: 30 }}>已確認工作</h3>
            </FormContainer>
          ) : isSubmitted ? (
            <FormContainer>
              <h3 style={{ paddingTop: 30 }}>
                {jobDetail?.attendance === 0 ? "已確認工作" : "已簽到"}
              </h3>
            </FormContainer>
          ) : (
            <FormContainer>
              <Button
                type="primary"
                onClick={() => handleComplete()}
                style={{ marginTop: 20 }}
              >
                {jobDetail?.attendance === 0 ? "確認工作" : "簽到"}
              </Button>
            </FormContainer>
          )}
        </>
      ) : (
        <FormContainer>
          <FormTitle>輸入電話號碼完成工作</FormTitle>
          <Input
            placeholder="輸入電話號碼"
            name="phone"
            value={submitData.phone}
            onChange={handleChange}
          />
          <Button
            type="primary"
            onClick={() => handleSubmit()}
            style={{ marginTop: 20 }}
          >
            提交
          </Button>
        </FormContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 5rem 10px;
  width: 350px;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  text-align: center;
  padding-bottom: 20px;
`;

const RowContainer = styled.div`
  padding-bottom: 10px;
`;

const FormContainer = styled.div`
  text-align: center;
`;

const FormTitle = styled.div`
  padding-top: 10px;
  padding-bottom: 5px;
  font-weight: bold;
`;
