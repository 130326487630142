import {useAsync} from "utils/use-async";
import {useCallback, useEffect} from "react";
import {cleanObject} from "utils/index";
import {useHttp} from "utils/http";
import {Helper} from "../screens/helper/helper-list/list";

interface Admin { id: number, username: string }

export const useAdmin = (param?: Partial<Admin>) => {
    const client = useHttp();
    const {run, ...result} = useAsync<{ list: Admin[], total: number }>();

    const fetchHelpers = useCallback(
        () => client("admin/account", {data: cleanObject(param || {})}),
        [param, client]
    );

    useEffect(() => {
        run(fetchHelpers(), {
            retry: fetchHelpers,
        });
    }, [param, run, fetchHelpers]);

    return result;
};

