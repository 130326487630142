import {ColumnsType} from "antd/es/table";
import {CreditDebitNote} from "../../types/credit-debit-note";
import {creditDebitType, invoiceRecordStatus} from "../../utils/common";

export const columns: ColumnsType<CreditDebitNote> = [
  {
    title: "編號",
    dataIndex: "invoiceNo",
    width: 100
  },
  {
    title: "種類",
    dataIndex: "type",
    width: 100,
    render: (text, record) => creditDebitType(text),
  },
  // {
  //   title: "狀態",
  //   dataIndex: "status",
  //   width: 100,
  //   render: (text, record) => invoiceRecordStatus(text),
  // },
  {
    title: "金額",
    dataIndex: "total",
    key: "total",
    width: 150,
    render: (text, record) => {
      return !text && text !== 0 ? "--" : `$${text}`;
    },
    // width: 100
  },
  {
    title: "Ref No",
    dataIndex: "refNo",
    key: "refNo",
    width: 120,
    render: (text, record) => {
      if (record.employer) {
        return record.invoice.invoiceNo || "--";
      }

      return  record.salaryRecord.recordeNo || "--";
    },
    // width: 100
  },
  {
    title: "備注",
    dataIndex: "remark",
    width: 160
    // render: (text, record) => invoiceRecordStatus(text),
  },
  // {
  //   title: "操作",
  //   width: 120,
  //   dataIndex: "",
  //   // render: (text, record) => {
  //   //   return (
  //   //     <>
  //   //       {record.status !== 3 && (
  //   //         <>
  //   //           <Button
  //   //             type="link"
  //   //             style={{padding: 0, marginRight: 10}}
  //   //             onClick={() => handleDeleteInvoice(record)}
  //   //           >
  //   //             刪除
  //   //           </Button>
  //   //           <Button
  //   //             type="link"
  //   //             style={{padding: 0, marginRight: 10}}
  //   //             onClick={() => onEditInvoiceClick(record)}
  //   //           >
  //   //             {record.status === 0 ? "編輯" : "詳情"}
  //   //           </Button>
  //   //         </>
  //   //       )}
  //   //     </>
  //   //   );
  //   // },
  // },
];
