import { useAsync } from "utils/use-async";
import { useCallback, useEffect } from "react";
import { cleanObject } from "utils/index";
import { useHttp } from "utils/http";
import { Helper } from "../screens/helper/helper-list/list";

export const useJobDetailActivityLogList = (param?: any) => {
  const client = useHttp();
  const { run, ...result } = useAsync<any>();

  const fetchActivityLogs = useCallback(
    () => client(`tasks/${param?.uuid}/histories`),
    [param, client]
  );

  useEffect(() => {
    run(fetchActivityLogs(), {
      retry: fetchActivityLogs,
    });
  }, [param, run, fetchActivityLogs]);

  return result;
};

export const useAddJobActivityLog = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: any, uuid: string) => {
    return run(
      client(`tasks/${uuid}/histories`, {
        data: params,
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useEditJobActivityLog = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: any, uuid: string, logUuid: string) => {
    return run(
      client(`tasks/${uuid}/histories/${logUuid}`, {
        data: params,
        method: "PUT",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useDeleteJobActivityLog = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (taskUuid: string, logUuid: string) => {
    return run(
      client(`tasks/${taskUuid}/histories/${logUuid}`, {
        data: {},
        method: "DELETE",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};
