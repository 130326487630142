import React, {useState} from "react";
import {Profiler} from "../../components/profiler";
import styled from "@emotion/styled";
import {Button, DatePicker, Space} from "antd";
import {useExportSalesReport} from "../../utils/report";
import PageContainer from "../../components/layout/PageContainer";

const {RangePicker} = DatePicker;


export const Report = () => {
  const [dateRange, setDateRange] = useState<any>([])

  const { mutate, isLoading, isError, isSuccess } = useExportSalesReport()

  const exportSalesReport = async () => {
    const tempUrl = await mutate(dateRange);

    if (tempUrl && typeof tempUrl === "string") {
      let a = document.createElement("a"); //Create <a>
      a.href = tempUrl
      a.download = `${dateRange.from}-${dateRange.to}sales_report.xlsx`;
      a.click()
    }
  }

  return (
    <Profiler id={"Report"}>
      <PageContainer>
        <ContentContainer>
          <h2>Sales Report</h2>
          <Space direction="horizontal" size={12}>
            <RangePicker
              // value={dateRange}
              onChange={(value: any) => {
                setDateRange({
                  from: value[0]?.format('YYYY-MM-DD'),
                  to: value[1]?.format('YYYY-MM-DD'),
                })
              }}
            />
            <Button loading={isLoading} onClick={exportSalesReport}>Export</Button>
          </Space>
        </ContentContainer>
      </PageContainer>
    </Profiler>
  )
}

const ContentContainer = styled.div`
  padding: 3.2rem;
`;

