import { useAsync } from "utils/use-async";
import { useCallback, useEffect } from "react";
import { cleanObject } from "utils/index";
import {useHttp, useHttpDownload} from "utils/http";
import { PaymentRecord } from "screens/helper/helper-details/payment-records";
import { HelperPaymentRecord } from "types/helper-payment";
import { Page } from "./paging";
import { HelperPaymentSearch } from "screens/helper-payments/search-panel";

export const useHelperPayments = (param?: Partial<PaymentRecord>) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{
    list: PaymentRecord[];
    total: number;
  }>();

  const fetchHelperPayments = useCallback(
    () =>
      client(`helpers/${param?.helperUuid}/salaries`, {
        data: cleanObject(param || {}),
      }),
    [param, client]
  );

  useEffect(() => {
    run(fetchHelperPayments(), {
      retry: fetchHelperPayments,
    });
  }, [param, run, fetchHelperPayments]);

  return result;
};

export const useHelperPaymentDetail = () => {
  const { run, ...asyncResult } = useAsync<Partial<HelperPaymentRecord>>();
  const client = useHttp();
  const mutate = (helperUuid: string, salaryRecordUuid: string) => {
    return run(
      client(`helpers/${helperUuid}/salaries/${salaryRecordUuid}/payments`, {})
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useAddHelperPayment = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (
    params: Partial<PaymentRecord>,
    helperUuid: string,
    salaryRecordUuid: string
  ) => {
    return run(
      client(`helpers/${helperUuid}/salaries/${salaryRecordUuid}/payments`, {
        data: params,
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useEditHelperPayment = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (
    params: Partial<PaymentRecord>,
    helperUuid: string,
    salaryRecordUuid: string
  ) => {
    return run(
      client(`helpers/${helperUuid}/salaries/${salaryRecordUuid}/payments`, {
        data: params,
        method: "PUT",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useAddHelperSalary = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: Partial<PaymentRecord>, helperUuid: string) => {
    return run(
      client(`helpers/${helperUuid}/salaries`, {
        data: params,
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useAllHelperPayments = (
  param?: HelperPaymentSearch,
  pageObject?: Page
) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{
    list: PaymentRecord[];
    total: number;
  }>();

  const fetchHelperPayments = useCallback(
    () =>
      client(`helpers/salary_records`, {
        data: cleanObject({ ...param, ...pageObject } || {}),
      }),
    [client, param]
  );

  useEffect(() => {
    run(fetchHelperPayments(), {
      retry: fetchHelperPayments,
    });
  }, [run, fetchHelperPayments]);

  return result;
};

export const useAddAllHelperSalary = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: Partial<PaymentRecord>) => {
    return run(
      client(`helpers/salary_records`, {
        data: params,
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useExportPaymentExcel = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttpDownload();
  const mutate = () => {
    return client(`helpers/salary_records/export`, {
      method: "POST"
    });
  };
  return {
    mutate,
    ...asyncResult,
  };
};
