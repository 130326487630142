import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  Button,
  Select,
  Input,
  InputNumber,
  DatePicker,
  Drawer,
  Form,
  Modal,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import styled from "@emotion/styled";
import { EmployerPaymentRecord } from "types/employer-payment-record";
import { paymentMethod } from "utils/common";
import { dateDisplay } from "utils/moment-util";
import {
  useEmployerPayments,
  useEmployerPaymentDetail,
  useEditEmployerPayment,
  useDeleteEmployerPayment,
} from "utils/employer";

export const PaymentRecords = (props: { employerUuid: string }) => {
  const { id } = useParams();
  const [param, setParam] = useState({ uuid: id });
  const { employerUuid } = props;
  const [paymentDetailsForm] = Form.useForm();
  const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState(false);

  const {
    data: paymentsData,
    isLoading: paymentsLoading,
    retry: retryPaymentsData,
  } = useEmployerPayments(param);

  const {
    mutate: getEmployerPaymentDetail,
    data: employerPaymentDetail,
  } = useEmployerPaymentDetail();

  const {
    mutate: editEmployerPayment,
    isLoading: editPaymentLoading,
    isSuccess: isEditPaymentSuccess,
  } = useEditEmployerPayment();

  const {
    mutate: deleteEmployerInvoice,
    isLoading: deleteInvoiceLoading,
    isSuccess: isDeleteInvoiceSuccess,
  } = useDeleteEmployerPayment();

  useEffect(() => {
    if (isEditPaymentSuccess) {
      retryPaymentsData();
      setShowPaymentDetailsModal(false);
    }
  }, [isEditPaymentSuccess]);

  const columns: ColumnsType<Partial<EmployerPaymentRecord>> = [
    {
      title: "付款紀錄編號",
      dataIndex: "paymentNo",
    },
    {
      title: "付款日",
      dataIndex: "settleDate",
      render: (text, record) => <>{dateDisplay(text)}</>,
    },
    {
      title: "金額",
      dataIndex: "amount",
      render: (text, record) => <>{`$${text}`}</>,
    },
    {
      title: "付款方法",
      dataIndex: "method",
      render: (text, record) => <>{paymentMethod(text)}</>,
    },
    {
      title: "操作",
      key: "action",
      dataIndex: "",
      render: (text, record) => (
        <>
          {/* <Button
            type="link"
            style={{ padding: 0, marginRight: 10 }}
            onClick={() => onEditPaymentClick(record.uuid || "")}
          >
            編輯
          </Button> */}

          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() =>
              onDeletePaymentClick(record.uuid || "", record.paymentNo || "")
            }
          >
            刪除
          </Button>
        </>
      ),
    },
  ];

  const onEditPaymentClick = async (paymentUuid: string) => {
    await getEmployerPaymentDetail(employerUuid, paymentUuid);
    setShowPaymentDetailsModal(true);
  };

  const onPaymentDetailsModalCancel = () => {
    setShowPaymentDetailsModal(false);
  };

  const onPaymentDetailsModalSubmit = async (values: any) => {
    console.log(values);
    // await editEmployerPayment();
  };

  const onDeletePayment = async (paymentUuid: string) => {
    await deleteEmployerInvoice(paymentUuid, employerUuid);
    retryPaymentsData();
  };

  const onDeletePaymentClick = (
    paymentUuid: string,
    paymentRecordNo: string
  ) => {
    Modal.confirm({
      title: "刪除付款記錄",
      icon: <ExclamationCircleOutlined />,
      content: `是否要刪除${paymentRecordNo}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      maskClosable: true,
      onOk() {
        onDeletePayment(paymentUuid);
      },
    });
  };

  return (
    <>
      <Table<Partial<EmployerPaymentRecord>>
        columns={columns}
        size="small"
        dataSource={paymentsData?.list || []}
        loading={paymentsLoading}
        scroll={{
          y: window?.innerHeight ? window?.innerHeight - 320 : "max-content",
        }}
      />

      <Drawer
        title="編輯付款記錄"
        placement="right"
        onClose={onPaymentDetailsModalCancel}
        visible={showPaymentDetailsModal}
        width={376}
      >
        <Form
          form={paymentDetailsForm}
          layout="vertical"
          // initialValues={createPaymentData}
          onFinish={onPaymentDetailsModalSubmit}
        >
          <DrawerContent>
            {/* <DrawerRow>
              <DrawerRowTitle>
                發票編號: {createPaymentData.invoiceNo}
              </DrawerRowTitle>
            </DrawerRow>
            <DrawerRow>
              <DrawerRowTitle>
                發票金額: ${createPaymentData.total}
              </DrawerRowTitle>
            </DrawerRow> */}

            <Form.Item name="invoiceUuid" label="invoiceUuid" hidden>
              <Input placeholder="invoiceUuid" />
            </Form.Item>

            <Form.Item
              name="method"
              label="付款方式"
              // rules={[{ required: true, message: "請輸入" }]}
            >
              <Select placeholder="付款方式">
                <Select.Option value={0}>支票</Select.Option>
                <Select.Option value={1}>銀行過數</Select.Option>
                <Select.Option value={2}>FPS</Select.Option>
                <Select.Option value={3}>WeChat pay</Select.Option>
                <Select.Option value={4}>Ali pay</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="referenceNo" label="參考編號">
              <Input placeholder="參考編號" />
            </Form.Item>

            <Form.Item name="amount" label="付款金額">
              <InputNumber placeholder="付款金額" style={{ width: 200 }} />
            </Form.Item>

            <Form.Item
              name="settleDate"
              label="付款日"
              rules={[{ required: true, message: "請輸入付款日" }]}
            >
              <DatePicker placeholder="付款日" />
            </Form.Item>

            {/* <DrawerImageTitle>參考圖片</DrawerImageTitle>
            <Upload
              listType="picture-card"
              fileList={imageList}
              onPreview={handleImageModalOpen}
              beforeUpload={handleImageUpload}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload> */}
          </DrawerContent>
          <DrawerButtonGroup>
            <Button
              htmlType="button"
              style={{ marginRight: 20 }}
              onClick={() => onPaymentDetailsModalCancel()}
            >
              取消
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={editPaymentLoading}
            >
              儲存
            </Button>
          </DrawerButtonGroup>
        </Form>
      </Drawer>
    </>
  );
};

const DrawerContent = styled.div`
  margin-bottom: 60px;
  over-flow: auto;
`;

const DrawerButtonGroup = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 52px;
  background-color: #ffffff;
  border-top: 1px solid #f0f0f0;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
`;

const DrawerRow = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const DrawerRowTitle = styled.div`
  flex-grow: 1;
`;

const DrawerImageTitle = styled.div`
  margin-bottom: 10px;
`;
