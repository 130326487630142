import React, { useState } from "react";
import moment from "moment";
import { Form, Input, Button, Modal } from "antd";
import {
  ExclamationCircleOutlined,
  SettingOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  useAddJobActivityLog,
  useEditJobActivityLog,
  useDeleteJobActivityLog,
} from "utils/job-activity-log";

interface LogActivity {
  uuid: string;
  type?: number;
  title?: string;
  description?: string;
  personInCharge?: { username: string };
  createdAt?: Date;
}

export const ActivityLog = (props: {
  jobId: string;
  logRecords: LogActivity[];
  reloadActivityLog: () => void;
}) => {
  const { jobId, logRecords, reloadActivityLog } = props;
  const [showActivityLogModal, setShowActivityLogModal] = useState(false);
  const [activityLogForm] = Form.useForm();
  const [formType, setFormType] = useState("create");

  const {
    mutate: addJobActivityLog,
    isSuccess: addJobActivityLogSuccess,
  } = useAddJobActivityLog();
  const {
    mutate: editJobActivityLog,
    isSuccess: editJobActivityLogSuccess,
  } = useEditJobActivityLog();
  const {
    mutate: deleteJobActivityLog,
    isSuccess: deleteJobActivityLogSuccess,
  } = useDeleteJobActivityLog();

  const handleActivityLogModalOpen = () => {
    setFormType("create");
    activityLogForm.setFieldsValue({
      uuid: "",
      title: "",
      description: "",
    });
    setShowActivityLogModal(true);
  };

  const handleEditActivityLog = (log: LogActivity) => {
    setFormType("edit");
    activityLogForm.setFieldsValue({
      uuid: log.uuid,
      title: log.title || "",
      description: log.description || "",
    });
    setShowActivityLogModal(true);
  };

  const handleActivityLogModalCancel = () => {
    setShowActivityLogModal(false);
  };

  const handleActivityLogModalSubmit = async (values: any) => {
    if (values.uuid) {
      await editJobActivityLog(
        {
          title: values.title,
          description: values.description,
        },
        jobId,
        values.uuid
      );
    } else {
      await addJobActivityLog(
        {
          title: values.title,
          description: values.description,
        },
        jobId
      );
    }
    setShowActivityLogModal(false);
    reloadActivityLog();
  };

  const handleDeleteActivityLog = async (uuid: string) => {
    await deleteJobActivityLog(jobId, uuid);
    reloadActivityLog();
  };

  const handleConfirmDelete = (uuid: string, title: string) => {
    Modal.confirm({
      title: "刪除工作活動紀錄",
      icon: <ExclamationCircleOutlined />,
      content: `刪除 ${title} ?`,
      okText: "確認",
      cancelText: "取消",
      maskClosable: true,
      onOk() {
        handleDeleteActivityLog(uuid);
      },
    });
  };

  return (
    <>
      <Container>
        <TitleContainer>
          <Title>工作活動紀錄</Title>
          <AddButton onClick={() => handleActivityLogModalOpen()}>
            <PlusOutlined style={{ color: "#3C3E42", fontSize: 14 }} /> 添加事件
          </AddButton>
        </TitleContainer>
        {logRecords.map((item) => (
          <LogContainer key={item.uuid}>
            <LogLogo
              style={
                item.type === 0
                  ? { backgroundColor: "#A3B7F9" }
                  : { backgroundColor: "#F9CCA3" }
              }
            >
              <SettingOutlined style={{ color: "#FFFFFF" }} />
            </LogLogo>
            <LogMainContainer>
              {item.title?.split(",")?.[0] && (
                <LogTitle>{item.title?.split(",")?.[0]}</LogTitle>
              )}
              {item.title?.split(",")?.[1] && (
                <LogSubtitle>{item.title?.split(",")?.[1]}</LogSubtitle>
              )}
              <LogDescription>{item.description}</LogDescription>
              <AdminName>
                {item.personInCharge?.username} ‧{" "}
                {moment(item.createdAt).format("YYYY-MM-DD")}
              </AdminName>
            </LogMainContainer>
            {item.type === 0 && (
              <LogActionButtonContainer>
                <EditOutlined
                  style={{ marginRight: 10 }}
                  onClick={() => handleEditActivityLog(item)}
                />
                <DeleteOutlined
                  onClick={() =>
                    handleConfirmDelete(item.uuid, item.title || "")
                  }
                />
              </LogActionButtonContainer>
            )}
          </LogContainer>
        ))}
      </Container>

      <Modal
        title={formType === "edit" ? "編輯工作活動紀錄" : "新增工作活動紀錄"}
        visible={showActivityLogModal}
        footer={null}
        onCancel={() => handleActivityLogModalCancel()}
      >
        <Form
          form={activityLogForm}
          layout="vertical"
          onFinish={handleActivityLogModalSubmit}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid" />
          </Form.Item>

          <Form.Item name="title" label="標題">
            <Input placeholder="標題" />
          </Form.Item>

          <Form.Item name="description" label="描述">
            <Input.TextArea
              placeholder="描述"
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>

          <Button
            htmlType="button"
            onClick={() => handleActivityLogModalCancel()}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};

const Container = styled.div`
  height: calc(100vh - 190px - 6px);
  padding: 2rem;
  background-color: #fcfcfd;
  border-left: 2px solid #e0e5ef;
  overflow: auto;
`;

const TitleContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Title = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
`;

const AddButton = styled.div`
  position: absolute;
  top: 6px;
  right: 10px;
  font-size: 14px;
  cursor: pointer;
`;

const LogContainer = styled.div`
  padding: 8px;
  position: relative;
  min-height: 62px;
  margin-top: 6px;
`;

const LogLogo = styled.div`
  position: absolute;
  top: 10px;
  left: 0px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  padding-left: 5px;
  padding-top: 1px;
`;

const LogActionButtonContainer = styled.div`
  position: absolute;
  top: 18px;
  right: 8px;
`;

const LogMainContainer = styled.div`
  margin-left: 30px;
  margin-right: 60px;
`;

const LogTitle = styled.div`
  display: inline-block;
  font-size: 12px;
  color: #3c3e42;
  font-weight: bold;
`;

const LogSubtitle = styled.div`
  display: inline-block;
  font-size: 12px;
  color: #8d939e;
  padding-left: 6px;
`;

const LogDescription = styled.div`
  font-size: 12px;
  color: #3c3e42;
  padding-top: 2px;
  white-space: pre;
`;

const AdminName = styled.div`
  font-size: 10px;
  color: #8d939e;
  padding-top: 2px;
`;
