import React, { useState } from "react";
import { LoginScreen } from "unauthenticated-app/login";
import { PublicApplyJobScreen } from "unauthenticated-app/public-apply-job";
import { PublicCheckoutJobScreen } from "unauthenticated-app/public-checkout-job";
import { PublicConfirmPaymentScreen } from "unauthenticated-app/public-confirm-payment";
import { Navigate, Routes } from "react-router";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Button, Card, Divider, Typography } from "antd";
import styled from "@emotion/styled";
import logo from "assets/logo.svg";
import left from "assets/left.svg";
import right from "assets/right.svg";
import { useDocumentTitle } from "utils";

export const UnauthenticatedApp = () => {
  const [error, setError] = useState<Error | null>(null);

  return (
    <Router>
      <Routes>
        <Route path={"/applyjob/:uuid"} element={<PublicApplyJobScreen />} />
        <Route
          path={"/checkoutjob/:uuid"}
          element={<PublicCheckoutJobScreen />}
        />
        <Route
          path={"/confirmpayment/:helperUuid/:salaryRecordUuid"}
          element={<PublicConfirmPaymentScreen />}
        />
        <Route
          path={"*"}
          element={<LoginScreen error={error} onError={setError} />}
        />
      </Routes>
    </Router>
  );
};

export const LongButton = styled(Button)`
  width: 100%;
`;
