import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import moment from "moment";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select, Space,
  Table, Tag,
  Upload,
} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {FormInstance} from "antd/es/form";
import {ColumnsType} from "antd/es/table";
import styled from "@emotion/styled";
import {Row as LibRow} from "components/lib";
import {useDebounce} from "utils";
import {
  useAddHelperPayment,
  useAddHelperSalary,
  useEditHelperPayment,
  useHelperPaymentDetail,
  useHelperPayments,
} from "utils/helper-payment";
import {BankAccount} from "types/bank-account";
import {HelperPaymentRecord} from "types/helper-payment";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {paymentMethod, paymentStatus} from "utils/common";
import {dateDisplay} from "utils/moment-util";
import {HelperAttendance} from "types/helper-attendance";
import {useCreateCreditDebitNote} from "../../../utils/credit-debit-note";
import {CreditDebitNote} from "../../../types/credit-debit-note";
import {CommonList} from "../../../components/listing/common-list";
import {HelperPaymentExpandedTable} from "../../../components/listing/helper-payment-expanded-table";

export interface PaymentRecord {
  helperUuid: string;
  uuid: string;
  paymentNo: string;
  paymentDate: string;
  paymentPeriod: string;
  salary: number | undefined;
  paymentMethod: string;
  settleFrom: Date | string | undefined;
  settleTo: Date | string | undefined;
  releaseDate: Date | string | undefined;
  payment: { uuid: string, status: number, method: number } | undefined;
  attachments: string[];
  helperAttendances: HelperAttendance[];
  helper: {
    uuid: string;
    phone: string;
    bankDetails: BankAccount[];
  };
}

export const PaymentRecords = (props: {
  helperUuid: string;
  bankDetails: BankAccount[];
}) => {
  const {helperUuid, bankDetails} = props;
  const {id} = useParams();
  const [param, setParam] = useState({helperUuid: id});
  const createPaymentFormRef = React.createRef<FormInstance>();
  const [createPaymentForm] = Form.useForm();
  const [showCreatePaymentModal, setShowCreatePaymentModal] = useState(false);
  const [paymentDetailsForm] = Form.useForm();
  const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState(false);
  const [createNoteForm] = Form.useForm();
  const [showCreateNoteModal, setShowCreateNoteModal] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<number | null | undefined>(null);
  const [confirmPaymentText, setConfirmPaymentText] = useState("");
  const [paymentDetailData, setPaymentDetailData] = useState<Partial<HelperPaymentRecord>>({
    method: null,
    referenceNo: "",
    // bankNumber: "",
    attachments: [],
    status: null,
  });

  const [creditDebitNoteDetails, setCreditDebitNoteDetails] = useState<Partial<CreditDebitNote>>({
    type: 0,
    invoiceDate: "",
    dueDate: "",
    remark: "",
    creditDebitNoteItems: [],
    total: 0,
  });

  const [salaryData, setSalaryData] = useState<Partial<HelperPaymentRecord>>(
    {}
  );

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [imageList, setImageList] = useState([]);

  const {data, isLoading: paymentLoading, retry} = useHelperPayments(
    useDebounce(param, 200)
  );

  const {
    data: paymentDetail,
    mutate: getPaymentDetail,
    isLoading: getPaymentDetailLoading,
  } = useHelperPaymentDetail();

  const {
    mutate: addHelperSalary,
    isLoading: createSalaryLoading,
  } = useAddHelperSalary();

  const {
    mutate: addHelperPayment,
    isLoading: createPaymentLoading,
  } = useAddHelperPayment();

  const {
    mutate: editHelperPayment,
    isLoading: editPaymentLoading,
  } = useEditHelperPayment();

  const {
    mutate: createCreditDebitNote,
    isLoading: createCreditDebitNoteLoading,
    isSuccess: isCreateCreditDebitNoteSuccess,
  } = useCreateCreditDebitNote();

  useEffect(() => {
    if (paymentDetail) {
      setSelectedPaymentMethod(paymentDetail.method);
      setPaymentDetailData({
        method: paymentDetail.method,
        // chequeNo: paymentDetail.method === 0 ? paymentDetail.referenceNo : "",
        // bankNumber: paymentDetail.method === 1 ? paymentDetail.referenceNo : "",
        referenceNo: paymentDetail?.referenceNo,
        attachments: paymentDetail?.attachments,
        status: paymentDetail.status,
      });
    } else {
      setPaymentDetailData({
        method: null,
        referenceNo: "",
        // bankNumber: "",
        attachments: [],
        status: null,
      });
    }
  }, [paymentDetail]);

  const columns: ColumnsType<PaymentRecord> = [
    {
      title: "出糧編號",
      dataIndex: "recordeNo",
      key: "recordeNo",
      render: (text, record) => (
        <a href={`/helper/${record.helper.uuid}?tab=2`}>{text}</a>
      ),
      width: 110
    },
    {
      title: "幫手",
      dataIndex: ["helper", "nameCn"],
      key: "helperName",
      render: (text, record) => (
        `${text} (${record.helper.phone})`
      ),
      // width: 90
    },
    {
      title: "出糧狀態",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      render: (text, record) =>
        record?.payment?.status ? (
          <Tag color="success">{paymentStatus(record?.payment?.status)}</Tag>
        ) : (
          // paymentMethod(text)
          "-"
        ),
      width: 90
    },
    {
      title: "出糧日期",
      dataIndex: "releaseDate",
      key: "releaseDate",
      render: (text, record) => {
        return dateDisplay(text);
      },
      width: 110
    },
    {
      title: "結算時段",
      dataIndex: "",
      key: "period",
      render: (text, record) => {
        return (
          dateDisplay(record.settleFrom) + " - " + dateDisplay(record.settleTo)
        );
      },
      width: 220
    },
    {
      title: "出糧方式",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      render: (text, record) =>
        record?.payment ? (
          <Tag color="success">{paymentMethod(record?.payment?.method)}</Tag>
        ) : (
          // paymentMethod(text)
          "-"
        ),
      width: 90
    },
    {
      title: "出糧薪金",
      dataIndex: "salary",
      key: "salary",
      render: (text, record) => {
        return !text && text !== 0 ? "--" : `$${text}`;
      },
      width: 120
    },
    {
      title: "功能",
      dataIndex: "",
      key: "action",
      // fixed: 'right',
      render: (_, record) => {
        return (
          <Space size="middle">
            <a onClick={() => handlePaymentDetailsOpen(record)}>出糧</a>
            {/* <a>編輯</a> */}
          </Space>
        );
      },
      width: 90
    },
  ];

  const updateConfirmPaymentText = (salaryRecordUuid?: string) => {
    setConfirmPaymentText(`
    *環宇護理UniCare360薪金領取確認*\n\n ✅ *確認薪金領取* ✅ \nhttps://360unicare.com/confirmpayment/${helperUuid}/${salaryRecordUuid}`);
  };

  const onCreatePaymentModalOpen = () => {
    createPaymentForm.setFieldsValue({
      datePeriod: [null, null],
    });
    setShowCreatePaymentModal(true);
  };

  const onCreatePaymentModalCancel = () => {
    setShowCreatePaymentModal(false);
  };

  const onCreatePaymentModalSubmit = async (values: any) => {
    const settleFrom = moment(values?.datePeriod?.[0]).format("YYYY-MM-DD");
    const settleTo = moment(values?.datePeriod?.[1]).format("YYYY-MM-DD");

    await addHelperSalary(
      {
        settleFrom: settleFrom,
        settleTo: settleTo,
      },
      helperUuid
    );

    retry();
    setShowCreatePaymentModal(false);
  };

  const handlePaymentDetailsOpen = async (record: Partial<PaymentRecord>) => {
    updateConfirmPaymentText(record.uuid);
    setSalaryData({
      uuid: record?.uuid,
      settleFrom: record.settleFrom
        ? moment(record.settleFrom).format("YYYY-MM-DD")
        : "",
      settleTo: record.settleTo
        ? moment(record.settleTo).format("YYYY-MM-DD")
        : "",
      salary: record.salary,
      payment: record.payment,
    });

    // if (record.payment) {
    //   await getPaymentDetail(helperUuid, record.uuid || "");
    // }

    await getPaymentDetail(helperUuid, record.uuid || "");
    setShowPaymentDetailsModal(true);
  };

  const handlePaymentDetailsClose = async () => {
    await setPaymentDetailData({
      method: null,
      referenceNo: "",
      // bankNumber: "",
      attachments: [],
      status: null,
    });
    await setShowPaymentDetailsModal(false);
  };

  const handlePaymentDetailsSubmit = async (values: any) => {
    let submitData = {
      method: values.method,
      referenceNo: values.referenceNo,
      attachments: [],
    };

    if (salaryData?.payment) {
      await editHelperPayment(submitData, helperUuid, salaryData?.uuid || "");
    } else {
      await addHelperPayment(submitData, helperUuid, salaryData?.uuid || "");
    }

    setShowPaymentDetailsModal(false);
  };

  const handleImageModalOpen = (file: any) => {
    setImageSrc(file?.url);
    setShowImageModal(true);
  };

  const handleImageModalClose = () => {
    setShowImageModal(false);
    setImageSrc("");
  };

  const handleImageUpload = (file: any, fileList: any[]) => {
    console.log(file);
    console.log(fileList);

    return false;
  };

  const handleNoteClick = async (
    salaryRecord: PaymentRecord
  ) => {
    createNoteForm.setFieldsValue({
      type: 0,
      invoiceDate: "",
      dueDate: "",
      remark: "",
      creditDebitNoteItems: [],
      // employerUuid: invoice.employerUuid,
      helperUuid: helperUuid,
      // invoiceUuid: invoice.uuid,
      salaryRecordUuid: salaryRecord.uuid
    });
    setShowCreateNoteModal(true);
  };

  const onCreateNoteModalSubmit = async (values: any) => {
    await createCreditDebitNote({
      ...values,
      invoiceDate: moment(values.invoiceDate).format("YYYY-MM-DD"),
      dueDate: moment(values.dueDate).format("YYYY-MM-DD"),
    });

    await setShowCreateNoteModal(false)
  };

  return (
    <>
      <LibRow marginBottom={2} between={true}>
        <div></div>
        <Button type="primary" onClick={() => onCreatePaymentModalOpen()}>
          ＋創建出糧記錄
        </Button>
      </LibRow>
      <CommonList
        refresh={retry}
        tableProps={{
          loading: paymentLoading,
          columns,
          dataSource: data?.list || [],
          expandable: {
            expandedRowRender: (record: {
              helperAttendances: HelperAttendance[];
            }) => HelperPaymentExpandedTable(record),
          },
          // pagination: false,
          // scroll: {
          //   y: windowHeight - 320,
          // }
        }}
        // paginationProps={{
        //   pageSize: pageObject.pageSize,
        //   current: pageObject.pageNumber,
        //   total: paymentsData?.total || 0,
        //   onChange: (page, pageSize) => {
        //     setPageObject({
        //       pageSize,
        //       pageNumber: page,
        //     });
        //   }
        // }}
      />

      <Modal
        title={"創建出糧紀錄"}
        visible={showCreatePaymentModal}
        footer={null}
        onCancel={onCreatePaymentModalCancel}
      >
        <Form
          form={createPaymentForm}
          ref={createPaymentFormRef}
          layout="vertical"
          onFinish={onCreatePaymentModalSubmit}
        >
          <Form.Item
            name="datePeriod"
            label="結算時段"
            rules={[{required: true, message: "請輸入日期"}]}
          >
            <DatePicker.RangePicker placeholder={["由", "至"]}/>
          </Form.Item>

          <Button
            htmlType="button"
            onClick={onCreatePaymentModalCancel}
            style={{marginRight: 20, marginTop: 20}}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={createSalaryLoading}
          >
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        visible={showImageModal}
        title="圖片"
        footer={null}
        onCancel={() => handleImageModalClose()}
      >
        <img alt="" style={{width: "100%"}} src={imageSrc}/>
      </Modal>

      <Drawer
        title="出糧紀錄"
        placement="right"
        destroyOnClose
        onClose={handlePaymentDetailsClose}
        visible={showPaymentDetailsModal}
        width={376}
      >
        <Form
          form={paymentDetailsForm}
          layout="vertical"
          preserve={false}
          initialValues={paymentDetailData}
          onFinish={handlePaymentDetailsSubmit}
          onValuesChange={(changedValues) => {
            if (Object.keys(changedValues)[0] === "method") {
              setSelectedPaymentMethod(
                changedValues[Object.keys(changedValues)[0]]
              );
            }
          }}
        >
          <DrawerContent>
            <Form.Item
              name="method"
              label="出糧方式"
              // rules={[{ required: true, message: "請輸入" }]}
            >
              <Select placeholder="出糧方式">
                <Select.Option value={0}>支票</Select.Option>
                <Select.Option value={1}>銀行過數</Select.Option>
                <Select.Option value={2}>FPS</Select.Option>
                <Select.Option value={3}>WeChat pay</Select.Option>
                <Select.Option value={4}>Ali pay</Select.Option>
                <Select.Option value={5}>現金</Select.Option>
              </Select>
            </Form.Item>

            {selectedPaymentMethod === 0 && (
              <Form.Item name="referenceNo" label="支票號碼">
                <Input placeholder="支票號碼"/>
              </Form.Item>
            )}

            {selectedPaymentMethod === 1 && (
              <Form.Item name="referenceNo" label="銀行戶口">
                <Select placeholder="銀行戶口">
                  {(bankDetails || []).map((item, index) => (
                    <Select.Option key={index} value={item.bankNumber}>
                      {item.bankName} - {item.bankNumber}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {selectedPaymentMethod !== 0 && selectedPaymentMethod !== 1 && (
              <Form.Item name="referenceNo" label="參考號碼">
                <Input placeholder="參考號碼"/>
              </Form.Item>
            )}

            <DrawerRow>
              <DrawerRowTitle>結算時段</DrawerRowTitle>
              <div>
                {salaryData.settleFrom} - {salaryData.settleTo}
              </div>
            </DrawerRow>

            <DrawerRow>
              <DrawerRowTitle>出糧薪金</DrawerRowTitle>
              <div>
                $
                {!salaryData.salary && salaryData.salary !== 0
                  ? "--"
                  : salaryData.salary}
              </div>
            </DrawerRow>

            <DrawerRow>
              <DrawerRowTitle>狀態</DrawerRowTitle>
              <div>{paymentStatus(paymentDetailData.status || 0)}</div>
              <DrawerLinkButton>
                {paymentDetailData.status === 0 && (
                  <CopyToClipboard
                    text={confirmPaymentText}
                    onCopy={() => alert("copied")}
                  >
                    <Button type="primary">發送確認領取提醒</Button>
                  </CopyToClipboard>
                )}
              </DrawerLinkButton>
            </DrawerRow>

            <DrawerImageTitle>參考圖片</DrawerImageTitle>
            <Upload
              listType="picture-card"
              fileList={imageList}
              onPreview={handleImageModalOpen}
              // onChange={this.handleChange}
              beforeUpload={handleImageUpload}
            >
              <div>
                <PlusOutlined/>
                <div style={{marginTop: 8}}>Upload</div>
              </div>
            </Upload>
          </DrawerContent>
          <DrawerButtonGroup>
            <Button
              htmlType="button"
              style={{marginRight: 20}}
              onClick={() => handlePaymentDetailsClose()}
            >
              取消
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={createPaymentLoading || editPaymentLoading}
            >
              儲存
            </Button>
          </DrawerButtonGroup>
        </Form>
      </Drawer>
      <Modal
        title="創建Note"
        visible={showCreateNoteModal}
        footer={null}
        onCancel={() => setShowCreateNoteModal(false)}
        width={1000}
      >
        <Form
          form={createNoteForm}
          layout="vertical"
          initialValues={creditDebitNoteDetails}
          onFinish={onCreateNoteModalSubmit}
          onValuesChange={(changedValues, allValues) => {
            if (Object.keys(changedValues)[0] === "creditDebitNoteItems") {
              let total = 0;
              let subTotal = 0;

              if (
                allValues.creditDebitNoteItems &&
                allValues.creditDebitNoteItems.length > 0
              ) {
                const newcreditDebitItems = [];

                for (
                  let i = 0;
                  i < allValues.creditDebitNoteItems.length;
                  i++
                ) {
                  subTotal =
                    allValues.creditDebitNoteItems[i]?.cost &&
                    allValues.creditDebitNoteItems[i]?.quantity
                      ? allValues.creditDebitNoteItems[i]?.cost *
                      allValues.creditDebitNoteItems[i]?.quantity
                      : 0;
                  total = total + subTotal;
                  newcreditDebitItems.push({
                    itemName: allValues.creditDebitNoteItems[i]?.itemName,
                    quantity: allValues.creditDebitNoteItems[i]?.quantity,
                    cost: allValues.creditDebitNoteItems[i]?.cost,
                    subTotal: subTotal,
                  });
                }

                createNoteForm.setFieldsValue({
                  creditDebitNoteItems: newcreditDebitItems,
                });
                setCreditDebitNoteDetails((prevState) => ({
                  ...prevState,
                  total: total,
                }));
              } else {
                setCreditDebitNoteDetails((prevState) => ({
                  ...prevState,
                  total: 0,
                }));
              }
            }
          }}
        >
          <Form.Item name="helperUuid" label="helperUuid" hidden>
            <Input placeholder="helperUuid"/>
          </Form.Item>
          <Form.Item name="salaryRecordUuid" label="salaryRecordUuid" hidden>
            <Input placeholder="salaryRecordUuid"/>
          </Form.Item>
          <Row>
            <Col span={13}>
              <Form.Item name="type" label="Type">
                <Select placeholder="Type" style={{width: 120}}>
                  <Select.Option value={0}>Credit</Select.Option>
                  <Select.Option value={1}>Debit</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={1}/>
            <Col span={5}>
              <Form.Item
                name="invoiceDate"
                label="發票日期"
                rules={[{required: true, message: "請輸入發票日期"}]}
              >
                <DatePicker placeholder="發票日期"/>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="dueDate"
                label="付款過期日"
                rules={[{required: true, message: "請輸入付款過期日"}]}
              >
                <DatePicker placeholder="付款過期日"/>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Divider/>
            </Col>
          </Row>

          <InvoiceDetailSectionTitle>項目</InvoiceDetailSectionTitle>

          <Row
            gutter={[16, 4]}
            style={{backgroundColor: "#E7ECFF", marginBottom: 10}}
          >
            <Col span={9}>項目</Col>
            <Col span={4}>數量</Col>
            <Col span={5}>價錢</Col>
            <Col span={5}>總額</Col>
            <Col span={1}></Col>
          </Row>

          <Form.List name="creditDebitNoteItems">
            {(fields, {add, remove}) => (
              <>
                {fields.map(({key, name, ...restField}) => (
                  <Row key={key} gutter={[16, 4]}>
                    <Col span={9}>
                      <Form.Item
                        {...restField}
                        name={[name, "itemName"]}
                        rules={[{required: true, message: "請輸入項目"}]}
                      >
                        <Input placeholder="項目"/>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, "quantity"]}
                        rules={[{required: true, message: "請輸入數量"}]}
                      >
                        <InputNumber
                          placeholder="數量"
                          style={{width: 120}}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        {...restField}
                        name={[name, "cost"]}
                        rules={[{required: true, message: "請輸入價錢"}]}
                      >
                        <InputNumber
                          placeholder="價錢"
                          style={{width: 120}}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item {...restField} name={[name, "subTotal"]}>
                        <Input
                          placeholder="總額"
                          style={{width: 140}}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <MinusCircleOutlined onClick={() => remove(name)}/>
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined/>}
                  >
                    增加項目
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Row>
            <Col span={24}>
              <Divider/>
            </Col>
          </Row>

          <Row gutter={[16, 4]}>
            <Col span={17}></Col>
            <Col span={2}>
              <EditInvoiceTotalPriceTitle>總額</EditInvoiceTotalPriceTitle>
            </Col>
            <Col span={5}>
              <EditInvoiceTotalPrice>
                {creditDebitNoteDetails.total}
              </EditInvoiceTotalPrice>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item name="remark" label="備註">
                <Input placeholder="備註"/>
              </Form.Item>
            </Col>
          </Row>

          <Button
            htmlType="button"
            onClick={() => setShowCreateNoteModal(false)}
            style={{marginRight: 20}}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={createCreditDebitNoteLoading}
          >
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};

const DrawerContent = styled.div`
  margin-bottom: 60px;
  over-flow: auto;
`;

const DrawerButtonGroup = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 52px;
  background-color: #ffffff;
  border-top: 1px solid #f0f0f0;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
`;

const DrawerRow = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const DrawerRowTitle = styled.div`
  flex-grow: 1;
`;

const DrawerImageTitle = styled.div`
  margin-bottom: 10px;
`;

const DrawerLinkButton = styled.div`
  padding-left: 8px;
`;

const EditInvoiceTotalPriceTitle = styled.div`
  color: #8d939e;
  font-size: 24px;
  font-weight: 500;
`;

const EditInvoiceTotalPrice = styled.div`
  color: #3c3e42;
  font-size: 24px;
  font-weight: bold;
`;

const InvoiceDetailSectionTitle = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 4px;
`;
