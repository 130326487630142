import React from "react";
import {Form, Input, Select} from "antd";
import styled from "@emotion/styled";
import {District} from "components/district";
import {Page} from "../../../utils/paging";
// import { Project } from "types/project";

const {Option} = Select;

export interface EmployerSearch extends Page {
    district: string
    status: string
    keyword: string
}

interface SearchPanelProps {
    param: EmployerSearch;
    setParam: (param: SearchPanelProps["param"]) => void;
}

export const SearchPanel = ({param, setParam}: SearchPanelProps) => {
    return (
        <TopSearchBar>
            <Form layout={"inline"}>
                <Form.Item

                >
                    <District
                        value={param.district && [...param.district.split(",")]}
                        onChange={(value: any) =>
                            setParam({
                                ...param,
                                district: value.toString(),
                            })
                        }
                    />
                </Form.Item>

                <Form.Item>
                    <Input
                        placeholder="姓名, 院舍名稱, ID, 電話"
                        allowClear
                        value={param.keyword}
                        onChange={(value) =>
                            setParam({
                                ...param,
                                keyword: value.target.value,
                            })
                        }
                    />
                </Form.Item>
            </Form>
        </TopSearchBar>
    );
};

const TopSearchBar = styled.div`
  width: 100%;
  height: 64px;
  padding: 16px 20px;
  background-color: #ffffff;
`;
