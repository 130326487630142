import {useAsync} from "./use-async";
import {useHttpDownload} from "./http";

export const useExportSalesReport = () => {
  const {run, ...asyncResult} = useAsync();
  const client = useHttpDownload();
  const mutate = (params: { from: string, to: string }) => {
    return client(`report/sales_report/export`, {
      data: params,
      method: "POST"
    });
  };
  return {
    mutate,
    ...asyncResult,
  };
};
