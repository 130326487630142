import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import { EditOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { ListTable } from "components/table/list-table";

export interface Position {
  uuid: string;
  name: string;
  type: number;
  description: string;
  helper1: number;
  helper2: number;
  helper3: number;
  helper4: number;
  helper5: number;
  helper6: number;
  helper7: number;
  helper8: number;
  helper9: number;
  helper10: number;
  helper11: number;
  helper12: number;
  employer1: number;
  employer2: number;
  employer3: number;
  employer4: number;
  employer5: number;
  employer6: number;
  employer7: number;
  employer8: number;
  employer9: number;
  employer10: number;
  employer11: number;
  employer12: number;
}

export const HelperPrice = (props: {
  positionData: Position[];
  handleEditPriceClick: (data: any) => void;
}) => {
  const navigate = useNavigate();

  const { positionData, handleEditPriceClick } = props;

  return (
    <>
      {positionData.map((item) => (
        <SectionContainer key={item.uuid}>
          <SectionTitle>{item.name}</SectionTitle>
          <SectionDescription>{item.description}</SectionDescription>
          <EditButton>
            <EditOutlined onClick={() => handleEditPriceClick(item)} />
          </EditButton>
          <DataContainer>
            <Row>
              <Col span={2}>
                <DataContent>幫手價錢</DataContent>
                <DataContent>僱主價錢</DataContent>
                <DataTitle>價錢</DataTitle>
              </Col>
              <Col span={22}>
                <Row>
                  <Col span={2}>
                    <DataContent>${item.helper1}</DataContent>
                    <DataContent>${item.employer1}</DataContent>
                    <DataTitle>1小時</DataTitle>
                  </Col>
                  <Col span={2}>
                    <DataContent>${item.helper2}</DataContent>
                    <DataContent>${item.employer2}</DataContent>
                    <DataTitle>2小時</DataTitle>
                  </Col>
                  <Col span={2}>
                    <DataContent>${item.helper3}</DataContent>
                    <DataContent>${item.employer3}</DataContent>
                    <DataTitle>3小時</DataTitle>
                  </Col>
                  <Col span={2}>
                    <DataContent>${item.helper4}</DataContent>
                    <DataContent>${item.employer4}</DataContent>
                    <DataTitle>4小時</DataTitle>
                  </Col>
                  <Col span={2}>
                    <DataContent>${item.helper5}</DataContent>
                    <DataContent>${item.employer5}</DataContent>
                    <DataTitle>5小時</DataTitle>
                  </Col>
                  <Col span={2}>
                    <DataContent>${item.helper6}</DataContent>
                    <DataContent>${item.employer6}</DataContent>
                    <DataTitle>6小時</DataTitle>
                  </Col>
                  <Col span={2}>
                    <DataContent>${item.helper7}</DataContent>
                    <DataContent>${item.employer7}</DataContent>
                    <DataTitle>7小時</DataTitle>
                  </Col>
                  <Col span={2}>
                    <DataContent>${item.helper8}</DataContent>
                    <DataContent>${item.employer8}</DataContent>
                    <DataTitle>8小時</DataTitle>
                  </Col>
                  <Col span={2}>
                    <DataContent>${item.helper9}</DataContent>
                    <DataContent>${item.employer9}</DataContent>
                    <DataTitle>9小時</DataTitle>
                  </Col>
                  <Col span={2}>
                    <DataContent>${item.helper10}</DataContent>
                    <DataContent>${item.employer10}</DataContent>
                    <DataTitle>10小時</DataTitle>
                  </Col>
                  <Col span={2}>
                    <DataContent>${item.helper11}</DataContent>
                    <DataContent>${item.employer11}</DataContent>
                    <DataTitle>11小時</DataTitle>
                  </Col>
                  <Col span={2}>
                    <DataContent>${item.helper12}</DataContent>
                    <DataContent>${item.employer12}</DataContent>
                    <DataTitle>12小時</DataTitle>
                  </Col>
                </Row>
              </Col>
            </Row>
          </DataContainer>
        </SectionContainer>
      ))}
    </>
  );
};

const SectionContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
  position: relative;
`;

const SectionTitle = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 6px;
`;

const SectionDescription = styled.div`
  color: #8d939e;
  //font-size: 16px;
  padding-bottom: 6px;
`;

const EditButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const DataContainer = styled.div`
  border-top: 1px solid #e0e5ef;
  padding-top: 8px;
`;

const DataTitle = styled.div`
  font-size: 12px;
  color: #8d939e;
  padding-top: 5px;
  padding-right: 5px;
`;

const DataContent = styled.div`
  //font-size: 16px;
  color: #3c3e42;
  font-weight: 500;
  padding-top: 2px;
`;
