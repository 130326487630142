import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Table,} from "antd";
import {MinusCircleOutlined, PlusOutlined,} from "@ant-design/icons";
import {ColumnsType} from "antd/es/table";
import styled from "@emotion/styled";
import {useDebounce,} from "utils";
import {useUrlQueryPaging} from "utils/paging";
import {creditDebitType, invoiceRecordStatus} from "utils/common";
import {Employer} from "screens/employer/employer-list/list";
import {useCreditDebitNoteSearchParams} from "search-utils/util";

import {
  useCreateCreditDebitNote,
  useCreditDebitNoteDetail,
  useCreditDebitNotes,
  useEmployerCreditDebitNotes,
} from "utils/credit-debit-note";
import {CreditDebitNote} from "types/credit-debit-note";
import {columns} from "../../../constants/columns/credit-debit-note.column";

export const CreditDebitNotes = (props: {
  employerUuid: string;
  employerData: Employer | null;
}) => {
  const {id} = useParams();
  const {employerUuid, employerData} = props;
  const [param, setParam] = useState({uuid: id});
  const [tempParam, setTempParam] = useCreditDebitNoteSearchParams();
  const [pageObject, setPageObject] = useUrlQueryPaging();

  const [creditDebitNoteDetails, setCreditDebitNoteDetails] = useState<Partial<CreditDebitNote>>({
    uuid: "",
    invoiceNo: "",
    status: 0,
    invoiceDate: "",
    dueDate: "",
    remark: "",
    total: 0,
    type: 0,
    creditDebitNoteItems: [],
    employer: {
      uuid: "",
      employerNo: "",
      type: 0,
      companyName: "",
      contactNumber: "",
      contactPersonPosition: "",
      contactPerson: "",
      district: "",
      address: "",
      patientName: "",
      patientGender: 0,
      patientHeight: 0,
      patientWeight: 0,
      patientAge: 0,
    },
    helper: {
      uuid: "",
      nameCn: "",
      helperNo: "",
      phone: "",
      nameEn: "",
      gender: 0,
      position: "",
    },
    invoice: {
      uuid: "",
      invoiceNo: "",
      status: 0,
      settleFrom: "",
      settleTo: "",
      invoiceDate: "",
      dueDate: "",
      remark: "",
      total: 0,
      type: 0,
      employerUuid: "",
      payments: [],
      invoiceItems: [],
      discountItems: [],
      employer: {
        uuid: "",
        employerNo: "",
        type: 0,
        companyName: "",
        contactNumber: "",
        contactPersonPosition: "",
        contactPerson: "",
        district: "",
        address: "",
        patientName: "",
        patientGender: 0,
        patientHeight: 0,
        patientWeight: 0,
        patientAge: 0,
      },
    },
    salaryRecord: {
      uuid: "",
      recordeNo: "",
      salary: 0,
      settleFrom: "",
      settleTo: "",
      releaseDate: "",
      payment: {
        uuid: "",
        paymentNo: "",
        status: 0,
        method: 0,
        referenceNo: "",
        remark: "",
        attachments: [],
      },
      helper: {
        nameCn: "",
        nameEn: "",
        gender: 0,
        position: "",
        language: "",
        idCardNo: "",
        idCardName: "",
        dob: "",
        dateOfVaccines: "",
        typesOfVaccines: "",
        phone: "",
        email: "",
        district: "",
        address: "",
        emergencyContactPerson: "",
        emergencyContactPhone: "",
        bankDetails: [],
        remark: "",
        dailyCareSkills: "",
        professionalNursingSkills: "",
        advancedNursingSkills: "",
        personInCharge: 0,
      },
      helperAttendances: [],
    },
    fixedCreditDebitItems: [],
    creditDebitItems: [],
  });

  const [creditDebitNoteDetailsForm] = Form.useForm();
  const [
    showCreditDebitNoteDetailsModal,
    setShowCreditDebitNoteDetailsModal,
  ] = useState(false);

  const {
    data: creditDebitNotes,
    isLoading: creditDebitNotesLoading,
    retry: retry,
  } = useEmployerCreditDebitNotes(useDebounce(tempParam, 200), pageObject, employerUuid);

  const {
    mutate: createCreditDebitNote,
    isLoading: createCreditDebitNoteLoading,
    isSuccess: isCreateCreditDebitNoteSuccess,
  } = useCreateCreditDebitNote();

  const {
    mutate: getCreditDebitNoteDetail,
    data: creditDebitNoteDetailData,
  } = useCreditDebitNoteDetail();

  useEffect(() => {
    if (isCreateCreditDebitNoteSuccess) {
      retry();
      setShowCreditDebitNoteDetailsModal(false);
    }
  }, [isCreateCreditDebitNoteSuccess]);

  useEffect(() => {
    if (creditDebitNoteDetailData) {
      setCreditDebitNoteDetails(creditDebitNoteDetailData);
      creditDebitNoteDetailsForm.setFieldsValue(creditDebitNoteDetailData);
    }
  }, [creditDebitNoteDetailData]);

  useEffect(() => {
    const tempFixedCreditDebitItems: Array<{
      isEditable?: number;
      itemName: string;
      quantity: number;
      cost: number;
      subTotal: number;
    }> = [];

    const tempCreditDebitItems: Array<{
      isEditable?: number;
      itemName: string;
      quantity: number;
      cost: number;
      subTotal: number;
    }> = [];

    if (creditDebitNoteDetailData?.creditDebitItems) {
      for (
        let i = 0;
        i < creditDebitNoteDetailData?.creditDebitItems?.length;
        i++
      ) {
        if (
          creditDebitNoteDetailData?.creditDebitItems?.[i]?.isEditable === 1
        ) {
          tempCreditDebitItems.push(
            creditDebitNoteDetailData?.creditDebitItems?.[i]
          );
        } else {
          tempFixedCreditDebitItems.push(
            creditDebitNoteDetailData?.creditDebitItems?.[i]
          );
        }
      }
    }

    const tempCreditDebitDetails = {
      uuid: "",
      invoiceNo: "",
      status: 0,
      invoiceDate: "",
      dueDate: "",
      remark: "",
      total: 0,
      type: 0,
      creditDebitNoteItems: [],
      employer: {
        uuid: "",
        employerNo: "",
        type: 0,
        companyName: "",
        contactNumber: "",
        contactPersonPosition: "",
        contactPerson: "",
        district: "",
        address: "",
        patientName: "",
        patientGender: 0,
        patientHeight: 0,
        patientWeight: 0,
        patientAge: 0,
      },
      helper: {
        uuid: "",
        nameCn: "",
        helperNo: "",
        phone: "",
        nameEn: "",
        gender: 0,
        position: "",
      },
      invoice: {
        uuid: "",
        invoiceNo: "",
        status: 0,
        settleFrom: "",
        settleTo: "",
        invoiceDate: "",
        dueDate: "",
        remark: "",
        total: 0,
        type: 0,
        employerUuid: "",
        payments: [],
        invoiceItems: [],
        discountItems: [],
        employer: {
          uuid: "",
          employerNo: "",
          type: 0,
          companyName: "",
          contactNumber: "",
          contactPersonPosition: "",
          contactPerson: "",
          district: "",
          address: "",
          patientName: "",
          patientGender: 0,
          patientHeight: 0,
          patientWeight: 0,
          patientAge: 0,
        },
      },
      salaryRecord: {
        uuid: "",
        recordeNo: "",
        salary: 0,
        settleFrom: "",
        settleTo: "",
        releaseDate: "",
        payment: {
          uuid: "",
          paymentNo: "",
          status: 0,
          method: 0,
          referenceNo: "",
          remark: "",
          attachments: [],
        },
        helper: {
          nameCn: "",
          nameEn: "",
          gender: 0,
          position: "",
          language: "",
          idCardNo: "",
          idCardName: "",
          dob: "",
          dateOfVaccines: "",
          typesOfVaccines: "",
          phone: "",
          email: "",
          district: "",
          address: "",
          emergencyContactPerson: "",
          emergencyContactPhone: "",
          bankDetails: [],
          remark: "",
          dailyCareSkills: "",
          professionalNursingSkills: "",
          advancedNursingSkills: "",
          personInCharge: 0,
        },
        helperAttendances: [],
      },
      fixedCreditDebitItems: tempFixedCreditDebitItems,
      creditDebitItems: tempCreditDebitItems,
    };

    setCreditDebitNoteDetails(tempCreditDebitDetails);
    creditDebitNoteDetailsForm.setFieldsValue(tempCreditDebitDetails);
  }, [creditDebitNoteDetailData]);

  const onCreateCreditDebitNoteModalOpen = () => {
    const noteData = {
      uuid: "",
      invoiceNo: "",
      status: 0,
      invoiceDate: "",
      dueDate: "",
      remark: "",
      total: 0,
      type: 0,
      creditDebitNoteItems: [],
      employer: {
        uuid: "",
        employerNo: "",
        type: 0,
        companyName: "",
        contactNumber: "",
        contactPersonPosition: "",
        contactPerson: "",
        district: "",
        address: "",
        patientName: "",
        patientGender: 0,
        patientHeight: 0,
        patientWeight: 0,
        patientAge: 0,
      },
      helper: {
        uuid: "",
        nameCn: "",
        helperNo: "",
        phone: "",
        nameEn: "",
        gender: 0,
        position: "",
      },
      invoice: {
        uuid: "",
        invoiceNo: "",
        status: 0,
        settleFrom: "",
        settleTo: "",
        invoiceDate: "",
        dueDate: "",
        remark: "",
        total: 0,
        type: 0,
        employerUuid: "",
        payments: [],
        invoiceItems: [],
        discountItems: [],
        employer: {
          uuid: "",
          employerNo: "",
          type: 0,
          companyName: "",
          contactNumber: "",
          contactPersonPosition: "",
          contactPerson: "",
          district: "",
          address: "",
          patientName: "",
          patientGender: 0,
          patientHeight: 0,
          patientWeight: 0,
          patientAge: 0,
        },
      },
      salaryRecord: {
        uuid: "",
        recordeNo: "",
        salary: 0,
        settleFrom: "",
        settleTo: "",
        releaseDate: "",
        payment: {
          uuid: "",
          paymentNo: "",
          status: 0,
          method: 0,
          referenceNo: "",
          remark: "",
          attachments: [],
        },
        helper: {
          nameCn: "",
          nameEn: "",
          gender: 0,
          position: "",
          language: "",
          idCardNo: "",
          idCardName: "",
          dob: "",
          dateOfVaccines: "",
          typesOfVaccines: "",
          phone: "",
          email: "",
          district: "",
          address: "",
          emergencyContactPerson: "",
          emergencyContactPhone: "",
          bankDetails: [],
          remark: "",
          dailyCareSkills: "",
          professionalNursingSkills: "",
          advancedNursingSkills: "",
          personInCharge: 0,
        },
        helperAttendances: [],
      },
      fixedCreditDebitItems: [],
      creditDebitItems: [],
    };

    setCreditDebitNoteDetails(noteData);
    creditDebitNoteDetailsForm.setFieldsValue(noteData);
    setShowCreditDebitNoteDetailsModal(true);
  };

  // const onEditInvoiceClick = async (
  //   invoice: CreditDebitNote
  // ) => {
  //   await getcreditDebitNoteDetails(employerUuid, invoice.uuid);
  //   setShowInvoiceDetailsModal(true);
  // };

  const onCreditDebitNoteDetailsModalCancel = () => {
    setShowCreditDebitNoteDetailsModal(false);
  };

  // const handleDeleteInvoice = async (
  //   invoice: CreditDebitNote
  // ) => {
  //   await editEmployerInvoice({status: 3}, employerUuid, invoice.uuid);
  // };

  const onInvoiceDetailsModalSubmit = async (values: any) => {
    // let tempCreditDebitItems = invoiceDetails?.fixedInvoiceItems
    //   ? invoiceDetails?.fixedInvoiceItems?.concat(values.creditDebitItems)
    //   : values.creditDebitItems;
    // for (let invoiceItem of tempCreditDebitItems) {
    //   invoiceItem.isEditable =
    //     invoiceItem.isEditable === undefined || invoiceItem.isEditable === 1
    //       ? true
    //       : false;
    //   delete invoiceItem.id;
    //   delete invoiceItem.uuid;
    // }
    // const submitData = {
    //   type: 1,
    //   invoiceDate: moment(values.invoiceDate).format("YYYY-MM-DD"),
    //   dueDate: moment(values.dueDate).format("YYYY-MM-DD"),
    //   remark: values.remark,
    //   invoiceItems: tempCreditDebitItems,
    //   total: invoiceDetails.total,
    // };
    // if (invoiceDetails.uuid) {
    //   await editEmployerInvoice(submitData, employerUuid, invoiceDetails.uuid);
    // } else {
    //   await createEmployerInvoice(submitData, employerUuid);
    // }
  };

  return (
    <div>
      {/* <LibRow marginBottom={2} between={true}>
        <div></div>
        <div>
          <Button type="primary" onClick={() => onCreateCreditDebitNoteModalOpen()}>
            ＋Note
          </Button>
        </div>
      </LibRow> */}

      <Table<CreditDebitNote>
        columns={columns}
        dataSource={creditDebitNotes?.list || []}
        size="small"
        loading={creditDebitNotesLoading}
        scroll={{
          y: window?.innerHeight ? window?.innerHeight - 320 : "max-content",
        }}
      />

      <Modal
        title={creditDebitNoteDetails.uuid ? "編輯Note" : "創建Note"}
        visible={showCreditDebitNoteDetailsModal}
        footer={null}
        onCancel={onCreditDebitNoteDetailsModalCancel}
        width={1000}
      >
        <Form
          form={creditDebitNoteDetailsForm}
          layout="vertical"
          initialValues={creditDebitNoteDetails}
          onFinish={onInvoiceDetailsModalSubmit}
          onValuesChange={(changedValues, allValues) => {
            if (Object.keys(changedValues)[0] === "creditDebitItems") {
              let total = 0;
              let subTotal = 0;

              if (
                creditDebitNoteDetails.fixedCreditDebitItems &&
                creditDebitNoteDetails.fixedCreditDebitItems.length > 0
              ) {
                for (
                  let i = 0;
                  i < creditDebitNoteDetails.fixedCreditDebitItems.length;
                  i++
                ) {
                  subTotal =
                    creditDebitNoteDetails.fixedCreditDebitItems[i]?.cost &&
                    creditDebitNoteDetails.fixedCreditDebitItems[i]?.quantity
                      ? creditDebitNoteDetails.fixedCreditDebitItems[i]?.cost *
                      creditDebitNoteDetails.fixedCreditDebitItems[i]
                        ?.quantity
                      : 0;
                  total = total + subTotal;
                }
              }

              if (
                allValues.creditDebitItems &&
                allValues.creditDebitItems.length > 0
              ) {
                const newcreditDebitItems = [];

                for (let i = 0; i < allValues.creditDebitItems.length; i++) {
                  subTotal =
                    allValues.creditDebitItems[i]?.cost &&
                    allValues.creditDebitItems[i]?.quantity
                      ? allValues.creditDebitItems[i]?.cost *
                      allValues.creditDebitItems[i]?.quantity
                      : 0;
                  total = total + subTotal;
                  newcreditDebitItems.push({
                    itemName: allValues.creditDebitItems[i]?.itemName,
                    quantity: allValues.creditDebitItems[i]?.quantity,
                    cost: allValues.creditDebitItems[i]?.cost,
                    subTotal: subTotal,
                  });
                }

                creditDebitNoteDetailsForm.setFieldsValue({
                  creditDebitItems: newcreditDebitItems,
                });
                setCreditDebitNoteDetails((prevState) => ({
                  ...prevState,
                  total: total,
                }));
              } else {
                setCreditDebitNoteDetails((prevState) => ({
                  ...prevState,
                  total: 0,
                }));
              }
            }
          }}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid"/>
          </Form.Item>

          {creditDebitNoteDetails.uuid && (
            <Row style={{marginBottom: 10}}>
              <Col span={6}>
                <EditInvoiceNo>
                  {creditDebitNoteDetails.invoiceNo}
                </EditInvoiceNo>
              </Col>
              <Col span={8}></Col>
              <Col span={5}></Col>
              <Col span={5}>
                付款狀態: {invoiceRecordStatus(creditDebitNoteDetails.status)}
              </Col>
            </Row>
          )}

          <Row>
            <Col span={13}>
              <EditInvoiceEmployerContainer>
                <EditInvoiceEmployerName>
                  {/* {creditDebitNoteDetails?.employerName} */}
                </EditInvoiceEmployerName>
                <EditInvoiceEmployerAddress>
                  {/* {creditDebitNoteDetails.employerAddress} */}
                </EditInvoiceEmployerAddress>
              </EditInvoiceEmployerContainer>
            </Col>
            <Col span={1}/>
            <Col span={5}>
              <Form.Item
                name="invoiceDate"
                label="發票日期"
                rules={[{required: true, message: "請輸入發票日期"}]}
              >
                <DatePicker
                  placeholder="發票日期"
                  disabled={creditDebitNoteDetails.status !== 0}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="dueDate"
                label="付款過期日"
                rules={[{required: true, message: "請輸入付款過期日"}]}
              >
                <DatePicker
                  placeholder="付款過期日"
                  disabled={
                    creditDebitNoteDetails.status === 1 ||
                    creditDebitNoteDetails.status === 3
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Divider/>
            </Col>
          </Row>

          <InvoiceDetailSectionTitle>項目</InvoiceDetailSectionTitle>

          <Row
            gutter={[16, 4]}
            style={{backgroundColor: "#E7ECFF", marginBottom: 10}}
          >
            <Col span={9}>項目</Col>
            <Col span={4}>數量</Col>
            <Col span={5}>價錢</Col>
            <Col span={5}>總額</Col>
            <Col span={1}></Col>
          </Row>

          {(creditDebitNoteDetails.fixedCreditDebitItems || []).map(
            (item, index) => (
              <Row key={index} gutter={[16, 4]}>
                <Col span={9}>{item.itemName}</Col>
                <Col span={4}>{item.quantity}</Col>
                <Col span={5}>{item.cost}</Col>
                <Col span={5}>{item.subTotal}</Col>
                <Col span={1}></Col>
              </Row>
            )
          )}

          <Form.List name="creditDebitItems">
            {(fields, {add, remove}) => (
              <>
                {fields.map(({key, name, ...restField}) => (
                  <Row key={key} gutter={[16, 4]}>
                    <Col span={9}>
                      <Form.Item
                        {...restField}
                        name={[name, "itemName"]}
                        rules={[{required: true, message: "請輸入項目"}]}
                      >
                        <Input
                          placeholder="項目"
                          disabled={creditDebitNoteDetails.status !== 0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, "quantity"]}
                        rules={[{required: true, message: "請輸入數量"}]}
                      >
                        <InputNumber
                          placeholder="數量"
                          style={{width: 120}}
                          disabled={creditDebitNoteDetails.status !== 0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        {...restField}
                        name={[name, "cost"]}
                        rules={[{required: true, message: "請輸入價錢"}]}
                      >
                        <InputNumber
                          placeholder="價錢"
                          style={{width: 120}}
                          disabled={creditDebitNoteDetails.status !== 0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item {...restField} name={[name, "subTotal"]}>
                        <Input
                          placeholder="總額"
                          style={{width: 140}}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <MinusCircleOutlined
                        onClick={() =>
                          creditDebitNoteDetails.status !== 0
                            ? ""
                            : remove(name)
                        }
                      />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined/>}
                    disabled={creditDebitNoteDetails.status !== 0}
                  >
                    增加項目
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Row>
            <Col span={24}>
              <Divider/>
            </Col>
          </Row>

          <Row gutter={[16, 4]}>
            <Col span={17}></Col>
            <Col span={2}>
              <EditInvoiceTotalPriceTitle>總額</EditInvoiceTotalPriceTitle>
            </Col>
            <Col span={5}>
              <EditInvoiceTotalPrice>
                {creditDebitNoteDetails.total}
              </EditInvoiceTotalPrice>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item name="remark" label="備註">
                <Input
                  placeholder="備註"
                  disabled={creditDebitNoteDetails.status === 1}
                />
              </Form.Item>
            </Col>
          </Row>

          {creditDebitNoteDetails.status !== 1 && (
            <>
              <Button
                htmlType="button"
                onClick={onCreditDebitNoteDetailsModalCancel}
                style={{marginRight: 20}}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={createCreditDebitNoteLoading}
              >
                Submit
              </Button>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
};

const EditInvoiceNo = styled.span`
  color: #8d939e;
  font-size: 20px;
  font-weight: bold;
  padding-right: 20px;
`;

const EditInvoiceEmployerContainer = styled.div`
  padding: 16px;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
`;

const EditInvoiceEmployerName = styled.div`
  color: #3c3e42;
  font-size: 14px;
  font-weight: bold;
`;

const EditInvoiceEmployerAddress = styled.div`
  color: #8d939e;
  font-size: 12px;
`;

const Divider = styled.hr`
  border-top: 1px solid #e0e5ef;
`;

const EditInvoiceTotalPriceTitle = styled.div`
  color: #8d939e;
  font-size: 24px;
  font-weight: 500;
`;

const EditInvoiceTotalPrice = styled.div`
  color: #3c3e42;
  font-size: 24px;
  font-weight: bold;
`;

const CreatePaymentInvoiceNo = styled.span`
  color: #8d939e;
  font-size: 20px;
  font-weight: bold;
`;

const InvoiceDetailSectionTitle = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 4px;
`;
