import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Table,
  Form,
  Modal,
  Input,
  Button,
  InputNumber,
  Select,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { JobGeneralData } from "types/job-general-data";
import moment from "moment";
import {
  changeArrayToWeekDay,
  workingPeriodStatus,
  gender,
} from "utils/common";
import { District } from "components/district";
import { AdminSelect } from "components/admin-select";
import { useEditJob, useEditJobPrice } from "utils/job";

export interface JobData extends Partial<JobGeneralData> {
  refresh: () => void;
}

export const GeneralData = (props: JobData) => {
  const {
    uuid,
    title,
    description,
    employer,
    personInCharge,
    address,
    district,
    taskHelperRequirements,
    workingPeriod,
    startDate,
    endDate,
    week,
    workingHours,
    startTime,
    refresh,
  } = props;

  const helperRequestColumns = [
    {
      title: "幫手職級",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "幫手數目",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "工作時數",
      key: "workingHours",
      render: (_: any) => `${workingHours} 小時`,
    },
    {
      title: "僱主價錢",
      dataIndex: "employerPrice",
      key: "employerPrice",
      render: (text: any, record: any) => (text ? `$${text}` : "--"),
    },
    {
      title: "幫手價錢",
      dataIndex: "helperSalary",
      key: "helperSalary",
      render: (text: any, record: any) => (text ? `$${text}` : "--"),
    },
  ];

  const { mutate: editJob, isSuccess: isEditJobSuccess } = useEditJob();
  const {
    mutate: editJobPrice,
    isLoading: editJobPriceLoading,
  } = useEditJobPrice();

  const [showEditJobModal, setShowEditJobModal] = useState(false);
  const [showRecordPriceModal, setShowRecordPriceModal] = useState(false);
  const [
    showRecordWorkingHoursModal,
    setShowRecordWorkingHoursModal,
  ] = useState(false);

  const [jobForm] = Form.useForm();
  const [recordPriceForm] = Form.useForm();
  const [recordWorkingHoursForm] = Form.useForm();

  useEffect(() => {
    if (isEditJobSuccess) {
      refresh();
      setShowEditJobModal(false);
    }
  }, [isEditJobSuccess]);

  const handleEditJobModalOpen = () => {
    jobForm.setFieldsValue({
      uuid: uuid,
      title: title,
      personInCharge: personInCharge?.id,
      description: description,
      district: district?.split(","),
      address: address,
    });

    setShowEditJobModal(true);
  };

  const handleEditJobModalCancel = () => {
    setShowEditJobModal(false);
  };

  const handleEditJobModalSubmit = async (values: any) => {
    const submitData = {
      ...values,
      district: values?.district?.toString() || "",
    };

    editJob(submitData);
  };

  const onRecordPriceModalSubmit = async (values: any) => {
    await editJobPrice({
      helperSalary: values.helperSalary,
      employerPrice: values.employerPrice,
      uuid: uuid || "",
    });

    setShowRecordPriceModal(false);
  };

  const onRecordWorkingHoursModalSubmit = async (values: any) => {
    // await editWorkingHours({
    //   helperSalary: values.helperSalary,
    //   employerPrice: values.employerPrice,
    //   uuid: uuid || "",
    // });

    setShowRecordWorkingHoursModal(false);
  };

  return (
    <>
      <Container>
        <MainContainer>
          <JobTitle>{title}</JobTitle>
          <Remarks>{description}</Remarks>
          <EditButton>
            <EditOutlined
              style={{
                color: "#3C3E42",
                fontSize: 22,
                marginRight: 30,
                paddingTop: 10,
              }}
              onClick={() => handleEditJobModalOpen()}
            />
            <Button
              style={{ marginRight: 15 }}
              onClick={() => setShowRecordPriceModal(true)}
            >
              更改價錢
            </Button>

            {/*<Button onClick={() => setShowRecordWorkingHoursModal(true)}>*/}
            {/*  更改時間*/}
            {/*</Button>*/}
          </EditButton>
          <SectionContainer>
            <EmployerName>
              {employer?.companyName
                ? employer.companyName
                : employer?.contactPerson}
            </EmployerName>
            <EmployerAddress>
              {district} {address}
            </EmployerAddress>
          </SectionContainer>
          {employer?.type === 0 && (
            <SectionContainer>
              <SectionTitle>服務對象</SectionTitle>
              <TargetTable>
                <Row>
                  <Col span={6}>
                    <TargetTableTitle>性別</TargetTableTitle>
                  </Col>
                  <Col span={6}>
                    <TargetTableTitle>年齡</TargetTableTitle>
                  </Col>
                  <Col span={6}>
                    <TargetTableTitle>身高 (cm)</TargetTableTitle>
                  </Col>
                  <Col span={6}>
                    <TargetTableTitle>體重 (kg)</TargetTableTitle>
                  </Col>
                </Row>
                <Row>
                  <Col span={6}>
                    <TargetTableContent>
                      {gender(employer?.patientGender)}
                    </TargetTableContent>
                  </Col>
                  <Col span={6}>
                    <TargetTableContent>
                      {employer?.patientAge}
                    </TargetTableContent>
                  </Col>
                  <Col span={6}>
                    <TargetTableContent>
                      {employer?.patientHeight}
                    </TargetTableContent>
                  </Col>
                  <Col span={6}>
                    <TargetTableContent>
                      {employer?.patientWeight}
                    </TargetTableContent>
                  </Col>
                </Row>
              </TargetTable>
            </SectionContainer>
          )}
          <SectionContainer>
            <SectionTitle>幫手要求</SectionTitle>
            <HelperRequestTable>
              <Table
                columns={helperRequestColumns}
                dataSource={taskHelperRequirements}
                pagination={false}
              />
            </HelperRequestTable>
          </SectionContainer>
          <SectionContainer>
            <SectionTitle>工作時間</SectionTitle>
            <SectionRow>
              <Row>
                <Col span={6}>
                  <SectionRowTitle>週期</SectionRowTitle>
                </Col>
                <Col span={18}>
                  <SectionRowContent>
                    {workingPeriodStatus(workingPeriod)}
                  </SectionRowContent>
                </Col>
              </Row>
            </SectionRow>
            <SectionRow>
              <Row>
                <Col span={6}>
                  <SectionRowTitle>開始日期</SectionRowTitle>
                </Col>
                <Col span={18}>
                  <SectionRowContent>{startDate}</SectionRowContent>
                </Col>
              </Row>
            </SectionRow>
            {week && workingPeriod !== 1 ? (
              <SectionRow>
                <Row>
                  <Col span={6}>
                    <SectionRowTitle>星期</SectionRowTitle>
                  </Col>
                  <Col span={18}>
                    <SectionRowContent>
                      {changeArrayToWeekDay(week || [])}
                    </SectionRowContent>
                  </Col>
                </Row>
              </SectionRow>
            ) : (
              <SectionRow>
                <Row>
                  <Col span={6}>
                    <SectionRowTitle>星期</SectionRowTitle>
                  </Col>
                  <Col span={18}>
                    <SectionRowContent>
                      {changeArrayToWeekDay([moment(startDate).format("E")])}
                    </SectionRowContent>
                  </Col>
                </Row>
              </SectionRow>
            )}
            {endDate && (
              <SectionRow>
                <Row>
                  <Col span={6}>
                    <SectionRowTitle>工作期</SectionRowTitle>
                  </Col>
                  <Col span={18}>
                    <SectionRowContent>
                      {" "}
                      {startDate} ~ {endDate}
                    </SectionRowContent>
                  </Col>
                </Row>
              </SectionRow>
            )}
            <SectionRow>
              <Row>
                <Col span={6}>
                  <SectionRowTitle>工作時間</SectionRowTitle>
                </Col>
                <Col span={18}>
                  <SectionRowContent>
                    {moment(startTime, "HH:mm:ss").format("HH:mm")} -{" "}
                    {moment(startTime, "HH:mm:ss")
                      .add(workingHours, "h")
                      .format("HH:mm")}
                  </SectionRowContent>
                </Col>
              </Row>
            </SectionRow>
          </SectionContainer>
        </MainContainer>
      </Container>

      <Modal
        title={"編輯工作"}
        visible={showEditJobModal}
        footer={null}
        onCancel={() => handleEditJobModalCancel()}
      >
        <Form
          form={jobForm}
          layout="vertical"
          onFinish={handleEditJobModalSubmit}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid" />
          </Form.Item>

          <Form.Item
            name="title"
            label="標題"
            rules={[{ required: true, message: "請輸入標題" }]}
          >
            <Input placeholder="標題" />
          </Form.Item>

          <Form.Item name="personInCharge" label="負責同事">
            <AdminSelect placeholder="負責同事" />
          </Form.Item>

          <Form.Item
            name="description"
            label="工作備註"
            rules={[{ required: true, message: "請輸入工作備註" }]}
          >
            <Input placeholder="工作備註" />
          </Form.Item>

          <Form.Item
            name="district"
            label="地區"
            rules={[{ required: true, message: "請輸入地區" }]}
          >
            <District />
          </Form.Item>

          <Form.Item
            name="address"
            label="地址"
            rules={[{ required: true, message: "請輸入地址" }]}
          >
            <Input placeholder="地址" />
          </Form.Item>

          <Button
            htmlType="button"
            onClick={() => handleEditJobModalCancel()}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>
      <Modal
        title={"更改價錢"}
        visible={showRecordPriceModal}
        footer={null}
        onCancel={() => setShowRecordPriceModal(false)}
      >
        <Form
          form={recordPriceForm}
          layout="vertical"
          onFinish={onRecordPriceModalSubmit}
        >
          <Form.Item
            name="employerPrice"
            label="僱主價錢"
            rules={[{ required: true, message: "請輸入僱主價錢" }]}
          >
            <InputNumber placeholder="僱主價錢" style={{ width: 200 }} />
          </Form.Item>

          <Form.Item
            name="helperSalary"
            label="幫手價錢"
            rules={[{ required: true, message: "請輸入幫手價錢" }]}
          >
            <InputNumber placeholder="幫手價錢" style={{ width: 200 }} />
          </Form.Item>

          <Button
            htmlType="button"
            onClick={() => setShowRecordPriceModal(false)}
            style={{ marginRight: 20, marginTop: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>
      <Modal
        title={"更改時間"}
        visible={showRecordWorkingHoursModal}
        footer={null}
        onCancel={() => setShowRecordWorkingHoursModal(false)}
      >
        <Form
          form={recordWorkingHoursForm}
          layout="vertical"
          onFinish={onRecordWorkingHoursModalSubmit}
        >
          <Form.Item
            name="workingHours"
            label="工作時數"
            rules={[{ required: true, message: "請輸入工作時數" }]}
          >
            <InputNumber type="number" />
            {/*<Select allowClear style={{ width: "100%" }}>*/}
            {/*  <Select.Option value={1}>1</Select.Option>*/}
            {/*  <Select.Option value={2}>2</Select.Option>*/}
            {/*  <Select.Option value={3}>3</Select.Option>*/}
            {/*  <Select.Option value={4}>4</Select.Option>*/}
            {/*  <Select.Option value={5}>5</Select.Option>*/}
            {/*  <Select.Option value={6}>6</Select.Option>*/}
            {/*  <Select.Option value={7}>7</Select.Option>*/}
            {/*  <Select.Option value={8}>8</Select.Option>*/}
            {/*  <Select.Option value={9}>9</Select.Option>*/}
            {/*  <Select.Option value={10}>10</Select.Option>*/}
            {/*  <Select.Option value={11}>11</Select.Option>*/}
            {/*  <Select.Option value={12}>12</Select.Option>*/}
            {/*</Select>*/}
          </Form.Item>

          <Button
            htmlType="button"
            onClick={() => setShowRecordWorkingHoursModal(false)}
            style={{ marginRight: 20, marginTop: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 2rem;
  height: calc(100vh - 190px - 6px);
  border-left: 2px solid #e0e5ef;
  background-color: #ffffff;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
`;

const JobTitle = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
`;

const Remarks = styled.div`
  color: #8d939e;
  font-size: 12px;
  padding-top: 4px;
`;

const EditButton = styled.div`
  position: absolute;
  top: 4px;
  right: 10px;
`;

const SectionContainer = styled.div`
  padding: 16px;
  border: 2px solid #e0e5ef;
  border-radius: 10px;
  width: 100%;
  margin-top: 16px;
`;

const SectionTitle = styled.div`
  color: #3c3e42;
  font-weight: bold;
`;

const SectionRow = styled.div`
  padding-top: 14px;
`;

const SectionRowTitle = styled.div`
  color: #8d939e;
  font-size: 12px;
  font-weight: 500;
`;

const SectionRowContent = styled.div`
  color: #3c3e42;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
`;

const EmployerName = styled.div`
  color: #3c3e42;
  font-size: 14px;
  font-weight: bold;
`;

const EmployerAddress = styled.div`
  color: #8d939e;
  font-size: 14px;
  padding-top: 2px;
`;

const HelperRequestTable = styled.div`
  padding-top: 12px;
`;

const TargetTable = styled.div`
  padding-top: 12px;
`;

const TargetTableTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #8d939e;
`;

const TargetTableContent = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #3c3e42;
`;
