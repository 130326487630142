import React from "react";
import {Button, Select} from "antd";
import styled from "@emotion/styled";
import {Page} from "../../../../utils/paging";
import Search from "antd/es/input/Search";
import {Row as LibRow} from "../../../../components/lib";
// import { Project } from "types/project";

const {Option} = Select;

export interface JobSearch extends Page {
    district: string
    status: string
    keyword: string
}

interface SearchPanelProps {
    onShowCreateModal: Function;
}

export const SearchPanel = ({onShowCreateModal = Function}:Partial<SearchPanelProps>) => {

    const handleOnSearch = () => {

    }
    return (
        <TopSearchBar>
            <LibRow marginBottom={2} between={true}>
                <Search placeholder="證書名稱" allowClear onSearch={handleOnSearch} style={{width: 300}}/>
                <Button type="primary" onClick={() => onShowCreateModal('create')}>
                    ＋創建證書
                </Button>
            </LibRow>
        </TopSearchBar>
    );
};

const TopSearchBar = styled.div`
  width: 100%;
  height: 64px;
  padding: 16px 20px;
  background-color: #ffffff;
`;
