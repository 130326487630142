import { useAsync } from "utils/use-async";
import { useCallback, useEffect } from "react";
import { cleanObject } from "utils/index";
import { useHttp } from "utils/http";
import { Job } from "../screens/job/all-jobs/list";
import { JobGeneralData } from "../types/job-general-data";
import { JobSearch } from "../screens/job/all-jobs/search-panel";
import { Page } from "./paging";

export const useJobs = (param?: Partial<JobSearch>, pageObject?: Page) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{ list: Job[]; total: number }>();

  const fetchJobs = useCallback(
    () =>
      client("tasks", { data: cleanObject({ ...param, ...pageObject } || {}) }),
    [param, client, pageObject]
  );

  useEffect(() => {
    run(fetchJobs(), {
      retry: fetchJobs,
    });
  }, [param, run, fetchJobs]);

  return result;
};

export const useLateCheckoutJobs = (param?: Partial<JobSearch>, pageObject?: Page) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{ list: Job[]; total: number }>();

  const fetchJobs = useCallback(
      () =>
          client("tasks/late_checkout", { data: cleanObject({ ...param, ...pageObject } || {}) }),
      [param, client, pageObject]
  );

  useEffect(() => {
    run(fetchJobs(), {
      retry: fetchJobs,
    });
  }, [param, run, fetchJobs]);

  return result;
};

export const useJobDetail = (param?: Partial<JobGeneralData>) => {
  const client = useHttp();
  const { run, ...result } = useAsync<JobGeneralData>();

  const fetchJobs = useCallback(() => client(`tasks/${param?.uuid}`, {}), [
    param,
    client,
  ]);

  useEffect(() => {
    run(fetchJobs(), {
      retry: fetchJobs,
    });
  }, [param, run, fetchJobs]);

  return result;
};

export const useEditJob = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: Partial<Job>) => {
    return run(
      client(`tasks/${params.uuid}`, {
        data: params,
        method: "PATCH",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useEditJobPrice = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: { employerPrice: number, helperSalary: number , uuid: string}) => {
    return run(
        client(`tasks/${params.uuid}/price`, {
          data: params,
          method: "PATCH",
        })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useEditJobWorkingHours = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: { workingHours: number , uuid: string}) => {
    return run(
      client(`tasks/${params.uuid}/working_hours`, {
        data: params,
        method: "PATCH",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useAddJob = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: Partial<Job>) => {
    return run(
      client(`tasks`, {
        data: cleanObject(params || {}),
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};
