import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDebounce } from "utils";
import { paymentMethod } from "utils/common";
import { dateDisplay } from "utils/moment-util";
import { Input, Button } from "antd";
import styled from "@emotion/styled";
import {
  useHelperPaymentData,
  useConfirmHelperPayment,
} from "utils/payment-public";

export const PublicConfirmPaymentScreen = () => {
  const { helperUuid, salaryRecordUuid } = useParams();
  const [param, setParam] = useState({ helperUuid, salaryRecordUuid });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    data: paymentData,
    retry,
    isError: getPaymentDataError,
  } = useHelperPaymentData(useDebounce(param, 200));

  const {
    mutate,
    isLoading,
    isError,
    isSuccess,
    data: confirmPaymentResult,
  } = useConfirmHelperPayment();

  useEffect(() => {
    if (isSuccess && confirmPaymentResult) {
      alert("成功確認領取薪金");

      setIsSubmitted(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      alert("確認領取薪金失敗");
    }
  }, [isError]);

  const handleSubmit = async () => {
    await mutate(helperUuid, salaryRecordUuid);
  };

  return (
    <Container>
      <HeaderContainer>
        <h1>環宇護理 UniCare360</h1>
        {getPaymentDataError ? (
          <h2>發生錯誤或者薪金已收取</h2>
        ) : (
          <h2>確認收取薪金</h2>
        )}
      </HeaderContainer>

      {!getPaymentDataError && (
        <>
          <RowContainer>
            <b>結算時段:</b> {dateDisplay(paymentData?.settleFrom)} -{" "}
            {dateDisplay(paymentData?.settleTo)}
          </RowContainer>

          <RowContainer>
            <b>薪金:</b> ${paymentData?.salary}
          </RowContainer>

          <RowContainer>
            <b>出糧方式:</b> {paymentMethod(paymentData?.method || 0)}
          </RowContainer>

          <FormContainer>
            {isSubmitted || paymentData?.status === 1 ? (
              <h3 style={{ paddingTop: 30 }}>已領取薪金</h3>
            ) : (
              <Button
                type="primary"
                onClick={() => handleSubmit()}
                style={{ marginTop: 20 }}
                loading={isLoading}
              >
                確認領取薪金
              </Button>
            )}
          </FormContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 5rem 10px;
  width: 350px;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  text-align: center;
  padding-bottom: 20px;
`;

const RowContainer = styled.div`
  padding-bottom: 10px;
`;

const FormContainer = styled.div`
  text-align: center;
`;

const FormTitle = styled.div`
  padding-top: 10px;
  padding-bottom: 5px;
  font-weight: bold;
`;
