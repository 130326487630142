import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useDebounce } from "utils";
import {
  workingPeriodStatus,
  changeArrayToWeekDay,
  gender,
} from "utils/common";
import { Input, Button } from "antd";
import styled from "@emotion/styled";
import { useJobDetail, useApplyJob } from "utils/job-public";

export const PublicApplyJobScreen = () => {
  const { uuid } = useParams();
  const [param, setParam] = useState({ uuid: uuid });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [submitData, setSubmitData] = useState({
    uuid: uuid,
    phone: "",
  });

  const { data: jobData, retry } = useJobDetail(useDebounce(param, 200));

  const {
    mutate,
    isLoading,
    isError,
    isSuccess,
    data: applyResult,
  } = useApplyJob();

  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setSubmitData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!submitData.phone) {
      return alert("請輸入電話號碼");
    }

    await mutate(submitData);
  };

  useEffect(() => {
    if (isSuccess && applyResult) {
      alert("成功提交申請");

      setIsSubmitted(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      alert("你不能申請此工作, 請聯絡 98675230");
    }
  }, [isError]);

  return (
    <Container>
      {true ? (
        <>
          <HeaderContainer>
            <h1>環宇護理 UniCare360</h1>
            <h2>工作邀請</h2>
          </HeaderContainer>

          <RowContainer>
            <b>工作編號:</b> {jobData?.taskNo}
          </RowContainer>

          <RowContainer>
            <b>工作週期:</b> {workingPeriodStatus(jobData?.workingPeriod)}
          </RowContainer>

          <RowContainer>
            <b>開始日期:</b> {jobData?.startDate}
          </RowContainer>

          <RowContainer>
            <b>開始時間:</b> {jobData?.startTime}
          </RowContainer>

          <RowContainer>
            <b>工作時數:</b> {jobData?.workingHours}小時
          </RowContainer>

          <RowContainer>
            <b>星期:</b> {changeArrayToWeekDay(jobData?.week)}
          </RowContainer>

          <RowContainer>
            <b>工作地址:</b> {jobData?.address}
          </RowContainer>

          <RowContainer>
            <b>服務對象:</b>{" "}
            {jobData?.employer?.type === 1
              ? jobData?.employer?.companyName
              : `${gender(jobData?.employer?.patientGender)}, 年齡: ${
                  jobData?.employer?.patientAge
                }, 身高: ${jobData?.employer?.patientHeight}cm, 體重: ${
                  jobData?.employer?.patientWeight
                }kg`}
          </RowContainer>

          <RowContainer>
            <b>工作備註:</b> {jobData?.description}
          </RowContainer>

          <RowContainer>
            如有查詢請致電/
            <a
              href={`https://wa.me/+85298675630/?text=我想查詢工作詳情，工作編號:${jobData?.taskNo}`}
              target="_blank"
              rel="noreferrer"
            >
              whatsApps
            </a>
            ：98675630，如果沒空接以上工作，請麻煩轉發給有興趣的朋友/同事，感激不盡🙇‍♀
          </RowContainer>

          {isSubmitted ? (
            <FormContainer>
              <h3 style={{ paddingTop: 30 }}>已申請工作</h3>
            </FormContainer>
          ) : (
            <FormContainer>
              <FormTitle>輸入電話號碼申請工作</FormTitle>
              <Input
                placeholder="輸入電話號碼"
                name="phone"
                value={submitData.phone}
                onChange={handleChange}
              />
              <Button
                type="primary"
                onClick={() => handleSubmit()}
                style={{ marginTop: 20 }}
              >
                申請工作
              </Button>
            </FormContainer>
          )}
        </>
      ) : (
        <h2>此工作不開放申請</h2>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 5rem 10px;
  width: 350px;
  margin: 0 auto;
`;

const HeaderContainer = styled.div`
  text-align: center;
  padding-bottom: 20px;
`;

const RowContainer = styled.div`
  padding-bottom: 10px;
`;

const FormContainer = styled.div`
  text-align: center;
`;

const FormTitle = styled.div`
  padding-top: 10px;
  padding-bottom: 5px;
  font-weight: bold;
`;
