import React from "react";
import moment from "moment";
import {Table} from "antd";
import { HelperAttendance } from "types/helper-attendance";

export const InvoiceExpandedTable = (record: {
  helperAttendances: HelperAttendance[];
}) => {
  const nestColumns = [
    {
      title: "工作編號",
      dataIndex: ["taskRecord", "task", "taskNo"],
      key: "taskNo",
    },
    {
      title: "標題",
      dataIndex: ["taskRecord", "task", "title"],
      key: "title",
    },
    {
      title: "幫手",
      dataIndex: ["taskAttendance", "helper"],
      key: "helper",
      render: (text: string, record: HelperAttendance) => (`${record?.helper?.nameCn || record?.helper?.nameEn} (${record?.helper?.phone})`)
    },
    {
      title: "工作日子",
      dataIndex: ["taskRecord", "startDate"],
      key: "startDate",
    },
    {
      title: "工作時間",
      dataIndex: "",
      key: "startTime",
      render: (text: string, workRecord: HelperAttendance) => {
        return (
          <>
            {moment(workRecord?.taskRecord?.startTime, "HH:mm:ss").format(
              "HH:mm"
            )}{" "}
            -{" "}
            {moment(workRecord.taskRecord.startTime, "HH:mm:ss")
              .add(workRecord.taskRecord.workingHours, "hour")
              .format("HH:mm")}{" "}
            ({workRecord.taskRecord.workingHours}小時)
          </>
        );
      },
    },
    {
      title: "僱主價錢",
      dataIndex: "employerPrice",
      key: "employerPrice",
      render: (text: string) => `$${text}`,
    },
  ];

  return (
    <Table
      columns={nestColumns}
      dataSource={record.helperAttendances}
      pagination={false}
    />
  );
};