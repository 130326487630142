import { useAsync } from "utils/use-async";
import { useCallback, useEffect } from "react";
import { cleanObject } from "utils/index";
import { useHttp } from "utils/http";
import { Position } from "screens/helper-prices/helper-price";

export const useHelperPositions = (param?: Partial<Position>) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{ list: Position[]; total: number }>();

  const fetchHelperPositions = useCallback(
    () => client("helpers/position/all", { data: cleanObject(param || {}) }),
    [param, client]
  );

  useEffect(() => {
    run(fetchHelperPositions(), {
      retry: fetchHelperPositions,
    });
  }, [param, run, fetchHelperPositions]);

  return result;
};

export const useHelperPositionDetail = (param?: Partial<Position>) => {
  const client = useHttp();
  const { run, ...result } = useAsync<Position>();

  const fetchHelperPositions = useCallback(
    () => client(`helpers/position/${param?.uuid}`, {}),
    [param, client]
  );

  useEffect(() => {
    run(fetchHelperPositions(), {
      retry: fetchHelperPositions,
    });
  }, [param, run, fetchHelperPositions]);

  return result;
};

export const useEditHelperPosition = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: Partial<Position>) => {
    return run(
      client(`helpers/position/${params.uuid}`, {
        data: params,
        method: "PUT",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useAddHelperPosition = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: Partial<Position>) => {
    return run(
      client(`helpers/position`, {
        data: params,
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};
