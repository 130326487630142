import React, { useState } from "react";
import moment from "moment";
import { Form, Input, Select, InputNumber, Button, Modal } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/es/form";
import styled from "@emotion/styled";
import { Employer } from "../employer-list/list";
import { AdminSelect } from "components/admin-select";
import { District } from "components/district";
import { changeArrayToStringInObject } from "utils";
import { employerStatus } from "utils/common";

interface SearchPanelProps extends Employer {
  onUpdate: (value: Partial<Employer>) => void;
  loading: boolean;
  reload: () => void;
}

export const CompanyEmployerDetails = ({
  onUpdate,
  reload,
  loading,
  ...data
}: SearchPanelProps) => {
  const formatData = {
    uuid: data.uuid,
    companyName: data.companyName,
    contactNumber: data.contactNumber,
    district: data?.district?.split(",") || [],
    address: data.address,
    email: data.email,
    status: data.status,
    personInCharge: data.personInCharge?.id,
  };

  const editBasicInfoFormRef = React.createRef<FormInstance>();
  const [editBasicInfoForm] = Form.useForm();
  const [showEditBasicInfoModal, setShowEditBasicInfoModal] = useState(false);

  const editContactFormRef = React.createRef<FormInstance>();
  const [editContactForm] = Form.useForm();
  const [showEditContactModal, setShowEditContactModal] = useState(false);

  const editAdminInfoFormRef = React.createRef<FormInstance>();
  const [editAdminInfoForm] = Form.useForm();
  const [showEditAdminInfoModal, setShowEditAdminInfoModal] = useState(false);

  const onEditFormClick = (type: string) => {
    switch (type) {
      case "basic":
        setShowEditBasicInfoModal(!showEditBasicInfoModal);
        break;
      case "contact":
        editContactForm.setFieldsValue(formatData);
        setShowEditContactModal(!showEditContactModal);
        break;
      case "adminInfo":
        editAdminInfoForm.setFieldsValue(formatData);
        setShowEditAdminInfoModal(!showEditAdminInfoModal);
        break;
    }
  };

  const onEditModalSubmit = async (values: Partial<Employer>) => {
    await onUpdate(changeArrayToStringInObject(values));
    reload();

    if (showEditBasicInfoModal) {
      setShowEditBasicInfoModal(!showEditBasicInfoModal);
    }

    if (showEditContactModal) {
      setShowEditContactModal(!showEditContactModal);
    }

    if (showEditAdminInfoModal) {
      setShowEditAdminInfoModal(!showEditAdminInfoModal);
    }
  };

  return (
    <>
      <SectionContainer>
        <SectionTitle>僱主信息</SectionTitle>
        <EditButton>
          <EditOutlined onClick={() => onEditFormClick("basic")} />
        </EditButton>
        <DataContainer>
          <DataTitle>公司名稱:</DataTitle>
          <DataContent>{data?.companyName}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>負責人姓名:</DataTitle>
          <DataContent>{data?.contactPerson}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>負責人職級:</DataTitle>
          <DataContent>{data?.contactPersonPosition}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>社區劵編號:</DataTitle>
          <DataContent>{data?.code}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>社區劵百分比:</DataTitle>
          <DataContent>{data?.codePercentage && `${data?.codePercentage}%`}</DataContent>
        </DataContainer>
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>聯絡信息</SectionTitle>
        <EditButton>
          <EditOutlined onClick={() => onEditFormClick("contact")} />
        </EditButton>
        <DataContainer>
          <DataTitle>聯絡電話:</DataTitle>
          <DataContent>{data?.contactNumber}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>電郵:</DataTitle>
          <DataContent>{data?.email}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>地區:</DataTitle>
          <DataContent>{data?.district}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>地址:</DataTitle>
          <DataContent>{data?.address}</DataContent>
        </DataContainer>
      </SectionContainer>

      <SectionContainer>
        <SectionTitle>UniCare備註</SectionTitle>
        <EditButton>
          <EditOutlined onClick={() => onEditFormClick("adminInfo")} />
        </EditButton>
        <DataContainer>
          <DataTitle>負責人:</DataTitle>
          <DataContent>{data?.personInCharge?.username}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>狀態:</DataTitle>
          <DataContent>{employerStatus(data?.status)}</DataContent>
        </DataContainer>
        <DataContainer>
          <DataTitle>備註:</DataTitle>
          <DataContent>{data?.remark}</DataContent>
        </DataContainer>
      </SectionContainer>

      <Modal
        title={"編輯僱主信息"}
        visible={showEditBasicInfoModal}
        footer={null}
        onCancel={() => onEditFormClick("basic")}
      >
        <Form
          form={editBasicInfoForm}
          ref={editBasicInfoFormRef}
          layout="vertical"
          initialValues={data}
          onFinish={onEditModalSubmit}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid" />
          </Form.Item>

          <Form.Item
            name="companyName"
            label="公司名稱"
            rules={[{ required: true, message: "請輸入公司名稱" }]}
          >
            <Input placeholder="公司名稱" />
          </Form.Item>

          <Form.Item
            name="contactPerson"
            label="負責人姓名"
            rules={[{ required: true, message: "請輸入負責人姓名" }]}
          >
            <Input placeholder="負責人姓名" />
          </Form.Item>

          <Form.Item name="contactPersonPosition" label="負責人職級">
            <Input placeholder="負責人職級" />
          </Form.Item>

          <Form.Item name="code" label="社區劵編號">
            <Input placeholder="社區劵編號" />
          </Form.Item>

          <Form.Item name="codePercentage" label="社區劵百分比">
            <InputNumber placeholder="社區劵百分比" style={{ width: "100%" }} />
          </Form.Item>

          <Button
            htmlType="button"
            onClick={() => onEditFormClick("basic")}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title={"編輯聯絡信息"}
        visible={showEditContactModal}
        footer={null}
        onCancel={() => onEditFormClick("contact")}
      >
        <Form
          form={editContactForm}
          ref={editContactFormRef}
          layout="vertical"
          initialValues={data}
          onFinish={onEditModalSubmit}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid" />
          </Form.Item>

          <Form.Item
            name="contactNumber"
            label="聯絡電話"
            rules={[{ required: true, message: "請輸入聯絡電話" }]}
          >
            <Input placeholder="聯絡電話" />
          </Form.Item>

          <Form.Item
            name="email"
            label="電郵"
            rules={[{ required: true, message: "請輸入電郵" }]}
          >
            <Input placeholder="電郵" />
          </Form.Item>

          <Form.Item
            name="district"
            label="服務地區"
            rules={[{ required: true, message: "請輸入服務地區" }]}
          >
            <District />
          </Form.Item>

          <Form.Item
            name="address"
            label="完整服務地址"
            rules={[{ required: true, message: "請輸入完整服務地址" }]}
          >
            <Input placeholder="完整服務地址" />
          </Form.Item>

          <Button
            htmlType="button"
            onClick={() => onEditFormClick("contact")}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title={"編輯UniCare備註"}
        visible={showEditAdminInfoModal}
        footer={null}
        onCancel={() => onEditFormClick("adminInfo")}
      >
        <Form
          form={editAdminInfoForm}
          ref={editAdminInfoFormRef}
          layout="vertical"
          initialValues={data}
          onFinish={onEditModalSubmit}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid" />
          </Form.Item>

          <Form.Item name="personInCharge" label="負責員工">
            <AdminSelect placeholder="負責員工" />
          </Form.Item>

          <Form.Item name="status" label="狀態">
            <Select placeholder="狀態">
              <Select.Option value={0}>啟用</Select.Option>
              <Select.Option value={1}>麻煩客</Select.Option>
              <Select.Option value={2}>停用</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="remark" label="備註">
            <Input.TextArea
              placeholder="備註"
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          </Form.Item>

          <Button
            htmlType="button"
            onClick={() => onEditFormClick("adminInfo")}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};

const SectionContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
`;

const SectionTitle = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 6px;
`;

const EditButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const DataContainer = styled.div`
  // padding-top: 2px;
`;

const DataTitle = styled.div`
  display: inline-block;
  font-size: 14px;
  color: #8d939e;
  padding-right: 5px;
`;

const DataContent = styled.div`
  display: inline-block;
  font-size: 14px;
  color: #3c3e42;
  font-weight: 500;
`;
