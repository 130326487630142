import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { useSearchHelpers } from "../utils/helper";

export const HelperSelect = (props: React.ComponentProps<typeof Select>) => {
  const {
    mutate: searchHelper,
    data: searchHelperResult,
    isSuccess,
  } = useSearchHelpers();
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );

  useEffect(() => {
    const tempList = [];

    for (const helper of searchHelperResult?.list || []) {
      tempList.push({
        label: `${helper.nameCn} (${helper.position})`,
        value: helper.uuid,
      });
    }

    setOptions(tempList);
  }, [isSuccess]);

  const onSearch = (val: string) => {
    searchHelper({ keyword: val });
  };

  return (
    <Select
      placeholder={"幫手列表"}
      showSearch
      allowClear
      onSearch={onSearch}
      filterOption={false}
      options={options}
      {...props}
    />
    //   {(searchHelperResult?.list || []).map((item) => (
    //     <Select.Option key={item.uuid} value={item.uuid}>
    //       {`${item.nameCn} (${item.position})`}
    //     </Select.Option>
    //   ))}
    // </Select>
  );
};
