import { useAsync } from "utils/use-async";
import { useCallback, useEffect } from "react";
import { cleanObject } from "utils/index";
import { useHttp } from "utils/http";
import { CreditDebitNoteSearch } from "screens/credit-debit-note/search-panel";
import { Page } from "./paging";
import { CreditDebitNote } from "types/credit-debit-note";

export const useCreditDebitNotes = (
  param?: CreditDebitNoteSearch,
  pageObject?: Page
) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{
    list: CreditDebitNote[];
    total: number;
  }>();

  const fetchCreditDebitNotes = useCallback(
    () =>
      client(`credit-debit-note`, {
        data: cleanObject({ ...param, ...pageObject } || {}),
      }),
    [client, param]
  );

  useEffect(() => {
    run(fetchCreditDebitNotes(), {
      retry: fetchCreditDebitNotes,
    });
  }, [run, fetchCreditDebitNotes]);

  return result;
};

export const useEmployerCreditDebitNotes = (
  param?: CreditDebitNoteSearch,
  pageObject?: Page,
  employerUuid?: string
) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{
    list: CreditDebitNote[];
    total: number;
  }>();

  const fetchCreditDebitNotes = useCallback(
    () =>
      client(`credit-debit-note/employers/${employerUuid}`, {
        data: cleanObject({ ...param, ...pageObject } || {}),
      }),
    [client, param]
  );

  useEffect(() => {
    run(fetchCreditDebitNotes(), {
      retry: fetchCreditDebitNotes,
    });
  }, [run, fetchCreditDebitNotes]);

  return result;
};

export const useHelperCreditDebitNotes = (
  param?: CreditDebitNoteSearch,
  pageObject?: Page,
  helperUuid?: string
) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{
    list: CreditDebitNote[];
    total: number;
  }>();

  const fetchCreditDebitNotes = useCallback(
    () =>
      client(`credit-debit-note/helpers/${helperUuid}`, {
        data: cleanObject({ ...param, ...pageObject } || {}),
      }),
    [client, param]
  );

  useEffect(() => {
    run(fetchCreditDebitNotes(), {
      retry: fetchCreditDebitNotes,
    });
  }, [run, fetchCreditDebitNotes]);

  return result;
};


export const useCreateCreditDebitNote = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: Partial<CreditDebitNote>) => {
    return run(
      client(`credit-debit-note`, {
        data: params,
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useCreditDebitNoteDetail = () => {
  const { run, ...asyncResult } = useAsync<CreditDebitNote>();
  const client = useHttp();
  const mutate = (invoiceUuid: string) => {
    return run(
      client(`credit-debit-note/${invoiceUuid}`, {
        data: {},
        method: "GET",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};
