import React, { useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Table, Space, Button, Tag } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import { PaymentRecord } from "screens/helper/helper-details/payment-records";
import { dateDisplay } from "utils/moment-util";
import { paymentStatus, paymentMethod } from "utils/common";
import { CreditDebitNote } from "types/credit-debit-note";

interface ListProps extends TableProps<CreditDebitNote> {
  refresh?: () => void;
}

export const List = ({ ...props }: ListProps) => {
  const navigate = useNavigate();

  const columns: ColumnsType<CreditDebitNote> = [
    {
      title: "編號",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      // width: 110
    },
    {
      title: "僱主",
      key: "employerName",
      render: (text, record) =>
        record.employer.companyName || record.employer.contactPerson,
      // width: 90
    },
    {
      title: "金額",
      dataIndex: "total",
      key: "total",
      render: (text, record) => {
        return !text && text !== 0 ? "--" : `$${text}`;
      },
      // width: 100
    },
    {
      title: "功能",
      dataIndex: "",
      key: "action",
      // fixed: 'right',
      render: (_, record) => {
        return (
          <Space size="middle">
            <a>出糧</a>
            <a>編輯</a>
          </Space>
        );
      },
      // width: 20
    },
  ];

  return (
    <div>
      <Table<CreditDebitNote> columns={columns} size="small" {...props} />
    </div>
  );
};
