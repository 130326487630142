import React, { useState } from "react";
import { Form, Input, Select, Button, Table, Space, Modal } from "antd";
import { FormInstance } from "antd/es/form";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { ColumnsType, TableProps } from "antd/es/table";
import styled from "@emotion/styled";
import { Row } from "components/lib";
import { useDebounce, useDocumentTitle } from "utils";

import { Profiler } from "components/profiler";
import { setTimeout } from "timers";
import PageContainer from "../../../components/layout/PageContainer";

export interface AccountingItem {
  uuid: number | string;
  type: string;
  category: string;
}

export const AccountingCategoryScreen = () => {
  useDocumentTitle("項目選項", false);
  const createFormRef = React.createRef<FormInstance>();
  const [createForm] = Form.useForm();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editCategoryData, setEditCategoryData] = useState<AccountingItem>({
    uuid: "",
    type: "",
    category: "",
  });

  const columns: ColumnsType<AccountingItem> = [
    {
      title: "ID",
      dataIndex: "uuid",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "category",
      render: (text, record) => (
        <Space size="middle">
          <EditOutlined onClick={() => handleEditClick(record)} />
          <DeleteOutlined onClick={() => handleCategoryDelete(record.uuid)} />
        </Space>
      ),
    },
  ];

  const itemData = [
    {
      uuid: "1",
      type: "Income",
      category: "收租",
    },
    {
      uuid: "2",
      type: "Expense",
      category: "電費",
    },
    {
      uuid: "3",
      type: "Expense",
      category: "水費",
    },
    {
      uuid: "4",
      type: "Asset",
      category: "現金",
    },
  ];

  const openCreateCategory = () => {
    setShowCreateModal(true);
  };

  const onCreateCategorySubmit = (values: any) => {
    if (!values.type || !values.category) {
      return;
    }

    setShowCreateModal(false);
    createFormRef.current!.resetFields();
  };

  const onCreateCategoryCancel = () => {
    setShowCreateModal(false);
    createFormRef.current!.resetFields();
  };

  const handleEditClick = (item: AccountingItem) => {
    setEditCategoryData(item);
    setShowEditModal(true);
  };

  const handleEditDataChange = (e: {
    target: { name: string; value: string };
  }) => {
    const { name, value } = e.target;

    setEditCategoryData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditDataSelectChange = (value: string) => {
    setEditCategoryData((prevState) => ({
      ...prevState,
      type: value,
    }));
  };

  const onEditCategorySubmit = () => {
    if (
      !editCategoryData.uuid ||
      !editCategoryData.type ||
      !editCategoryData.category
    ) {
      return;
    }

    setShowEditModal(false);
  };

  const onEditCategoryCancel = () => {
    setShowEditModal(false);
  };

  const handleCategoryDelete = (uuid: string | number) => {
    Modal.confirm({
      title: "Are you sure delete this category?",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure delete this category?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      maskClosable: true,
      onOk() {
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <Profiler id={"項目選項"}>
      <PageContainer>
        <ContentContainer>
          <Row marginBottom={2} between={true}>
            <h1>項目選項列表</h1>
            <Button type="primary" onClick={() => openCreateCategory()}>
              Add Category
            </Button>
          </Row>

          <Table<AccountingItem>
            columns={columns}
            dataSource={itemData}
            pagination={{ current: 1, total: 100 }}
          />
        </ContentContainer>
      </PageContainer>

      <Modal
        title="Add Category"
        visible={showCreateModal}
        footer={null}
        onCancel={onCreateCategoryCancel}
        // centered
      >
        <Form
          form={createForm}
          ref={createFormRef}
          layout="vertical"
          onFinish={onCreateCategorySubmit}
        >
          <Form.Item name="type" label="Type" required>
            <Select placeholder="Type">
              <Select.Option value="income">Income</Select.Option>
              <Select.Option value="expense">Expense</Select.Option>
              <Select.Option value="asset">Asset</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="category" label="Category" required>
            <Input placeholder="Category" />
          </Form.Item>
          <Button
            htmlType="button"
            onClick={onCreateCategoryCancel}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title="Edit Category"
        visible={showEditModal}
        footer={null}
        onCancel={onEditCategoryCancel}
        // centered
      >
        <EditTitle>*Type</EditTitle>
        <Select
          placeholder="Type"
          value={editCategoryData.type}
          onChange={handleEditDataSelectChange}
          style={{ width: "100%" }}
        >
          <Select.Option value="income">Income</Select.Option>
          <Select.Option value="expense">Expense</Select.Option>
        </Select>
        <EditTitle style={{ paddingTop: 20 }}>*Category</EditTitle>
        <Input
          placeholder="Category"
          name="category"
          value={editCategoryData.category}
          style={{ marginBottom: 30, width: "100%" }}
          onChange={handleEditDataChange}
        />
        <Button onClick={onEditCategoryCancel} style={{ marginRight: 20 }}>
          Cancel
        </Button>
        <Button type="primary" onClick={() => onEditCategorySubmit()}>
          Submit
        </Button>
      </Modal>
    </Profiler>
  );
};

const ContentContainer = styled.div`
  margin-top: 3px;
  // padding: 3.2rem;
  padding: 2rem;
  flex-direction: column;
  position: relative;
  overflow: auto;
`;

const EditTitle = styled.div`
  font-size: 14px;
  color: #8d939e;
  // padding-top: 15px;
  padding-bottom: 2px;
`;

AccountingCategoryScreen.whyDidYouRender = false;
