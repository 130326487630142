import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import styled from "@emotion/styled";
import { SearchPanel } from "screens/job/all-jobs/search-panel";
import { JobList } from "components/listing/job-list";
import { useDebounce, useDocumentTitle } from "utils";

import { Row } from "components/lib";
import { Profiler } from "components/profiler";
import { useJobs } from "utils/job";
import { useJobSearchParams } from "../../../search-utils/util";
import { useUrlQueryPaging } from "../../../utils/paging";
import useWindowSize from "hooks/useWindowSize";
import PageContainer from "../../../components/layout/PageContainer";

export const AllJobsScreen = () => {
  useDocumentTitle("工作列表", false);
  const navigate = useNavigate();
  const { windowHeight } = useWindowSize();

  const [param, setParam] = useJobSearchParams();
  const [pageObject, setPageObject] = useUrlQueryPaging();

  const { isLoading, error, data, retry } = useJobs(
    useDebounce(param, 500),
    pageObject
  );

  return (
    <Profiler id={"工作列表"}>
      <PageContainer>
        <SearchPanel param={param} setParam={setParam} />
        <ContentContainer>
          <Row marginBottom={2} between={true}>
            <h1>工作列表</h1>
            <Button type="primary" onClick={() => navigate("/create_job")}>
              ＋創建工作
            </Button>
          </Row>

          <JobList
            refresh={retry}
            tableProps={{
              loading: isLoading,
              dataSource: data?.list || [],
              pagination: false,
              scroll: {
                y: windowHeight - 320,
              }
            }}
            paginationProps={{
              pageSize: pageObject.pageSize,
              current: pageObject.pageNumber,
              total: data?.total || 0,
              onChange: (page, pageSize) => {
                setPageObject({
                  pageSize,
                  pageNumber: page,
                });
              }
            }}
          />
        </ContentContainer>
      </PageContainer>
    </Profiler>
  );
};

const ContentContainer = styled.div`
  padding: 3.2rem;
`;

AllJobsScreen.whyDidYouRender = false;
