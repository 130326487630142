import React from "react";
import { Select } from "antd";

export const HelperPaymentMethodSelect = (
  props: React.ComponentProps<typeof Select>
) => {
  return (
    <Select placeholder="出糧方式" {...props}>
      <Select.Option value={0}>支票</Select.Option>
      <Select.Option value={1}>銀行過數</Select.Option>
      <Select.Option value={2}>FPS</Select.Option>
      <Select.Option value={3}>WeChat pay</Select.Option>
      <Select.Option value={4}>Ali pay</Select.Option>
      <Select.Option value={5}>現金</Select.Option>
    </Select>
  );
};
