import { useAsync } from "utils/use-async";
import { Project } from "screens/project-list/list";
import { useCallback, useEffect } from "react";
import { cleanObject } from "utils/index";
import { useHttp, useHttpDownload } from "utils/http";
import { Employer } from "../screens/employer/employer-list/list";
import { EmployerInvoiceRecord } from "types/employer-invoice-record";
import { EmployerPaymentRecord } from "types/employer-payment-record";
import { Page } from "./paging";
import { InvoiceOtherData } from "screens/employer/employer-details/invoice-records";
import { EmployerSearch } from "../screens/employer/employer-list/search-panel";

export const useEmployers = (param?: EmployerSearch, pageObject?: Page) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{ list: Employer[]; total: number }>();

  const fetchEmployers = useCallback(
    () =>
      client("employers", {
        data: cleanObject({ ...param, ...pageObject } || {}),
      }),
    [param, client, pageObject]
  );

  useEffect(() => {
    run(fetchEmployers(), {
      retry: fetchEmployers,
    });
  }, [param, run, fetchEmployers]);

  return result;
};

export const useSearchEmployers = () => {
  const { run, ...asyncResult } = useAsync<{
    list: Employer[];
    total: number;
    pageSize: number;
    pageNumber: number;
  }>();
  const client = useHttp();
  const mutate = (params: { keyword: string }) => {
    return run(
      client(`employers`, {
        data: {
          keyword: params.keyword,
          pageSize: 20,
          pageNumber: 1,
        },
        method: "GET",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useEmployerDetail = (param?: Partial<Employer>) => {
  const client = useHttp();
  const { run, ...result } = useAsync<Employer>();

  const fetchEmployers = useCallback(
    () => client(`employers/${param?.uuid}`, {}),
    [param, client]
  );

  useEffect(() => {
    run(fetchEmployers(), {
      retry: fetchEmployers,
    });
  }, [param, run, fetchEmployers]);

  return result;
};

export const useEditEmployer = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: Partial<Employer>) => {
    return run(
      client(`employers/${params.uuid}`, {
        data: params,
        method: "PUT",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useAddEmployer = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: Partial<Employer>) => {
    return run(
      client(`employers`, {
        data: params,
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useEmployerInvoices = (param: { uuid: string }, pageObject?: Page) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{
    list: EmployerInvoiceRecord[];
    total: number;
  }>();

  const fetchEmployerInvoices = useCallback(
    () =>
      client(`employers/${param.uuid}/invoices`, {
        data: pageObject,
      }),
    [param, client]
  );

  useEffect(() => {
    run(fetchEmployerInvoices(), {
      retry: fetchEmployerInvoices,
    });
  }, [param, run, fetchEmployerInvoices]);

  return result;
};

export const useEmployerInvoiceDetail = () => {
  const { run, ...asyncResult } = useAsync<
    EmployerInvoiceRecord & InvoiceOtherData
  >();
  const client = useHttp();
  const mutate = (employerUuid: string, invoiceUuid: string) => {
    return run(
      client(`employers/${employerUuid}/invoices/${invoiceUuid}`, {
        data: {},
        method: "GET",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useAddEmployerNormalInvoice = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (
    params: {
      type: number;
      invoiceDate: string;
      dueDate: string;
      remark: string;
      invoiceItems: Array<{
        itemName: string;
        quantity: number;
        cost: number;
        subTotal: number;
      }>;
    },
    employerUuid: string
  ) => {
    return run(
      client(`employers/${employerUuid}/invoices`, {
        data: params,
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useAddEmployerTaskInvoice = () => {
    const { run, ...asyncResult } = useAsync();
    const client = useHttp();
    const mutate = (params: { settleFrom: string; settleTo: string}, employerUuid: string ) => {
        return run(
            client(`employers/${employerUuid}/invoices/task`, {
                data: params,
                method: "POST",
            })
        );
    };
    return {
        mutate,
        ...asyncResult,
    };
};


export const useEditEmployerInvoice = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (
    params: {
      type?: number;
      invoiceDate?: string;
      dueDate?: string;
      remark?: string;
      invoiceItems?: Array<{
        itemName: string;
        quantity: number;
        cost: number;
        subTotal: number;
      }>;
      status?: number
    },
    employerUuid: string,
    invoiceUuid: string
  ) => {
    return run(
      client(`employers/${employerUuid}/invoices/${invoiceUuid}`, {
        data: params,
        method: "PUT",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useAddEmployerPayment = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (
    params: {
      method: number;
      amount: number;
      settleDate: string;
      referenceNo: string;
      remark: string;
      attachments: string[];
      invoiceUuid: string;
    },
    employerUuid: string
  ) => {
    return run(
      client(`employers/${employerUuid}/payments`, {
        data: params,
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useEmployerPayments = (param: { uuid: string }) => {
  const client = useHttp();
  const { run, ...result } = useAsync<{
    list: EmployerPaymentRecord[];
    total: number;
  }>();

  const fetchEmployerPayments = useCallback(
    () =>
      client(`employers/${param.uuid}/payments`, {
        data: {pageSize: 99999},
      }),
    [client, param]
  );

  useEffect(() => {
    run(fetchEmployerPayments(), {
      retry: fetchEmployerPayments,
    });
  }, [run, fetchEmployerPayments]);

  return result;
};

export const useEmployerPaymentDetail = () => {
  const { run, ...asyncResult } = useAsync<EmployerPaymentRecord>();
  const client = useHttp();
  const mutate = (employerUuid: string, paymentUuid: string) => {
    return run(
      client(`employers/${employerUuid}/payments/${paymentUuid}`, {
        data: {},
        method: "GET",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useEditEmployerPayment = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (
    params: {
      method: number;
      amount: number;
      settleDate: string;
      remark: string;
      referenceNo: string;
      attachments: string[];
    },
    paymentUuid: string,
    employerUuid: string
  ) => {
    return run(
      client(`employers/${employerUuid}/payments/${paymentUuid}`, {
        data: params,
        method: "PUT",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useDeleteEmployerPayment = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (paymentUuid: string, employerUuid: string) => {
    return run(
      client(`employers/${employerUuid}/payments/${paymentUuid}`, {
        data: {},
        method: "DELETE",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useAddEmployerInvoiceAttachments = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: FormData) => {
    return run(
      client(`employers/attachments/upload`, {
        data: params,
        method: "POST",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useDeleteEmployerInvoiceAttachments = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (params: { uuid: string }) => {
    return run(
      client(`employers/attachments/${params.uuid}`, {
        data: params,
        method: "DELETE",
      })
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};

export const useGetEmployerAttachment = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttpDownload();
  const mutate = (uuid: string) => {
    return client(`employers/attachments/${uuid}`);
  };
  return {
    mutate,
    ...asyncResult,
  };
};
