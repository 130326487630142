import React, { useState, useEffect } from "react";
import { useAdmin } from "utils/admin";
import { Select } from "antd";

export const AdminSelect = (props: React.ComponentProps<typeof Select>) => {
  const { data: admins } = useAdmin();

  const [adminList, setAdminList] = useState<any[]>([]);

  useEffect(() => {
    const tempList = admins?.list || [];
    const formattedList = [];

    for (const list of tempList) {
      formattedList.push({
        ...list,
        value: list.id,
        label: list.username,
      });
    }
    setAdminList(formattedList);
  }, [admins]);

  return <Select options={adminList} placeholder={"admin同事"} {...props} />;
};
