import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {Layout, Menu} from "antd";
import {
  AccountBookOutlined,
  AuditOutlined,
  BarsOutlined,
  PayCircleOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserOutlined,
  BarChartOutlined
} from "@ant-design/icons";

const {Sider} = Layout;

export const SideMenu = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // path change
  }, [location.pathname]);

  return (
    <Sider
      theme="light"
      collapsible
      collapsed={collapsed}
      style={{zIndex: 999}}
      onCollapse={(collapseState) => setCollapsed(collapseState)}
    >
      <div className="logo"/>
      <Menu theme="light" defaultSelectedKeys={["analysis"]} mode="inline">
        <Menu.Item key="analysis" icon={<BarChartOutlined />}>
          <Link to="analysis">Report</Link>
        </Menu.Item>
        <Menu.SubMenu key="jobs" icon={<SolutionOutlined/>} title="工作列表">
          <Menu.Item key="all_jobs">
            <Link to="all_jobs">所有工作</Link>
          </Menu.Item>
          <Menu.Item key="new_jobs">
            <Link to="all_jobs?status=0">新工作</Link>
          </Menu.Item>
          <Menu.Item key="waiting_jobs">
            <Link to="all_jobs?status=1">待確認幫手</Link>
          </Menu.Item>
          {/*<Menu.Item key="starting_jobs">*/}
          {/*  <Link to="starting_jobs">待開始</Link>*/}
          {/*</Menu.Item>*/}
          <Menu.Item key="in_progress_jobs">
            <Link to="all_jobs?status=2">進行中</Link>
          </Menu.Item>
          <Menu.Item key="completed_jobs">
            <Link to="all_jobs?status=3">已完成</Link>
          </Menu.Item>
          <Menu.Item key="cancelled_jobs">
            <Link to="all_jobs?status=4">已取消</Link>
          </Menu.Item>
          <Menu.Item key="late_checkout_jobs">
            <Link to="late_checkout_jobs">遲簽到</Link>
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item key="helpers" icon={<TeamOutlined/>}>
          <Link to="helpers">幫手列表</Link>
        </Menu.Item>
        <Menu.Item key="employers" icon={<UserOutlined/>}>
          <Link to="employers">僱主列表</Link>
        </Menu.Item>
        <Menu.Item key="employer_invoices" icon={<AuditOutlined/>}>
          <Link to="employer_invoices">僱主發票</Link>
        </Menu.Item>
        <Menu.Item key="helper_payments" icon={<AccountBookOutlined/>}>
          <Link to="helper_payments">幫手出糧帳單</Link>
        </Menu.Item>
        {/* <Menu.Item key="credit_debit_notes" icon={<FormOutlined />}>
          <Link to="credit_debit_notes">Credit Debit Notes</Link>
        </Menu.Item> */}
        <Menu.Item key="hepler_prices" icon={<BarsOutlined/>}>
          <Link to="hepler_prices">幫手職級價目表</Link>
        </Menu.Item>
        <Menu.SubMenu
          key="accounting"
          icon={<PayCircleOutlined/>}
          title="Accounting"
        >
          <Menu.Item key="accounting_report">
            <Link to="accounting_report">Report</Link>
          </Menu.Item>
          <Menu.Item key="accounting_category">
            <Link to="accounting_category">項目選項</Link>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </Sider>
  );
};
