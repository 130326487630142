export const changeArrayToWeekDay = (arr: never[] | string[] | undefined) => {
  const weekday = [
    "",
    "星期一",
    "星期二",
    "星期三",
    "星期四",
    "星期五",
    "星期六",
    "星期日",
  ];
  let result = [];
  const temArr = arr || [];

  for (let i = 0; i < temArr.length; i++) {
    result.push(weekday[parseInt(temArr[i])]);
  }

  return result.toString();
};

export const workingPeriodStatus = (item: number = 0): string => {
  enum PeriodStatus {
    "時段性",
    "一次性",
    "週期性",
  }
  return PeriodStatus[item];
};

export const taskStatus = (item: number = 0): string => {
  enum TaskStatusEum {
    "新工作",
    "待確認幫手",
    "進行中",
    "已完成",
    "已取消",
  }
  return TaskStatusEum[item];
};

export const helperStatus = (item: number = 0): string => {
  enum HelperStatusEum {
    "啟用",
    "停用",
  }
  return HelperStatusEum[item];
};

export const employerStatus = (item: number = 0): string => {
  enum EmployerStatusEum {
    "啟用",
    "麻煩客",
    "停用",
  }
  return EmployerStatusEum[item];
};

export const taskAttendanceStatus = (item: number = 0): string => {
  enum TaskAttendanceEnum {
    "等待中",
    "確認",
    "簽到",
    "缺席",
    "請假",
    "取消",
  }
  return TaskAttendanceEnum[item];
};

export const gender = (item: number = 0): string => {
  enum GenderEum {
    "男性",
    "女性",
  }
  return GenderEum[item];
};

export const taskRecord = (item: number = 0): string => {
  enum GenderEum {
    "進行中",
    "完成",
    "取消",
  }
  return GenderEum[item];
};

export const creditDebitType = (item: number = 0): string => {
  enum NoteTypeEum {
    "Credit Note",
    "Debit Note",
  }
  return NoteTypeEum[item];
};

export const invoiceRecordStatus = (item: number = 0): string => {
  enum InvoiceStatusEum {
    "未付款",
    "已付款",
    "過期",
    "取消",
  }
  return InvoiceStatusEum[item];
};

export const paymentMethod = (item: number = 0): string => {
  enum PaymentMethodEum {
    "支票",
    "銀行過數",
    "FPS",
    "WeChat pay",
    "Ali pay",
    "現金",
  }
  return PaymentMethodEum[item];
};

export const paymentMethodEng = (item: number = 0): string => {
  enum PaymentMethodEum {
    "Cheque",
    "Bank Payment",
    "FPS",
    "WeChat pay",
    "Ali pay",
  }
  return PaymentMethodEum[item];
};

export const paymentStatus = (item: number = 0): string => {
  enum PaymentStatusEum {
    "未支付",
    "已支付",
  }
  return PaymentStatusEum[item];
};

export const languages = (item: string = ""): string => {
  if (!item) {
    return '';
  }

  const formatLanguage = (lang: string): string => {
    switch (lang) {
      case "cantonese":
        return "廣東話";
      case "english":
        return "英語";
      case "putonghua":
        return "普通話";
      case "潮洲話":
        return "潮洲話";
      case "上海話":
        return "上海話";
      case "福建話":
        return "福建話";
      case "客家話":
        return "客家話";
      default:
        return "";
    }
  };

  let tempLanguageArray = item.split(",");
  let formattedLanguageArray = [];

  for (const language of tempLanguageArray) {
    formattedLanguageArray.push(formatLanguage(language));
  }

  return formattedLanguageArray.toString();
};

export const timeTableDisplay = (timeTable: { monAm: boolean }): string => {
  const formatTime = (key: string): string => {
    switch (key) {
      case "monAm":
        return "星期一上午";
      case "monPm":
        return "星期一上午";
      case "tueAm":
        return "星期一上午";
      case "tuePm":
        return "星期一上午";
      case "wedAm":
        return "星期一上午";
      case "wedPm":
        return "星期一上午";
      case "thuAm":
        return "星期一上午";
      case "thuPm":
        return "星期一上午";
      case "friAm":
        return "星期一上午";
      case "friPm":
        return "星期一上午";
      case "satAm":
        return "星期一上午";
      case "satPm":
        return "星期一上午";
      case "sunAm":
        return "星期一上午";
      case "sunPm":
        return "星期一上午";
      default:
        return "";
    }
  };

  // let formattedTimeTableArray = [];
  // let tempTimeArray = [];

  // for (const [key, value] of Object.entries(timeTable)) {

  // }

  // for (const time of tempTimeArray) {
  //   formattedTimeTableArray.push(formatTime(time));
  // }

  // return formattedTimeTableArray.toString();
  return "";
};

export const formatTimeTable = (timeTable: string[]) => {
  // const timeArray = ["monAm", "monPm", "tueAm", "tuePm", "wedAm", "wedPm", "thuAm", "thuPm", "friAm", "friPm", "satAm", "satPm", "sunAm", "sunPm"];
  let newTimeTable: {
    [key: string]: boolean;
  } = {
    monAm: false,
    monPm: false,
    tueAm: false,
    tuePm: false,
    wedAm: false,
    wedPm: false,
    thuAm: false,
    thuPm: false,
    friAm: false,
    friPm: false,
    satAm: false,
    satPm: false,
    sunAm: false,
    sunPm: false,
  };
  for (const time of timeTable) {
    newTimeTable[time] = true;
  }

  return newTimeTable;
};
