import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col, Popover } from "antd";
import { ColumnsType } from "antd/es/table";
import styled from "@emotion/styled";
import { ListTable } from "components/table/list-table";
import { Job } from "../../job/all-jobs/list";
import { gender } from "utils/common";

export const JobList = (props: { jobs: Job[] }) => {
  const navigate = useNavigate();

  const geHelperPopover = (taskHelpers: any[]) => {
    return (
      <PopoverContainer>
        <Row>
          {taskHelpers?.map((taskHelper: any, index: number) => (
            <Col key={index} span={12}>
              <PopoverItem>
                <PopoverItemTitle>
                  {taskHelper?.helper?.nameCn}
                </PopoverItemTitle>
                <PopoverItemDescription>{`${gender(
                  taskHelper?.helper?.gender
                )} ． ${taskHelper?.helper?.position}`}</PopoverItemDescription>
              </PopoverItem>
            </Col>
          ))}
        </Row>
      </PopoverContainer>
    );
  };

  const columns: ColumnsType<Job> = [
    {
      title: "工作編號",
      width: 100,
      dataIndex: ["taskNo"],
      key: "taskNo",
      render: (text, record) => <a href={`/job/${record.uuid}`}>{text}</a>,
    },
    {
      title: "幫手",
      dataIndex: "taskHelpers",
      key: "taskHelpers",
      width: 150,
      render: (text, record) =>
        record.taskHelpers && record.taskHelpers.length > 0 ? (
          <Popover
            content={geHelperPopover(record.taskHelpers || [])}
            title="幫手"
            trigger="hover"
          >
            {record.taskHelpers?.length || 0} (顯示全部)
          </Popover>
        ) : (
          0
        ),
    },
    {
      title: "標題",
      dataIndex: "title",
      key: "title",
      width: 300,
    },
    {
      title: "日期",
      dataIndex: "startDate",
      key: "startDate",
      width: 130,
    },
    {
      title: "負責人",
      dataIndex: ["personInCharge", "username"],
      key: "personInCharge",
    },
  ];

  const { jobs } = props;

  return (
    <div>
      <Table<Job>
        columns={columns}
        size="small"
        dataSource={jobs}
        // pagination={{ current: 1, total: 100 }}
      />
    </div>
  );
};

const PopoverContainer = styled.div`
  width: 400px;
`;

const PopoverItem = styled.div`
  padding: 8px 12px;
`;

const PopoverItemTitle = styled.div`
  color: #3c3e42;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 2px;
`;

const PopoverItemDescription = styled.div`
  color: #8d939e;
  font-size: 12px;
`;
