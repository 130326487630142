import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Select, InputNumber, Row, Col } from "antd";
import styled from "@emotion/styled";
import { TopBar } from "screens/employer/create-employer/top-bar";
import {
  changeArrayToStringInObject,
  useDebounce,
  useDocumentTitle,
} from "utils";

import { District } from "components/district";
import { AdminSelect } from "components/admin-select";
import { Profiler } from "components/profiler";
import { useAddEmployer } from "../../../../utils/employer";
import PageContainer from "../../../../components/layout/PageContainer";

export const CreateCompanyEmployerScreen = () => {
  useDocumentTitle("創建院舍/公司僱主", false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutate, isLoading, isError, isSuccess, data } = useAddEmployer();

  useEffect(() => {
    if (isSuccess && data) {
      // @ts-ignore
      navigate(`/employer/${data?.uuid}`);
    }
  }, [isSuccess]);

  const onSubmit = async (values?: any) => {
    await mutate(changeArrayToStringInObject(values));
  };

  return (
    <Profiler id="創建院舍/公司僱主">
      <PageContainer>
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          initialValues={{ type: 1 }}
        >
          <TopBar title="創建院舍/公司僱主" isLoading={isLoading} />
          <Form.Item name="type" hidden>
            <Input />
          </Form.Item>
          <ContentContainer>
            <FormCard>
              <CardTitle>僱主信息</CardTitle>
              <Row gutter={[16, 4]}>
                <Col span={12}>
                  <Form.Item
                    name="companyName"
                    label="公司名稱"
                    rules={[{ required: true, message: "請輸入公司名稱" }]}
                  >
                    <Input placeholder="公司名稱" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="contactPerson"
                    label="負責人姓名"
                    rules={[{ required: true, message: "請輸入負責人姓名" }]}
                  >
                    <Input placeholder="負責人姓名" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="contactPersonPosition" label="負責人職級">
                    <Input placeholder="負責人職級" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="code" label="社區劵編號">
                    <Input placeholder="社區劵編號" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="codePercentage" label="社區劵百分比">
                    <InputNumber
                      placeholder="社區劵百分比"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </FormCard>

            <FormCard>
              <CardTitle>聯絡信息</CardTitle>
              <Row gutter={[16, 4]}>
                <Col span={12}>
                  <Form.Item
                    name="contactNumber"
                    label="聯絡電話"
                    rules={[{ required: true, message: "請輸入公司名稱" }]}
                  >
                    <Input placeholder="聯絡電話" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="email" label="電郵">
                    <Input placeholder="電郵" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="district"
                    label="服務地區"
                    rules={[{ required: true, message: "請選擇服務地區" }]}
                  >
                    <District />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="address"
                    label="完整服務地址"
                    rules={[{ required: true, message: "請輸入完整服務地址" }]}
                  >
                    <Input placeholder="完整服務地址" />
                  </Form.Item>
                </Col>
              </Row>
            </FormCard>

            <FormCard>
              <CardTitle>UniCare備註</CardTitle>
              <Row gutter={[16, 4]}>
                <Col span={12}>
                  <Form.Item
                    name="personInCharge"
                    label="負責員工"
                    rules={[{ required: true, message: "請選擇負責員工" }]}
                  >
                    <AdminSelect placeholder="負責員工" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="remark" label="備註">
                    <Input.TextArea
                      placeholder="備註"
                      autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </FormCard>
          </ContentContainer>
        </Form>
      </PageContainer>
    </Profiler>
  );
};

const ContentContainer = styled.div`
  padding: 3.2rem;
`;

const FormCard = styled.div`
  padding: 2.4rem;
  background-color: #ffffff;
  margin-bottom: 2.4rem;
`;

const CardTitle = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
`;

const InputLabel = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
`;

CreateCompanyEmployerScreen.whyDidYouRender = false;
