import {useHttp} from "./http";
import {useAsync} from "./use-async";
import {useCallback, useEffect} from "react";
import {cleanObject} from "./index";
import {Blacklist} from "../screens/employer/employer-details/blacklist";

export const useBlacklist = (param: { uuid: string, keyword: string }) => {
    const client = useHttp();
    const {run, ...result} = useAsync<{ list: Blacklist[]; total: number }>();

    const fetchEmployers = useCallback(
        () =>
            client(`employers/${param.uuid}/helper_blacklist`, {
                data: cleanObject({...param} || {}),
            }),
        [param, client]
    );

    useEffect(() => {
        run(fetchEmployers(), {
            retry: fetchEmployers,
        });
    }, [param, run, fetchEmployers]);

    return result;
};

export const useAddEmployerBlacklist = () => {
    const {run, ...asyncResult} = useAsync();
    const client = useHttp();
    const mutate = (params: Partial<Blacklist>) => {
        return run(
            client(`employers/${params?.employerId}/helper_blacklist`, {
                data: params,
                method: "POST",
            })
        );
    };
    return {
        mutate,
        ...asyncResult,
    };
};


export const useDeleteEmployerBlacklist = () => {
    const {run, ...asyncResult} = useAsync();
    const client = useHttp();
    const mutate = (params: Partial<Blacklist>) => {
        return run(
            client(`employers/${params?.employerId}/helper_blacklist/${params?.uuid}`, {
                method: "DELETE",
            })
        );
    };
    return {
        mutate,
        ...asyncResult,
    };
};
