import React, { useState, useEffect } from "react";
import { Cascader } from "antd";

export const District = (props: any) => {
  // const {
  //   handleChange
  // } = props;

  const options = [
    {
      value: "港島",
      label: "港島",
      children: [
        {
          value: "中西區",
          label: "中西區",
          children: [
            {
              value: "堅尼地城",
              label: "堅尼地城",
            },
            {
              value: "石塘咀",
              label: "石塘咀",
            },
            {
              value: "西營盤",
              label: "西營盤",
            },
            {
              value: "上環",
              label: "上環",
            },
            {
              value: "中環",
              label: "中環",
            },
            {
              value: "金鐘",
              label: "金鐘",
            },
          ],
        },
        {
          value: "半山區",
          label: "半山區",
          children: [
            {
              value: "山頂",
              label: "山頂",
            },
          ],
        },
        {
          value: "灣仔區",
          label: "灣仔區",
          children: [
            {
              value: "灣仔",
              label: "灣仔",
            },
            {
              value: "銅鑼灣",
              label: "銅鑼灣",
            },
            {
              value: "跑馬地",
              label: "跑馬地",
            },
            {
              value: "大坑",
              label: "大坑",
            },
            {
              value: "掃桿埔",
              label: "掃桿埔",
            },
            {
              value: "渣甸山",
              label: "渣甸山",
            },
          ],
        },
        {
          value: "東區",
          label: "東區",
          children: [
            {
              value: "天后",
              label: "天后",
            },
            {
              value: "寶馬山",
              label: "寶馬山",
            },
            {
              value: "北角",
              label: "北角",
            },
            {
              value: "鰂魚涌",
              label: "鰂魚涌",
            },
            {
              value: "西灣河",
              label: "西灣河",
            },
            {
              value: "筲箕灣",
              label: "筲箕灣",
            },
            {
              value: "柴灣",
              label: "柴灣",
            },
            {
              value: "小西灣",
              label: "小西灣",
            },
          ],
        },
        {
          value: "南區",
          label: "南區",
          children: [
            {
              value: "薄扶林",
              label: "薄扶林",
            },
            {
              value: "香港仔",
              label: "香港仔",
            },
            {
              value: "鴨脷洲",
              label: "鴨脷洲",
            },
            {
              value: "黃竹坑",
              label: "黃竹坑",
            },
            {
              value: "壽臣山",
              label: "壽臣山",
            },
            {
              value: "淺水灣",
              label: "淺水灣",
            },
            {
              value: "舂磡角",
              label: "舂磡角",
            },
            {
              value: "赤柱",
              label: "赤柱",
            },
            {
              value: "大潭",
              label: "大潭",
            },
            {
              value: "石澳",
              label: "石澳",
            },
          ],
        },
      ],
    },
    {
      value: "九龍",
      label: "九龍",
      children: [
        {
          value: "油尖旺區",
          label: "油尖旺區",
          children: [
            {
              value: "尖沙咀",
              label: "尖沙咀",
            },
            {
              value: "油麻地",
              label: "油麻地",
            },
            {
              value: "西九龍填海區",
              label: "西九龍填海區",
            },
            {
              value: "京士柏",
              label: "京士柏",
            },
            {
              value: "旺角",
              label: "旺角",
            },
            {
              value: "大角咀",
              label: "大角咀",
            },
          ],
        },
        {
          value: "深水埗區",
          label: "深水埗區",
          children: [
            {
              value: "美孚",
              label: "美孚",
            },
            {
              value: "荔枝角",
              label: "荔枝角",
            },
            {
              value: "長沙灣",
              label: "長沙灣",
            },
            {
              value: "深水埗",
              label: "深水埗",
            },
            {
              value: "石硤尾",
              label: "石硤尾",
            },
            {
              value: "又一村",
              label: "又一村",
            },
            {
              value: "大窩坪",
              label: "大窩坪",
            },
            {
              value: "昂船洲",
              label: "昂船洲",
            },
          ],
        },
        {
          value: "九龍城區",
          label: "九龍城區",
          children: [
            {
              value: "紅磡",
              label: "紅磡",
            },
            {
              value: "土瓜灣",
              label: "土瓜灣",
            },
            {
              value: "馬頭角",
              label: "馬頭角",
            },
            {
              value: "馬頭圍",
              label: "馬頭圍",
            },
            {
              value: "啟德",
              label: "啟德",
            },
            {
              value: "九龍城",
              label: "九龍城",
            },
            {
              value: "何文田",
              label: "何文田",
            },
            {
              value: "九龍塘",
              label: "九龍塘",
            },
            {
              value: "筆架山",
              label: "筆架山",
            },
          ],
        },
        {
          value: "黃大仙區",
          label: "黃大仙區",
          children: [
            {
              value: "新蒲崗",
              label: "新蒲崗",
            },
            {
              value: "黃大仙",
              label: "黃大仙",
            },
            {
              value: "東頭",
              label: "東頭",
            },
            {
              value: "橫頭磡",
              label: "橫頭磡",
            },
            {
              value: "樂富",
              label: "樂富",
            },
            {
              value: "鑽石山",
              label: "鑽石山",
            },
            {
              value: "慈雲山",
              label: "慈雲山",
            },
            {
              value: "牛池灣",
              label: "牛池灣",
            },
          ],
        },
        {
          value: "觀塘區",
          label: "觀塘區",
          children: [
            {
              value: "坪石",
              label: "坪石",
            },
            {
              value: "九龍灣",
              label: "九龍灣",
            },
            {
              value: "牛頭角",
              label: "牛頭角",
            },
            {
              value: "佐敦谷",
              label: "佐敦谷",
            },
            {
              value: "觀塘",
              label: "觀塘",
            },
            {
              value: "秀茂坪",
              label: "秀茂坪",
            },
            {
              value: "藍田",
              label: "藍田",
            },
            {
              value: "油塘",
              label: "油塘",
            },
            {
              value: "鯉魚門",
              label: "鯉魚門",
            },
          ],
        },
      ],
    },
    {
      value: "新界",
      label: "新界",
      children: [
        {
          value: "葵青區",
          label: "葵青區",
          children: [
            {
              value: "葵涌",
              label: "葵涌",
            },
            {
              value: "青衣",
              label: "青衣",
            },
          ],
        },
        {
          value: "荃灣區",
          label: "荃灣區",
          children: [
            {
              value: "荃灣",
              label: "荃灣",
            },
            {
              value: "梨木樹",
              label: "梨木樹",
            },
            {
              value: "汀九",
              label: "汀九",
            },
            {
              value: "深井",
              label: "深井",
            },
            {
              value: "青龍頭",
              label: "青龍頭",
            },
            {
              value: "馬灣",
              label: "馬灣",
            },
            {
              value: "欣澳",
              label: "欣澳",
            },
          ],
        },
        {
          value: "屯門區",
          label: "屯門區",
          children: [
            {
              value: "大欖涌",
              label: "大欖涌",
            },
            {
              value: "掃管笏",
              label: "掃管笏",
            },
            {
              value: "屯門",
              label: "屯門",
            },
            {
              value: "藍地",
              label: "藍地",
            },
          ],
        },
        {
          value: "元朗區",
          label: "元朗區",
          children: [
            {
              value: "洪水橋",
              label: "洪水橋",
            },
            {
              value: "廈村",
              label: "廈村",
            },
            {
              value: "流浮山",
              label: "流浮山",
            },
            {
              value: "天水圍",
              label: "天水圍",
            },
            {
              value: "元朗",
              label: "元朗",
            },
            {
              value: "新田",
              label: "新田",
            },
            {
              value: "落馬洲",
              label: "落馬洲",
            },
            {
              value: "錦田",
              label: "錦田",
            },
            {
              value: "石崗",
              label: "石崗",
            },
            {
              value: "八鄉",
              label: "八鄉",
            },
          ],
        },
        {
          value: "北區",
          label: "北區",
          children: [
            {
              value: "粉嶺",
              label: "粉嶺",
            },
            {
              value: "聯和墟",
              label: "聯和墟",
            },
            {
              value: "上水",
              label: "上水",
            },
            {
              value: "石湖墟",
              label: "石湖墟",
            },
            {
              value: "沙頭角",
              label: "沙頭角",
            },
            {
              value: "鹿頸",
              label: "鹿頸",
            },
            {
              value: "烏蛟騰",
              label: "烏蛟騰",
            },
          ],
        },
        {
          value: "大埔區",
          label: "大埔區",
          children: [
            {
              value: "大埔墟",
              label: "大埔墟",
            },
            {
              value: "大埔",
              label: "大埔",
            },
            {
              value: "大埔滘",
              label: "大埔滘",
            },
            {
              value: "大尾篤",
              label: "大尾篤",
            },
            {
              value: "船灣",
              label: "船灣",
            },
            {
              value: "樟木頭",
              label: "樟木頭",
            },
            {
              value: "企嶺下",
              label: "企嶺下",
            },
          ],
        },
        {
          value: "沙田區",
          label: "沙田區",
          children: [
            {
              value: "大圍",
              label: "大圍",
            },
            {
              value: "沙田",
              label: "沙田",
            },
            {
              value: "火炭",
              label: "火炭",
            },
            {
              value: "馬料水",
              label: "馬料水",
            },
            {
              value: "烏溪沙",
              label: "烏溪沙",
            },
            {
              value: "馬鞍山",
              label: "馬鞍山",
            },
          ],
        },
        {
          value: "西貢區",
          label: "西貢區",
          children: [
            {
              value: "清水灣",
              label: "清水灣",
            },
            {
              value: "西貢",
              label: "西貢",
            },
            {
              value: "大網仔",
              label: "大網仔",
            },
            {
              value: "將軍澳",
              label: "將軍澳",
            },
            {
              value: "坑口",
              label: "坑口",
            },
            {
              value: "調景嶺",
              label: "調景嶺",
            },
            {
              value: "馬游塘",
              label: "馬游塘",
            },
          ],
        },
        {
          value: "離島區",
          label: "離島區",
          children: [
            {
              value: "長洲",
              label: "長洲",
            },
            {
              value: "坪洲",
              label: "坪洲",
            },
            {
              value: "大嶼山",
              label: "大嶼山",
            },
            {
              value: "東涌",
              label: "東涌",
            },
            {
              value: "南丫島",
              label: "南丫島",
            },
          ],
        },
      ],
    },
  ];

  return (
    <Cascader
      options={options}
      // onChange={handleChange}
      placeholder="選擇地區"
      {...props}
    />
  );
};
