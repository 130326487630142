import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Button, Input, InputNumber, Form, Modal } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Row } from "components/lib";
import { Employer } from "../employer-list/list";
import { employerStatus } from "utils/common";

interface TopBarData extends Partial<Employer> {
  creditLimit?: number;
  activeTab?: string;
  tabChange: (key: string) => void;
  onUpdate: (value: Partial<Employer>) => void;
  reload: () => void;
}

export const TopBar = (props: TopBarData) => {
  const navigate = useNavigate();
  const {
    uuid,
    type,
    contactPerson,
    patientName,
    status,
    credit,
    creditLimit,
    activeTab,
    tabChange,
    onUpdate,
    reload,
  } = props;


  const [editCreditLimitForm] = Form.useForm();
  const [showCreditLimitModal, setShowCreditLimitModal] = useState(false);

  const handleCreditLimitModalOpen = () => {
    editCreditLimitForm.setFieldsValue({
      uuid: uuid,
      creditLimit: creditLimit,
      credit: credit,
    });
    setShowCreditLimitModal(true);
  };

  const handleCreditLimitModalCancel = () => {
    setShowCreditLimitModal(false);
  };

  const handleCreditLimitModalSubmit = async (values: any) => {
    await onUpdate(values);
    reload();
    setShowCreditLimitModal(false);
  };

  return (
    <>
      <Container>
        <FixedBar between={true}>
          <BarLeft gap={true}>
            <ArrowLeftOutlined onClick={() => navigate(-1)} />
            <Title>{contactPerson} {patientName && `( ${patientName} )`}</Title>
            <Status>{employerStatus(status)}</Status>
          </BarLeft>
          <BarRight>
            <CreditLimitTitle>Balance:</CreditLimitTitle>
            <CreditLimitText>
              {credit || credit === 0 ? `$${credit}` : "--"}
            </CreditLimitText>
            <CreditLimitTitle>Balance Limit:</CreditLimitTitle>
            <CreditLimitText>
              {creditLimit || creditLimit === 0 ? `$${creditLimit}` : "--"}
            </CreditLimitText>
            <Button type="link" onClick={() => handleCreditLimitModalOpen()}>
              更改
            </Button>
          </BarRight>
        </FixedBar>
        <TabContainer>
          <Tabs activeKey={activeTab} onChange={(key) => tabChange(key)}>
            <Tabs.TabPane tab="工作紀錄" key="1"></Tabs.TabPane>
            <Tabs.TabPane tab="發票紀錄" key="2"></Tabs.TabPane>
            <Tabs.TabPane tab="付款紀錄" key="3"></Tabs.TabPane>
            <Tabs.TabPane tab="Credit Debit" key="4"></Tabs.TabPane>
            <Tabs.TabPane tab="僱主信息" key="5"></Tabs.TabPane>
            <Tabs.TabPane tab="黑名單" key="6"></Tabs.TabPane>
          </Tabs>
        </TabContainer>
      </Container>

      <Modal
        title={"編輯Balance Limit"}
        visible={showCreditLimitModal}
        footer={null}
        onCancel={() => handleCreditLimitModalCancel()}
      >
        <Form
          form={editCreditLimitForm}
          layout="vertical"
          onFinish={handleCreditLimitModalSubmit}
        >
          <Form.Item name="uuid" label="employerId" hidden>
            <Input placeholder="employerId" />
          </Form.Item>

          <Form.Item name="creditLimit" label="Balance Limit">
            <InputNumber placeholder="Balance Limit" style={{ width: 200 }} />
          </Form.Item>

          <Form.Item name="credit" label="Balance">
            <InputNumber placeholder="Balance" style={{ width: 200 }} />
          </Form.Item>

          <Button
            htmlType="button"
            onClick={() => handleCreditLimitModalCancel()}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: 122px;
  padding: 28px 32px;
  background-color: #ffffff;
  position: sticky;
  top: 0px;
  z-index: 999;
`;

const FixedBar = styled(Row)`
  z-index: 1;
`;
const BarLeft = styled(Row)``;
const BarRight = styled(Row)``;

const CreditLimitTitle = styled.div`
  display: inline-block;
  color: #8d939e;
  font-size: 14px;
  margin-left: 10px;
  padding-right: 2px;
`;

const CreditLimitText = styled.div`
  display: inline-block;
  color: #3c3e42;
  font-size: 14px;
  font-weight: bold;
`;

const Title = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
`;

const Status = styled.div`
  background-color: #d1f3f8;
  padding: 5px 10px;
  color: #2498aa;
  font-size: 12px;
  font-weight: bold;
  border-radius: 8px;
`;

const TabContainer = styled.div`
  padding-top: 21px;
`;
