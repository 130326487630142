import React, { useState, useEffect } from "react";
import { useHelperPositions } from "utils/helper-position";
import { Select } from "antd";

export const HelperPositionSelect = (
  props: React.ComponentProps<typeof Select>
) => {
  const { data: helperPositions } = useHelperPositions();

  return (
    <Select placeholder={"幫手職級"} style={{ width: "100%" }} {...props}>
      {(helperPositions?.list || []).map((item) => (
        <Select.Option key={item.uuid} value={item.name}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
};
