import React, { useState } from "react";
import {
  Modal,
  Input,
  Form,
  Select,
  DatePicker,
  Menu,
  Dropdown,
  Button,
  Row,
  Col,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { FormInstance } from "antd/es/form";
import styled from "@emotion/styled";
import { useDebounce, useDocumentTitle } from "utils";
import { Row as LibRow } from "components/lib";
import { Profiler } from "components/profiler";
import PageContainer from "../../../components/layout/PageContainer";

export interface Item {
  [key: string]: number | string;
  uuid: number | string;
  name: string;
  description: string;
  amount: number | string;
}

export interface Category {
  uuid: number | string;
  type: string;
  category: string;
  amount: number | string;
  items: Item[];
}

export const AccountingReportScreen = () => {
  useDocumentTitle("Accounting Report", false);
  const createYearFormRef = React.createRef<FormInstance>();
  const [createYearForm] = Form.useForm();
  const selectYearFormRef = React.createRef<FormInstance>();
  const [selectYearForm] = Form.useForm();
  const [showCreateYearModal, setShowCreateYearModal] = useState(false);
  const [showPreviousYearModal, setShowPreviousYearModal] = useState(false);
  const [showItemModal, setShowItemModal] = useState(false);
  const [modalType, setModalType] = useState("create");

  const [categoryData, setCategoryData] = useState<Category>({
    uuid: "",
    type: "",
    category: "",
    amount: 0,
    items: [],
  });

  const reportYearMenu = (
    <Menu>
      <Menu.Item>
        <div onClick={() => setShowCreateYearModal(true)}>新財報年度</div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => setShowPreviousYearModal(true)}>舊財報年度</div>
      </Menu.Item>
    </Menu>
  );

  const reportItemMenu = (
    <Menu>
      <Menu.Item>
        <div onClick={() => downloadReport("profit_and_loss")}>
          Profit and Loss
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => downloadReport("sales_report")}>Sales Report</div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => downloadReport("aged_receivable")}>
          Aged Receivable
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => downloadReport("general_ledger")}>
          General Ledger
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => downloadReport("trail_balance")}>Trail Balance</div>
      </Menu.Item>
    </Menu>
  );

  const assetData = [
    {
      key: "1",
      id: 1,
      uuid: 1,
      type: "asset",
      category: "現金",
      items: [
        {
          id: 1,
          uuid: 1,
          name: "姐姐租金",
          description: "賣姐姐出去",
          amount: 100,
        },
      ],
      amount: 600,
    },
    {
      key: "2",
      id: 2,
      uuid: 2,
      type: "asset",
      category: "存款",
      items: [],
      amount: 600,
    },
  ];

  const incomeData = [
    {
      key: "1",
      id: 1,
      uuid: 1,
      type: "income",
      category: "租金",
      items: [
        {
          id: 1,
          uuid: 1,
          name: "姐姐租金",
          description: "賣姐姐出去",
          amount: 100,
        },
      ],
      amount: 600,
    },
    {
      key: "2",
      id: 2,
      uuid: 2,
      type: "income",
      category: "租金",
      items: [
        {
          id: 1,
          uuid: 1,
          name: "姐姐租金",
          description: "賣姐姐出去",
          amount: 100,
        },
      ],
      amount: 600,
    },
  ];

  const expenseData = [
    {
      key: "1",
      id: 1,
      uuid: 1,
      type: "expense",
      category: "電費",
      items: [
        {
          id: 1,
          uuid: 1,
          name: "手機充電",
          description: "好多部手機要充電",
          amount: 100,
        },
        {
          id: 2,
          uuid: 2,
          name: "手機充電",
          description: "好多部手機要充電",
          amount: 200,
        },
        {
          id: 3,
          uuid: 3,
          name: "手機充電",
          description: "好多部手機要充電",
          amount: 300,
        },
      ],
      amount: 600,
    },
    {
      key: "2",
      id: 2,
      uuid: 2,
      type: "expense",
      category: "水費",
      items: [
        {
          id: 1,
          uuid: 1,
          name: "姐姐租金",
          description: "賣姐姐出去",
          amount: 100,
        },
      ],
      amount: 600,
    },
  ];

  const downloadReport = (type: string) => {
    console.log("download report " + type);
  };

  const handleAddNewCategoryClick = (type: string) => {
    setModalType("create");
    setCategoryData({
      uuid: "",
      type: type,
      category: "",
      amount: 0,
      items: [],
    });
    setShowItemModal(true);
  };

  const handleCategoryDelete = (uuid: string | number, category: string) => {
    console.log(uuid);

    Modal.confirm({
      title: "Are you sure delete this category?",
      icon: <ExclamationCircleOutlined />,
      content: `Delete ${category} ?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      maskClosable: true,
      onOk() {
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleCategoryEditClick = (data: Category) => {
    setCategoryData(data);
    setModalType("edit");
    setShowItemModal(true);
  };

  const handleDataTypeChange = (value: string) => {
    setCategoryData((prevState) => ({
      ...prevState,
      type: value,
    }));
  };

  const handleDataCategoryChange = (value: string) => {
    setCategoryData((prevState) => ({
      ...prevState,
      category: value,
    }));
  };

  const handleDataChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;

    setCategoryData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleItemDataChange = (
    e: { target: { name: string; value: string } },
    index: number
  ) => {
    const { name, value } = e.target;

    setCategoryData((prevState) => ({
      ...prevState,
      items: prevState.items.map((item, i) => {
        if (i === index) {
          item[name] = value;
        }

        return item;
      }),
    }));
  };

  const onItemModalCancel = () => {
    setShowItemModal(false);
    setCategoryData({
      uuid: "",
      type: "",
      category: "",
      amount: 0,
      items: [],
    });
  };

  const handleAddItem = () => {
    setCategoryData((prevState) => ({
      ...prevState,
      items: prevState.items.concat({
        uuid: "",
        name: "",
        description: "",
        amount: 0,
      }),
    }));
  };

  const handleDeleteItem = (index: number) => {
    setCategoryData((prevState) => ({
      ...prevState,
      items: prevState.items.filter((item, i) => i !== index),
    }));
  };

  const onItemModalSubmit = () => {};

  const onCreateYearModalCancel = () => {
    setShowCreateYearModal(false);
    createYearFormRef.current!.resetFields();
  };

  const onCreateYearModalSubmit = () => {
    setShowCreateYearModal(false);
    createYearFormRef.current!.resetFields();
  };

  const onSelectYearModalCancel = () => {
    setShowPreviousYearModal(false);
    selectYearFormRef.current!.resetFields();
  };

  const onSelectYearModalSubmit = () => {
    setShowPreviousYearModal(false);
    selectYearFormRef.current!.resetFields();
  };

  return (
    <Profiler id={"Accounting Report"}>
      <PageContainer>
        <FixedBar between={true}>
          <BarLeft gap={true}>
            <Title>01/04/2021 - 31/03/2022</Title>
            <Dropdown overlay={reportYearMenu} placement="bottomRight" arrow>
              <Button>其他年度</Button>
            </Dropdown>
          </BarLeft>
          <BarRight>
            <Dropdown overlay={reportItemMenu} placement="bottomRight" arrow>
              <Button type="primary">Download Report</Button>
            </Dropdown>
          </BarRight>
        </FixedBar>

        <ContentContainer>
          <SectionContainer>
            <SectionTitle>Asset</SectionTitle>
            <EditButton>
              <PlusOutlined
                onClick={() => handleAddNewCategoryClick("asset")}
              />
            </EditButton>

            <Row
              style={{
                padding: 6,
                fontWeight: 600,
              }}
            >
              <Col span={4}>Category</Col>
              <Col span={12}>Items</Col>
              <Col span={4}>Amount</Col>
              <Col span={4}>Actions</Col>
            </Row>

            {assetData.map((asset, index) => (
              <Row key={asset.id} style={{ padding: 6 }}>
                <Col span={4}>{asset.category}</Col>
                <Col span={12}>
                  {(asset.items || []).map((item, i) => (
                    <Row key={item.id}>
                      <Col span={6}>{item.name}</Col>
                      <Col span={12}>{item.description}</Col>
                      <Col span={6}>{item.amount}</Col>
                    </Row>
                  ))}
                </Col>
                <Col span={4}>{asset.amount}</Col>
                <Col span={4}>
                  <EditOutlined
                    style={{ marginRight: 10 }}
                    onClick={() => handleCategoryEditClick(asset)}
                  />
                  <DeleteOutlined
                    onClick={() =>
                      handleCategoryDelete(asset.uuid, asset.category)
                    }
                  />
                </Col>
              </Row>
            ))}
          </SectionContainer>

          <SectionContainer style={{ backgroundColor: "#ABE4C7" }}>
            <SectionTitle>Income</SectionTitle>
            <EditButton>
              <PlusOutlined
                onClick={() => handleAddNewCategoryClick("income")}
              />
            </EditButton>

            <Row
              style={{
                backgroundColor: "#00CC66",
                padding: 6,
                fontWeight: 600,
              }}
            >
              <Col span={4}>Category</Col>
              <Col span={12}>Items</Col>
              <Col span={4}>Amount</Col>
              <Col span={4}>Actions</Col>
            </Row>

            {incomeData.map((income, index) => (
              <Row key={income.id} style={{ padding: 6 }}>
                <Col span={4}>{income.category}</Col>
                <Col span={12}>
                  {(income.items || []).map((item, i) => (
                    <Row key={item.id}>
                      <Col span={6}>{item.name}</Col>
                      <Col span={12}>{item.description}</Col>
                      <Col span={6}>{item.amount}</Col>
                    </Row>
                  ))}
                </Col>
                <Col span={4}>{income.amount}</Col>
                <Col span={4}>
                  <EditOutlined
                    style={{ marginRight: 10 }}
                    onClick={() => handleCategoryEditClick(income)}
                  />
                  <DeleteOutlined
                    onClick={() =>
                      handleCategoryDelete(income.uuid, income.category)
                    }
                  />
                </Col>
              </Row>
            ))}
          </SectionContainer>

          <SectionContainer style={{ backgroundColor: "#F7E1D0" }}>
            <SectionTitle>Expense</SectionTitle>
            <EditButton>
              <PlusOutlined
                onClick={() => handleAddNewCategoryClick("expense")}
              />
            </EditButton>

            <Row
              style={{
                backgroundColor: "#F7C096",
                padding: 6,
                fontWeight: 600,
              }}
            >
              <Col span={4}>Category</Col>
              <Col span={12}>Items</Col>
              <Col span={4}>Amount</Col>
              <Col span={4}>Actions</Col>
            </Row>

            {expenseData.map((expense, index) => (
              <Row key={expense.id} style={{ padding: 6 }}>
                <Col span={4}>{expense.category}</Col>
                <Col span={12}>
                  {(expense.items || []).map((item, i) => (
                    <Row key={item.id}>
                      <Col span={6}>{item.name}</Col>
                      <Col span={12}>{item.description}</Col>
                      <Col span={6}>{item.amount}</Col>
                    </Row>
                  ))}
                </Col>
                <Col span={4}>{expense.amount}</Col>
                <Col span={4}>
                  <EditOutlined
                    style={{ marginRight: 10 }}
                    onClick={() => handleCategoryEditClick(expense)}
                  />
                  <DeleteOutlined
                    onClick={() =>
                      handleCategoryDelete(expense.uuid, expense.category)
                    }
                  />
                </Col>
              </Row>
            ))}
          </SectionContainer>
        </ContentContainer>
      </PageContainer>

      <Modal
        title={modalType === "create" ? "Add Category" : "Edit Category"}
        visible={showItemModal}
        footer={null}
        onCancel={onItemModalCancel}
        width={1000}
        // centered
      >
        <FormTitle>*Type</FormTitle>
        <Select
          placeholder="Type"
          value={categoryData.type}
          onChange={handleDataTypeChange}
          style={{ width: "100%" }}
        >
          <Select.Option value="income">Income</Select.Option>
          <Select.Option value="expense">Expense</Select.Option>
          <Select.Option value="asset">Asset</Select.Option>
        </Select>
        <FormTitle style={{ paddingTop: 20 }}>*Category</FormTitle>
        <Select
          placeholder="Category"
          value={categoryData.category}
          onChange={handleDataCategoryChange}
          style={{ width: "100%" }}
        >
          <Select.Option value="income">租金</Select.Option>
          <Select.Option value="expense">電費</Select.Option>
        </Select>
        <FormTitle style={{ paddingTop: 20 }}>*Amount</FormTitle>
        <Input
          placeholder="Amount"
          name="amount"
          value={categoryData.amount}
          onChange={handleDataChange}
        />
        <FormTitle style={{ paddingTop: 20 }}>Items</FormTitle>
        <Row
          style={{
            backgroundColor: "#EEEEEE",
            // padding: 6,
            // fontWeight: 600,
          }}
          gutter={[16, 4]}
        >
          <Col span={6}>Name</Col>
          <Col span={12}>Description</Col>
          <Col span={4}>Amount</Col>
          <Col span={2}>Action</Col>
        </Row>

        {(categoryData.items || []).map((item, index) => (
          <Row
            key={index}
            gutter={[16, 4]}
            // style={{
            //   backgroundColor: "#EEEEEE",
            // }}
          >
            <Col span={6}>
              <Input
                placeholder="Name"
                name="name"
                value={item.name}
                onChange={(e) => handleItemDataChange(e, index)}
              />
            </Col>
            <Col span={12}>
              <Input
                placeholder="Description"
                name="description"
                value={item.description}
                onChange={(e) => handleItemDataChange(e, index)}
              />
            </Col>
            <Col span={4}>
              <Input
                placeholder="Amount"
                name="amount"
                value={item.amount}
                onChange={(e) => handleItemDataChange(e, index)}
              />
            </Col>
            <Col span={2}>
              <DeleteOutlined onClick={() => handleDeleteItem(index)} />
            </Col>
          </Row>
        ))}

        <Button
          onClick={handleAddItem}
          type="dashed"
          style={{ marginTop: 10, marginBottom: 30, width: "100%" }}
        >
          + Add Item
        </Button>

        <Button onClick={onItemModalCancel} style={{ marginRight: 20 }}>
          Cancel
        </Button>
        <Button type="primary" onClick={() => onItemModalSubmit()}>
          Submit
        </Button>
      </Modal>

      <Modal
        title={"創建新年度"}
        visible={showCreateYearModal}
        footer={null}
        onCancel={onCreateYearModalCancel}
      >
        <Form
          form={createYearForm}
          ref={createYearFormRef}
          layout="vertical"
          onFinish={onCreateYearModalSubmit}
        >
          <Form.Item name="startDate" label="Start Date" required>
            <DatePicker placeholder="Start Date" />
          </Form.Item>
          <Form.Item name="endDate" label="End Date" required>
            <DatePicker placeholder="End Date" />
          </Form.Item>
          <Button
            htmlType="button"
            onClick={onCreateYearModalCancel}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title={"選擇舊新年度"}
        visible={showPreviousYearModal}
        footer={null}
        onCancel={onSelectYearModalCancel}
      >
        <Form
          form={selectYearForm}
          ref={selectYearFormRef}
          layout="vertical"
          onFinish={onSelectYearModalSubmit}
        >
          <Form.Item name="previousYear" label="選擇舊新年度" required>
            <Select placeholder="選擇舊新年度">
              <Select.Option value="1">01/04/2020 - 31/03/2021</Select.Option>
              <Select.Option value="2">01/04/2019 - 31/03/2020</Select.Option>
            </Select>
          </Form.Item>
          <Button
            htmlType="button"
            onClick={onSelectYearModalCancel}
            style={{ marginRight: 20 }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>
    </Profiler>
  );
};

const FixedBar = styled(LibRow)`
  z-index: 1;
  padding: 20px;
  background-color: #ffffff;
`;
const BarLeft = styled(LibRow)``;
const BarRight = styled(LibRow)``;

const Title = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
`;

const ContentContainer = styled.div`
  margin-top: 3px;
  // padding: 3.2rem;
  padding: 2rem;
  flex-direction: column;
  position: relative;
  overflow: auto;
`;

const SectionContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  position: relative;
`;

const SectionTitle = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 6px;
`;

const EditButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const FormTitle = styled.div`
  //font-size: 16px;
`;

AccountingReportScreen.whyDidYouRender = false;
