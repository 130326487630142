import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import moment from "moment";
import {
  Button, Checkbox,
  Col,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Modal,
  Row,
  Select,
  Table,
  Upload,
} from "antd";
import {
  DownOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {ColumnsType} from "antd/es/table";
import styled from "@emotion/styled";
import {Row as LibRow} from "components/lib";
import {EmployerInvoiceRecord} from "types/employer-invoice-record";
import { CommonList } from "components/listing/common-list";
import { InvoiceExpandedTable } from "components/listing/invoice-expanded-table";
import { HelperAttendance } from "types/helper-attendance";
import {
  useAddEmployerInvoiceAttachments,
  useAddEmployerNormalInvoice,
  useAddEmployerPayment,
  useAddEmployerTaskInvoice,
  useEditEmployerInvoice,
  useEmployerInvoiceDetail,
  useEmployerInvoices,
} from "utils/employer";
import {
  getBase64,
  useConvertToUploadInvoiceAttachmentFileList,
  useDebounce,
} from "utils";
import {dateDisplay} from "utils/moment-util";
import {invoiceRecordStatus} from "utils/common";
import {Employer} from "screens/employer/employer-list/list";
import {Attachment} from "../../helper/helper-details/certifications";
import {UploadFile} from "antd/es/upload/interface";
import {RcFile} from "antd/lib/upload";
import {useDeleteHelperCertAttachments} from "utils/helper";
import {useCreateCreditDebitNote} from "utils/credit-debit-note";
import {CreditDebitNote} from "types/credit-debit-note";
import {useReactToPrint} from "react-to-print";
import {ComponentToPrint} from "components/component-to-print";
import useWindowSize from "hooks/useWindowSize";

const pageStyle = `
  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
    
    footer {
      position: fixed;
      bottom: 0;
    }
  }

  @page {
    size: auto;
    margin: 0mm; 
    margin-top: 5mm;
    margin-bottom: 5mm;
    
    // margin-left: 0mm;
    // margin-right: 0mm;
  }
`;

export interface InvoiceRecord {
  key?: number | string;
  uuid: string;
  invoiceNo: string;
  paymentStatus: string;
  paymentRecordNo: string;
  totalPrice: number;
  type: number;
  invoiceDate: string;
  dueDate: string;
  periodStartDate: string;
  periodEndDate: string;
  remarks: string;
  adminName: string;
  attachments?: Attachment[];
  fileList?: UploadFile<RcFile>[];
}

export interface InvoiceOtherData {
  fixedInvoiceItems?: Array<{
    isEditable?: number;
    itemName: string;
    quantity: number;
    cost: number;
    subTotal: number;
  }>;
  invoiceItems?: Array<{
    isEditable?: number;
    itemName: string;
    quantity: number;
    cost: number;
    subTotal: number;
  }>;
  discountItems?: Array<{
    discountItemType: number;
    discountType: number;
    quantity: number;
  }>;
  employerName?: string;
  employerAddress?: string;
}

export interface PaymentData {
  // invoiceId: string;
  // invoiceNo: string;
  // paymentPrice: number;
  // paymentDate: string;
  // paymentMethod: string;
  // remarks: string;

  employerUuid: string;
  invoiceUuid: string;
  invoiceNo: string;
  total: number;
}

export const InvoiceRecords = (props: {
  employerUuid: string;
  employerData: Employer | null;
}) => {
  const {id} = useParams();
  const {employerUuid, employerData} = props;
  const { windowHeight } = useWindowSize();
  const printComponentRef = useRef(null);
  const [param, setParam] = useState({uuid: id});
  const [pageObject, setPageObject] = useState({
    pageNumber: 1,
    pageSize: 25,
  });
  const {
    data: invoicesData,
    isLoading: invoicesLoading,
    retry: retryInvoicesData,
  } = useEmployerInvoices(useDebounce(param, 200), pageObject);

  const [invoiceDetailsForm] = Form.useForm();
  const [createInvoiceForm] = Form.useForm();
  const [showInvoiceDetailsModal, setShowInvoiceDetailsModal] = useState(false);

  const [createPaymentForm] = Form.useForm();
  const [showCreatePaymentModal, setShowCreatePaymentModal] = useState(false);
  const [createNoteForm] = Form.useForm();
  const [showCreateNoteModal, setShowCreateNoteModal] = useState(false);
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
  const {
    mutate: uploadInvoiceAttachments,
  } = useAddEmployerInvoiceAttachments();
  const {mutate: deleteFile} = useDeleteHelperCertAttachments();

  const [fileList, setFileList] = useState<any>();
  const [printType, setPrintType] = useState<string>();
  const [attachments, setAttachments] = useState<Attachment[]>();

  const {convertAttachment} = useConvertToUploadInvoiceAttachmentFileList();

  const handlePrint = useReactToPrint({
    content: () => {
      return printComponentRef.current;
    },
    // copyStyles:false,
    pageStyle: pageStyle,
  });

  const [fileState, setFileState] = useState({
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
  });

  const [invoiceDetails, setInvoiceDetails] = useState<EmployerInvoiceRecord & InvoiceOtherData>({
    employerUuid: "",
    uuid: "",
    invoiceNo: "",
    status: 0,
    total: 0,
    type: 0,
    invoiceDate: "",
    dueDate: "",
    settleFrom: "",
    settleTo: "",
    remark: "",
    invoiceItems: [],
    helperAttendances: [],
    fixedInvoiceItems: [],
    employerName: "",
    employerAddress: "",
  });

  const [createPaymentData, setCreatePaymentData] = useState<PaymentData>({
    employerUuid: "",
    invoiceUuid: "",
    invoiceNo: "",
    total: 0,
  });

  const [creditDebitNoteDetails, setCreditDebitNoteDetails] = useState<Partial<CreditDebitNote>>({
    type: 0,
    invoiceDate: "",
    dueDate: "",
    remark: "",
    creditDebitNoteItems: [],
    total: 0,
  });

  const {
    mutate: createEmployerInvoice,
    isLoading: createInvoiceLoading,
    isSuccess: isCreateInvoiceSuccess,
  } = useAddEmployerNormalInvoice();

  const {
    mutate: createEmployerTaskInvoice,
    isLoading: createTaskInvoiceLoading,
    isSuccess: isCreateTaskInvoiceSuccess,
  } = useAddEmployerTaskInvoice();

  const {
    mutate: getEmployerInvoiceDetail,
    data: employerInvoiceDetail,
  } = useEmployerInvoiceDetail();

  const {
    mutate: editEmployerInvoice,
    isSuccess: isEditInvoiceSuccess,
  } = useEditEmployerInvoice();

  const {
    mutate: createEmployerPayment,
    isLoading: createPaymentLoading,
    isSuccess: isCreatePaymentSuccess,
  } = useAddEmployerPayment();

  const {
    mutate: createCreditDebitNote,
    isLoading: createCreditDebitNoteLoading,
    isSuccess: isCreateCreditDebitNoteSuccess,
  } = useCreateCreditDebitNote();

  const handleOpenCreateJobInvoiceModal = () => {
    const invoiceData = {
      type: 1,
      employerUuid: "",
      uuid: "",
      invoiceNo: "",
      status: 0,
      total: 0,
      invoiceDate: "",
      dueDate: "",
      settleFrom: "",
      settleTo: "",
      remark: "",
      invoiceItems: [],
      helperAttendances: [],
      employerName: employerData?.companyName || employerData?.contactPerson,
      employerAddress: employerData?.address,
    };

    setInvoiceDetails(invoiceData);
    invoiceDetailsForm.setFieldsValue(invoiceData);
    setShowInvoiceDetailsModal(true);
  };

  useEffect(() => {
    if (isCreateInvoiceSuccess) {
      retryInvoicesData();
      setShowInvoiceDetailsModal(false);
    }
  }, [isCreateInvoiceSuccess]);

  useEffect(() => {
    if (isCreatePaymentSuccess) {
      retryInvoicesData();
      setShowCreatePaymentModal(false);
    }
  }, [isCreatePaymentSuccess]);

  useEffect(() => {
    if (isEditInvoiceSuccess) {
      retryInvoicesData();
      setShowInvoiceDetailsModal(false);
    }
  }, [isEditInvoiceSuccess]);

  useEffect(() => {
    const tempFixedInvoiceItems: Array<{
      isEditable?: number;
      itemName: string;
      quantity: number;
      cost: number;
      subTotal: number;
    }> = [];

    const tempInvoiceItems: Array<{
      isEditable?: number;
      itemName: string;
      quantity: number;
      cost: number;
      subTotal: number;
    }> = [];

    if (employerInvoiceDetail?.invoiceItems) {
      for (let i = 0; i < employerInvoiceDetail?.invoiceItems?.length; i++) {
        if (employerInvoiceDetail?.invoiceItems?.[i]?.isEditable === 1) {
          tempInvoiceItems.push(employerInvoiceDetail?.invoiceItems?.[i]);
        } else {
          tempFixedInvoiceItems.push(employerInvoiceDetail?.invoiceItems?.[i]);
        }
      }
    }

    const tempInvoiceDetails = {
      employerUuid: employerInvoiceDetail?.employer?.uuid || "",
      uuid: employerInvoiceDetail?.uuid || "",
      invoiceNo: employerInvoiceDetail?.invoiceNo || "",
      status: employerInvoiceDetail?.status || 0,
      total: employerInvoiceDetail?.total || 0,
      type: employerInvoiceDetail?.type || 0,
      invoiceDate: employerInvoiceDetail?.invoiceDate
        ? moment(employerInvoiceDetail?.invoiceDate)
        : "",
      dueDate: employerInvoiceDetail?.dueDate
        ? moment(employerInvoiceDetail?.dueDate)
        : "",
      settleFrom: employerInvoiceDetail?.settleFrom
        ? moment(employerInvoiceDetail?.settleFrom)
        : "",
      settleTo: employerInvoiceDetail?.settleTo
        ? moment(employerInvoiceDetail?.settleTo)
        : "",
      remark: employerInvoiceDetail?.remark || "",
      invoiceItems: tempInvoiceItems,
      fixedInvoiceItems: tempFixedInvoiceItems,
      helperAttendances: employerInvoiceDetail?.helperAttendances || [],
      employerName:
        employerInvoiceDetail?.employer?.companyName ||
        employerInvoiceDetail?.employer?.contactPerson ||
        "",
      employerAddress: employerInvoiceDetail?.employer?.address || "",
      contactNumber: employerInvoiceDetail?.employer?.contactNumber || "",
    };

    setInvoiceDetails(tempInvoiceDetails);
    invoiceDetailsForm.setFieldsValue(tempInvoiceDetails);
  }, [employerInvoiceDetail]);

  useEffect(() => {
    if (isCreateCreditDebitNoteSuccess) {
      setShowCreateNoteModal(false);
    }
  }, [isCreateCreditDebitNoteSuccess]);

  const columns: ColumnsType<EmployerInvoiceRecord & InvoiceOtherData> = [
    {
      title: "發票編號",
      dataIndex: "invoiceNo",
    },
    {
      title: "付款狀態",
      dataIndex: "status",
      render: (text, record) => invoiceRecordStatus(text),
    },
    // {
    //   title: "付款紀錄編號",
    //   dataIndex: "paymentNo",
    // },
    {
      title: "總額",
      dataIndex: "total",
      render: (text, record) => `$${text}`,
    },
    {
      title: "付款日期",
      dataIndex: "paymentDate",
      render: (text, record) => {
        if (record.payments && record.payments.length > 0) {
          return dateDisplay(record.payments[record.payments?.length - 1].settleDate || "") + ` (${record.payments?.length} 次)`
        }
      },
    },
    {
      title: "已付款",
      dataIndex: "paid",
      render: (text, record) => {
        let paidAmount = 0;

        if (record?.payments && record?.payments.length > 0) {
          for (let i = 0; i < record?.payments.length; i++) {
            paidAmount += record?.payments[i].amount;
          }
        }

        return record.total > paidAmount ? (
          <span style={{color: "red"}}>${paidAmount}</span>
        ) : (
          <span>${paidAmount}</span>
        );
      },
    },
    {
      title: "發票日期",
      dataIndex: "invoiceDate",
      render: (text, record) => dateDisplay(text),
    },
    {
      title: "截止日期",
      dataIndex: "dueDate",
      render: (text, record) => dateDisplay(text),
    },
    {
      title: "操作",
      dataIndex: "",
      render: (text, record) => {
        return (
          <>
            {record.status !== 3 && (
              <>
                <Button
                  type="link"
                  style={{padding: 0, marginRight: 10}}
                  onClick={() => handleDeleteInvoice(record)}
                >
                  刪除
                </Button>
                <Button
                  type="link"
                  style={{padding: 0, marginRight: 10}}
                  onClick={() => onEditInvoiceClick(record)}
                >
                  {record.status === 0 ? "編輯" : "詳情"}
                </Button>
                <Button
                  type="link"
                  style={{padding: 0, marginRight: 10}}
                  onClick={() => handlePaymentClick(record)}
                >
                  付款
                </Button>
                <Button
                  type="link"
                  style={{padding: 0, marginRight: 10}}
                  onClick={() => handleNoteClick(record)}
                >
                  Credit/Debit
                </Button>
                <Dropdown overlay={() => menu(record)}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    列印 <DownOutlined/>
                  </a>
                </Dropdown>
              </>
            )}
          </>
        );
      },
    },
  ];

  const menu = (record: any) => {
    return (
      <Menu>
        <Menu.Item key="1">
          <Button
            type="link"
            style={{padding: 0}}
            onClick={() => onPrintInvoiceClick(record, "INVOICE")}
          >
            列印發票
          </Button>
        </Menu.Item>
        <Menu.Item key="2">
          <Button
            type="link"
            style={{padding: 0}}
            onClick={() => onPrintInvoiceClick(record, "RECEIPT")}
          >
            列印收據
          </Button>
        </Menu.Item>
        <Menu.Item key="2">
          <Button
            type="link"
            style={{padding: 0}}
            onClick={() => onPrintInvoiceClick(record, "QUOTATION")}
          >
            列印Quotation
          </Button>
        </Menu.Item>
      </Menu>
    );
  };

  const handlePaymentClick = (
    invoice: EmployerInvoiceRecord & InvoiceOtherData
  ) => {
    setCreatePaymentData({
      employerUuid: invoice.employerUuid,
      invoiceUuid: invoice.uuid,
      invoiceNo: invoice.invoiceNo,
      total: invoice.total,
    });

    createPaymentForm.setFieldsValue({
      employerUuid: invoice.employerUuid,
      invoiceUuid: invoice.uuid,
      invoiceNo: invoice.invoiceNo,
      total: invoice.total,

      method: null,
      amount: null,
      settleDate: null,
      referenceNo: "",
      remark: "",
      attachments: [],
    });
    setShowCreatePaymentModal(true);
  };

  const onCreatePaymentModalCancel = () => {
    setShowCreatePaymentModal(false);
    setCreatePaymentData({
      employerUuid: "",
      invoiceUuid: "",
      invoiceNo: "",
      total: 0,
    });
  };

  const onCreatePaymentModalSubmit = async (values: any) => {
    await createEmployerPayment(
      {
        method: values.method,
        amount: values.amount,
        settleDate: moment(values.settleDate).format("YYYY-MM-DD"),
        referenceNo: values.referenceNo,
        remark: values.remark,
        invoiceUuid: values.invoiceUuid,
        attachments: attachments?.map((item) => item.uuid) || [],
      },
      employerUuid
    );
  };

  const handleNoteClick = async (
    invoice: EmployerInvoiceRecord & InvoiceOtherData
  ) => {
    createNoteForm.setFieldsValue({
      type: 0,
      invoiceDate: "",
      dueDate: "",
      remark: "",
      creditDebitNoteItems: [],
      employerUuid: invoice.employerUuid,
      // helperUuid: "",
      invoiceUuid: invoice.uuid,
      // salaryRecordUuid: ""
    });
    setShowCreateNoteModal(true);
  };

  const onCreateNoteModalCancel = () => {
    setShowCreateNoteModal(false);
    setCreditDebitNoteDetails({
      type: 0,
      invoiceDate: "",
      dueDate: "",
      remark: "",
      creditDebitNoteItems: [],
    });
  };

  const onCreateNoteModalSubmit = async (values: any) => {
    await createCreditDebitNote({
      ...values,
      invoiceDate: moment(values.invoiceDate).format("YYYY-MM-DD"),
      dueDate: moment(values.dueDate).format("YYYY-MM-DD"),
    });

    await setShowCreateNoteModal(false)
  };

  const onEditInvoiceClick = async (
    invoice: EmployerInvoiceRecord & InvoiceOtherData
  ) => {
    await getEmployerInvoiceDetail(employerUuid, invoice.uuid);
    setShowInvoiceDetailsModal(true);
  };

  const onPrintInvoiceClick = async (
    invoice: EmployerInvoiceRecord & InvoiceOtherData,
    type: string
  ) => {
    await setPrintType(type);
    await getEmployerInvoiceDetail(employerUuid, invoice.uuid);
    await handlePrint();
  };

  const onInvoiceDetailsModalCancel = () => {
    setShowInvoiceDetailsModal(false);
  };

  const handleDeleteInvoice = async (
    invoice: EmployerInvoiceRecord & InvoiceOtherData
  ) => {
    await editEmployerInvoice({status: 3}, employerUuid, invoice.uuid);
  };

  const onInvoiceDetailsModalSubmit = async (values: any) => {
    let tempInvoiceItems = invoiceDetails?.fixedInvoiceItems
      ? invoiceDetails?.fixedInvoiceItems?.concat(values.invoiceItems)
      : values.invoiceItems;

    for (let invoiceItem of tempInvoiceItems) {
      invoiceItem.isEditable =
        invoiceItem.isEditable === undefined || invoiceItem.isEditable === 1
          ? true
          : false;
      delete invoiceItem.id;
      delete invoiceItem.uuid;
    }

    const submitData = {
      type: 1,
      invoiceDate: moment(values.invoiceDate).format("YYYY-MM-DD"),
      dueDate: moment(values.dueDate).format("YYYY-MM-DD"),
      remark: values.remark,
      invoiceItems: tempInvoiceItems,
      total: invoiceDetails.total,
    };

    if (invoiceDetails.uuid) {
      await editEmployerInvoice(submitData, employerUuid, invoiceDetails.uuid);
    } else {
      await createEmployerInvoice(submitData, employerUuid);
    }
  };

  const uploadCert = async (options: any) => {
    const {onSuccess, onError, file, onProgress} = options;

    const formData = new FormData();
    formData.append("image", file);

    try {
      const res: Attachment = await uploadInvoiceAttachments(formData);

      const tempAttachments: Attachment[] = [...(attachments || [])];

      tempAttachments?.push({
        fileName: res.fileName,
        fileSize: res.fileSize,
        name: res.name,
        type: res.type,
        uuid: res.uuid,
      });

      const tempFileList = await convertAttachment(tempAttachments || []);

      await setAttachments(tempAttachments);
      await setFileList(tempFileList);

      // createPaymentForm.setFieldsValue(tempData)

      onSuccess("Ok");
    } catch (err) {
      const error = new Error("Some error");
      onError({err});
    }
  };

  const uploadMaterialProps: any = {
    name: "file",
    multiple: true,
    // showUploadList: {
    //   showRemoveIcon: ["master", "admin", "clerk"].includes(
    //       getAuthority().toString()
    //   ),
    // },
    // fileList,
    customRequest: uploadCert,
    onChange(info: { file: { status: any; uid: any } }) {
      const {status, uid} = info.file;
      if (status === "removed") {
        deleteFile({uuid: uid});
      }
    },
    onRemove: (file: { url: any; name: any }) => {
      return new Promise((resolve, reject) => {
        if (!file.url) {
          return resolve(false);
        }

        const isConfirm = window.confirm(`確定刪除 ${file.name} ？`);

        if (isConfirm) {
          resolve(true);
        }
      });
    },
  };

  const onCreateInvoiceModalCancel = () => {
    setShowCreateInvoiceModal(false);
  };

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setFileState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  const onCreateInvoiceModalSubmit = async (values: any) => {
    const settleFrom = moment(values?.dateRange?.[0]).format("YYYY-MM-DD");
    const settleTo = moment(values?.dateRange?.[1]).format("YYYY-MM-DD");
    await createEmployerTaskInvoice(
      {
        settleFrom,
        settleTo,
      },
      employerUuid
    );

    // retry();
    await retryInvoicesData();
    await setShowCreateInvoiceModal(false);
  };

  const onCreateInvoiceModalOpen = () => {
    createInvoiceForm.setFieldsValue({
      dateRange: [null, null],
    });
    setShowCreateInvoiceModal(true);
  };

  return (
    <div>
      <LibRow marginBottom={2} between={true}>
        <div></div>
        <div>
          <Button
            type="dashed"
            onClick={() => handleOpenCreateJobInvoiceModal()}
            style={{marginRight: 10}}
          >
            ＋創建普通發票
          </Button>
          <Button type="primary" onClick={() => onCreateInvoiceModalOpen()}>
            ＋創建工作發票
          </Button>
        </div>
      </LibRow>
      <div style={{display: "none"}}>
        <ComponentToPrint
          ref={printComponentRef}
          invoiceDetails={employerInvoiceDetail || null}
          printType={printType}
        />
      </div>

      <CommonList
        tableProps={{
          loading: invoicesLoading,
          columns,
          dataSource: invoicesData?.list || [],
          expandable: {
            expandedRowRender: (record: {
              helperAttendances: HelperAttendance[];
            }) => InvoiceExpandedTable(record),
          },
          pagination: false,
          rowKey: (record) => record.uuid,
          scroll: {
            y: windowHeight - 320
          }
        }}
        paginationProps={{
          pageSize: pageObject.pageSize,
          current: pageObject.pageNumber,
          total: invoicesData?.total || 0,
          onChange: (page, pageSize) => {
            setPageObject({
              pageSize,
              pageNumber: page,
            });
          }
        }}
      />

      <Modal
        title={invoiceDetails.uuid ? "編輯發票記錄" : "創建普通發票"}
        visible={showInvoiceDetailsModal}
        footer={null}
        onCancel={onInvoiceDetailsModalCancel}
        width={1000}
      >
        <Form
          form={invoiceDetailsForm}
          layout="vertical"
          initialValues={invoiceDetails}
          onFinish={onInvoiceDetailsModalSubmit}
          onValuesChange={(changedValues, allValues) => {
            if (Object.keys(changedValues)[0] === "invoiceItems") {
              let total = 0;
              let subTotal = 0;

              if (
                invoiceDetails.fixedInvoiceItems &&
                invoiceDetails.fixedInvoiceItems.length > 0
              ) {
                for (
                  let i = 0;
                  i < invoiceDetails.fixedInvoiceItems.length;
                  i++
                ) {
                  subTotal =
                    invoiceDetails.fixedInvoiceItems[i]?.cost &&
                    invoiceDetails.fixedInvoiceItems[i]?.quantity
                      ? invoiceDetails.fixedInvoiceItems[i]?.cost *
                      invoiceDetails.fixedInvoiceItems[i]?.quantity
                      : 0;
                  total = total + subTotal;
                }
              }

              if (allValues.invoiceItems && allValues.invoiceItems.length > 0) {
                const newInvoiceItems = [];

                for (let i = 0; i < allValues.invoiceItems.length; i++) {
                  subTotal =
                    allValues.invoiceItems[i]?.cost &&
                    allValues.invoiceItems[i]?.quantity
                      ? allValues.invoiceItems[i]?.cost *
                      allValues.invoiceItems[i]?.quantity
                      : 0;
                  total = total + subTotal;
                  newInvoiceItems.push({
                    itemName: allValues.invoiceItems[i]?.itemName,
                    quantity: allValues.invoiceItems[i]?.quantity,
                    cost: allValues.invoiceItems[i]?.cost,
                    subTotal: subTotal,
                  });
                }

                invoiceDetailsForm.setFieldsValue({
                  invoiceItems: newInvoiceItems,
                });
                setInvoiceDetails((prevState) => ({
                  ...prevState,
                  total: total,
                }));
              } else {
                setInvoiceDetails((prevState) => ({
                  ...prevState,
                  total: 0,
                }));
              }
            }
          }}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid"/>
          </Form.Item>

          {invoiceDetails.uuid && (
            <Row style={{marginBottom: 10}}>
              <Col span={6}>
                <EditInvoiceNo>{invoiceDetails.invoiceNo}</EditInvoiceNo>
              </Col>
              <Col span={8}>
                {invoiceDetails.type === 0 && (
                  <>
                    結算時段: {invoiceDetails.settleFrom} -{" "}
                    {invoiceDetails.settleTo}
                  </>
                )}
              </Col>
              <Col span={5}>
                {/* 發票日期: {dateDisplay(invoiceDetails.invoiceDate)} */}
              </Col>
              <Col span={5}>
                付款狀態: {invoiceRecordStatus(invoiceDetails.status)}
              </Col>
            </Row>
          )}

          <Row>
            <Col span={13}>
              <EditInvoiceEmployerContainer>
                <EditInvoiceEmployerName>
                  {invoiceDetails?.employerName}
                </EditInvoiceEmployerName>
                <EditInvoiceEmployerAddress>
                  {invoiceDetails.employerAddress}
                </EditInvoiceEmployerAddress>
              </EditInvoiceEmployerContainer>
            </Col>
            <Col span={1}/>
            <Col span={5}>
              <Form.Item
                name="invoiceDate"
                label="發票日期"
                rules={[{required: true, message: "請輸入發票日期"}]}
              >
                <DatePicker
                  placeholder="發票日期"
                  disabled={invoiceDetails.status !== 0}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="dueDate"
                label="付款過期日"
                rules={[{required: true, message: "請輸入付款過期日"}]}
              >
                <DatePicker
                  placeholder="付款過期日"
                  disabled={
                    invoiceDetails.status === 1 || invoiceDetails.status === 3
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Divider/>
            </Col>
          </Row>

          <InvoiceDetailSectionTitle>項目</InvoiceDetailSectionTitle>

          <Row
            gutter={[16, 4]}
            style={{backgroundColor: "#E7ECFF", marginBottom: 10}}
          >
            <Col span={9}>項目</Col>
            <Col span={4}>數量</Col>
            <Col span={5}>價錢</Col>
            <Col span={5}>總額</Col>
            <Col span={1}></Col>
          </Row>

          {(invoiceDetails.fixedInvoiceItems || []).map((item, index) => (
            <Row key={index} gutter={[16, 4]}>
              <Col span={9}>{item.itemName}</Col>
              <Col span={4}>{item.quantity}</Col>
              <Col span={5}>{item.cost}</Col>
              <Col span={5}>{item.subTotal}</Col>
              <Col span={1}></Col>
            </Row>
          ))}

          <Form.List name="invoiceItems">
            {(fields, {add, remove}) => (
              <>
                {fields.map(({key, name, ...restField}) => (
                  <Row key={key} gutter={[16, 4]}>
                    <Col span={9}>
                      <Form.Item
                        {...restField}
                        name={[name, "itemName"]}
                        rules={[{required: true, message: "請輸入項目"}]}
                      >
                        <Input
                          placeholder="項目"
                          disabled={invoiceDetails.status !== 0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, "quantity"]}
                        rules={[{required: true, message: "請輸入數量"}]}
                      >
                        <InputNumber
                          placeholder="數量"
                          style={{width: 120}}
                          disabled={invoiceDetails.status !== 0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        {...restField}
                        name={[name, "cost"]}
                        rules={[{required: true, message: "請輸入價錢"}]}
                      >
                        <InputNumber
                          placeholder="價錢"
                          style={{width: 120}}
                          disabled={invoiceDetails.status !== 0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item {...restField} name={[name, "subTotal"]}>
                        <Input
                          placeholder="總額"
                          style={{width: 140}}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <MinusCircleOutlined
                        onClick={() =>
                          invoiceDetails.status !== 0 ? "" : remove(name)
                        }
                      />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined/>}
                    disabled={invoiceDetails.status !== 0}
                  >
                    增加項目
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Row>
            <Col span={24}>
              <Divider/>
            </Col>
          </Row>

          <Row gutter={[16, 4]}>
            <Col span={17}></Col>
            <Col span={2}>
              <EditInvoiceTotalPriceTitle>總額</EditInvoiceTotalPriceTitle>
            </Col>
            <Col span={5}>
              <EditInvoiceTotalPrice>
                {invoiceDetails.total}
              </EditInvoiceTotalPrice>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item name="remark" label="備註">
                <Input
                  placeholder="備註"
                  disabled={invoiceDetails.status === 1}
                />
              </Form.Item>
            </Col>
          </Row>

          {invoiceDetails.status !== 1 && (
            <>
              <Button
                htmlType="button"
                onClick={onInvoiceDetailsModalCancel}
                style={{marginRight: 20}}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={createInvoiceLoading}
              >
                Submit
              </Button>
            </>
          )}
        </Form>
      </Modal>

      <Drawer
        title="創建付款記錄"
        placement="right"
        onClose={onCreatePaymentModalCancel}
        visible={showCreatePaymentModal}
        width={376}
      >
        <Form
          form={createPaymentForm}
          layout="vertical"
          // initialValues={createPaymentData}
          onFinish={onCreatePaymentModalSubmit}
        >
          <DrawerContent>
            <DrawerRow>
              <DrawerRowTitle>
                發票編號: {createPaymentData.invoiceNo}
              </DrawerRowTitle>
            </DrawerRow>
            <DrawerRow>
              <DrawerRowTitle>
                發票金額: ${createPaymentData.total}
              </DrawerRowTitle>
            </DrawerRow>

            <Form.Item name="invoiceUuid" label="invoiceUuid" hidden>
              <Input placeholder="invoiceUuid"/>
            </Form.Item>
            <Form.Item
              name="method"
              label="付款方式"
              // rules={[{ required: true, message: "請輸入" }]}
            >
              <Select placeholder="付款方式">
                <Select.Option value={0}>支票</Select.Option>
                <Select.Option value={1}>銀行過數</Select.Option>
                <Select.Option value={2}>FPS</Select.Option>
                <Select.Option value={3}>WeChat pay</Select.Option>
                <Select.Option value={4}>Ali pay</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="referenceNo" label="參考編號">
              <Input placeholder="參考編號"/>
            </Form.Item>

            <Form.Item name="amount" label="付款金額">
              <InputNumber placeholder="付款金額" style={{width: 200}}/>
            </Form.Item>

            <Form.Item
              name="settleDate"
              label="付款日"
              rules={[{required: true, message: "請輸入付款日"}]}
            >
              <DatePicker placeholder="付款日"/>
            </Form.Item>

            <Form.Item name="remark" label="備注">
              <Input.TextArea
                autoSize={{minRows: 3, maxRows: 5}}
                placeholder="備注"
              />
            </Form.Item>

            {/* <DrawerImageTitle>參考圖片</DrawerImageTitle>
            <Upload
              listType="picture-card"
              fileList={imageList}
              onPreview={handleImageModalOpen}
              beforeUpload={handleImageUpload}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload> */}
            <Form.Item valuePropName="attachments" noStyle>
              <Upload
                {...uploadMaterialProps}
                fileList={fileList}
                listType="picture"
                onPreview={handlePreview}
              >
                <Button icon={<UploadOutlined/>}>Upload</Button>
              </Upload>
            </Form.Item>
          </DrawerContent>
          <DrawerButtonGroup>
            <Button
              htmlType="button"
              style={{marginRight: 20}}
              onClick={() => onCreatePaymentModalCancel()}
            >
              取消
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={createPaymentLoading}
            >
              儲存
            </Button>
          </DrawerButtonGroup>
        </Form>
      </Drawer>
      <Modal
        visible={fileState.previewVisible}
        title={fileState.previewTitle}
        footer={null}
        onCancel={() => setFileState({...fileState, previewVisible: false})}
      >
        <img
          alt="example"
          style={{width: "100%"}}
          src={fileState.previewImage}
        />
      </Modal>

      <Modal
        title={"創建工作發票"}
        visible={showCreateInvoiceModal}
        footer={null}
        onCancel={onCreateInvoiceModalCancel}
      >
        <Form
          form={createInvoiceForm}
          layout="vertical"
          onFinish={onCreateInvoiceModalSubmit}
        >
          <Form.Item
            name="dateRange"
            label="結算時段"
            rules={[{required: true, message: "請輸入日期"}]}
          >
            <DatePicker.RangePicker placeholder={["由", "至"]}/>
          </Form.Item>

          <Form.Item
            name="signInOnly"
            valuePropName="checked"
          >
            <Checkbox>只計算已簽到記錄</Checkbox>
          </Form.Item>

          <Button
            htmlType="button"
            onClick={onCreateInvoiceModalCancel}
            loading={createTaskInvoiceLoading}
            style={{marginRight: 20, marginTop: 20}}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={createTaskInvoiceLoading}
          >
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title="創建Note"
        visible={showCreateNoteModal}
        footer={null}
        onCancel={onCreateNoteModalCancel}
        width={1000}
      >
        <Form
          form={createNoteForm}
          layout="vertical"
          initialValues={creditDebitNoteDetails}
          onFinish={onCreateNoteModalSubmit}
          onValuesChange={(changedValues, allValues) => {
            if (Object.keys(changedValues)[0] === "creditDebitNoteItems") {
              let total = 0;
              let subTotal = 0;

              if (
                allValues.creditDebitNoteItems &&
                allValues.creditDebitNoteItems.length > 0
              ) {
                const newcreditDebitItems = [];

                for (
                  let i = 0;
                  i < allValues.creditDebitNoteItems.length;
                  i++
                ) {
                  subTotal =
                    allValues.creditDebitNoteItems[i]?.cost &&
                    allValues.creditDebitNoteItems[i]?.quantity
                      ? allValues.creditDebitNoteItems[i]?.cost *
                      allValues.creditDebitNoteItems[i]?.quantity
                      : 0;
                  total = total + subTotal;
                  newcreditDebitItems.push({
                    itemName: allValues.creditDebitNoteItems[i]?.itemName,
                    quantity: allValues.creditDebitNoteItems[i]?.quantity,
                    cost: allValues.creditDebitNoteItems[i]?.cost,
                    subTotal: subTotal,
                  });
                }

                createNoteForm.setFieldsValue({
                  creditDebitNoteItems: newcreditDebitItems,
                });
                setCreditDebitNoteDetails((prevState) => ({
                  ...prevState,
                  total: total,
                }));
              } else {
                setCreditDebitNoteDetails((prevState) => ({
                  ...prevState,
                  total: 0,
                }));
              }
            }
          }}
        >
          <Form.Item name="employerUuid" label="employerUuid" hidden>
            <Input placeholder="employerUuid"/>
          </Form.Item>
          <Form.Item name="invoiceUuid" label="invoiceUuid" hidden>
            <Input placeholder="invoiceUuid"/>
          </Form.Item>
          <Row>
            <Col span={13}>
              <Form.Item name="type" label="Type">
                <Select placeholder="Type" style={{width: 120}}>
                  <Select.Option value={0}>Credit</Select.Option>
                  <Select.Option value={1}>Debit</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={1}/>
            <Col span={5}>
              <Form.Item
                name="invoiceDate"
                label="發票日期"
                rules={[{required: true, message: "請輸入發票日期"}]}
              >
                <DatePicker placeholder="發票日期"/>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="dueDate"
                label="付款過期日"
                rules={[{required: true, message: "請輸入付款過期日"}]}
              >
                <DatePicker placeholder="付款過期日"/>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Divider/>
            </Col>
          </Row>

          <InvoiceDetailSectionTitle>項目</InvoiceDetailSectionTitle>

          <Row
            gutter={[16, 4]}
            style={{backgroundColor: "#E7ECFF", marginBottom: 10}}
          >
            <Col span={9}>項目</Col>
            <Col span={4}>數量</Col>
            <Col span={5}>價錢</Col>
            <Col span={5}>總額</Col>
            <Col span={1}></Col>
          </Row>

          <Form.List name="creditDebitNoteItems">
            {(fields, {add, remove}) => (
              <>
                {fields.map(({key, name, ...restField}) => (
                  <Row key={key} gutter={[16, 4]}>
                    <Col span={9}>
                      <Form.Item
                        {...restField}
                        name={[name, "itemName"]}
                        rules={[{required: true, message: "請輸入項目"}]}
                      >
                        <Input placeholder="項目"/>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, "quantity"]}
                        rules={[{required: true, message: "請輸入數量"}]}
                      >
                        <InputNumber
                          placeholder="數量"
                          style={{width: 120}}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        {...restField}
                        name={[name, "cost"]}
                        rules={[{required: true, message: "請輸入價錢"}]}
                      >
                        <InputNumber
                          placeholder="價錢"
                          style={{width: 120}}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item {...restField} name={[name, "subTotal"]}>
                        <Input
                          placeholder="總額"
                          style={{width: 140}}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <MinusCircleOutlined onClick={() => remove(name)}/>
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined/>}
                  >
                    增加項目
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Row>
            <Col span={24}>
              <Divider/>
            </Col>
          </Row>

          <Row gutter={[16, 4]}>
            <Col span={17}></Col>
            <Col span={2}>
              <EditInvoiceTotalPriceTitle>總額</EditInvoiceTotalPriceTitle>
            </Col>
            <Col span={5}>
              <EditInvoiceTotalPrice>
                {creditDebitNoteDetails.total}
              </EditInvoiceTotalPrice>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item name="remark" label="備註">
                <Input placeholder="備註"/>
              </Form.Item>
            </Col>
          </Row>

          <Button
            htmlType="button"
            onClick={onCreateNoteModalCancel}
            style={{marginRight: 20}}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={createCreditDebitNoteLoading}
          >
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

const EditInvoiceNo = styled.span`
  color: #8d939e;
  font-size: 20px;
  font-weight: bold;
  padding-right: 20px;
`;

const EditInvoiceEmployerContainer = styled.div`
  padding: 16px;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
`;

const EditInvoiceEmployerName = styled.div`
  color: #3c3e42;
  font-size: 14px;
  font-weight: bold;
`;

const EditInvoiceEmployerAddress = styled.div`
  color: #8d939e;
  font-size: 12px;
`;

const Divider = styled.hr`
  border-top: 1px solid #e0e5ef;
`;

const EditInvoiceTotalPriceTitle = styled.div`
  color: #8d939e;
  font-size: 24px;
  font-weight: 500;
`;

const EditInvoiceTotalPrice = styled.div`
  color: #3c3e42;
  font-size: 24px;
  font-weight: bold;
`;

const CreatePaymentInvoiceNo = styled.span`
  color: #8d939e;
  font-size: 20px;
  font-weight: bold;
`;

const InvoiceDetailSectionTitle = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 4px;
`;

const DrawerContent = styled.div`
  margin-bottom: 60px;
  over-flow: auto;
`;

const DrawerButtonGroup = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 52px;
  background-color: #ffffff;
  border-top: 1px solid #f0f0f0;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
`;

const DrawerRow = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const DrawerRowTitle = styled.div`
  flex-grow: 1;
`;

const DrawerImageTitle = styled.div`
  margin-bottom: 10px;
`;

const DrawerLinkButton = styled.div`
  padding-left: 8px;
`;

const PrintButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;
