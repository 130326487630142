import React, {useEffect, useState} from "react";
import {DatePicker, Form, Input, Select} from "antd";
import styled from "@emotion/styled";
import {Page} from "../../utils/paging";
import moment from "moment";

const { RangePicker } = DatePicker;

const {Option} = Select;

export interface EmployerSearch extends Page {
    settleFrom: string
    settleTo: string
    status: string
    keyword: string
}

interface SearchPanelProps {
    param: EmployerSearch;
    setParam: (param: SearchPanelProps["param"]) => void;
}

export const SearchPanel = ({param, setParam}: SearchPanelProps) => {
    const [dateRange, setDateRange] = useState<any>([])

    useEffect(() => {
        if (param.settleFrom || param.settleTo) {
            setDateRange([moment(param.settleFrom), moment(param.settleTo)])
        }
    }, [param])

    return (
        <TopSearchBar>
            <Form layout={"inline"}>
                <Form.Item>
                    <RangePicker
                        value={dateRange}
                        onChange={(value: any) => {
                            if (value && value.length > 0) {
                                setParam({
                                    ...param,
                                    settleFrom: value[0]?.format('YYYY-MM-DD'),
                                    settleTo: value[1]?.format('YYYY-MM-DD'),
                                })
                            } else {
                                setParam({
                                    ...param,
                                    settleFrom: '',
                                    settleTo: '',
                                })
                            }
                            setDateRange(value)
                        }

                        }
                    />
                </Form.Item>

                <Form.Item

                >
                    <Select
                        value={param.status}
                        style={{width: 200}}
                        allowClear
                        // mode={"multiple"}
                        options={[
                            {label: '未付款', value: '0'},
                            {label: '已付款', value: '1'},
                            {label: '過期', value: '2'},
                            {label: '取消', value: '3'},
                        ]}
                        onChange={(value: any) =>
                            setParam({
                                ...param,
                                status: value,
                            })
                        }

                    />
                </Form.Item>
                <Form.Item>
                    <Input
                        placeholder="僱主姓名, ID, 電話"
                        allowClear
                        value={param.keyword}
                        onChange={(value) =>
                            setParam({
                                ...param,
                                keyword: value.target.value,
                            })
                        }
                    />
                </Form.Item>
            </Form>

        </TopSearchBar>
    );
};

const TopSearchBar = styled.div`
  width: 100%;
  height: 64px;
  padding: 16px 20px;
  background-color: #ffffff;
`;
