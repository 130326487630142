import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Space} from "antd";
import {EditOutlined, MinusCircleOutlined, PlusOutlined,} from "@ant-design/icons";
import moment from "moment";
import { v4 as uuid } from "uuid";
import styled from "@emotion/styled";
import {TopBar} from "screens/employer/create-employer/top-bar";
import {changeArrayToStringInObject, useDocumentTitle} from "utils";
import {Profiler} from "components/profiler";
import {District} from "components/district";
import {HelperPaymentMethodSelect} from "components/helper-payment-method";
import {useAddHelper} from "../../../utils/helper";
import {useNavigate} from "react-router-dom";
import {useHelperPositions} from "../../../utils/helper-position";
import PageContainer from "../../../components/layout/PageContainer";

export interface BankAccount {
  [key: string]: number | string | boolean;
  bankName: string;
  default: boolean;
  paymentMethod: number;
  accountName: string;
  bankNumber: string;
}

export const CreateHelperScreen = () => {
  const navigate = useNavigate();

  useDocumentTitle("創建幫手", false);
  const [form] = Form.useForm();

  const [bankAccounts, setBankAccounts] = useState<BankAccount[]>([]);
  const { data: helperPositions } = useHelperPositions();

  const { mutate, isLoading, isError, isSuccess, data } = useAddHelper();

  useEffect(() => {
    if (isSuccess && data) {
      // @ts-ignore
      navigate(`/helper/${data?.uuid}`);
    }
  }, [isSuccess]);

  const onSubmit = async (values?: any) => {

    if (values.bankDetails.length === 0) {
      return alert("請輸入銀行戶口");
    }



    if (values.dob) {
      values.dob = moment(values.dob).format("YYYY-MM-DD");
    }

    if (values.dateOfVaccines) {
      values.dateOfVaccines = moment(values.dateOfVaccines).format("YYYY-MM-DD");
    }

    if (values.timeTable && values.timeTable.length > 0) {
      values.timeTable = values.timeTable.reduce(
          (a: any, v: string) => ({...a, [v]: true}),
          {}
      );
    }

    await mutate(changeArrayToStringInObject(values));
  };

  return (
    <Profiler id="創建幫手">
      <PageContainer>
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}

          onValuesChange={(changedValues, allValues) => {

            if (changedValues.bankDetails) {
              let itemIndex = 0;
              let isUpdateDefault = false;

              for (let i = 0; i < changedValues.bankDetails.length; i++) {
                if (changedValues.bankDetails[i] && changedValues.bankDetails[i].default !== undefined) {
                  itemIndex = i;
                  isUpdateDefault = true;
                  break;
                }
              }

              if (isUpdateDefault) {
                let newBankDetails = [];
                let bankDetails = allValues?.bankDetails || [];

                for (let i = 0; i < bankDetails.length; i++) {
                  if (i === itemIndex) {
                    newBankDetails.push(bankDetails[i]);
                  } else {
                    newBankDetails.push({
                      ...bankDetails[i],
                      default: false,
                    });
                  }
                }

                form.setFieldsValue({
                  ...allValues,
                  bankDetails: newBankDetails
                });
              }
            }
          }}
        >
          <TopBar title="創建幫手" />
          <ContentContainer>
            <FormCard>
              <CardTitle>幫手信息</CardTitle>
              <Row gutter={[16, 4]}>
                <Col span={8}>
                  <Form.Item
                    name="nameCn"
                    label="中文姓名"
                    rules={[{ required: true, message: "請輸入中文姓名" }]}
                  >
                    <Input placeholder="中文姓名" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="nameEn"
                    label="英文姓名"
                    rules={[{ required: true, message: "請輸入英文姓名" }]}
                  >
                    <Input placeholder="英文姓名" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 4]}>
                <Col span={5}>
                  <Form.Item
                    name="gender"
                    label="性別"
                    rules={[{ required: true, message: "請輸入性別" }]}
                  >
                    <Radio.Group>
                      <Radio value={1}>女性</Radio>
                      <Radio value={0}>男性</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="idCardNo"
                    label="身份證號碼"
                    rules={[{ required: true, message: "請輸入身份證號碼" }]}
                  >
                    <Input placeholder="身份證號碼" />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="idCardName"
                    label="身份證明文件上名稱"
                    rules={[{ required: true, message: "請輸入身份證明文件上名稱" }]}
                  >
                    <Input placeholder="身份證明文件上名稱" />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="dob"
                    label="出生日期"
                    rules={[{ required: true, message: "請輸入出生日期" }]}
                  >
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      placeholder="DD/MM/YYYY"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 4]}>
                <Col span={5}>
                  <Form.Item
                    name="height"
                    label="身高 (cm)"
                    rules={[{ required: true, message: "請輸入身高" }]}
                  >
                    <InputNumber
                      min={0}
                      max={250}
                      style={{ width: 180 }}
                      placeholder="身高 (cm)"
                    />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item
                    name="weight"
                    label="體重 (kg)"
                    rules={[{ required: true, message: "請輸入體重" }]}
                  >
                    <InputNumber
                      min={0}
                      max={300}
                      style={{ width: 180 }}
                      placeholder="體重 (kg)"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 4]}>
                <Col span={6}>
                  <Form.Item
                    name="position"
                    label="職級"
                    rules={[{ required: true, message: "請輸入職級" }]}
                  >
                    <Select placeholder={"幫手職級"} style={{ width: "100%" }}>
                      {(helperPositions?.list || []).map((item) => (
                        <Select.Option key={item.uuid} value={item.name}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="language"
                    label="語言"
                    rules={[{ required: true, message: "請輸入語言" }]}
                  >
                    <Select mode="multiple" placeholder="語言">
                      <Select.Option value="cantonese">廣東話</Select.Option>
                      <Select.Option value="english">英語</Select.Option>
                      <Select.Option value="putonghua">普通話</Select.Option>
                      <Select.Option value="潮洲話">潮洲話</Select.Option>
                      <Select.Option value="上海話">上海話</Select.Option>
                      <Select.Option value="福建話">福建話</Select.Option>
                      <Select.Option value="客家話">客家話</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 4]}>
                <Col span={6}>
                  <Form.Item
                    name="phone"
                    label="電話"
                    rules={[{ required: true, message: "請輸入電話" }]}
                  >
                    <Input placeholder="電話" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="email" label="電郵">
                    <Input placeholder="電郵" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="emergencyContactPerson"
                    label="緊急聯絡人"
                    rules={[{ required: true, message: "請輸入緊急聯絡人" }]}
                  >
                    <Input placeholder="緊急聯絡人" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="emergencyContactPhone"
                    label="緊急聯絡人電話"
                    rules={[
                      { required: true, message: "請輸入緊急聯絡人電話" },
                    ]}
                  >
                    <Input placeholder="緊急聯絡人電話" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 4]}>
                <Col span={6}>
                  <Form.Item
                    name="district"
                    label="地區"
                    rules={[{ required: true, message: "請輸入地區" }]}
                  >
                    <District />
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <Form.Item
                    name="address"
                    label="完整地址"
                    rules={[{ required: true, message: "請輸入完整地址" }]}
                  >
                    <Input placeholder="完整地址" />
                  </Form.Item>
                </Col>
              </Row>
              {/* <Row>
                <Col span={6}>
                  <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture"
                  >
                    <Button icon={<UploadOutlined />}>上傳幫手身分證</Button>
                  </Upload>
                </Col>
              </Row> */}
            </FormCard>

            <FormCard>
              <CardTitle>疫苗接種</CardTitle>
              <Row gutter={[16, 4]}>
                {/* <Col span={6}>
                  <Form.Item label="接種新冠肺炎疫苗">
                    <Radio.Group>
                      <Radio value="y">有</Radio>
                      <Radio value="n">沒有</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col> */}
                <Col span={6}>
                  <Form.Item
                      name="typesOfVaccines"
                      label="疫苗種類 / 次數"
                      rules={[{required: true, message: "請輸入疫苗種類 / 次數"}]}
                  >
                    <Input placeholder="疫苗種類"/>
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                      name="dateOfVaccines"
                      label="接種日期"
                      // rules={[{ required: true, message: "請輸入接種日期" }]}
                  >
                    <DatePicker placeholder="接種日期"/>
                  </Form.Item>
                </Col>
              </Row>
            </FormCard>

            <FormCard>
              <CardTitle>銀行戶口</CardTitle>
              <Form.List name="bankDetails">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Space key={field.key} align="baseline">
                      <Form.Item
                        {...field}
                        key={uuid()}
                        label="銀行名稱"
                        name={[field.name, "bankName"]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        key={uuid()}
                        label="戶口持有人姓名"
                        name={[field.name, "accountName"]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        key={uuid()}
                        label="戶口號碼"
                        name={[field.name, "bankNumber"]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        key={uuid()}
                        label="出糧方式"
                        style={{ width: 140 }}
                        name={[field.name, "paymentMethod"]}
                      >
                        <HelperPaymentMethodSelect />
                      </Form.Item>

                      <Form.Item
                        {...field}
                        key={uuid()}
                        label=" "
                        valuePropName="checked"
                        name={[field.name, "default"]}
                      >
                        <Checkbox>Default</Checkbox>
                      </Form.Item>

                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Space>
                  ))}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      銀行戶口
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </FormCard>

            <FormCard>
              <CardTitle>技能</CardTitle>
              <Row gutter={[16, 4]}>
                <Col span={24}>
                  <Form.Item
                    name="dailyCareSkills"
                    label="日常護理技能"
                    // rules={[{ required: true, message: "請選擇日常護理技能" }]}
                  >
                    <Checkbox.Group style={{ width: "100%" }}>
                      <Row>
                        <Col span={4}>
                          <Checkbox value="簡單煮食">簡單煮食</Checkbox>
                        </Col>
                        <Col span={4}>
                          <Checkbox value="換尿片">換尿片</Checkbox>
                        </Col>
                        <Col span={4}>
                          <Checkbox value="扶抱">扶抱</Checkbox>
                        </Col>
                        <Col span={4}>
                          <Checkbox value="餵飯">餵飯</Checkbox>
                        </Col>
                        <Col span={4}>
                          <Checkbox value="提醒服藥">提醒服藥</Checkbox>
                        </Col>
                        <Col span={4}>
                          <Checkbox value="沐浴">沐浴</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="professionalNursingSkills"
                    label="專業護理技能"
                    rules={[{ required: true, message: "請選擇專業護理技能" }]}
                  >
                    <Checkbox.Group style={{ width: "100%" }}>
                      <Row>
                        <Col span={6}>
                          <Checkbox value="胃喉餵食">胃喉餵食</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="手術後護理">手術後護理</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="簡單傷口處理">簡單傷口處理</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="量度生命表徵">量度生命表徵</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="檢驗血糖，尿液">
                            檢驗血糖，尿液
                          </Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="處理尿袋">處理尿袋</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="PEG">PEG</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="呼吸機">呼吸機</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="腹膜透析">腹膜透析</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="靈養服務/疼痛處理">
                            靈養服務/疼痛處理
                          </Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="入院，岀院及轉送技巧">
                            入院，岀院及轉送技巧
                          </Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="復康運動">復康運動</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="按摩（有證書）">
                            按摩（有證書）
                          </Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="整理床舖及更換床單">
                            整理床舖及更換床單
                          </Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="傷口護理及更換止血墊">
                            傷口護理及更換止血墊
                          </Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="造口護理（包括：腹部造口/結腸造口護理)">
                            造口護理（包括：腹部造口/結腸造口護理)
                          </Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="藥物注射及服用">
                            藥物注射及服用
                          </Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="基本急救（有證書）">
                            基本急救（有證書）
                          </Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="advancedNursingSkills"
                    label="進階護理技能"
                    // rules={[{ required: true, message: "請選擇進階護理技能" }]}
                  >
                    <Checkbox.Group style={{ width: "100%" }}>
                      <Row>
                        <Col span={6}>
                          <Checkbox value="心肺復甦法（有證書）">
                            心肺復甦法（有證書）
                          </Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="接種疫苗和免疫接種">
                            接種疫苗和免疫接種
                          </Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="協助病人預備X光檢查及接受心電圖測試的技巧">
                            協助病人預備X光檢查及接受心電圖測試的技巧
                          </Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="抽痰">抽痰</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="抽血">抽血</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="更換胃喉">更換胃喉</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="更換尿喉">更換尿喉</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="打胰島素針">打胰島素針</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="otherSkill"
                    label="其他"
                    style={{width: 280}}
                    // rules={[{ required: true, message: "請選擇日常護理技能" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </FormCard>

            <FormCard>
              <CardTitle>時間表</CardTitle>
              <InfoLabel>早更:00:00-11:59 夜更:12:00-24:00</InfoLabel>
              <br />
              <Form.Item
                name="timeTable"
                rules={[{ required: true, message: "請選擇時間表" }]}
              >
                <Checkbox.Group>
                  <Row gutter={[16, 4]}>
                    <Col flex={1}>
                      <div>星期一</div>
                      <Checkbox value="monAm">早更</Checkbox>
                      <br />
                      <br />
                      <Checkbox value="monPm">夜更</Checkbox>
                    </Col>
                    <Col flex={2}>
                      <div>星期二</div>
                      <Checkbox value="tueAm">早更</Checkbox>
                      <br />
                      <br />
                      <Checkbox value="tuePm">夜更</Checkbox>
                    </Col>
                    <Col flex={3}>
                      <div>星期三</div>
                      <Checkbox value="wedAm">早更</Checkbox>
                      <br />
                      <br />
                      <Checkbox value="wedPm">夜更</Checkbox>
                    </Col>
                    <Col flex={4}>
                      <div>星期四</div>
                      <Checkbox value="thuAm">早更</Checkbox>
                      <br />
                      <br />
                      <Checkbox value="thuPm">夜更</Checkbox>
                    </Col>
                    <Col flex={5}>
                      <div>星期五</div>
                      <Checkbox value="friAm">早更</Checkbox>
                      <br />
                      <br />
                      <Checkbox value="friPm">夜更</Checkbox>
                    </Col>
                    <Col flex={6}>
                      <div>星期六</div>
                      <Checkbox value="satAm">早更</Checkbox>
                      <br />
                      <br />
                      <Checkbox value="satPm">夜更</Checkbox>
                    </Col>
                    <Col flex={7}>
                      <div>星期日</div>
                      <Checkbox value="sunAm">早更</Checkbox>
                      <br />
                      <br />
                      <Checkbox value="sunPm">夜更</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </FormCard>
          </ContentContainer>
        </Form>
      </PageContainer>
    </Profiler>
  );
};

const ContentContainer = styled.div`
  padding: 3.2rem;
`;

const FormCard = styled.div`
  padding: 2.4rem;
  background-color: #ffffff;
  margin-bottom: 2.4rem;
`;

const CardTitle = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
`;

const InfoLabel = styled.div`
  color: #8d939e;
  font-size: 12px;
`;

CreateHelperScreen.whyDidYouRender = false;
