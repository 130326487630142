import { useAsync } from "utils/use-async";
import { useCallback, useEffect } from "react";
import { useHttp } from "utils/http";
import { HelperPaymentRecord } from "../types/helper-payment";

export const useHelperPaymentData = (param: {
  helperUuid: string;
  salaryRecordUuid: string;
}) => {
  const client = useHttp();
  const { run, ...result } = useAsync<HelperPaymentRecord>();

  const fetchHelperPaymentData = useCallback(
    () =>
      client(
        `helpers/${param.helperUuid}/salaries/${param.salaryRecordUuid}/confirm-payments`,
        {}
      ),
    [param, client]
  );

  useEffect(() => {
    run(fetchHelperPaymentData(), {
      retry: fetchHelperPaymentData,
    });
  }, [param, run, fetchHelperPaymentData]);

  return result;
};

export const useConfirmHelperPayment = () => {
  const { run, ...asyncResult } = useAsync();
  const client = useHttp();
  const mutate = (helperUuid: string, salaryRecordUuid: string) => {
    return run(
      client(
        `helpers/${helperUuid}/salaries/${salaryRecordUuid}/confirm-payments`,
        {
          data: {},
          method: "PUT",
        }
      )
    );
  };
  return {
    mutate,
    ...asyncResult,
  };
};
