import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Table,
  Upload,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Row as LibRow } from "components/lib";
import { Profiler } from "components/profiler";
import { useUrlQueryPaging } from "utils/paging";
import { TablePagination } from "components/table-pagination";
import { SearchPanel } from "./search-panel";
import { List } from "./list";
import { useDebounce, useDocumentTitle } from "utils";
import { dateDisplay } from "utils/moment-util";
import { invoiceRecordStatus } from "utils/common";
import { useCreditDebitNoteSearchParams } from "search-utils/util";
import {
  useCreditDebitNotes,
  useCreateCreditDebitNote,
  useCreditDebitNoteDetail,
} from "utils/credit-debit-note";
import { CreditDebitNote } from "types/credit-debit-note";
import PageContainer from "../../components/layout/PageContainer";

export const CreditDebitNoteListScreen = () => {
  useDocumentTitle("Credit Debit Note", false);

  const [param, setParam] = useCreditDebitNoteSearchParams();
  const [pageObject, setPageObject] = useUrlQueryPaging();

  const [creditDebitNoteDetails, setCreditDebitNoteDetails] = useState<
    CreditDebitNote
  >({
    uuid: "",
    invoiceNo: "",
    status: 0,
    invoiceDate: "",
    dueDate: "",
    remark: "",
    total: 0,
    type: 0,
    creditDebitNoteItems: [],
    employer: {
      uuid: "",
      employerNo: "",
      type: 0,
      companyName: "",
      contactNumber: "",
      contactPersonPosition: "",
      contactPerson: "",
      district: "",
      address: "",
      patientName: "",
      patientGender: 0,
      patientHeight: 0,
      patientWeight: 0,
      patientAge: 0,
    },
    helper: {
      uuid: "",
      nameCn: "",
      helperNo: "",
      phone: "",
      nameEn: "",
      gender: 0,
      position: "",
    },
    invoice: {
      uuid: "",
      invoiceNo: "",
      status: 0,
      settleFrom: "",
      settleTo: "",
      invoiceDate: "",
      dueDate: "",
      remark: "",
      total: 0,
      type: 0,
      employerUuid: "",
      payments: [],
      invoiceItems: [],
      discountItems: [],
      employer: {
        uuid: "",
        employerNo: "",
        type: 0,
        companyName: "",
        contactNumber: "",
        contactPersonPosition: "",
        contactPerson: "",
        district: "",
        address: "",
        patientName: "",
        patientGender: 0,
        patientHeight: 0,
        patientWeight: 0,
        patientAge: 0,
      },
    },
    salaryRecord: {
      uuid: "",
      recordeNo: "",
      salary: 0,
      settleFrom: "",
      settleTo: "",
      releaseDate: "",
      payment: {
        uuid: "",
        paymentNo: "",
        status: 0,
        method: 0,
        referenceNo: "",
        remark: "",
        attachments: [],
      },
      helper: {
        nameCn: "",
        nameEn: "",
        gender: 0,
        position: "",
        language: "",
        idCardNo: "",
        idCardName: "",
        dob: "",
        dateOfVaccines: "",
        typesOfVaccines: "",
        phone: "",
        email: "",
        district: "",
        address: "",
        emergencyContactPerson: "",
        emergencyContactPhone: "",
        bankDetails: [],
        remark: "",
        dailyCareSkills: "",
        professionalNursingSkills: "",
        advancedNursingSkills: "",
        personInCharge: 0,
      },
      helperAttendances: [],
    },
    fixedCreditDebitItems: [],
    creditDebitItems: [],
  });

  const [creditDebitNoteDetailsForm] = Form.useForm();
  const [
    showCreditDebitNoteDetailsModal,
    setShowCreditDebitNoteDetailsModal,
  ] = useState(false);

  const {
    data: creditDebitNotes,
    isLoading: creditDebitNotesLoading,
    retry: retry,
  } = useCreditDebitNotes(useDebounce(param, 200), pageObject);

  const {
    mutate: createCreditDebitNote,
    isLoading: createCreditDebitNoteLoading,
    isSuccess: isCreateCreditDebitNoteSuccess,
  } = useCreateCreditDebitNote();

  const {
    mutate: getCreditDebitNoteDetail,
    data: creditDebitNoteDetailData,
  } = useCreditDebitNoteDetail();

  useEffect(() => {
    if (isCreateCreditDebitNoteSuccess) {
      retry();
      setShowCreditDebitNoteDetailsModal(false);
    }
  }, [isCreateCreditDebitNoteSuccess]);

  useEffect(() => {
    if (creditDebitNoteDetailData) {
      setCreditDebitNoteDetails(creditDebitNoteDetailData);
      creditDebitNoteDetailsForm.setFieldsValue(creditDebitNoteDetailData);
    }
  }, [creditDebitNoteDetailData]);

  const onCreateCreditDebitNoteModalOpen = () => {
    setShowCreditDebitNoteDetailsModal(true);
  };

  const onCreditDebitNoteDetailsModalOpen = async (record: CreditDebitNote) => {
    await getCreditDebitNoteDetail(record.uuid);
    setShowCreditDebitNoteDetailsModal(true);
  };

  const onCreditDebitNoteDetailsModalCancel = () => {
    setShowCreditDebitNoteDetailsModal(false);
  };

  const onCreditDebitNoteDetailsModalSubmit = async (values: any) => {
    const submitData = {
      type: 1,
      invoiceDate: moment(values.invoiceDate).format("YYYY-MM-DD"),
      dueDate: moment(values.dueDate).format("YYYY-MM-DD"),
      remark: values.remark,
    };

    if (creditDebitNoteDetails?.uuid) {
    } else {
      await createCreditDebitNote(submitData);
    }
  };

  return (
    <Profiler id={"Credit Debit Note"}>
      <PageContainer>
        <SearchPanel param={param} setParam={setParam} />
        <ContentContainer>
          <LibRow marginBottom={2} between={true}>
            <h1>Credit Debit Note</h1>
            <Button
              type="primary"
              onClick={() => onCreateCreditDebitNoteModalOpen()}
            >
              ＋Note
            </Button>
          </LibRow>

          <List
            refresh={retry}
            loading={creditDebitNotesLoading}
            dataSource={creditDebitNotes?.list || []}
            pagination={false}
            scroll={{
              y: window?.innerHeight
                ? window?.innerHeight - 320
                : "max-content",
            }}
          />
          <TablePagination
            pageSize={pageObject.pageSize}
            pageSizeOptions={["10", "25", "50", "100"]}
            showSizeChanger={true}
            showQuickJumper={false}
            current={pageObject.pageNumber}
            total={(creditDebitNotes && creditDebitNotes.total) || 0}
            onChange={(page, pageSize) => {
              setPageObject({
                pageSize,
                pageNumber: page,
              });
            }}
          />
        </ContentContainer>
      </PageContainer>

      <Modal
        title={creditDebitNoteDetails?.uuid ? "編輯Note" : "創建Note"}
        visible={showCreditDebitNoteDetailsModal}
        footer={null}
        onCancel={onCreditDebitNoteDetailsModalCancel}
        width={1000}
      >
        <Form
          form={creditDebitNoteDetailsForm}
          layout="vertical"
          initialValues={creditDebitNoteDetails}
          onFinish={onCreditDebitNoteDetailsModalSubmit}
          onValuesChange={(changedValues, allValues) => {
            if (Object.keys(changedValues)[0] === "creditDebitItems") {
              let total = 0;
              let subTotal = 0;

              if (
                creditDebitNoteDetails?.fixedCreditDebitItems &&
                creditDebitNoteDetails.fixedCreditDebitItems.length > 0
              ) {
                for (
                  let i = 0;
                  i < creditDebitNoteDetails.fixedCreditDebitItems.length;
                  i++
                ) {
                  subTotal =
                    creditDebitNoteDetails.fixedCreditDebitItems[i]?.cost &&
                    creditDebitNoteDetails.fixedCreditDebitItems[i]?.quantity
                      ? creditDebitNoteDetails.fixedCreditDebitItems[i]?.cost *
                        creditDebitNoteDetails.fixedCreditDebitItems[i]
                          ?.quantity
                      : 0;
                  total = total + subTotal;
                }
              }

              if (
                allValues.creditDebitItems &&
                allValues.creditDebitItems.length > 0
              ) {
                const newcreditDebitItems = [];

                for (let i = 0; i < allValues.creditDebitItems.length; i++) {
                  subTotal =
                    allValues.creditDebitItems[i]?.cost &&
                    allValues.creditDebitItems[i]?.quantity
                      ? allValues.creditDebitItems[i]?.cost *
                        allValues.creditDebitItems[i]?.quantity
                      : 0;
                  total = total + subTotal;
                  newcreditDebitItems.push({
                    itemName: allValues.creditDebitItems[i]?.itemName,
                    quantity: allValues.creditDebitItems[i]?.quantity,
                    cost: allValues.creditDebitItems[i]?.cost,
                    subTotal: subTotal,
                  });
                }

                creditDebitNoteDetailsForm.setFieldsValue({
                  creditDebitItems: newcreditDebitItems,
                });
                setCreditDebitNoteDetails((prevState) => ({
                  ...prevState,
                  total: total,
                }));
              } else {
                setCreditDebitNoteDetails((prevState) => ({
                  ...prevState,
                  total: 0,
                }));
              }
            }
          }}
        >
          <Form.Item name="uuid" label="uuid" hidden>
            <Input placeholder="uuid" />
          </Form.Item>

          {creditDebitNoteDetails.uuid && (
            <Row style={{ marginBottom: 10 }}>
              <Col span={6}>
                <EditInvoiceNo>
                  {creditDebitNoteDetails.invoiceNo}
                </EditInvoiceNo>
              </Col>
              <Col span={8}></Col>
              <Col span={5}>
                {/* 發票日期: {dateDisplay(creditDebitNoteDetails.invoiceDate)} */}
              </Col>
              <Col span={5}>
                付款狀態: {invoiceRecordStatus(creditDebitNoteDetails.status)}
              </Col>
            </Row>
          )}

          <Row>
            <Col span={13}>
              <EditInvoiceEmployerContainer>
                <EditInvoiceEmployerName>
                  {/* {creditDebitNoteDetails.employerName} */}
                </EditInvoiceEmployerName>
                <EditInvoiceEmployerAddress>
                  {/* {creditDebitNoteDetails.employerAddress} */}
                </EditInvoiceEmployerAddress>
              </EditInvoiceEmployerContainer>
            </Col>
            <Col span={1} />
            <Col span={5}>
              <Form.Item
                name="invoiceDate"
                label="發票日期"
                rules={[{ required: true, message: "請輸入發票日期" }]}
              >
                <DatePicker
                  placeholder="發票日期"
                  disabled={creditDebitNoteDetails.status !== 0}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="dueDate"
                label="付款過期日"
                rules={[{ required: true, message: "請輸入付款過期日" }]}
              >
                <DatePicker
                  placeholder="付款過期日"
                  disabled={
                    creditDebitNoteDetails.status === 1 ||
                    creditDebitNoteDetails.status === 3
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Divider />
            </Col>
          </Row>

          <InvoiceDetailSectionTitle>項目</InvoiceDetailSectionTitle>

          <Row
            gutter={[16, 4]}
            style={{ backgroundColor: "#E7ECFF", marginBottom: 10 }}
          >
            <Col span={9}>項目</Col>
            <Col span={4}>數量</Col>
            <Col span={5}>價錢</Col>
            <Col span={5}>總額</Col>
            <Col span={1}></Col>
          </Row>

          {(creditDebitNoteDetails?.fixedCreditDebitItems || []).map(
            (item, index) => (
              <Row key={index} gutter={[16, 4]}>
                <Col span={9}>{item.itemName}</Col>
                <Col span={4}>{item.quantity}</Col>
                <Col span={5}>{item.cost}</Col>
                <Col span={5}>{item.subTotal}</Col>
                <Col span={1}></Col>
              </Row>
            )
          )}

          <Form.List name="creditDebitItems">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Row key={key} gutter={[16, 4]}>
                    <Col span={9}>
                      <Form.Item
                        {...restField}
                        name={[name, "itemName"]}
                        rules={[{ required: true, message: "請輸入項目" }]}
                      >
                        <Input
                          placeholder="項目"
                          disabled={creditDebitNoteDetails.status !== 0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, "quantity"]}
                        rules={[{ required: true, message: "請輸入數量" }]}
                      >
                        <InputNumber
                          placeholder="數量"
                          style={{ width: 120 }}
                          disabled={creditDebitNoteDetails.status !== 0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item
                        {...restField}
                        name={[name, "cost"]}
                        rules={[{ required: true, message: "請輸入價錢" }]}
                      >
                        <InputNumber
                          placeholder="價錢"
                          style={{ width: 120 }}
                          disabled={creditDebitNoteDetails.status !== 0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={5}>
                      <Form.Item {...restField} name={[name, "subTotal"]}>
                        <Input
                          placeholder="總額"
                          style={{ width: 140 }}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <MinusCircleOutlined
                        onClick={() =>
                          creditDebitNoteDetails.status !== 0
                            ? ""
                            : remove(name)
                        }
                      />
                    </Col>
                  </Row>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                    disabled={creditDebitNoteDetails.status !== 0}
                  >
                    增加項目
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Row>
            <Col span={24}>
              <Divider />
            </Col>
          </Row>

          <Row gutter={[16, 4]}>
            <Col span={17}></Col>
            <Col span={2}>
              <EditInvoiceTotalPriceTitle>總額</EditInvoiceTotalPriceTitle>
            </Col>
            <Col span={5}>
              <EditInvoiceTotalPrice>
                {creditDebitNoteDetails.total}
              </EditInvoiceTotalPrice>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item name="remark" label="備註">
                <Input
                  placeholder="備註"
                  disabled={creditDebitNoteDetails.status === 1}
                />
              </Form.Item>
            </Col>
          </Row>

          {creditDebitNoteDetails.status !== 1 && (
            <>
              <Button
                htmlType="button"
                onClick={onCreditDebitNoteDetailsModalCancel}
                style={{ marginRight: 20 }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={createCreditDebitNoteLoading}
              >
                Submit
              </Button>
            </>
          )}
        </Form>
      </Modal>
    </Profiler>
  );
};

const ContentContainer = styled.div`
  padding: 3.2rem;
`;

const EditInvoiceNo = styled.span`
  color: #8d939e;
  font-size: 20px;
  font-weight: bold;
  padding-right: 20px;
`;

const EditInvoiceEmployerContainer = styled.div`
  padding: 16px;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
`;

const EditInvoiceEmployerName = styled.div`
  color: #3c3e42;
  font-size: 14px;
  font-weight: bold;
`;

const EditInvoiceEmployerAddress = styled.div`
  color: #8d939e;
  font-size: 12px;
`;

const Divider = styled.hr`
  border-top: 1px solid #e0e5ef;
`;

const EditInvoiceTotalPriceTitle = styled.div`
  color: #8d939e;
  font-size: 24px;
  font-weight: 500;
`;

const EditInvoiceTotalPrice = styled.div`
  color: #3c3e42;
  font-size: 24px;
  font-weight: bold;
`;

const CreatePaymentInvoiceNo = styled.span`
  color: #8d939e;
  font-size: 20px;
  font-weight: bold;
`;

const InvoiceDetailSectionTitle = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 4px;
`;

const DrawerContent = styled.div`
  margin-bottom: 60px;
  over-flow: auto;
`;

const DrawerButtonGroup = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 52px;
  background-color: #ffffff;
  border-top: 1px solid #f0f0f0;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
`;

const DrawerRow = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const DrawerRowTitle = styled.div`
  flex-grow: 1;
`;

const DrawerImageTitle = styled.div`
  margin-bottom: 10px;
`;

const DrawerLinkButton = styled.div`
  padding-left: 8px;
`;

const PrintButtonContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;
