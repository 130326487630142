import React from "react";
import {Form, Input, Select} from "antd";
import styled from "@emotion/styled";
import {Page} from "../../../utils/paging";
import {Position} from "../../helper-prices/helper-price";
// import { Project } from "types/project";

const {Option} = Select;

export interface HelperSearch extends Page {
    // district: string
    position: string
    keyword: string
}

interface SearchPanelProps {
    param: HelperSearch;
    helperPositions: Position[];
    setParam: (param: SearchPanelProps["param"]) => void;
}

export const SearchPanel = ({param, setParam, helperPositions}: SearchPanelProps) => {
    return (
        <TopSearchBar>
            <Form layout={"inline"}>
                <Form.Item>
                    <Select
                        placeholder={"幫手職級"}
                        style={{width: "200px"}}
                        value={param.position}
                        allowClear
                        onChange={(value) => {
                            setParam({
                                ...param,
                                position: value,
                            })
                        }}
                    >
                        {(helperPositions || []).map((item) => (
                            <Select.Option key={item.uuid} value={item.name}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Input
                        placeholder="姓名, ID, E-mail, 電話"
                        allowClear
                        value={param.keyword}
                        onChange={(value) =>
                            setParam({
                                ...param,
                                keyword: value.target.value,
                            })
                        }
                    />
                </Form.Item>
            </Form>
        </TopSearchBar>
    );
};

const TopSearchBar = styled.div`
  width: 100%;
  height: 64px;
  padding: 16px 20px;
  background-color: #ffffff;
`;
