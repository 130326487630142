import { useUrlQueryParam } from "utils/url";
import { useMemo } from "react";

// 项目列表搜索的参数
export const useProjectsSearchParams = () => {
  const [param, setParam] = useUrlQueryParam(["name", "personId"]);
  return [
    useMemo(
      () => ({ ...param, personId: Number(param.personId) || undefined }),
      [param]
    ),
    setParam,
  ] as const;
};

export const useJobSearchParams = () => {
  const [param, setParam] = useUrlQueryParam([
    "district",
    "status",
    "keyword",
    "dateFrom",
    "dateTo",
  ]);
  return [
    useMemo(() => ({ ...(param || undefined) }), [param]),
    setParam,
  ] as const;
};

export const useHelperSearchParams = () => {
  const [param, setParam] = useUrlQueryParam(["position", "keyword"]);
  return [
    useMemo(() => ({ ...(param || undefined) }), [param]),
    setParam,
  ] as const;
};

export const useHelperPaymentSearchParams = () => {
  const [param, setParam] = useUrlQueryParam([
    // "district",
    "status",
    "keyword",
    "position",
  ]);
  return [
    useMemo(() => ({ ...(param || undefined) }), [param]),
    setParam,
  ] as const;
};

export const useEmployerSearchParams = () => {
  const [param, setParam] = useUrlQueryParam(["district", "status", "keyword"]);
  return [
    useMemo(() => ({ ...(param || undefined) }), [param]),
    setParam,
  ] as const;
};

export const useEmployerInvoiceSearchParams = () => {
  const [param, setParam] = useUrlQueryParam([
    "district",
    "status",
    "keyword",
    "settleFrom",
    "settleTo",
  ]);
  return [
    useMemo(() => ({ ...(param || undefined) }), [param]),
    setParam,
  ] as const;
};

export const useEmployerBlacklistSearchParams = () => {
  const [param, setParam] = useUrlQueryParam(["keyword"]);
  return [
    useMemo(() => ({ ...(param || undefined) }), [param]),
    setParam,
  ] as const;
};

export const useCreditDebitNoteSearchParams = () => {
  const [param, setParam] = useUrlQueryParam(["keyword"]);
  return [
    useMemo(() => ({ ...(param || undefined) }), [param]),
    setParam,
  ] as const;
};
