import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col } from "antd";
import { ColumnsType } from "antd/es/table";
import styled from "@emotion/styled";
import { ListTable } from "components/table/list-table";
import { Job } from "../../job/all-jobs/list";
import { taskStatus, workingPeriodStatus } from "../../../utils/common";
import moment from "moment";

export interface TaskHelpers {
  uuid: string;
  status: number;
  task: Job;
}

export const JobList = (props: {
  jobs: TaskHelpers[];
  numOfReject: number;
  numOfCancelled: number;
  numOfLeave: number;
  numOfAbsence: number;
}) => {
  const navigate = useNavigate();

  const columns: ColumnsType<TaskHelpers> = [
    {
      title: "工作編號",
      dataIndex: ["task", "taskNo"],
      key: "taskNo",
      render: (text, record) => <a href={`/job/${record.task.uuid}`}>{text}</a>,
    },
    {
      title: "標題",
      dataIndex: ["task", "title"],
      key: "title",
    },
    {
      title: "僱主",
      dataIndex: ["task", "employer", "companyName"],
      key: "employerName",
      render: (text, record) =>
        record.task.employer?.companyName ||
        record.task.employer?.contactPerson,
    },
    {
      title: "工作狀態",
      dataIndex: ["task", "status"],
      key: "status",
      render: (text, record) => taskStatus(parseInt(text)),
    },
    {
      title: "工作時間",
      dataIndex: ["task", "startTime"],
      key: "startTime",
      render: (text, record) => moment(text, "HH:mm:ss").format("HH:mm"),
    },
    {
      title: "開始日期",
      dataIndex: ["task", "startDate"],
      key: "startDate",
    },
    {
      title: "週期",
      dataIndex: ["task", "workingPeriod"],
      key: "workingPeriod",
      render: (text, record) => workingPeriodStatus(parseInt(text)),
    },
  ];

  const { jobs, numOfReject, numOfCancelled, numOfLeave, numOfAbsence } = props;

  return (
    <>
      <OtherDataContainer>
        <Row>
          <Col span={6}>
            <JobStatusDataContainer>
              <OtherDataTitle>拒絕僱主工作</OtherDataTitle>
              <OtherDataText>{numOfReject}</OtherDataText>
            </JobStatusDataContainer>
          </Col>
          <Col span={6}>
            <JobStatusDataContainer>
              <OtherDataTitle>被取消工作</OtherDataTitle>
              <OtherDataText>{numOfCancelled}</OtherDataText>
            </JobStatusDataContainer>
          </Col>
          <Col span={6}>
            <JobStatusDataContainer>
              <OtherDataTitle>放棄工作</OtherDataTitle>
              <OtherDataText>{numOfLeave}</OtherDataText>
            </JobStatusDataContainer>
          </Col>
          <Col span={6}>
            <JobStatusDataContainer>
              <OtherDataTitle>無故曠工</OtherDataTitle>
              <OtherDataText>{numOfAbsence}</OtherDataText>
            </JobStatusDataContainer>
          </Col>
        </Row>
      </OtherDataContainer>
      <Table<TaskHelpers>
        columns={columns}
        dataSource={jobs}
        // pagination={{ current: 1, total: 100 }}
      />
    </>
  );
};

const OtherDataContainer = styled.div`
  padding-bottom: 25px;
`;

const OtherDataTitle = styled.div`
  color: #8d939e;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 5px;
`;

const OtherDataText = styled.div`
  color: #3c3e42;
  font-size: 24px;
  font-weight: 500;
`;

const JobStatusDataContainer = styled.div`
  background-color: #ffffff;
  padding: 16px 20px;
  margin: 0 20px;
  text-align: center;
  border-radius: 8px;
`;
