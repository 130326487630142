import React from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Select, Button } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Row } from "components/lib";

const { Option } = Select;

export const TopBar = ({ title = "", isLoading = false }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <FixedBar between={true}>
        <BarLeft gap={true}>
          <ArrowLeftOutlined onClick={() => navigate(-1)} />
          <Title>{title}</Title>
        </BarLeft>
        <BarRight>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              確定創建
              <ArrowRightOutlined />
            </Button>
          </Form.Item>
        </BarRight>
      </FixedBar>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 80px;
  padding: 28px 32px;
  background-color: #ffffff;
  position: sticky;
  top: 0px;
  z-index: 999;
`;

const FixedBar = styled(Row)`
  z-index: 1;
`;
const BarLeft = styled(Row)``;
const BarRight = styled(Row)``;

const Title = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
`;
