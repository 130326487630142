import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import styled from "@emotion/styled";
import { TopBar } from "screens/employer/create-employer/top-bar";
import { changeArrayToStringInObject, useDocumentTitle } from "utils";
import { Profiler } from "components/profiler";
import { useAddEmployer } from "utils/employer";
import { District } from "components/district";
import { AdminSelect } from "components/admin-select";
import PageContainer from "../../../../components/layout/PageContainer";

export const CreateIndividualEmployerScreen = () => {
  useDocumentTitle("創建個人僱主", false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutate, isLoading, isError, isSuccess, data } = useAddEmployer();

  useEffect(() => {
    if (isSuccess && data) {
      // @ts-ignore
      navigate(`/employer/${data?.uuid}`);
    }
  }, [isSuccess]);

  const onSubmit = async (values?: any) => {
    await mutate(changeArrayToStringInObject(values));
  };

  return (
    <Profiler id="創建個人僱主">
      <PageContainer>
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          initialValues={{ type: 0 }}
        >
          <TopBar title="創建個人僱主" isLoading={isLoading} />
          <Form.Item name="type" hidden>
            <Input />
          </Form.Item>
          <ContentContainer>
            <FormCard>
              <CardTitle>僱主信息</CardTitle>
              <Row gutter={[16, 4]}>
                <Col span={12}>
                  <Form.Item
                    name="contactPerson"
                    label="聯絡人姓名"
                    rules={[{ required: true, message: "請輸入聯絡人姓名" }]}
                  >
                    <Input placeholder="聯絡人姓名" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="relation"
                    label="與病人關係"
                    rules={[{ required: true, message: "請輸入與病人關係" }]}
                  >
                    <Input placeholder="與病人關係" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="code" label="社區劵編號">
                    <Input placeholder="社區劵編號" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="codePercentage" label="社區劵百分比">
                    <InputNumber
                      placeholder="社區劵百分比"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </FormCard>

            <FormCard>
              <CardTitle>聯絡信息</CardTitle>
              <Row gutter={[16, 4]}>
                <Col span={12}>
                  <Form.Item
                    name="contactNumber"
                    label="聯絡電話"
                    rules={[{ required: true, message: "請輸入聯絡電話" }]}
                  >
                    <Input placeholder="聯絡電話" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="email" label="電郵">
                    <Input placeholder="電郵" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="district"
                    label="服務地區"
                    rules={[{ required: true, message: "請選擇服務地區" }]}
                  >
                    <District />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="address"
                    label="完整服務地址"
                    rules={[{ required: true, message: "請輸入完整服務地址" }]}
                  >
                    <Input placeholder="完整服務地址" />
                  </Form.Item>
                </Col>
              </Row>
            </FormCard>

            <FormCard>
              <CardTitle>UniCare備註</CardTitle>
              <Row gutter={[16, 4]}>
                <Col span={12}>
                  <Form.Item
                    name="personInCharge"
                    label="負責員工"
                    rules={[{ required: true, message: "請選擇負責員工" }]}
                  >
                    <AdminSelect placeholder="負責員工" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="remark" label="備註">
                    <Input.TextArea
                      placeholder="備註"
                      autoSize={{ minRows: 2, maxRows: 6 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </FormCard>

            <FormCard>
              <CardTitle>病人信息</CardTitle>
              <Row gutter={[16, 4]}>
                <Col span={12}>
                  <Form.Item
                    name="patientName"
                    label="病人姓名"
                    rules={[{ required: true, message: "請輸入病人姓名" }]}
                  >
                    <Input placeholder="病人姓名" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="patientIdCardNum"
                    label="香港身分證/護照號碼 (頭4位)"
                    rules={[
                      { required: true, message: "請輸入香港身分證/護照號碼" },
                    ]}
                  >
                    <Input placeholder="香港身分證/護照號碼" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="patientGender"
                    label="性別"
                    rules={[{ required: true, message: "請輸入性別" }]}
                  >
                    <Radio.Group>
                      <Radio value={1}>女性</Radio>
                      <Radio value={0}>男性</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="patientAge"
                    label="年齡"
                    rules={[{ required: true, message: "請輸入年齡" }]}
                  >
                    <InputNumber min={0} max={150} placeholder="年齡" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="patientHeight"
                    label="身高(cm)"
                    rules={[{ required: true, message: "請輸入身高" }]}
                  >
                    <InputNumber min={0} max={300} placeholder="身高(cm)" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="patientWeight"
                    label="體重(kg)"
                    rules={[{ required: true, message: "請輸入體重" }]}
                  >
                    <InputNumber min={0} max={1000} placeholder="體重(kg)" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="patientDisease" label="疾病">
                    <Checkbox.Group>
                      <Row>
                        <Col span={6}>
                          <Checkbox value="中風">中風</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="糖尿病">糖尿病</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="高血壓">高血壓</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="心臟病">心臟病</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="肝炎">肝炎</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="傳染病">傳染病</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="腎衰竭">腎衰竭</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="cancer" label="癌症">
                    <Input placeholder="請註明器官/位置" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="otherDisease" label="其他疾病">
                    <Input placeholder="請註明" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="throatPipe" label="喉管">
                    <Checkbox.Group>
                      <Row>
                        <Col span={6}>
                          <Checkbox value="胃喉">胃喉</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="尿喉">尿喉</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="PEG">PEG</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="氣管造口">氣管造口</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="氧氣喉">氧氣喉</Checkbox>
                        </Col>
                        <Col span={6}>
                          <Checkbox value="洗肚喉管">洗肚喉管</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="otherPipe" label="其他喉管">
                    <Input placeholder="請註明" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="bodyCondition"
                    label="身體狀況"
                    rules={[{ required: true, message: "請輸入身體狀況" }]}
                  >
                    <Radio.Group>
                      <Radio value="穩定健康">穩定健康</Radio>
                      <Radio value="情況穩定">情況穩定</Radio>
                      <Radio value="情況不穩定">情況不穩定</Radio>
                      <Radio value="情況危重">情況危重</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="mentalCondition"
                    label="精神狀況"
                    rules={[{ required: true, message: "請輸入精神狀況" }]}
                  >
                    <Radio.Group>
                      <Radio value="清醒">清醒</Radio>
                      <Radio value="嗜睡/呆滯">嗜睡/呆滯</Radio>
                      <Radio value="混亂/對答糊塗">混亂/對答糊塗</Radio>
                      <Radio value="昏迷/不能回應">昏迷/不能回應</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="ability"
                    label="活動能力"
                    rules={[{ required: true, message: "請輸入活動能力" }]}
                  >
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value="經常下床走動">經常下床走動</Radio>
                        <Radio value="需要協助才可行走">需要協助才可行走</Radio>
                        <Radio value="僅限坐姿(輪椅)">僅限坐姿(輪椅)</Radio>
                        <Radio value="不能移動(無法移動肢體或身體，不能轉換姿勢)">
                          不能移動(無法移動肢體或身體，不能轉換姿勢)
                        </Radio>
                        <Radio value="非常限制(需協助轉換姿勢，在協助下才可移動肢體或身體)">
                          非常限制(需協助轉換姿勢，在協助下才可移動肢體或身體)
                        </Radio>
                        <Radio value="少許限制(可控制及移動肢體或身體，但有限制，需協助轉換姿勢)">
                          少許限制(可控制及移動肢體或身體，但有限制，需協助轉換姿勢)
                        </Radio>
                        <Radio value="沒有限制(經常可獨立移動肢體或身體)">
                          沒有限制(經常可獨立移動肢體或身體)
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="incontinence"
                    label="失禁"
                    rules={[{ required: true, message: "請輸入失禁" }]}
                  >
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value="沒有">沒有</Radio>
                        <Radio value="大小便失禁(不能控制大小便，約 7-10 次/天)">
                          大小便失禁(不能控制大小便，約 7-10 次/天)
                        </Radio>
                        <Radio value=" 通常小便失禁(小便約 3-6 次小便失禁/天，或過去 24小時腹瀉)">
                          通常小便失禁(小便約 3-6 次小便失禁/天，或過去 24
                          小時腹瀉)
                        </Radio>
                        <Radio value=" 間中(約 1-2 次大便或小便失禁/天，但不是因為利尿藥/瀉藥，有尿喉但可克制大便)">
                          間中(約 1-2
                          次大便或小便失禁/天，但不是因為利尿藥/瀉藥，有尿喉但可克制大便)
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="mainCare" label="主要護理">
                    <Checkbox.Group>
                      <Row>
                        <Col span={5}>
                          <Checkbox value="洗肚">洗肚</Checkbox>
                        </Col>
                        <Col span={5}>
                          <Checkbox value="洗血">洗血</Checkbox>
                        </Col>
                        <Col span={5}>
                          <Checkbox value="沖涼/抹身">沖涼/抹身</Checkbox>
                        </Col>
                        <Col span={5}>
                          <Checkbox value="轉身">轉身</Checkbox>
                        </Col>
                        <Col span={4}>
                          <Checkbox value="餵食">餵食</Checkbox>
                        </Col>
                        <Col span={5}>
                          <Checkbox value="抽痰">抽痰</Checkbox>
                        </Col>
                        <Col span={5}>
                          <Checkbox value="洗傷口">洗傷口</Checkbox>
                        </Col>
                        <Col span={5}>
                          <Checkbox value="換尿片">換尿片</Checkbox>
                        </Col>
                        <Col span={5}>
                          <Checkbox value="簡單運動">簡單運動</Checkbox>
                        </Col>
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="injection" label="打針">
                    <Input placeholder="註明藥物名稱" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="oralDrugs" label="口服藥物">
                    <Input placeholder="註明藥物名稱" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="otherNursing" label="其他護理">
                    <Input placeholder="請註明" />
                  </Form.Item>
                </Col>
              </Row>
            </FormCard>
          </ContentContainer>
        </Form>
      </PageContainer>
    </Profiler>
  );
};


const ContentContainer = styled.div`
  padding: 3.2rem;
`;

const FormCard = styled.div`
  padding: 2.4rem;
  background-color: #ffffff;
  margin-bottom: 2.4rem;
`;

const CardTitle = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
`;

const InputLabel = styled.div`
  color: #3c3e42;
  font-size: 20px;
  font-weight: bold;
`;
