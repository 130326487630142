import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Dropdown, Menu } from "antd";
import styled from "@emotion/styled";
import { CommonList } from "components/listing/common-list";
import { useDebounce, useDocumentTitle } from "utils";
import { ColumnsType } from "antd/es/table";
import { Row } from "components/lib";
import { Profiler } from "components/profiler";
import { useEmployers } from "../../../utils/employer";
import { useUrlQueryPaging } from "../../../utils/paging";
import { SearchPanel } from "./search-panel";
import { useEmployerSearchParams } from "../../../search-utils/util";
import { Employer } from "types/employer";
import { employerStatus } from "utils/common";
import useWindowSize from "hooks/useWindowSize";
import PageContainer from "../../../components/layout/PageContainer";

export const EmployerListScreen = () => {
  useDocumentTitle("僱主列表", false);
  const navigate = useNavigate();
  const { windowHeight } = useWindowSize();

  const [param, setParam] = useEmployerSearchParams();
  const [pageObject, setPageObject] = useUrlQueryPaging();

  const { isLoading, error, data, retry } = useEmployers(
    useDebounce(param, 500),
    pageObject
  );

  useEffect(() => {
    setPageObject({
      pageSize: pageObject.pageSize,
      pageNumber: 1,
    });
  }, [param.district, param.status, param.keyword]);

  const createEmployerMenu = (
    <Menu>
      <Menu.Item>
        <div onClick={() => navigate("/create_company_employer")}>
          院舍/公司
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => navigate("/create_individual_employer")}>個人</div>
      </Menu.Item>
    </Menu>
  );

  const columns: ColumnsType<Employer> = [
    {
      title: "僱主編號",
      dataIndex: "employerNo",
      key: "employerNo",
      render: (text, record) => <a href={`/employer/${record.uuid}`}>{text}</a>,
    },
    {
      title: "僱主",
      dataIndex: "contactPerson",
      key: "contactPerson",
      render: (text, row) => {
        let returnText = row.companyName ? row.companyName : row.contactPerson;

        if (row.patientName) {
          returnText += ` (${row.patientName})`
        }
        return returnText;
      }
        ,
    },
    {
      title: "用戶狀態",
      dataIndex: "status",
      key: "status",
      render: (text, row) => employerStatus(row.status),
    },
    {
      title: "電話",
      dataIndex: "contactNumber",
      key: "contactNumber",
    },
    {
      title: "負責人",
      dataIndex: ["personInCharge", "username"],
      key: "personInCharge",
    },
  ];

  return (
    <Profiler id={"僱主列表"}>
      <PageContainer>
        <SearchPanel param={param} setParam={setParam} />
        <ContentContainer>
          <Row marginBottom={2} between={true}>
            <h1>僱主列表</h1>
            <Dropdown
              overlay={createEmployerMenu}
              placement="bottomRight"
              arrow
            >
              <Button type="primary">＋創建僱主</Button>
            </Dropdown>
          </Row>

          <CommonList
            refresh={retry}
            tableProps={{
              loading: isLoading,
              columns,
              dataSource: data?.list || [],
              pagination: false,
              scroll: {
                y: windowHeight - 320,
              }
            }}
            paginationProps={{
              pageSize: pageObject.pageSize,
              current: pageObject.pageNumber,
              total: data?.total || 0,
              onChange: (page, pageSize) => {
                setPageObject({
                  pageSize,
                  pageNumber: page,
                });
              }
            }}
          />
        </ContentContainer>
      </PageContainer>
    </Profiler>
  );
};


const ContentContainer = styled.div`
  padding: 3.2rem;
`;

const ListContainer = styled.div`
  overflow: auto;
  height: calc(100vh - 240px);
`;

EmployerListScreen.whyDidYouRender = false;
